import React, {useState, useEffect} from 'react';
import {Switch, Route, span, useLocation, useHistory, useParams } from "react-router-dom";

import Home from "./Home";
import BottomFooter from "./BottomFooter";
import ETHPage from "./ETHPage";
import Cloud from "./Cloud";
import Market from "./Market";
import Mine from "./Mine";
import NFT from "./NFT";
import NFTOrder from "./NFTOrder";
import Service from "./Service";
import Project from "./Project";
import Assets from "./Assets";
import UserSetting from "./UserSetting";
import UserDeposit from "./UserDeposit";
import UserElectricity from "./UserElectricity";
import UserHashrate from "./UserHashrate";
import UserInvite from "./UserInvite";
import UserOrder from "./UserOrder";
import UserWithdraw from "./UserWithdraw";
import Buy from "./Buy";
import Download from "./Download";
import About from "./About";
import './App.css';
import appServices from './services';
import { useMiracleObserver, useMiracleObserverHot, initStore, getStore, createNotifyStation, SubscribeMatchType } from 'miracle-observer';

import stations from './stations';
import Business from "./Business";
import Calculator from "./Calculator";
import Contact from "./Contact";
import Privacy from "./Privacy";
import Notice from "./Notice";
import NoticeList from "./NoticeList";
import anonymous from './anonymous';
import Termsandconditions from "./Termsandconditions";
import ALEO from "./ALEO";
import Kas from "./Kas";
import MessageList from "./Message";
import Filecoin from "./Filecoin";
import FIL from "./FIL";
const { stationDefault } = stations;

function usePageViews(setLocationUrl, locationUrl) {
    let location = useLocation();

    if (setLocationUrl) {

    }

    React.useEffect(() => {
        const pathname = window.location.pathname;
        stationDefault.notify('getBannerIndex', pathname);
        if (getStore('search')) {
            initStore(false, 'search');
        }
        if (pathname.indexOf("project") >= 0) {
            appServices.queryProductListID(parseInt(window.location.pathname.split('/')[2], 10));
        } else if (pathname === '/mine') {
            appServices.queryProductList(getStore("minSelect"));
        } else if (pathname === '/eth') {
            appServices.getETHPro();
            if (getStore("AccountId")) {
                appServices.getETHScore();
                appServices.getETHScoreDaily();
            }
        } else if (pathname === '/assets') {
            if (getStore("AccountId")) {
                appServices.getAccountAssets();
                appServices.getAccountVip();
            }
        } else if (pathname === '/user') {
            if (getStore("AccountId")) {
                appServices.getAccountAddress();
            }
        }
    }, [location]);
}

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
  // example
  store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);

appServices.getSystemSetting();
appServices.getNoticeList("CN");

export default function App() {
    anonymous.anonymous();
    const [language, setLanguage] = useState(() => {
        return getStore('language');
    });
    const [inviteCode] = useState(() => {
        return getStore('inviteCode');
    });
    const [loginEmail, setLoginEmail] = useState('');
    const [errorOpen, setErrorOpen] = useState(false);
    const [depositOpen, setDepositOpen] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);
    const [tlOpen, setTlOpen] = useState(false);
    const [vxOpen, setVXOpen] = useState(false);
    const [itemOpen, setItemOpen] = useState(false);
    const [buyOpen, setBuyOpen] = useState(0);
    const [errorMsg, setErrorMsg] = useState('');
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [codeTime, setCodeTime] = useState(0);
    const [password, setPassword] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [w3wOpen, setW3wOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(getStore('inviteCode') ? 1 : 0);
    const [registerCode, setRegisterCode] = useState("");
    const [registerOpen, setRegisterOpen] = useState(false);
    const [locationUrl, setLocationUrl] = useState('/');
    const [bannerIndex, setBannerIndex] = useState(() => {
        if (getStore('bannerIndex') === '/fil' || getStore('bannerIndex') === '/download' || getStore('bannerIndex') === '/eth' || getStore('bannerIndex') ===  '/aleo' || getStore('bannerIndex') ===  '/cloud' ||  getStore('bannerIndex') ===  '/service' || getStore('bannerIndex') ===  '/nftOrder' || getStore('bannerIndex') === '/nft'|| getStore('bannerIndex') === '/project'|| getStore('bannerIndex') === '/mine' || getStore('bannerIndex') === '/app') {
            return getStore('bannerIndex');
        } else {
            if (getStore('bannerIndex') !== "/") {
                setTimeout(() => {
                    history.push('/');
                }, 100);
            }
            return ('/');
        }
    });
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [noticeMove, setNoticeMove] = useState(0);
    const [noticeList, setNoticeList] = useState([]);

    if (!window.NoticeClick) {
        window.NoticeClick = true;
        setInterval(() => {
            const value = (getStore("noticeMove") >= getStore("NoticeList").length -1) ? 0 : (getStore("noticeMove") + 1);
            stationDefault.notify('noticeMove', value);
        }, 3000);
    }
    
    let history = useHistory();
    usePageViews(setLocationUrl, locationUrl);
    
    useMiracleObserver(
        'setBannerIndex,loginSuccess,setLoginOpen,RegisterSuccess,setPostError,NoticeList,noticeMove,userBuyBtcProductSuccess,getBannerIndex',
        (key, value, store) => {
            if (key === 'setBannerIndex') {
                setBannerIndex(value);
            }
            else if (key === 'userBuyBtcProductSuccess') {
                setBuyOpen(value)
            }
            else if (key === 'noticeMove') {
                setNoticeMove(value)
            }
            else if (key === 'getBannerIndex') {
                let open = (value ==='/assets' || value ==='/message' || value ==='/user' || value ==='/hashrate' || value ==='/withdraw' || value ==='/invite' || value ==='/deposit' || value ==='/electricity' || value ==='/order');
                setW3wOpen(open)
                if (value === "/" || value === "/mine"  || value === "/kas" || value === '/fil' || value === "/aleo" || value === '/service') {
                    setBannerIndex(value)
                }
            }
            else if (key === 'loginSuccess') {
                setCode("");
                setLoginOpen(0);
                setLoginEmail(document.getElementById("emailInput").value);
                appServices.getAccountAssets();
                appServices.queryProductBuyList();
                appServices.getAccountVip();
                appServices.getAccountAddress();
                // appServices.getETHScore();
                appServices.getMessageList(getStore("language") === "CN" ? "CH" : "OTHER");
                appServices.updateLang(getStore("language") === "CN" ? "CH" : "OTHER");
                if (window.location.pathname === '/eth') {
                    appServices.getETHScoreDaily();
                }
            }
            else if (key === 'NoticeList') {
                setNoticeList(value)
            }
            else if (key === 'RegisterSuccess') {
                setLoginOpen(2);
                setCode("");
                if (value) {
                    setRegisterOpen(true);
                    setRegisterCode(value);
                    document.getElementById('googleCodeScan').innerText = "";
                    const qart = new window.QRCode(document.getElementById('googleCodeScan'),`otpauth://totp/${document.getElementById("emailInput").value}?secret=${value}&issuer=Mintsat` );
                }
            }
            else if (key === 'setLoginOpen') {
                setLoginOpen(value);
            }
            else if (key === 'setPostError') {
                setErrorOpen(true);
                const msg = value.code && languageInfo["PostError" + value.code + getStore('language')] ? languageInfo["PostError" + value.code + getStore('language')] : value.message || JSON.stringify(value);
                if (msg.length > 200) {
                    setErrorMsg(languageInfo["Error" + value.code + getStore('language')]);
                } else {
                    setErrorMsg(msg);
                }

                if (value.deposit) {
                    setDepositOpen(true)
                } else {
                    setDepositOpen(false)
                }
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

  return (
      <div className={"rootContent" + (w3wOpen ? " scrollbar W3Wscrollbar" : "")}>
        <div className="mobileTap mobileShow">
              <div className="flexCenter" style={{ width: 160, height: "100%" }}>
                  <div
                      className="mobileIcon"
                      onClick={() => {
                          history.push('/');
                          document.getElementById("root").scrollTo(0,0);
                          setBannerIndex('/')
                      }}
                  >
                      <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MobileHome.png" style={{ height: 80 }} />
                  </div>
              </div>
              <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/phone/image/logoTitle.png" style={{ height: 28 }} />
              <div className="flexCenter" style={{ height: "100%" }}>
                  <div className="mobileIcon" onClick={() => { setLanguageOpen(true) }}>
                      <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MobileLanguage.png" style={{ height: 80 }} />
                  </div>
                  <div className="mobileIcon" onClick={() => { setItemOpen(true) }}>
                      <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MobileList.png" style={{ height: 80 }} />
                  </div>
              </div>
          </div>
        <div onClick={() => { setLanguageOpen(false) }} className="mobileList mobileShow" style={{ pointerEvents: languageOpen ? 'all' : 'none', background:  languageOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)' }}>
          <div style={{ height: 120, width: "100%" }}></div>
          <div className="flexBetween" >
              <div style={{ width: '50%' }}></div>
              <div className="mobileListBlock" style={languageOpen ? { right: -1 } : {}}>
                  <div onClick={(e) => { setLanguage("CN"); initStore("CN", "language"); e.stopPropagation(); setLanguageOpen(false) }}>繁体中文</div>
                  <hr />
                  <div onClick={(e) => { setLanguage("EN"); initStore("EN", "language"); appServices.getNoticeList("EN"); e.stopPropagation(); setLanguageOpen(false)  }}>English</div>
              </div>
          </div>
        </div>
        <div onClick={() => { setItemOpen(false) }} className="mobileList mobileShow" style={{ pointerEvents: itemOpen ? 'all' : 'none', background:  itemOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)' }}>
              <div style={{ height: 120, width: "100%" }}></div>
              <div className="flexBetween">
                  <div style={{ width: '50%' }}></div>
                  <div className="mobileListBlock" style={itemOpen ? { right: -1 } : {}}>
                      <div
                          onClick={(e) => {
                              history.push('/download');
                              document.getElementById("root").scrollTo(0,0);
                              setBannerIndex('/download');
                              e.stopPropagation();
                              setItemOpen(false);
                          }}
                      >
                          <div>{languageInfo["download" + language]}</div>
                      </div>
                      <hr />
                      <div
                           onClick={
                               (e) => {
                                   history.push('/mine');
                                   document.getElementById("root").scrollTo(0,0);
                                   setBannerIndex('/mine')
                                   e.stopPropagation();
                                   setItemOpen(false);
                               }}
                      >
                          <span>{languageInfo["MobileCloud" + language]}</span>
                      </div>
                      <hr style={{ opacity: 0 }} />
                      <div
                          onClick={(e) => {
                              history.push('/fil');
                              document.getElementById("root").scrollTo(0,0);
                              setBannerIndex('/fil')
                              e.stopPropagation();
                              setItemOpen(false);
                          }}
                      >
                          <span>FIL</span>
                      </div>
                      <hr style={{ opacity: 0 }} />
                      <div
                          onClick={(e) => {
                              history.push('/aleo');
                              document.getElementById("root").scrollTo(0,0);
                              setBannerIndex('/aleo')
                              e.stopPropagation();
                              setItemOpen(false);
                          }}
                      >
                          <span>Aleo</span>
                      </div>
                      <hr style={{ opacity: 0 }} />
                      <div
                          onClick={(e) => {
                              history.push('/kas');
                              document.getElementById("root").scrollTo(0,0);
                              setBannerIndex('/kas')
                              e.stopPropagation();
                              setItemOpen(false);
                              appServices.getMiners()
                          }}
                      >
                          <span>Kaspa</span>
                      </div>
                      <hr style={{ opacity: 0 }} />
                      <div
                          onClick={(e) => {
                              history.push('/nft');
                              document.getElementById("root").scrollTo(0,0);
                              setBannerIndex('/nft')
                              e.stopPropagation();
                              setItemOpen(false);
                          }}
                      >
                          <span>NFT</span>
                      </div>
                      <hr style={{ opacity: 0 }} />
                      <hr style={{ opacity: 0 }} />
                      <div
                          onClick={(e) => {
                              history.push('/service');
                              document.getElementById("root").scrollTo(0,0);
                              setBannerIndex('/service')
                              e.stopPropagation();
                              setItemOpen(false);
                          }}
                      >
                          <span>{languageInfo["Institutional" + language]}</span>
                      </div>
                      <hr />

                      <div
                          onClick={(e) => {
                              history.push('/privacy');
                              document.getElementById("root").scrollTo(0,0);
                              stationDefault.notify('setBannerIndex', '/privacy');
                              e.stopPropagation();
                              setItemOpen(false);
                          }}
                      >
                          {languageInfo["Support4" + getStore("language")]}
                      </div>
                      <hr style={{ opacity: 0 }} />
                      <div
                          onClick={(e) => {
                              history.push('/termsandconditions');
                              document.getElementById("root").scrollTo(0,0);
                              stationDefault.notify('setBannerIndex', '/termsandconditions');
                              e.stopPropagation();
                              setItemOpen(false);
                          }}
                      >
                          {languageInfo["Support5" + getStore("language")]}
                      </div>

                      <hr />

                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <a target="_blank" style={{ height: 48, marginRight: 48, color: '#ffffff', textDecoration: 'none' }} href="https://twitter.com/Mintsat_Offical">
                              <img
                                  style={{ height: '100%' }}
                                  src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/app3icon.png"
                                  alt=""
                              />
                          </a>
                          {/*
                          <div onClick={() => { setTlOpen(true) }} style={{ height: 48, marginRight: 48, color: '#ffffff',  cursor: 'pointer' }}>
                              <img
                                  style={{ height: '100%' }}
                                  src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/app1icon.png"
                                  alt=""
                              />
                          </div>
                          */}
                          <div onClick={() => { setVXOpen(true) }} style={{ height: 56, color: '#ffffff',  cursor: 'pointer', position: 'relative', left: 7, top: 7 }}>
                              <img
                                  style={{ height: '100%' }}
                                  src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/app6icon.png"
                                  alt=""
                              />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="webShow" style={{ height: 42 }}>
              <div className="topLine">
                  <div className="flexCenter" style={{ padding: "0 12px", height: 24, width: 360,overflow: 'hidden', borderRadius: 12, backgroundColor: 'rgba(255,255,255,0.2)', justifyContent: 'space-between' }}>
                      <img style={{ marginRight: 8, marginBottom: 2, height: 16 }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/notice.png" />
                      <div style={{ width: 200, height: 24,flex: 1, transitionDuration: '0.5s', marginTop: noticeMove * -48 }}>
                          {noticeList.map(item => (
                              <div
                                  onClick={() => {
                                      stationDefault.notify('notice', item);
                                      history.push('/notice');
                                      document.getElementById("root").scrollTo(0,0);
                                      setBannerIndex('/notice')
                                  }}
                                  key={`noticeList${item.id}`}
                                  className="notice canClick"
                              >
                                  {JSON.parse(item.message).title}
                              </div>
                          ))}
                      </div>
                      <div
                          onClick={() => {
                              history.push('/noticeList');
                              document.getElementById("root").scrollTo(0,0);
                          }}
                          className="canClick"
                          style={{ fontSize: 12, color: '#ffffff', textDecoration: 'underline' }}
                      >
                          {languageInfo["MoreAnnouncement" + getStore("language")]}
                      </div>
                  </div>
                  <div style={{flex: 1 }}></div>
                  <div
                      className={'languageBox'}
                      onClick={() => {
                          history.push('/download');
                          document.getElementById("root").scrollTo(0,0);
                          setBannerIndex('/download')
                      }}
                  >
                      <div className="canClick displayFlex" style={{ position: 'relative', height: 30  }}>
                          <span>{languageInfo["download" + language]}</span>
                      </div>
                  </div>
                  <div className="languageBox">
                      <div className="canClick displayFlex" style={{ position: 'relative', height: 30  }}>
                          <img style={{ height: 15, marginRight: 8 }}  src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MobileLanguage.png" />
                          <span>{language === "CN" ? '繁体中文' : 'English'} </span>
                          <img style={{ marginLeft: 8, marginBottom: 2 }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/languageDown.png" />

                          <div className="hoverShow" style={{  boxShadow: "0 0 4px #ccc",position: 'absolute', top: 0,left: -16, width: 100, marginTop: 30, fontSize: 13, fontWeight: 'lighter' }}>
                              <div className="list" onClick={() => { setLanguage("CN"); initStore("CN", "language"); appServices.getNoticeList("CN"); }} style={{ padding: '12px 16px' }}>繁体中文</div>
                              <div className="list" onClick={() => { setLanguage("EN"); initStore("EN", "language"); appServices.getNoticeList("EN");  }} style={{ padding: '12px 16px' }}>English</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="webShow" style={{ height: 70 }}>
              <div className={"navStyle"}>
                  <div className="flexCenter" style={{ flex: 1, justifyContent: 'center' }}>
                      <img
                          className="appLogin"
                          onClick={
                              () => {
                                  history.push('/');
                                  document.getElementById("root").scrollTo(0,0);
                                  setBannerIndex('/')
                              }}
                          alt=""
                          src={"https://amintsat.s3.ap-southeast-1.amazonaws.com/image/title.png"}
                      />
                      <div className={'navBox'+(bannerIndex==='/mine'?' navShadow':'')}>
                          <div className={'navSelect'}
                               onClick={
                                   () => {
                                       history.push('/mine');
                                       document.getElementById("root").scrollTo(0,0);
                                       setBannerIndex('/mine')
                                   }}
                          >
                              <span>{languageInfo["Cloud" + language]}</span>
                          </div>
                          <div className="navShow">

                          </div>
                      </div>
                      {/*<div className={'navBox'+(bannerIndex==='/eth'?' navShadow':'')}>
                        <div
                            className={'navSelect'}
                            onClick={() => {
                                history.push('/eth');
                                document.getElementById("root").scrollTo(0,0);
                                setBannerIndex('/eth')
                            }}
                        >
                            <span>ETH2.0</span>
                        </div>
                        <div className="navShow">

                        </div>
                    </div>*/}
                      <div className={'navBox'+(bannerIndex==='/fil'?' navShadow':'')}>
                          <div
                              className={'navSelect'}
                              onClick={() => {
                                  history.push('/fil');
                                  document.getElementById("root").scrollTo(0,0);
                                  setBannerIndex('/fil')
                              }}
                          >
                              <span>FIL</span>
                          </div>
                          <div className="navShow">

                          </div>
                      </div>
                      <div className={'navBox'+(bannerIndex==='/aleo'?' navShadow':'')}>
                          <div
                              className={'navSelect'}
                              onClick={() => {
                                  history.push('/aleo');
                                  document.getElementById("root").scrollTo(0,0);
                                  setBannerIndex('/aleo')
                              }}
                          >
                              <span>Aleo</span>
                          </div>
                          <div className="navShow">

                          </div>
                      </div>
                      <div className={'navBox'+(bannerIndex==='/kas'?' navShadow':'')}>
                          <div
                              className={'navSelect'}
                              onClick={() => {
                                  history.push('/kas');
                                  document.getElementById("root").scrollTo(0,0);
                                  setBannerIndex('/kas')
                                  appServices.getMiners()
                              }}
                          >
                              <span>Kaspa</span>
                          </div>
                          <div className="navShow">

                          </div>
                      </div>
                      <div className={'navBox'+(bannerIndex==='/nft'?' navShadow':'')}>
                            <div
                                className={'navSelect'}
                                onClick={() => {
                                    history.push('/nft');
                                    document.getElementById("root").scrollTo(0,0);
                                    setBannerIndex('/nft')
                                }}
                            >
                                <span>NFT</span>
                            </div>
                            <div className="navShow">

                            </div>
                      </div>
                      {/*<div className={'navBox'+(bannerIndex==='/market'?' navShadow':'')}>
                            <div
                                className={'navSelect'}
                                onClick={() => {
                                    history.push('/market');
                                    document.getElementById("root").scrollTo(0,0);
                                    setBannerIndex('/market')
                                }}
                            >
                                <span>{languageInfo["Market" + language]}</span>
                            </div>
                            <div className="navShow">

                            </div>
                        </div>*/}

                      <div className={'navBox'+(bannerIndex==='/service'?' navShadow':'')}>
                          <div
                              onClick={() => {
                                  history.push('/service');
                                  document.getElementById("root").scrollTo(0,0);
                                  setBannerIndex('/service')
                              }}
                              className={'navSelect'}
                          >
                              <span>{languageInfo["Institutional" + language]}</span>
                          </div>
                          <div className="navShow">

                          </div>
                      </div>
                  </div>

                  <div className={'navBox'}>
                      <div
                          onClick={() => {
                              if (!loginEmail) {
                                  setLoginOpen(2)
                              }
                          }}
                          className="canClick displayFlex"
                          style={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: 20, height: 30  }}
                      >
                          <img
                              style={{ marginRight: 4, marginBottom: -1, height: 15 }}
                              alt=""
                              src={"https://amintsat.s3.ap-southeast-1.amazonaws.com/image/user.png"}
                          />
                          <span>{loginEmail ? languageInfo["PersonalCenter" + language] : languageInfo["login" + language]}</span>

                          {loginEmail ?
                              <div className="hoverShow" style={{ boxShadow: "0 0 4px #ccc",position: 'absolute', top: 0,left: -16, width: 100, marginTop: 30, fontSize: 13, fontWeight: 'lighter' }}>
                                  <div className="list" onClick={() => {
                                      history.push('/assets');
                                      document.getElementById("root").scrollTo(0,0);
                                      setBannerIndex('/assets')
                                  }} style={{ padding: '12px 16px' }}>{languageInfo["Homepage" + language]}</div>
                                  <div className="list" onClick={() => { setLoginEmail(""); initStore("", "AccountId"); history.push('/'); setBannerIndex('/')  }} style={{ padding: '12px 16px' }}>{languageInfo["SignOut" + language]}</div>
                              </div>
                              :
                              null
                          }
                      </div>
                  </div>
              </div>
          </div>
        <div style={w3wOpen ? { display: 'flex', padding: '2% 8% 4%', boxSizing: 'border-box', minHeight: '100vh' } : {}}>
            {w3wOpen  ? //
                <div className="navLeftTab">
                    <div style={{ padding: '16px 8px', textAlign: 'center' }}>
                        <img style={{ width: 80 }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserIcon.png" />
                        <div style={{ marginTop: 12 }}>{loginEmail}</div>
                    </div>
                    <div
                        onClick={() => {
                            history.push('/assets');
                            document.getElementById("root").scrollTo(0,0);
                            setBannerIndex('/assets')
                            // appServices.queryProductBuyList();
                        }}
                        className={"leftTab canClick " + (bannerIndex === '/assets' ? 'tabSelect' : '')}
                    >
                        <img style={{ width: 20, margin: '0 20px' }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserAssets.png" />
                        <span>{languageInfo["assets" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            history.push('/user');
                            document.getElementById("root").scrollTo(0,0);
                            setBannerIndex('/user')
                        }}
                        className={"leftTab canClick " + (bannerIndex === '/user' ? 'tabSelect' : '')}
                    >
                        <img style={{ width: 20, margin: '0 20px' }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserSetting.png" />
                        <span>{languageInfo["user" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            history.push('/hashrate');
                            document.getElementById("root").scrollTo(0,0);
                            setBannerIndex('/hashrate')
                            appServices.queryProductSum();
                        }}
                        className={"leftTab canClick " + (bannerIndex === '/hashrate' ? 'tabSelect' : '')}
                    >
                        <img style={{ width: 20, margin: '0 20px' }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserHashrate.png" />
                        <span>{languageInfo["hashrate" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            history.push('/order');
                            document.getElementById("root").scrollTo(0,0);
                            setBannerIndex('/order');
                        }}
                        className={"leftTab canClick " + (bannerIndex === '/order' ? 'tabSelect' : '')}
                    >
                        <img style={{ width: 20, margin: '0 20px' }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserOrder.png" />
                        <span>{languageInfo["order" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            history.push('/electricity');
                            document.getElementById("root").scrollTo(0,0);
                            setBannerIndex('/electricity');
                            appServices.queryProductIncomeList();
                            appServices.queryLog("TransPower");
                        }}
                        className={"leftTab canClick " + (bannerIndex === '/electricity' ? 'tabSelect' : '')}
                    >
                        <img style={{ width: 20, margin: '0 20px' }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserElectricity.png" />
                        <span>{languageInfo["electricity" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            history.push('/deposit');
                            document.getElementById("root").scrollTo(0,0);
                            setBannerIndex('/deposit')
                            appServices.getAccountStoreIn();
                        }}
                        className={"leftTab canClick " + (bannerIndex === '/deposit' ? 'tabSelect' : '')}
                    >
                        <img style={{ width: 20, margin: '0 20px' }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserDeposit.png" />
                        <span>{languageInfo["deposit" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            history.push('/withdraw');
                            document.getElementById("root").scrollTo(0,0);
                            setBannerIndex('/withdraw')
                            appServices.getAccountStoreOut();
                        }}
                        className={"leftTab canClick " + (bannerIndex === '/withdraw' ? 'tabSelect' : '')}
                    >
                        <img style={{ width: 20, margin: '0 20px' }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserWithdraw.png" />
                        <span>{languageInfo["withdraw" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            history.push('/invite');
                            document.getElementById("root").scrollTo(0,0);
                            setBannerIndex('/invite');
                            appServices.getInviteIncome();
                            appServices.queryVipInvite();
                            appServices.getAccountVip();
                        }}
                        className={"leftTab canClick " + (bannerIndex === '/invite' ? 'tabSelect' : '')}
                    >
                        <img style={{ width: 20, margin: '0 20px' }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserInvite.png" />
                        <span>{languageInfo["invite" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            history.push('/message');
                            document.getElementById("root").scrollTo(0,0);
                            setBannerIndex('/message')
                            appServices.updateMessage();
                            stationDefault.notify('UnreadCount', 0);
                        }}
                        className={"leftTab canClick " + (bannerIndex === '/message' ? 'tabSelect' : '')}
                    >
                        <img style={{ width: 20, margin: '0 20px' }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserNotice.png" />
                        <span>{languageInfo["message" + getStore("language")]}</span>
                        <div style={{ padding: "2px 6px", background: "#8833ff", borderRadius: '50%', marginLeft: 4, color: '#fff', fontSize: 13, opacity: getStore("UnreadCount") > 0 ? 1 : 0 }}>{getStore("UnreadCount")}</div>
                    </div>
                </div>
                :
                null
            }
            <Switch>
                <Route path="/eth">
                    <ETHPage />
                </Route>
                <Route path="/mine">
                    <Mine />
                </Route>
                <Route path="/nft">
                    <NFT />
                </Route>
                <Route path="/nftOrder">
                    <NFTOrder />
                </Route>
                <Route path="/cloud">
                    <Cloud />
                </Route>
                <Route path="/market">
                    <Market />
                </Route>
                <Route path="/service">
                    <Service />
                </Route>
                <Route path="/download">
                    <Download />
                </Route>
                <Route path="/assets">
                    <Assets />
                </Route>
                <Route path="/user">
                    <UserSetting />
                </Route>
                <Route path="/hashrate">
                    <UserHashrate />
                </Route>
                <Route path="/withdraw">
                    <UserWithdraw />
                </Route>
                <Route path="/invite">
                    <UserInvite />
                </Route>
                <Route path="/deposit">
                    <UserDeposit />
                </Route>
                <Route path="/electricity">
                    <UserElectricity />
                </Route>
                <Route path="/order">
                    <UserOrder />
                </Route>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/business">
                    <Business />
                </Route>
                <Route path="/calculator">
                    <Calculator />
                </Route>
                <Route path="/contact">
                    <Contact />
                </Route>
                <Route path="/privacy">
                    <Privacy />
                </Route>
                <Route path="/notice">
                    <Notice />
                </Route>
                <Route path="/noticeList">
                    <NoticeList />
                </Route>
                <Route path="/message">
                    <MessageList />
                </Route>
                <Route path="/filecoin">
                    <Filecoin />
                </Route>
                <Route path="/aleo">
                    <ALEO />
                </Route>
                <Route path="/kas">
                    <Kas />
                </Route>
                <Route path="/fil">
                    <FIL />
                </Route>
                <Route path="/termsandconditions">
                    <Termsandconditions />
                </Route>
                <Route path="/project/:id">
                    <Project />
                </Route>
                <Route path="/buy/:id">
                    <Buy />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </div>
        {bannerIndex === '/project' ?
            null
            :
            <BottomFooter />
        }
        <div className="scaleOpen" style={{ pointerEvents: loginOpen !== 0 ? 'all' : 'none', background:  loginOpen !== 0 ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
            <div
                className="loginBg"
                style={{
                    opacity: loginOpen !== 0 ? 1 : 0, transform: loginOpen !== 0 ? 'scale(1)' :'scale(0.8)',
                }}
            >
                <img
                    className="canClick"
                    style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                    src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                    alt=""
                    onClick={() => {
                        setLoginOpen(0);
                    }}
                />
                <div className="flexCenter">
                    <img alt="" style={{ height: 36, marginRight: 16 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserIcon.png" />
                    <div style={{ fontWeight: 600 }}>{languageInfo["Welcome" + getStore("language")]} MintSat</div>
                </div>
                <div className="AppInput">
                    <div style={{ flex: 1, borderRadius: 8, position: 'relative' }}>
                        {!email ?
                            <div style={{ padding: '0 12px',color: '#c3c3c3',opacity: 0.5, position: 'absolute', display: 'flex', alignItems: 'center', height: '100%', top: 0, pointerEvents: 'none' }}>
                                <span style={{ fontSize: 16 }}>{languageInfo["emailInput" + getStore("language")]}</span>
                            </div>
                            :
                            null
                        }

                        <input
                            id="emailInput"
                            value={email}
                            onInput={(e)=> {
                                setEmail(e.target.value);
                            }}
                            className="inputStyle canClick"
                        />
                    </div>
                </div>
                {loginOpen === 1 || loginOpen === 3 ?
                    <div className="AppInput">
                        <div style={{ flex: 1, borderRadius: 8, position: 'relative' }}>
                            {!code ?
                                <div style={{ padding: '0 12px',color: '#c3c3c3',opacity: 0.5, position: 'absolute', display: 'flex', alignItems: 'center', height: '100%', top: 0, pointerEvents: 'none' }}>
                                    <span style={{ fontSize: 16 }}>{languageInfo["codeInput" + getStore("language")]}</span>
                                </div>
                                :
                                null
                            }

                            <input
                                value={code}
                                onInput={(e)=> {
                                    setCode(e.target.value);
                                }}
                                className="inputStyle canClick"
                            />
                        </div>

                        <div
                            className="canClick newHomeBtn"
                            onClick={() => {
                                if (codeTime <= 0) {
                                    appServices.postCode(email);
                                    setCodeTime(60);
                                    if (window.codeInterval) {
                                        clearInterval(window.codeInterval);
                                    }
                                    window.codeInterval = setInterval(() => {
                                        if (document.getElementById("codeTime")) {
                                            let time = parseInt(document.getElementById("codeTime").innerHTML, 10);
                                            if (time > 0) {
                                                if (time === 1) {
                                                    clearInterval(window.codeInterval);
                                                }

                                                setCodeTime(time - 1);
                                            } else {
                                                clearInterval(window.codeInterval);
                                            }
                                        }
                                    }, 1000);
                                }
                            }}
                            style={{
                                opacity: codeTime === 0 ? 1 : 0.7,
                                width: 100,
                                padding: 0,
                                lineHeight: '36px',
                                fontSize: 13,
                                marginLeft: 16,
                                marginBottom: 6
                            }}
                        >
                            {codeTime === 0 ?
                                <span id="codeTime">{languageInfo["SendCode" + getStore("language")]}</span>
                                :
                                <span id="codeTime" style={{fontSize: 20}}>{codeTime}</span>
                            }
                        </div>
                    </div>
                    :
                    null
                }

                <div className="AppInput">
                    <div style={{ flex: 1, borderRadius: 8, position: 'relative' }}>
                        {!password ?
                            <div style={{ padding: '0 12px',color: '#c3c3c3',opacity: 0.5, position: 'absolute', display: 'flex', alignItems: 'center', height: '100%', top: 0, pointerEvents: 'none' }}>
                                <span style={{ fontSize: 16 }}>{loginOpen === 3 ? languageInfo["NewPassword" + getStore("language")] : loginOpen === 1 ? languageInfo["SettingPassword" + getStore("language")] :  languageInfo["inputPassword" + getStore("language")]}</span>
                            </div>
                            :
                            null
                        }

                        <input
                            id="passwordInput"
                            type={!passwordShow ? "password" : "text"}
                            value={password}
                            onInput={(e)=> {
                                setPassword(e.target.value);
                            }}
                            className="inputStyle canClick"
                            onKeyDown={(e) => {
                                var evt = window.event || e;
                                if (evt.keyCode === 13) {
                                    if (loginOpen === 3) {
                                        appServices.userForgetPassword(email, password, code);
                                    } else if (loginOpen === 1) {
                                        appServices.userRegister(email, password, code, inviteCode);
                                    } else {
                                        appServices.userLogin(email, password, code);
                                    }
                                }
                            }}
                        />
                    </div>
                    <img className="canClick" onClick={() => { setPassword('') }} alt="" style={{  height: 20, padding: 12 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClear.png" />
                    <img className="canClick" onClick={() => { setPasswordShow(!passwordShow) }} alt="" style={{ width: 24 }} src={passwordShow ? "https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserShow.png" : "https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserHide.png"} />
                </div>

                {/*{loginOpen === 2 ?
                    <div className="AppInput">
                        <div style={{ flex: 1, borderRadius: 8, position: 'relative' }}>
                            {!code ?
                                <div style={{ padding: '0 12px',color: '#c3c3c3',opacity: 0.5, position: 'absolute', display: 'flex', alignItems: 'center', height: '100%', top: 0, pointerEvents: 'none' }}>
                                    <span style={{ fontSize: 16 }}>{languageInfo["googleCode" + getStore("language")]}</span>
                                </div>
                                :
                                null
                            }

                            <input
                                value={code}
                                onInput={(e)=> {
                                    setCode(e.target.value);
                                }}
                                className="inputStyle canClick"
                            />
                        </div>
                        {registerCode !== '' ?
                            <div
                                className="canClick newHomeBtn"
                                onClick={() => {
                                    setRegisterOpen(true);
                                }}
                                style={{width: 100, padding: 0, borderRadius: 4, lineHeight: '36px', fontSize: 13, marginLeft: 16}}
                            >
                                <span>{languageInfo["copyKey" + getStore("language")]}</span>
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }*/}

                {loginOpen === 2 ?
                    <div className="flexBetween" style={{ fontSize: 16 }}>
                        <div style={{ textAlign: "left", padding: '16px 0px' }} onClick={() => { setLoginOpen(1); }} className="colorClick">
                            <span>{languageInfo["noAccount" + getStore("language")]}?</span>
                            <span style={{ color: '#8833ff', marginLeft: 8 }}>{languageInfo["SignUpNow" + getStore("language")]}</span>
                        </div>
                        <div onClick={() => { history.push('/contact'); }} className="colorClick">
                            <span>{languageInfo["Information2" + getStore("language")]}</span>
                        </div>
                    </div>
                    :
                    null
                }
                {loginOpen === 3 ?
                    <div
                        className="loginBtn canClick"
                        onClick={() => {
                            appServices.userForgetPassword(email, password, code);
                        }}
                    >
                        {languageInfo["ResetPassword" + getStore("language")]}
                    </div>
                    :
                    loginOpen === 1 ?
                        <div
                            className="loginBtn canClick"
                            onClick={() => {
                                appServices.userRegister(email, password, code, inviteCode);
                            }}
                        >
                            {languageInfo["Register" + getStore("language")]}
                        </div>
                        :
                        <div
                            className="loginBtn canClick"
                            onClick={() => {
                                appServices.userLogin(email, password, code);
                            }}
                        >
                            {languageInfo["Login" + getStore("language")]}
                        </div>
                }
                <div style={{ color: '#959595', fontSize: 16, paddingTop: 12 }}>
                    {loginOpen !== 2 ?
                        <div style={{ padding: '6px 14px'}} onClick={() => {
                            setLoginOpen(2);
                        }} className="colorClick">
                            {languageInfo["PasswordLogin" + getStore("language")]}
                        </div>
                        :
                        <div style={{minWidth: 180, padding: '6px 14px'}} onClick={() => {
                            setLoginOpen(3);
                        }} className="colorClick">
                            <span>{languageInfo["ResetPassword" + getStore("language")]}</span>
                        </div>
                    }
                </div>
            </div>
        </div>
        <div className="scaleOpen" style={{ pointerEvents: registerOpen ? 'all' : 'none', background:  registerOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
            <div
                className="loginBg"
                style={{
                    transitionDuration: '0.5s', opacity: registerOpen ? 1 : 0, transform: registerOpen ? 'scale(1)' :'scale(0.8)',
                    width: 500,
                    height: 360,
                    background: '#ffffff'
                }}
            >
                <img
                    className="canClick"
                    style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                    src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                    alt=""
                    onClick={() => {
                        setRegisterOpen(false);
                    }}
                />
                <div className="flexCenter">
                    <div style={{ fontWeight: 600 }}>{languageInfo["RegistrationSuccess" + getStore("language")]}</div>
                </div>

                <div style={{ textAlign: 'left', marginTop: 20 }}>
                    {languageInfo["AuthenticatorFirst" + getStore("language")]}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                    <div
                        style={{ width: '100%', borderRadius: 48, marginRight: 16 }}
                        className="loginBtn canClick"
                        onClick={() => {
                            window.open("https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=US")
                        }}
                    >
                        Android
                    </div>
                    <div
                        style={{ width: '100%', borderRadius: 48 }}
                        className="loginBtn canClick"
                        onClick={() => {
                            window.open("https://apps.apple.com/cn/app/google-authenticator/id388497605")
                        }}
                    >
                        IOS
                    </div>
                </div>
                <div style={{ textAlign: 'left' }}>
                    {languageInfo["AuthenticatorSecond" + getStore("language")]}
                </div>
                <div id="googleCodeScan" className="scanCode"></div>
                <div className="AppInput">
                    <div style={{ flex: 1, fontWeight: 700, textAlign: 'left' }}>{registerCode}</div>
                    <textarea style={{ opacity: 0, height: 1, padding: 0 , border: 0, position: 'absolute',top: 0, left: 0 }} id="googleCode" readOnly="readonly">Copy Success</textarea>

                    <div
                        className="canClick flexCenter"
                        onClick={() => {
                            var e = document.getElementById("googleCode");
                            e.value = registerCode;
                            e.select(); //
                            document.execCommand("Copy");
                        }}
                    >
                        <span style={{ color: '#8833ff' }}>{languageInfo["copyKey" + getStore("language")]}</span>
                        <img
                            className="canClick"
                            style={{  width: 20, height: 20, padding: 4 }}
                            alt=""
                            src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/Copy.png"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="scaleOpen" style={{ pointerEvents: errorOpen ? 'all' : 'none', background:  errorOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 12 }}>
              <div
                  style={{
                      transitionDuration: '0.5s', opacity: errorOpen ? 1 : 0, transform: errorOpen ? 'scale(1)' :'scale(0.8)',
                      position: 'relative', background: "#ffffff", fontSize: 20, color: '#1d1d27', display: 'flex', flexDirection: 'column',
                      width: 520, height: 300, marginTop: 50, textAlign: 'left', borderRadius: 24, overflow: 'hidden' }}
              >
                  <div className="flexCenter" style={{ height: "100%", justifyContent: 'center', flexDirection: 'column' }}>
                      <img
                          className="canClick"
                          style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                          src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                          alt=""
                          onClick={() => {
                              setErrorOpen(false);
                          }}
                      />
                      <div style={{ padding: 24, wordBreak: 'break-all', textAlign: 'center' }}>
                          {errorMsg}
                      </div>
                      {depositOpen ?
                          <div
                              className="loginBtn canClick"
                              onClick={() => {
                                  history.push('/deposit');
                                  document.getElementById("root").scrollTo(0,0);
                                  setBannerIndex('/deposit')
                                  appServices.getAccountStoreIn();
                                  setErrorOpen(false);
                              }}
                          >
                              {languageInfo["Recharge" + getStore("language")]}
                          </div>
                          :
                          null
                      }
                  </div>
              </div>
          </div>
        <div className="scaleOpen" style={{ pointerEvents: buyOpen ? 'all' : 'none', background:  buyOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 12 }}>
              <div
                  style={{
                      transitionDuration: '0.5s', opacity: buyOpen ? 1 : 0, transform: buyOpen ? 'scale(1)' :'scale(0.8)',
                      position: 'relative', background: "#ffffff", fontSize: 20, color: '#1d1d27', display: 'flex', flexDirection: 'column',
                      width: 540, height: 320, marginTop: 50, textAlign: 'left', borderRadius: 24, overflow: 'hidden' }}
              >
                  <div className="flexCenter" style={{ height: "100%", justifyContent: 'center' }}>
                      <img
                          className="canClick"
                          style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                          src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                          alt=""
                          onClick={() => {
                              setBuyOpen(0);
                          }}
                      />
                      <div style={{ padding: 24, wordBreak: 'break-all', textAlign: 'center', fontSize: 14 }}>
                          <div className="displayFlex" style={{ textAlign: 'left' }}>
                              <img alt="" style={{ height: 36, marginRight: 16 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserIcon.png" />
                              <div style={{ fontWeight: 600, fontSize: 20 }}>{languageInfo["BuySuccess1" + getStore("language")]}</div>
                          </div>
                          <div style={{ marginTop: 12 }}>{languageInfo["BuySuccess3" + getStore("language")]}{languageInfo["Plan" + getStore("language")]}/{getStore("productInfo").productName}/{getStore("productInfo").productSustainTime}{languageInfo["Days" + getStore("language")]}</div>
                          <div>
                              { getStore("productInfo").outputType === "FIL" ?
                                  <img alt="" style={{ height: 120, margin: 16 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/buyFILPop.png" />
                                  :
                                  <img alt="" style={{ height: 120, margin: 16 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/buyPop.png" />
                              }
                          </div>
                          <div>{languageInfo["BuySuccess2" + getStore("language")]}</div>
                          <div style={{ color: '#8833ff', marginTop: 6 }}>mintsatbusiness@gmail.com</div>
                      </div>
                  </div>
              </div>
          </div>

        <div onClick={() => { setTlOpen(false) }} className="scaleOpen" style={{ pointerEvents: tlOpen ? 'all' : 'none', background:  tlOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 12 }}>
          <div
              style={{
                  transitionDuration: '0.5s', opacity: tlOpen ? 1 : 0, transform: tlOpen ? 'scale(1)' :'scale(0.8)',
                  position: 'relative', display: 'flex', flexDirection: 'column',
                  width: 500, height: 500, marginTop: 50 }}
          >
              <div className="flexCenter" style={{ height: "100%", justifyContent: 'center' }}>
                  <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/mintsatTL.png" alt="" style={{ width: "100%" }} />
              </div>
          </div>
      </div>
        <div onClick={() => { setVXOpen(false) }} className="scaleOpen" style={{ pointerEvents: vxOpen ? 'all' : 'none', background:  vxOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 12 }}>
              <div
                  style={{
                      transitionDuration: '0.5s', opacity: vxOpen ? 1 : 0, transform: vxOpen ? 'scale(1)' :'scale(0.8)',
                      position: 'relative', display: 'flex', flexDirection: 'column',
                      width: 500, height: 500, marginTop: 50 }}
              >
                  <div className="flexCenter" style={{ height: "100%", justifyContent: 'center' }}>
                      <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/mintsatVX.png" alt="" style={{ width: "100%" }} />
                  </div>
              </div>
          </div>
      </div>
  );
}
