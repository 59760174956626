import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function UserHashrate(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [orderType, setOrderType] = useState(0);
    const [selectType, setSelectType] = useState("BTC");
    const [productBuyList, setProductBuyList] = useState(() => {
        return getStore('productBuyList');
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [outputOpen, setOutputOpen] = useState(0);
    const [productIncomeList, setProductIncomeList] = useState(() => {
        return getStore('productIncomeList');
    });
    const [buyObj, setBuyObj]  = useState(() => {
        return getStore('buyObj');
    });
    const [productSum, setProductSum] = useState(() => {
        return getStore('productSum');
    });
    const [realIncome, setRealIncome] = useState(() => {
        return getStore('productSum').realIncome ? (getStore('productSum').realIncome + "").replace(/^(.*\..{6}).*$/,"$1") : 0;
    });
    const [productOpen, setProductOpen] = useState(false);
    const [productInfo, setProductInfo] = useState({ buyTime: "" });



    useMiracleObserver(
        'productBuyList,updateProductBuyBtcSuccess,queryProductIncomeList,productSum,buyObj',
        (key, value, store) => {
            if (key === 'productBuyList') {
                setProductBuyList(value);
            } else if (key === 'buyObj') {
                setBuyObj(value);
            } else if (key === 'updateProductBuyBtcSuccess') {
                searchList()
            } else if (key === 'queryProductIncomeList') {
                setProductIncomeList(value);
            } else if (key === 'productSum') {
                if (value.realIncome) {
                    setRealIncome((value.realIncome + "").replace(/^(.*\..{6}).*$/,"$1"))
                } else {
                    setRealIncome(0);
                }
                setProductSum(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    const clickProduct = (item) => {
        setProductOpen(true);
        setProductInfo(item);
    }

    const searchList = () => {
        appServices.queryProductBuyList(["","begin","end"][orderType],selectType);
    }

    const updateVale = (value) => {
        setSelectType(value);
        appServices.queryProductBuyList(["","begin","end"][orderType],value);
    }

    function returnUnit() {
        if (productInfo.productType === "FIL") {
            return ""
        } else {
            return "$"
        }
    }

    function returnCoin() {
        if (productInfo.productType === "FIL") {
            return "FIL"
        } else {
            return ""
        }
    }

    return (
        <div className="userContent">
            <div className="displayFlex">
                <div onClick={() => { updateVale("BTC") }} className="hashrateTab" style={{ background: selectType === "BTC" ? 'linear-gradient(277deg,#313545,#121118)' : '#ffffff', opacity:  selectType === "BTC" ? 1 : 0.5, color: selectType === "BTC" ? "#ffffff" : "#333333" }}>
                    <div className="displayFlex" style={{ padding: '12px'  }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerBTC.png" alt="" style={{ height: 30 }} />
                        <div className="MineFont">BTC</div>
                    </div>
                </div>
                <div onClick={() => { updateVale("FIL") }} className="hashrateTab" style={{ background: selectType === "FIL" ? 'linear-gradient(277deg,#313545,#121118)' : '#ffffff', opacity:  selectType === "FIL" ? 1 : 0.5, color: selectType === "FIL" ? "#ffffff" : "#333333" }}>
                    <div className="displayFlex" style={{ padding: '12px'  }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerFIL.png" alt="" style={{ height: 30 }} />
                        <div className="MineFont">FIL</div>
                    </div>
                </div>
                <div onClick={() => { updateVale("KAS") }} className="hashrateTab" style={{ background: selectType === "KAS" ? 'linear-gradient(277deg,#313545,#121118)' : '#ffffff', opacity:  selectType === "KAS" ? 1 : 0.5, color: selectType === "KAS" ? "#ffffff" : "#333333" }}>
                    <div className="displayFlex" style={{ padding: '12px'  }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserKaspa.png" alt="" style={{ height: 30 }} />
                        <div className="MineFont">KAS</div>
                    </div>
                </div>
                <div onClick={() => { updateVale("ALEO") }} className="hashrateTab" style={{ background: selectType === "ALEO" ? 'linear-gradient(277deg,#313545,#121118)' : '#ffffff', opacity:  selectType === "ALEO" ? 1 : 0.5, color: selectType === "ALEO" ? "#ffffff" : "#333333" }}>
                    <div className="displayFlex" style={{ padding: '12px'  }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerALEO.png" alt="" style={{ height: 30 }} />
                        <div className="MineFont">Aleo</div>
                    </div>
                </div>
            </div>
            <div style={{ padding: '12px 0%' }}>
                {selectType === "BTC" ?
                    <div className="flexCenter" style={{ alignItems: 'stretch' }}>
                        <div className="orderCard">
                            <div style={{ color: '#a3a3a3', fontSize: 14 }}>BTC {languageInfo["TotalPower" + getStore("language")]}</div>
                            <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{productSum.productBuyAmount}</span>TH/s</div>
                            <div style={{ textAlign: 'right', marginTop: 8 }}>
                                <span style={{ padding: '6px 12px', background: '#f5f3fe', borderRadius: 8, fontSize: 13 }} className="canClick"
                                      onClick={() => {
                                          setOrderType(1);
                                          appServices.queryProductBuyList("begin",selectType);
                                      }}>{languageInfo["PackageAllocation" + getStore("language")]}</span>
                            </div>
                        </div>
                        <div className="orderCard">
                            <div style={{ color: '#a3a3a3', fontSize: 14 }}>BTC {languageInfo["TotalOutput" + getStore("language")]}</div>
                            <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{productSum.incomeAmount ? (productSum.incomeAmount + "").replace(/^(.*\..{6}).*$/,"$1") : 0}</span>BTC</div>
                            <div style={{ textAlign: 'right', marginTop: 8 }}>
                            <span
                                style={{ padding: '6px 12px', background: '#f5f3fe', borderRadius: 8, fontSize: 13 }}
                                className="canClick"
                                onClick={() => {
                                    setModalOpen(true)
                                    appServices.queryProductIncomeList({ btc: true });
                                }}
                            >{languageInfo["MoreOutputData" + getStore("language")]}></span>
                            </div>
                        </div>
                        <div className="orderCard">
                            <div style={{ color: '#a3a3a3', fontSize: 14 }}>BTC {languageInfo["CumulativeNetOutput" + getStore("language")]}</div>
                            <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{realIncome}</span>BTC</div>
                        </div>
                    </div>
                    :
                    selectType === 'KAS' ? null :
                    selectType === 'ALEO' ?
                        <div className="flexCenter" style={{ alignItems: 'stretch' }}>
                            <div className="orderCard">
                                <div style={{ color: '#a3a3a3', fontSize: 14 }}>ALEO {languageInfo["Quantity" + getStore("language")]}</div>
                                <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{productSum.AleoProductBuyAmount}</span></div>
                                <div style={{ textAlign: 'right', marginTop: 8 }}>
                                <span style={{ padding: '6px 12px', background: '#f5f3fe', borderRadius: 8, fontSize: 13 }} className="canClick"
                                      onClick={() => {
                                          setOrderType(1);
                                          appServices.queryProductBuyList("begin",selectType);
                                      }}>{languageInfo["PackageAllocation" + getStore("language")]}</span>
                                </div>
                            </div>
                            <div className="orderCard">
                                <div style={{ color: '#a3a3a3', fontSize: 14 }}>ALEO {languageInfo["TotalOutput" + getStore("language")]}</div>
                                <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{productSum.AleoIncomeAmount ? (productSum.AleoIncomeAmount + "").replace(/^(.*\..{6}).*$/,"$1") : 0}</span>Aleo</div>
                                <div style={{ textAlign: 'right', marginTop: 8 }}>
                            <span
                                style={{ padding: '6px 12px', background: '#f5f3fe', borderRadius: 8, fontSize: 13 }}
                                className="canClick"
                                onClick={() => {
                                    setModalOpen(true)
                                    appServices.queryProductIncomeList({ aleo: true });
                                }}
                            >{languageInfo["MoreOutputData" + getStore("language")]}></span>
                                </div>
                            </div>
                            <div className="orderCard" style={{ opacity: 0 }}>
                                <div style={{ color: '#a3a3a3', fontSize: 14 }}>ALEO {languageInfo["TotalOutput" + getStore("language")]}</div>
                                <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{productSum.AleoIncomeAmount ? (productSum.AleoIncomeAmount + "").replace(/^(.*\..{6}).*$/,"$1") : 0}</span>Aleo</div>
                            </div>
                        </div>
                        :
                        <div className="flexCenter" style={{ alignItems: 'stretch' }}>
                            <div className="orderCard">
                                <div className="flexCenter">
                                    <div style={{ color: '#a3a3a3', fontSize: 14, width: '50%' }}>FIL {languageInfo["TotalPower" + getStore("language")]}</div>
                                    <div style={{ color: '#a3a3a3', fontSize: 14, width: "50%" }}>FIL {languageInfo["Stake" + getStore("language")]}</div>
                                </div>
                                <div className="flexCenter" style={{ marginTop: 8 }}>
                                    <div style={{ color: '#1d1d27', width: "50%" }}><span style={{ marginRight: 8, fontSize: 24 }}>{productSum.FilProductBuyAmount || 0}</span>T</div>
                                    <div style={{ color: '#1d1d27', width: "50%" }}><span style={{ marginRight: 8, fontSize: 24 }}>{getStore("Assets").frozenFil || 0}</span>FIL</div>
                                </div>
                                <div style={{ textAlign: 'right', marginTop: 8 }}>
                                <span style={{ padding: '6px 12px', background: '#f5f3fe', borderRadius: 8, fontSize: 13 }} className="canClick"
                                      onClick={() => {
                                          setOrderType(1);
                                          appServices.queryProductBuyList("begin",selectType);
                                      }}>{languageInfo["PackageAllocation" + getStore("language")]}</span>
                                </div>
                            </div>
                            <div className="orderCard">
                                <div style={{ color: '#a3a3a3', fontSize: 14 }}>FIL {languageInfo["TotalOutput" + getStore("language")]}</div>
                                <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{productSum.FilIncomeAmount ? (productSum.FilIncomeAmount + "").replace(/^(.*\..{6}).*$/,"$1") : 0}</span>FIL</div>
                                <div style={{ textAlign: 'right', marginTop: 8 }}>
                                    <span
                                        style={{ padding: '6px 12px', background: '#f5f3fe', borderRadius: 8, fontSize: 13 }}
                                        className="canClick"
                                        onClick={() => {
                                            setOutputOpen(1)
                                            appServices.queryProductIncomeList({ fil: true });
                                        }}
                                    >{languageInfo["MoreOutputData" + getStore("language")]}></span>
                                </div>
                            </div>
                            <div className="orderCard">
                                <div style={{ color: '#a3a3a3', fontSize: 14 }}>FIL {languageInfo["Lockup" + getStore("language")]}</div>
                                <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{productSum.FilFrozenIncomeAmount &&  Math.abs(productSum.FilFrozenIncomeAmount) > 0.0000001 ? (productSum.FilFrozenIncomeAmount + "").replace(/^(.*\..{6}).*$/,"$1") : 0}</span>FIL</div>
                                <div style={{ textAlign: 'right', marginTop: 8 }}>
                                    <span
                                        style={{ padding: '6px 12px', background: '#f5f3fe', borderRadius: 8, fontSize: 13 }}
                                        className="canClick"
                                        onClick={() => {
                                            setOutputOpen(2)
                                            appServices.queryProductIncomeList({ fil: true });
                                        }}
                                    >{languageInfo["MoreOutputData" + getStore("language")]}></span>
                                </div>
                            </div>
                            <div className="orderCard">
                                <div style={{ color: '#a3a3a3', fontSize: 14 }}>FIL {languageInfo["Issued" + getStore("language")]}</div>
                                <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{productSum.FilProduce ? (productSum.FilProduce + "").replace(/^(.*\..{6}).*$/,"$1") : 0}</span>FIL</div>
                                <div style={{ textAlign: 'right', marginTop: 8 }}>
                            <span
                                style={{ padding: '6px 12px', background: '#f5f3fe', borderRadius: 8, fontSize: 13 }}
                                className="canClick"
                                onClick={() => {
                                    setModalOpen(true)
                                    appServices.queryProductIncomeList({ fil: true });
                                }}
                            >{languageInfo["MoreOutputData" + getStore("language")]}></span>
                                </div>
                            </div>
                        </div>
                }
                <div className="flexCenter">
                    <div
                        onClick={() => {
                            if (orderType !== 0) {
                                setOrderType(0);
                                appServices.queryProductBuyList("",selectType);
                            }
                        }}
                        className="userTab canClick"
                        style={{ borderRadius: '8px 0 0 8px', borderRight: 0, paddingLeft: 36 }}
                    >
                        <div style={{ height: 3 }}></div>
                        <div style={{ color:  orderType === 0 ? "#222" : '#888' }}>{languageInfo["All" + getStore("language")]}</div>
                        <div style={{ height: 3, background: orderType === 0 ? '#be8fff' : 'transparent', borderRadius: 3 }}></div>
                    </div>
                    <div
                        onClick={() => {
                            if (orderType !== 1) {
                                setOrderType(1);
                                appServices.queryProductBuyList("begin",selectType);
                            }
                        }}
                        className="userTab canClick"
                        style={{ borderLeft: 0, borderRight: 0 }}
                    >
                        <div style={{ height: 3 }}></div>
                        <div style={{ color:  orderType === 1 ? "#222" : '#888' }}>{languageInfo["Serving" + getStore("language")]}</div>
                        <div style={{ height: 3, background: orderType === 1 ? '#be8fff' : 'transparent', borderRadius: 3 }}></div>
                    </div>
                    <div
                        onClick={() => {
                            if (orderType !== 2) {
                                setOrderType(2);
                                appServices.queryProductBuyList("end",selectType);
                            }
                        }}
                        className="userTab canClick"
                        style={{ borderRadius: '0 8px 8px 0', borderLeft: 0, paddingRight: 36 }}
                    >
                        <div style={{ height: 3 }}></div>
                        <div style={{ color:  orderType === 2 ? "#222" : '#888' }}>{languageInfo["Completed" + getStore("language")]}</div>
                        <div style={{ height: 3, background: orderType === 2 ? '#be8fff' : 'transparent', borderRadius: 3 }}></div>
                    </div>
                </div>


                <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden", marginTop: 16 }}>
                    <div className="flexCenter" style={{ height: 56, paddingLeft: 40, background: '#faf9fe', color: '#a3a3a3' }}>
                        <div style={{ width: "10%" }}>{languageInfo["OrderID" + getStore("language")]}</div>
                        <div style={{ width: "20%" }}>{languageInfo["ProductName" + getStore("language")]}</div>
                        <div style={{ width: "12.5%" }}>{languageInfo["Duration" + getStore("language")]}</div>
                        <div style={{ width: "12.5%" }}>{languageInfo["TotalOrder" + getStore("language")]}</div>
                        <div style={{ width: "20%" }}>{languageInfo["ElectricityType" + getStore("language")]}</div>
                        <div style={{ width: "15%" }}>{languageInfo["OrderStatus" + getStore("language")]}</div>
                        <div style={{ width: "15%" }}>{languageInfo["StartTime" + getStore("language")]}</div>
                    </div>
                    {productBuyList.map(item => ( item.outputType === selectType ?
                        <div onClick={() => { clickProduct(item); }} key={`1productBuyList${item.buyId}`} className="flexCenter canClick" style={{ height: 56, paddingLeft: 40, color: '#1d1d27', borderTop: "2px solid #faf9fe" }}>
                            <div style={{ width: "10%" }}>{item.buyId}</div>
                            <div style={{ width: "20%" }}>{item.productName}</div>
                            <div style={{ width: "12.5%" }}>{item.productDays}{languageInfo["Days" + getStore("language")]}{item.outputType.indexOf("FIL") >= 0 ? "+180" + languageInfo["Days" + getStore("language")] : ""}</div>
                            <div style={{ width: "12.5%" }}><span>{item.outputType.indexOf("FIL") >= 0 ? item.buyAmount + "T" : item.outputType === "BTC" || item.outputType === "KAS" ? item.buyAmount + "TH/s" : parseFloat(item.productHistory.split("/")[2]) * item.buyAmount + "c/s"}</span></div>
                            <div style={{ width: "20%" }}>
                                {item.status === "end" || item.productPower === 0 || item.freePower ?
                                    <span style={{ color: item.status === "end" ? "#cccccc" : '#8833ff' }}>{item.productPower === 0 || item.freePower ? languageInfo["free" + getStore("language")] : item.btcPower? languageInfo["PayText6" + getStore("language")] : languageInfo["PayText5" + getStore("language")]}</span>
                                    :
                                    <div style={{ background: item.btcPower? '#E85600' : '#ffb900', borderRadius: 4, textAlign: 'center', padding: '4px 8px', color: '#ffffff', boxShadow: '0px 0px 4px #757474', maxWidth: '78%', display: 'inline-block' }} className="canClick" onClick={(e) => { e.stopPropagation(); appServices.updateProductBuyBtc(item) }}>{item.btcPower? languageInfo["PayText6" + getStore("language")] : languageInfo["PayText5" + getStore("language")]}</div>
                                }
                            </div>
                            <div style={{ width: "15%" }}>{item.status === "end" ? languageInfo["Completed" + getStore("language")] : item.status === 'stop' ? languageInfo["Stop" + getStore("language")] : item.status === 'begin' ? languageInfo["Serving" + getStore("language")] : languageInfo["NoStart" + getStore("language")]}</div>
                            <div style={{ width: "15%" }}>{new Date(item.startTime).toLocaleDateString()}</div>
                        </div> : null
                    ))}
                </div>
            </div>

            <div className="scaleOpen" style={{ pointerEvents: modalOpen ? 'all' : 'none', background:  modalOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: modalOpen ? 1 : 0, transform: modalOpen ? 'scale(1)' :'scale(0.8)',
                        width: 640,
                        height: "60%",
                        background: '#ffffff',
                        marginTop: 0,
                        padding: '48px 20px 20px 30px',
                        fontSize: 14
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    />
                    <div className="scrollbar" style={{ paddingRight: 12 }}>
                        <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden", marginTop: 16 }}>
                            <div className="flexCenter" style={{ height: 56, background: '#faf9fe', color: '#a3a3a3' }}>
                                <div style={{ width: "15%" }}>{languageInfo["DateTime" + getStore("language")]}</div>
                                <div style={{ width: "20%" }}>{languageInfo["ProductName" + getStore("language")]}</div>
                                {/*<div style={{ width: "15%" }}>{languageInfo["TotalOrder" + getStore("language")]}</div>*/}
                                <div style={{ width: "35%" }}>{languageInfo["Return" + getStore("language")]}</div>
                                <div style={{ width: "30%" }}>{languageInfo["ManageFee" + getStore("language")]}</div>
                            </div>
                            {productIncomeList.map(item => (
                                <div key={`productIncomeList${item.incomeId}`} className="flexCenter canClick" style={{ height: 56, color: '#1d1d27', borderTop: "2px solid #faf9fe" }}>
                                    <div style={{ width: "15%" }}>{item.incomeTime.split("T")[0]}</div>
                                    <div style={{ width: "20%" }}>{buyObj[item.buyId] ? buyObj[item.buyId].productName : ""}</div>
                                    {/*<div style={{ width: "15%" }}><span>{buyObj[item.buyId] ? (buyObj[item.buyId].outputType.indexOf("FIL") >= 0 ? buyObj[item.buyId].buyAmount : buyObj[item.buyId].outputType === "BTC" ? buyObj[item.buyId].buyAmount + "TH/s" : parseFloat(buyObj[item.buyId].productHistory.split("/")[2]) * buyObj[item.buyId].buyAmount + "c/s") : ""}</span></div>*/}
                                    <div style={{ width: "35%" }}>
                                        {item.incomeAmount != item.powerBtc ?
                                            <span style={{ marginLeft: 12 }}>+{parseFloat(item.incomeAmount-item.powerBtc).toFixed(6)} {item.incomeIsEqual === "ALEO" ? "Aleo(Coinbase)" : item.incomeIsEqual === "income_is_equal" ? "BTC" : "FIL"}</span>                                            :
                                            <span style={{ color: '#f77e00' }}>{languageInfo["AutomaticDeduction" + getStore("language")]}{parseFloat(item.powerBtc).toFixed(6)}BTC</span>
                                        }
                                    </div>
                                    <div style={{ width: "30%" }}><span>{parseFloat(item.productManage).toFixed(6)}</span>{item.incomeIsEqual === "ALEO" ? "Aleo(Coinbase)" : item.incomeIsEqual === "income_is_equal" ? "BTC" : "FIL"}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="scaleOpen" style={{ pointerEvents: outputOpen !== 0 ? 'all' : 'none', background:  outputOpen !== 0  ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: outputOpen !== 0  ? 1 : 0, transform: outputOpen !== 0  ? 'scale(1)' :'scale(0.8)',
                        width: 640,
                        height: "60%",
                        background: '#ffffff',
                        marginTop: 0,
                        padding: '48px 20px 20px 30px',
                        fontSize: 14
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setOutputOpen(0);
                        }}
                    />
                    <div className="scrollbar" style={{ paddingRight: 12 }}>
                        <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden", marginTop: 16 }}>
                            <div className="flexCenter" style={{ height: 56, background: '#faf9fe', color: '#a3a3a3' }}>
                                <div style={{ width: "20%" }}>{languageInfo["DateTime" + getStore("language")]}</div>
                                <div style={{ width: "35%" }}>{languageInfo["ProductName" + getStore("language")]}</div>
                                {outputOpen === 1 ?
                                    <div style={{ width: "35%" }}>{languageInfo["Out" + getStore("language")]}</div>
                                    :
                                    null
                                }
                                {outputOpen === 2 ?
                                    <div style={{ width: "35%" }}>{languageInfo["Lockup" + getStore("language")]}</div>
                                    :
                                    null
                                }
                            </div>
                            {productIncomeList.map(item => (
                                <div key={`productIncomeList${item.incomeId}`} className="flexCenter canClick" style={{ height: 56, color: '#1d1d27', borderTop: "2px solid #faf9fe" }}>
                                    <div style={{ width: "20%" }}>{item.incomeTime.split("T")[0]}</div>
                                    <div style={{ width: "35%" }}>{buyObj[item.buyId] ? buyObj[item.buyId].productName : ""}</div>
                                    {outputOpen === 1 ?
                                        <div style={{ width: "35%" }}>{item.takeIncome > 0 ? "+" : ""}<span>{parseFloat(item.takeIncome).toFixed(6)}</span>{"FIL"}</div>
                                        :
                                        null
                                    }
                                    {outputOpen === 2 ?
                                        <div style={{ width: "35%" }}>{item.frozenIncome > 0 ? "+" : ""}<span>{parseFloat(item.frozenIncome).toFixed(6)}</span>{"FIL"}</div>
                                        :
                                        null
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="scaleOpen" style={{ pointerEvents: productOpen ? 'all' : 'none', background: productOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: productOpen ? 1 : 0, transform: productOpen ? 'scale(1)' :'scale(0.8)',
                        width: 880,
                        background: '#ffffff',
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setProductOpen(false);
                        }}
                    />
                    <div style={{ fontSize: 24, marginBottom: 20, position: 'relative' }}>
                        <div>{productInfo.productName}{languageInfo["Plan" + getStore("language")]}</div>
                    </div>
                    <div className="orderInfo">
                        <div className="buyItem">
                            <div>{languageInfo["OrderID" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.buyId}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["OrderStatus" + getStore("language")]}</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.status === "end" ? languageInfo["Completed" + getStore("language")] : productInfo.status === 'stop' ? languageInfo["Stop" + getStore("language")] : productInfo.status === 'begin' ? languageInfo["Serving" + getStore("language")] : languageInfo["NoStart" + getStore("language")]}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["TotalOrder" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.outputType === "FIL" ? productInfo.buyAmount + "T" : productInfo.outputType === "BTC" ? productInfo.buyAmount + "TH/s" : productInfo.outputType === "ALEO" ? parseFloat(productInfo.productHistory.split("/")[2]) * productInfo.buyAmount + "c/s" : ""}</div>
                        </div>

                        <div className="buyItem">
                            <div>{languageInfo["OrderDate" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{new Date(productInfo.buyTime).toLocaleDateString()}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["StartTime" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{new Date(productInfo.startTime).toLocaleDateString()}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["EndTime" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{new Date(new Date(productInfo.startTime).getTime() + productInfo.productDays * 24 * 60 * 60 * 1000).toLocaleDateString()}</div>
                        </div>
                        {productInfo.productType !== "NORMAL" && productInfo.productType !== "FREE" ?
                            productInfo.productType === "FIL" ?
                                null
                            :
                            <div className="buyItem">
                                <div>{languageInfo["PayText14" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}:</div>
                                <div style={{ marginLeft: 8 }}>{returnUnit()}{(productInfo.productPrice / productInfo.productDays).toFixed(4)}{returnCoin()}</div>
                            </div>
                            :
                            <div className="buyItem">
                                <div>{languageInfo["Runtime" + getStore("language")]}</div>
                                <div style={{ marginLeft: 8 }}>100%</div>
                                <div className="canClick" style={{ position: 'relative' }}>
                                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                    <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                        <div className="question">{languageInfo["RuntimeQuestion" + getStore("language")]}</div>
                                        <div className="questionArrow"></div>
                                        <div style={{ height: 30 }}></div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="buyItem">
                            <div>{languageInfo["Electricity" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}:</div>
                            {productInfo.productPower === 0 || productInfo.freePower ?
                                <div style={{ marginLeft: 8 }}>{languageInfo["free" + getStore("language")]}</div>
                                :
                                <div style={{ marginLeft: 8 }}>${productInfo.freePower ? 0 : productInfo.productPower}</div>
                            }
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["PayText12" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.productDays}{languageInfo["Days" + getStore("language")]}{productInfo.outputType === "FIL" ? "+180" + languageInfo["Days" + getStore("language")] : ""}</div>
                        </div>

                        <div className="buyItem">
                            <div>{languageInfo["PayText10" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}> {productInfo.outputType === "ALEO" ? "Aleo(Coinbase)" : productInfo.outputType}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["PayoutCycle" + getStore("language")]}</div>
                            <div style={{ marginLeft: 8 }}>{languageInfo["PayoutCycle1" + getStore("language")]}</div>
                        </div>

                        <div className="buyItem">
                            <div>{languageInfo["PayText16" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>Mintsat</div>
                        </div>
                        {productInfo.productType !== "NORMAL" && productInfo.productType !== "FREE" ?
                            <div className="buyItem">
                                <div>{languageInfo["Runtime" + getStore("language")]}</div>
                                <div style={{ marginLeft: 8 }}>100%</div>
                                <div className="canClick" style={{ position: 'relative' }}>
                                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                    <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                        <div className="question">{languageInfo["RuntimeQuestion" + getStore("language")]}</div>
                                        <div className="questionArrow"></div>
                                        <div style={{ height: 30 }}></div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {productInfo.productType !== "NORMAL" && productInfo.productType !== "FREE" ?
                            <div className="buyItem">
                                <div>{languageInfo["PayText18" + getStore("language")]}:</div>
                                <div style={{ marginLeft: 8 }}>{returnUnit()}{productInfo.productPrice * productInfo.buyAmount}{returnCoin()}</div>
                            </div>
                            :
                            null
                        }

                        <div className="buyItem">
                            <div>{languageInfo["Share" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.productManage}%</div>
                            <div className="canClick" style={{ position: 'relative' }}>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                    <div className="question">
                                        <span>{languageInfo["ShareQuestion1" + getStore("language")]}</span>
                                        <span>{100 - productInfo.productManage}%</span>
                                        <span>{languageInfo["ShareQuestion2" + getStore("language")]}</span>
                                    </div>
                                    <div className="questionArrow"></div>
                                    <div style={{ height: 30 }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserHashrate;