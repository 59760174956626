import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Home(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [amount, setAmount] = useState('');

    const toMiner = (value) => {
        document.getElementById("root").scrollTo(0,0);
        const select = JSON.parse(JSON.stringify(getStore('minSelect')));
        select.type = value;
        initStore(select, 'minSelect');
        history.push('/mine');
    }

    const toNFT = () => {
        history.push('/nft');
    }

    return (
        <div>
            <div className="mobilePaddingTop" style={{ position: 'relative', textAlign: 'center' }}>
                <img src="/image/NFTHomeBanner.jpg" alt="" className="banner ALEOBanner" />

                <div className="pageBanner">
                    <div className="BannerFont" style={{ fontWeight: 700,color: '#ffffff', fontSize: getStore("language") === 'CN' ? 56 : 48, letterSpacing:  getStore("language") === 'CN' ? 6 : 0 }}>
                        {languageInfo["NFTHomeTitle" + getStore("language")]}
                    </div>
                    <div className="BannerFont" style={{ fontWeight: 500, fontSize: 20,lineHeight: getStore("language") === 'CN' ? "32px" : "24px", letterSpacing: getStore("language") === 'CN' ? 2 : 0, margin: "30px 0", color: '#ffffff' }}>
                        {languageInfo["NFTHomeInfo" + getStore("language")]}
                    </div>
                    <div className="flexCenter">
                        <div className="CardBtn" onClick={toNFT} style={{ color: '#8833ff', background: '#ffffff', justifyContent: 'center', width: 120 }}>
                            <div>{languageInfo["Buy" + getStore("language")]}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <div className="homeVideo">
                    <div className="webShow" style={{ position: 'relative', height: '100%', width: '100%', overflow: 'hidden' }}>
                        <video style={{ objectFit: 'cover', zIndex: 1 }} width="100%" height="100%" autoPlay={true} loop={true} muted="muted" playsInline={true} object-fit="fill">
                            <source src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/HomeBanner.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="mobileShow" style={{ position: 'relative', height: '100%', width: '100%', overflow: 'hidden' }}>
                        <img
                            alt=""
                            src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/HomeBanner.gif"
                            style={{
                                objectFit: 'cover', zIndex: 1,
                                width: '100%', height: '100%'
                            }}
                        />
                    </div>
                </div>
                <div className="positionAbsolute" style={{ background: 'rgba(0,0,0,0.3)' }}>
                    <div className="bannerTitle" style={{ color: '#ffffff' }}>
                        <div className="homeTitle" style={{ fontWeight: 700, fontSize: getStore("language") === 'CN' ? 56 : 40,letterSpacing:  getStore("language") === 'CN' ? 6 : 0 }}>
                            {languageInfo["BannerTitle" + getStore("language")]}
                        </div>
                        <div className="homeTitle" style={{ fontWeight: 500,opacity: 0.8, fontSize: 20, letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginTop: 30 }}>
                            {languageInfo["BannerInfo" + getStore("language")]}
                        </div>
                        <div className="webShow" style={{ display: 'flex', justifyContent: 'start', textAlign: 'center', fontWeight: 'bold', fontSize: 16, marginTop: 40, width: "100%" }}>
                            <div style={{ background: '#ffffff', color: '#cccccc', borderRadius: 32, width: "40%", marginRight: 24 }}>
                                <input
                                    placeholder={languageInfo["InputEmail" + getStore("language")]}
                                    style={{ border: 'none', borderRadius: 36, flex: 1, fontSize: 16, padding: "12px 5%", background: 'transparent', color: '#cccccc', textAlign: 'left', width: "100%", boxSizing: 'border-box' }}
                                    onInput={(e)=> {
                                        setAmount(e.target.value);
                                    }}
                                    value={amount}
                                />
                            </div>
                            <div onClick={() => { stationDefault.notify('OpenScan', true); }} className="canClick" style={{ background: '#252B2B', color: '#ffffff', padding: 10, borderRadius: 32, minWidth: 100, marginRight: 24 }}>{languageInfo["Begin" + getStore("language")]}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ fontSize: 48, color: '#1d1d27', fontWeight: 'bold',textAlign: 'center', marginTop: 80 }}>{languageInfo["Complete" + getStore("language")]}</div>
            <div style={{ fontSize: 24, color: '#8f8f90', margin: 24,textAlign: 'center' }}>{languageInfo["CompleteRemark" + getStore("language")]}</div>
            <div className="HomeCardList">
                <div className="HomeCard">
                    <img className="CardImg" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/Project1.png" alt="" />
                    <div className="HomeCardContent">
                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>{languageInfo["Complete1" + getStore("language")]}</div>
                        <div style={{ marginTop: 8, color: '#7d7d7d' }}>{languageInfo["Complete1Remark" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div className="flexCenter webShow" style={{ justifyContent: 'center', paddingBottom: 20 }}>
                        <div className="CardBtn" onClick={() => {toMiner("BTC") }}>
                            <div style={{ fontSize: 13 }}>{languageInfo["SelectPlans" + getStore("language")]}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                        </div>
                    </div>
                    <div className="positionAbsolute mobileShow" onClick={() => { history.push('/mine'); document.getElementById("root").scrollTo(0,0); }}>

                    </div>
                </div>
                <div className="HomeCard">
                    <img className="CardImg" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/Project4.png" alt="" />
                    <div className="HomeCardContent">
                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>FIL</div>
                        <div style={{ marginTop: 8, color: '#7d7d7d' }}>{languageInfo["Complete4Remark" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>

                    <div className="flexCenter webShow" style={{ justifyContent: 'center', paddingBottom: 20 }}>
                        <div className="CardBtn" onClick={() => { history.push('/fil'); document.getElementById("root").scrollTo(0,0);  }}>
                            <div style={{ fontSize: 13 }}>{languageInfo["ProjectIntroduction" + getStore("language")]}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                        </div>
                    </div>
                    <div className="positionAbsolute mobileShow" onClick={() => { history.push('/fil'); document.getElementById("root").scrollTo(0,0); }}>

                    </div>
                </div>
                {/*<div className="HomeCard">
                    <img className="CardImg" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/Project2.png" alt="" />
                    <div className="HomeCardContent">
                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>ETH2.0</div>
                        <div style={{ marginTop: 8, color: '#7d7d7d' }}>{languageInfo["Complete2Remark" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>

                    <div className="flexCenter" style={{ justifyContent: 'center', paddingBottom: 20 }}>
                        <div className="CardBtn webShow" onClick={() => { history.push('/eth'); }}>
                            <div style={{ fontSize: 13 }}>{languageInfo["Now" + getStore("language")]}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                        </div>
                    </div>
                </div>*/}
                <div className="HomeCard">
                    <img className="CardImg" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/Project3.png" alt="" />
                    <div className="HomeCardContent">
                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>{languageInfo["Complete3" + getStore("language")]}</div>
                        <div style={{ marginTop: 8, color: '#7d7d7d' }}>{languageInfo["Complete3Remark" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>

                    <div className="flexCenter webShow" style={{ justifyContent: 'center', paddingBottom: 20 }}>
                        <div className="CardBtn" onClick={() => { history.push('/aleo'); document.getElementById("root").scrollTo(0,0);  }}>
                            <div style={{ fontSize: 13 }}>{languageInfo["ProjectIntroduction" + getStore("language")]}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                        </div>
                    </div>

                    <div className="positionAbsolute mobileShow" onClick={() => { history.push('/aleo'); document.getElementById("root").scrollTo(0,0); }}>

                    </div>
                </div>
                <div className="HomeCard">
                    <img className="CardImg" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/Project5.png" alt="" />
                    <div className="HomeCardContent">
                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>{languageInfo["Complete5" + getStore("language")]}</div>
                        <div style={{ marginTop: 8, color: '#7d7d7d' }}>{languageInfo["Complete5Remark" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div className="flexCenter webShow" style={{ justifyContent: 'center', paddingBottom: 20 }}>
                        <div className="CardBtn" onClick={() => { history.push('/service'); document.getElementById("root").scrollTo(0,0);  }}>
                            <div style={{ fontSize: 13 }}>{languageInfo["Inquiry" + getStore("language")]}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                        </div>
                    </div>
                    <div className="positionAbsolute mobileShow" onClick={() => { history.push('/service'); document.getElementById("root").scrollTo(0,0); }}>

                    </div>
                </div>
                <div className="HomeCard">
                    <img className="CardImg" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/kasps.png" alt="" />
                    <div className="HomeCardContent">
                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>{languageInfo["Complete7" + getStore("language")]}</div>
                        <div style={{ marginTop: 8, color: '#7d7d7d' }}>{languageInfo["Complete7Remark" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div className="flexCenter webShow" style={{ justifyContent: 'center', paddingBottom: 20 }}>
                        <div className="CardBtn" onClick={() => { history.push('/kas'); document.getElementById("root").scrollTo(0,0);  }}>
                            <div style={{ fontSize: 13 }}>{languageInfo["ProjectIntroduction" + getStore("language")]}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                        </div>
                    </div>

                    <div className="positionAbsolute mobileShow" onClick={() => { history.push('/aleo'); document.getElementById("root").scrollTo(0,0); }}>

                    </div>
                </div>
            </div>
            <div style={{ position: 'relative', textAlign: 'center', padding: '32px 7.5%' }}>
                <div style={{ fontSize: 48, color: '#1d1d27', fontWeight: 'bold',textAlign: 'center', margin: '18px 0px 2%' }}>{languageInfo["Why" + getStore("language")]}</div>
                <div style={{ fontSize: 24, color: '#8f8f90',textAlign: 'center' }}>{languageInfo["WhyRemark" + getStore("language")]}</div>

                <div className="content" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontWeight: 'bold', justifyContent: 'space-around' }}>
                    <div className="choicesBlock">
                        <div className="choicesImg">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ChoicesInfo1.png" alt="" style={{ height: 200 }} />
                        </div>
                        <div style={{ color: '#1d1d27', fontSize: 18, margin: "12px 0" }}>{languageInfo["Service1" + getStore("language")]}</div>
                        <div style={{ color: '#8f8f90' }}>{languageInfo["Service1Remark" + getStore("language")]}</div>
                    </div>
                    <div className="choicesBlock">
                        <div className="choicesImg">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ChoicesInfo2.png" alt="" style={{ height: 200, position: 'relative' }} />
                        </div>
                        <div style={{ color: '#1d1d27', fontSize: 18, margin: "12px 0" }}>{languageInfo["Service2" + getStore("language")]}</div>
                        <div style={{ color: '#8f8f90' }}>{languageInfo["Service2Remark" + getStore("language")]}</div>
                    </div>
                    <div className="choicesBlock" style={{ width: '40%', textAlign: 'center', margin: '2% 0px' }}>
                        <div className="choicesImg">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ChoicesInfo3.png" alt="" style={{ height: 200, position: 'relative' }} />
                        </div>
                        <div style={{ color: '#1d1d27', fontSize: 18, margin: "12px 0" }}>{languageInfo["Service3" + getStore("language")]}</div>
                        <div style={{ color: '#8f8f90' }}>{languageInfo["Service3Remark" + getStore("language")]}</div>
                    </div>
                    <div className="choicesBlock" style={{ width: '40%', textAlign: 'center', margin: '2% 0px' }}>
                        <div className="choicesImg">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ChoicesInfo4.png" alt="" style={{ height: 200 }} />
                        </div>

                        <div style={{ color: '#1d1d27', fontSize: 18, margin: "12px 0" }}>{languageInfo["Service4" + getStore("language")]}</div>
                        <div style={{ color: '#8f8f90' }}>{languageInfo["Service4Remark" + getStore("language")]}</div>
                    </div>
                </div>
            </div>
            {/*
            <div style={{ position: 'relative', textAlign: 'center', padding: '5%' }}>
                <embed type="image/svg+xml" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ChooseBanner.svg" className="map" data-v-63941621="">
                </embed>
                <div className="positionAbsolute flexCenter" style={{ flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ fontSize: 48, color: '#ffffff', fontWeight: 'bold',textAlign: 'center' }}>{languageInfo["Why" + getStore("language")]}</div>
                    <div className="margin1" style={{ fontSize: 24, color: '#8f8f90',textAlign: 'center' }}>{languageInfo["WhyRemark" + getStore("language")]}</div>

                    <div className="content" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontWeight: 'bold', justifyContent: 'space-around' }}>
                        <div className="choicesBlock">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ChoicesInfo1.png" alt="" style={{ height: 80, marginRight: 24 }} />
                            <div style={{ flex: 1, textAlign: 'left' }}>
                                <div style={{ color: '#ffffff', fontSize: 18, margin: "12px 0" }}>{languageInfo["Service1" + getStore("language")]}</div>
                                <div style={{ color: '#8f8f90' }}>{languageInfo["Service1Remark" + getStore("language")]}</div>
                            </div>
                        </div>
                        <div className="choicesBlock">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ChoicesInfo2.png" alt="" style={{ height: 120, marginRight: 24 }} />
                            <div style={{ flex: 1, textAlign: 'left' }}>
                                <div style={{ color: '#ffffff', fontSize: 18, margin: "12px 0" }}>{languageInfo["Service2" + getStore("language")]}</div>
                                <div style={{ color: '#8f8f90' }}>{languageInfo["Service2Remark" + getStore("language")]}</div>
                            </div>
                        </div>
                        <div className="choicesBlock" style={{ width: '40%', textAlign: 'center', margin: '2% 0px' }}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ChoicesInfo3.png" alt="" style={{ height: 120, marginRight: 24 }} />
                            <div style={{ flex: 1, textAlign: 'left' }}>
                                <div style={{ color: '#ffffff', fontSize: 18, margin: "12px 0" }}>{languageInfo["Service3" + getStore("language")]}</div>
                                <div style={{ color: '#8f8f90' }}>{languageInfo["Service3Remark" + getStore("language")]}</div>
                            </div>
                        </div>
                        <div className="choicesBlock" style={{ width: '40%', textAlign: 'center', margin: '2% 0px' }}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ChoicesInfo4.png" alt="" style={{ height: 90, marginRight: 40 }} />
                            <div style={{ flex: 1, textAlign: 'left' }}>
                                <div style={{ color: '#ffffff', fontSize: 18, margin: "12px 0" }}>{languageInfo["Service4" + getStore("language")]}</div>
                                <div style={{ color: '#8f8f90' }}>{languageInfo["Service4Remark" + getStore("language")]}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            */}
            <div className="threeCard">
                <div style={{ textAlign: 'center', fontSize: 28, fontWeight: 'bold',letterSpacing: '2px' }}>
                    {languageInfo["AboutText12" + getStore("language")]}
                </div>
                <div className="flexCenter" style={{ padding: "48px 0 80px", textAlign: "center" }}>
                    <div style={{ flex: 1 }}></div>
                    <div className="flexCenter canClick" onClick={() => { if (getStore('AccountId')) { history.push('/assets'); document.getElementById("root").scrollTo(0,0); stationDefault.notify('setBannerIndex', "/assets"); } else { stationDefault.notify('setLoginOpen', 1); } }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutHelp1.png" alt="" style={{ width: 80, padding: '0 16px' }} />
                        <div style={{ position: 'absolute', top: 100, width: '200%', left: '-50%' }}>  {languageInfo["AboutText13" + getStore("language")]}</div>
                    </div>
                    <div style={{ width: '24%', height: 1, borderBottom: '1px dashed #666666' }}></div>
                    <div className="flexCenter canClick" onClick={() => { history.push('/mine'); document.getElementById("root").scrollTo(0,0); }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutHelp2.png" alt="" style={{ width: 80, padding: '0 16px' }} />
                        <div style={{ position: 'absolute', top: 100, width: '200%', left: '-50%' }}>  {languageInfo["AboutText14" + getStore("language")]}</div>
                    </div>
                    <div style={{ width: '24%', height: 1, borderBottom: '1px dashed #666666' }}></div>
                    <div className="flexCenter canClick" onClick={() => { if (getStore('AccountId')) { history.push('/withdraw'); document.getElementById("root").scrollTo(0,0); stationDefault.notify('setBannerIndex', "/withdraw"); } else { stationDefault.notify('setLoginOpen', 2); } }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutHelp3.png" alt="" style={{ width: 80, padding: '0 16px' }} />
                        <div style={{ position: 'absolute', top: 100, width: '200%', left: '-50%' }}>  {languageInfo["AboutText15" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                </div>
            </div>
        </div>
    );
}

export default Home;