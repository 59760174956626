import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Filecoin(props) {
    let history = useHistory();
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', margin: '72px 15%' }}>
                {getStore("language") === 'CN' ?
                    <div className="fileCoin" style={{ width: '100%',color: '#333333', background: '#f8f8f8', padding: 36, borderRadius: 16, boxSizing: 'border-box', margin: '16px 0 80px',fontSize: 16 }}>
                        <p style={{ fontSize: 32 }}>Filecoin套餐收益+線性釋放</p>
                        <p style={{ paddingTop: 16 }}>
                            當您購買了Filecoin礦機共享服務套餐，礦池會自動為您的訂單創建一個子賬户（如您有多個訂單，則對應的多個子賬户是不能合并或者刪除的，每個訂單的收益都是單獨計算的）。
                        </p>
                        <p style={{ paddingTop: 16 }}>
                            <span style={{ fontWeight: 'bold' }}>經典套餐：</span>當套餐的累計<span style={{ fontWeight: 'bold' }}>總分賬淨收益</span>達到礦池的最低打款門檻（<span style={{ fontWeight: 'bold' }}>0.1FIL</span>）後，礦池會在第二天（累計總分賬淨收益值達到門檻值以後的24小時以內）將收益打到套餐收款地址中。
                        </p>
                        <p style={{ fontWeight: 'bold' }}>注：</p>
                        <div>
                            <p>1.總分賬淨收益=當日分賬淨收益=釋放總收益*（1-技術服務費比例）</p>
                            <p>2.釋放總收益=今日產出的25%+往日產出的75%的1/180的總和</p>
                        </div>
                        <p style={{ fontWeight: 'bold',paddingTop: 16 }}>例：</p>
                        <p>
                            如您購買了套餐週期為540天 + 180天線性釋放收益、技術服務費為20%的Filecoin套餐
                        </p>
                        <div>
                            <p>
                                <span style={{ fontWeight: 'bold' }}>1）</span>2021.10.31的當日產出為0.02815707 FIL，則：
                            </p>
                            <p>
                                2021.10.31當日您的總分賬淨收益 = 釋放總收益*（1-技術服務費比例）
                            </p>
                            <p>
                                = （今日產出的25%+往日產出的75%的1/180的總和） *（1-技術服務費比例）
                            </p>
                            <p>
                                = （0.02815707 FIL * 25% + 0 * 75% * 1/180）*（1-20%）
                            </p>
                            <p>
                                =   0.00703927 FIL * （1-20%）
                            </p>
                            <p>
                                =   0.00563141 FIL
                            </p>
                        </div>

                        <div style={{ paddingTop: 16 }}>
                            <p><span style={{ fontWeight: 'bold' }}>2）</span>2021.11.01當日產出為0.02814644 FIL，則</p>
                            <p>2021.11.01當日您的總分賬淨收益 = 釋放總收益*（1-技術服務費比例）</p>
                            <p>= （今日產出的25%+往日產出的75%的1/180的總和） *（1-技術服務費比例）</p>
                            <p>= （0.02814644 FIL * 25% +0.02815707 FIL* 75% * 1/180 ） *（1-20%）</p>
                            <p>=  0.00715392 FIL * （1-20%）</p>
                            <p>=  0.00572314 FIL</p>
                            <p>依次類推，收益會在540+180天全部打到套餐收款地址中。</p>
                        </div>
                    </div>
                    :
                    <div className="fileCoin" style={{ width: '100%',color: '#333333', background: '#f8f8f8', padding: 36, borderRadius: 16, boxSizing: 'border-box', margin: '16px 0 80px',fontSize: 16 }}>
                        <p style={{ fontSize: 32 }}>Filecoin Plan Revenue+ Linear Release</p>
                        <p style={{ paddingTop: 16 }}>
                            Once you have purchased the Filecoin plan, the mining pool will automatically create a sub-account for your order (if you have multiple orders, the corresponding multiple sub-accounts can’t be merged or deleted, and the revenue of each order is calculated separately).
                        </p>
                        <p style={{ paddingTop: 16 }}>
                            <span style={{ fontWeight: 'bold' }}>Classic Plan: </span>when the cumulative<span style={{ fontWeight: 'bold' }}>Allocated Net Revenue Total</span>of the Plan reaches the minimum payment amount (<span style={{ fontWeight: 'bold' }}>0.1FIL</span>) of the mining pool, the mining pool will transfer the revenue to the receiving address of the plan on the next day (within 24 hours after the cumulative<span style={{ fontWeight: 'bold' }}>Allocated Net Revenue Total</span>reaches the amount).
                        </p>
                        <p style={{ fontWeight: 'bold' }}>Note:</p>
                        <div>
                            <p>1.<span style={{ fontWeight: 'bold' }}>Allocated Net Revenue Total</span>= Daily Allocated Net Revenue = Total Release Revenue * (1 - Tech Service Fee Ratio)</p>
                            <p>2.Total Release Revenue = 25% of Today's Output + Sum of 1/180 of 75% of the Past Output</p>
                        </div>
                        <p style={{ fontWeight: 'bold',paddingTop: 16 }}>For example:</p>
                        <p>
                            If you purchase the Filecoin Plan with a period of 540 days + 180-Day Linear Release Revenue and Tech Service Fee of 20%
                        </p>
                        <div>
                            <p>
                                <span style={{ fontWeight: 'bold' }}>1）</span>If Today’s Output of 2021.10.31 is 0.02815707 FIL, then:
                            </p>
                            <p>
                                Your Allocated Net Revenue Total of 2021.10.31 = Total Release Revenue * (1 - Tech Service Fee Ratio)
                            </p>
                            <p>
                                = (25% of Today's Output + Sum of 1/180 of 75% of the Past Output) * (1 - Tech Service Fee Ratio)
                            </p>
                            <p>
                                = （0.02815707 FIL * 25% + 0 * 75% * 1/180）*（1-20%）
                            </p>
                            <p>
                                =   0.00703927 FIL * （1-20%）
                            </p>
                            <p>
                                =   0.00563141 FIL
                            </p>
                        </div>

                        <div style={{ paddingTop: 16 }}>
                            <p><span style={{ fontWeight: 'bold' }}>2）</span>If Today’s Output of 2021.11.01 is 0.02814644 FIL，then</p>
                            <p>Your Allocated Net Revenue Total of 2021.11.01 = Total Release Revenue * (1 - Tech Service Fee Ratio)</p>
                            <p>= (25% of Today's Output + Sum of 1/180 of 75% of the Past Output) * (1 - Tech Service Fee Ratio)  </p>
                            <p>= (0.02814644 FIL * 25% +0.02815707 FIL* 75% * 1/180 )*(1-20%)</p>
                            <p>= 0.00715392 FIL * (1-20%)</p>
                            <p>= 0.00572314 FIL</p>
                            <p>In turn, all the revenues will be paid to the receiving address of the plan in 540 + 180 days.</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Filecoin;