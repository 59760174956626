import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from './services';
import './App.css';

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Buy(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [systemSetting, setSystemSetting] = useState(() => {
        return getStore('systemSetting');
    });
    const [productInfo] = useState(getStore("productInfo"));
    const [Time, setTime] = useState(10)
    const [TimeType, setTimeType] = useState(false)
    const [Amount, setAmount] = useState(1)
    const [TH] = useState(getStore("TH"))

    useMiracleObserver(
        'userBuyBtcProductSuccess',
        (key, value, store) => {
            if (key === 'userBuyBtcProductSuccess') {
                history.push('/order');
                document.getElementById("root").scrollTo(0,0);
                stationDefault.notify('setBannerIndex', "order");

                appServices.queryProductBuyList();
            }
        },
        SubscribeMatchType.ContainsNotify,
    );


    function returnUnit() {
        if (productInfo.productType === "FIL") {
            return ""
        } else {
            return "$"
        }
    }

    function returnCoin() {
        if (productInfo.productType === "FIL") {
            return "FIL"
        } else {
            return ""
        }
    }

    return (
        <div style={{ background: '#F7F8FA', position: 'relative', top: -80, paddingTop: 80 }}>
            <div className="content" style={{ paddingTop: 40, background: '#ffffff', padding: 24, boxSizing: 'border-box', display: 'flex', alignItems: 'start', fontSize: 14 }}>
                <div style={{ color: '#687898', flex: 1 }}>
                    {productInfo.productPower ?
                        <div>
                            <div style={{ fontSize: 20,borderBottom: '2px dashed #f7f8fa', paddingBottom: 12, marginBottom: 20,marginTop: 10, position: 'relative' }}>
                                <div>{languageInfo["PayText1" + getStore("language")]}</div>
                            </div>
                            <div style={{ marginBottom: 20 }}>{languageInfo["PayText2" + getStore("language")]}</div>
                            <div className="timeList">
                                <div
                                    onClick={() => {
                                        setTime(10);
                                    }}
                                    className="timeAmount canClick"
                                    style={Time === 10 ?  {  background: '#f77e00',color: '#ffffff' } : {}}
                                >
                                    <div className="rateCircle">
                                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/BuySelect.png" alt="" style={{ width: 14, display: Time === 10 ? 'block' : 'none', borderRadius: '50%' }} />
                                    </div>
                                    <div>10</div>
                                </div>
                                <div
                                    onClick={() => {
                                        setTime(20);
                                    }}
                                    className="timeAmount canClick"
                                    style={Time === 20 ?  {  background: '#f77e00',color: '#ffffff' } : {}}
                                >
                                    <div className="rateCircle">
                                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/BuySelect.png" alt="" style={{ width: 14, display: Time === 20 ? 'block' : 'none', borderRadius: '50%' }} />
                                    </div>
                                    <div>20</div>
                                </div>
                                <div
                                    onClick={() => {
                                        setTime(30);
                                    }}
                                    className="timeAmount canClick"
                                    style={Time === 30 ?  {  background: '#f77e00',color: '#ffffff' } : {}}
                                >
                                    <div className="rateCircle">
                                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/BuySelect.png" alt="" style={{ width: 14, display: Time === 30 ? 'block' : 'none', borderRadius: '50%' }} />
                                    </div>
                                    <div>30</div>
                                </div>
                                <div
                                    onClick={() => {
                                        setTime("");
                                    }}
                                    className="timeAmount"
                                    style={Time !== 30 && Time !== 10 && Time !== 20  ?  {  background: '#f77e00',color: '#ffffff', padding: 0 } : { padding: 0 }}
                                >

                                    <input
                                        placeholder={languageInfo["PayText3" + getStore("language")]}
                                        type="number"
                                        style={{ border: 'none', borderRadius: 36,fontSize: 17, flex: 1, padding: "8px 24px", background: 'transparent', color: '#ffffff', width: 120, boxSizing: 'border-box' }}
                                        onInput={(e)=> {
                                            setTime(e.target.value.toString());
                                        }}
                                        value={Time !== 30 && Time !== 10 && Time !== 20  ? Time : ""}
                                    />
                                </div>
                            </div>
                            <div style={{ marginBottom: 20 }}>{languageInfo["PayText4" + getStore("language")]}</div>
                            <div className="timeList">
                                <div
                                    onClick={() => {
                                        setTimeType(false);
                                        setTime(10);
                                    }}
                                    className="timeAmount canClick"
                                    style={TimeType === false ?  {  background: '#f77e00',color: '#ffffff' } : {}}
                                >
                                    <div className="rateCircle">
                                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/BuySelect.png" alt="" style={{ width: 14, display: TimeType === false ? 'block' : 'none', borderRadius: '50%' }} />
                                    </div>
                                    <div>{languageInfo["PayText5" + getStore("language")]}</div>
                                </div>
                                <div
                                    onClick={() => {
                                        setTimeType(true);
                                        setTime(0);
                                    }}
                                    className="timeAmount canClick"
                                    style={TimeType ?  {  background: '#f77e00',color: '#ffffff' } : {}}
                                >
                                    <div className="rateCircle">
                                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/BuySelect.png" alt="" style={{ width: 14, display: TimeType ? 'block' : 'none', borderRadius: '50%' }} />
                                    </div>
                                    <div>{languageInfo["PayText6" + getStore("language")]}</div>
                                </div>
                            </div>
                            <div style={{ padding: '12px 24px', borderRadius: 12, background: '#f7f7f7' }}>
                                <div style={{ color: '#f77e00' }}>{languageInfo["PayText7" + getStore("language")]}</div>
                                <div style={{ marginTop: 8 }}>{languageInfo["PayText8" + getStore("language")]}</div>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className="flexBetween" style={{ fontSize: 20, paddingBottom: 12, marginBottom: 20,marginTop: 50, position: 'relative' }}>
                        <div>{productInfo.productName}{languageInfo["Plan" + getStore("language")]}/{productInfo.productSustainTime}{languageInfo["Days" + getStore("language")]}/{productInfo.outputType === "ALEO" ? productInfo.productHistory.split("/")[2] + "c/s" : TH }</div>
                        <div className="flexCenter">
                            <div
                                onClick={() => {
                                    if (Amount > 1) {
                                        setAmount(parseInt(Amount,10) - 1)
                                    }
                                }}
                                className="flexCenter AmountClick"
                                style={{ width: 45, height: 45, justifyContent: 'center', opacity: Amount > 1 ? 1 : 0.7 }}
                            >
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/BuyRemove.png" alt="" style={{ height: 3 }} />
                            </div>
                            <div className="flexCenter AmountClick" style={{ width: 65, height: 45, justifyContent: 'center', margin: '0 4px' }}>
                                <input
                                    type="number"
                                    style={{ color: '#f77e00', textAlign: 'center', padding: '12px 0px 12px 12px' }}
                                    value={Amount}
                                    onInput={(e)=> {
                                        setAmount(parseInt(e.target.value > 1 ? e.target.value : 1, 10));
                                    }}
                                    className="inputStyle"
                                />
                            </div>
                            <div
                                onClick={() => {
                                    setAmount(parseInt(Amount,10) + 1)
                                }}
                                className="flexCenter AmountClick" style={{ width: 45, height: 45, justifyContent: 'center' }}
                            >
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/BuyAdd.png" alt="" style={{ height: 20 }} />
                            </div>
                        </div>
                    </div>
                    <div className="orderInfo">
                        <div className="buyItem">
                            <div>{languageInfo["PayText10" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}> {productInfo.productType === "FIL" ? "FIL" : productInfo.outputType}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["PayText13" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{languageInfo["EstimatedTime1" + getStore("language")]}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["PayText16" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>Mintsat</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["PayText11" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}> {productInfo.outputType === "FIL" ? TH : productInfo.outputType === "ALEO" ? productInfo.productHistory.split("/")[2] + "c/s" : TH + "H/s"}</div>
                        </div>
                        {productInfo.productType !== "FIL" ?
                            <div className="buyItem">
                                <div>{languageInfo["PayText14" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}:</div>
                                <div style={{ marginLeft: 8 }}>{returnUnit()}{(productInfo.productPrice / productInfo.productSustainTime).toFixed(4)} {returnCoin()}</div>
                            </div>
                            :
                            null
                        }
                        <div className="buyItem">
                            <div>{languageInfo["PayText17" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{Amount}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["PayText12" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.productSustainTime}{languageInfo["Days" + getStore("language")]}{productInfo.outputType === "FIL" ? "+180" + languageInfo["Days" + getStore("language")] : ""}</div>
                        </div>
                        {productInfo.outputType && productInfo.outputType.indexOf("FIL") >= 0 ?
                            <div className="buyItem">
                                <div>{languageInfo["MinerStake" + getStore("language")]}:</div>
                                <div style={{ marginLeft: 8 }}> {parseFloat(productInfo.productPrice * parseFloat(TH.split("T")[0]) * productInfo.returnRatio / 100).toFixed(4)}FIL</div>
                            </div>
                            :
                            null
                        }
                        <div className="buyItem">
                            <div>{languageInfo["PayText15" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{returnUnit()}{productInfo.productPrice * parseFloat(TH.split("T")[0])} {returnCoin()}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["PayText18" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{returnUnit()}{productInfo.productPrice * Amount * parseFloat(TH.split("T")[0])} {returnCoin()}</div>
                        </div>
                    </div>
                    {productInfo.productPower ?
                        <div style={{ marginTop: 50, position: 'relative' }}>
                            <div style={{ fontSize: 20, marginBottom: 20 }}>
                                {getStore("language") === 'CN' ?
                                    <div>用電 {Time} 日</div>
                                    :
                                    <div>{Time}day(s) of electricity</div>
                                }
                            </div>
                            <div className="orderInfo">
                                <div className="buyItem">
                                    <div>{languageInfo["PayText10" + getStore("language")]}:</div>
                                    <div style={{ marginLeft: 8 }}> {productInfo.productType === "FIL" ? "FIL" : productInfo.outputType}</div>
                                </div>
                                <div className="buyItem">
                                    <div>{languageInfo["PayText14" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}:</div>
                                    <div style={{ marginLeft: 8 }}>${productInfo.productPower}</div>
                                </div>
                                <div className="buyItem">
                                    <div>{languageInfo["PayText17" + getStore("language")]}:</div>
                                    <div style={{ marginLeft: 8 }}>{Amount}</div>
                                </div>
                                <div className="buyItem">
                                    <div>{languageInfo["PayText11" + getStore("language")]}:</div>
                                    <div style={{ marginLeft: 8 }}> {productInfo.outputType === "ALEO" ? productInfo.productHistory.split("/")[2] + " c/s" : productInfo.outputType === "FIL" ? TH : TH + "H/s"}</div>
                                </div>
                                {systemSetting["POWER-DISCOUNT-" + getStore("vip")] ?
                                    <div className="buyItem">
                                        <div>{languageInfo["PayText15" + getStore("language")]}:</div>
                                        <div style={{ marginLeft: 8 }}>{getStore("vip") !== 0 && systemSetting["POWER-DISCOUNT-"+ getStore("vip")].valueDouble ? <span style={{ textDecoration: 'line-through', marginRight: 4 }}>${parseFloat(productInfo.productPower * parseFloat(TH.split("T")[0])).toFixed(4)}</span>: null}<span>${parseFloat(productInfo.productPower * parseFloat(TH.split("T")[0])  *  (1 - systemSetting["POWER-DISCOUNT-"+ getStore("vip")].valueDouble)).toFixed(4)}</span></div>
                                    </div>
                                    :
                                    null
                                }
                                {systemSetting["POWER-DISCOUNT-" + getStore("vip")] && systemSetting["POWER-DISCOUNT-" + getStore("vip")].valueDouble ?
                                    <div className="buyItem">
                                        <div>{languageInfo["PayText18" + getStore("language")]}:</div>
                                        <div style={{marginLeft: 8}}>${parseFloat(productInfo.productPower * Time * Amount * parseFloat(TH.split("T")[0]) - productInfo.productPower * Time * (systemSetting["POWER-DISCOUNT-" + getStore("vip")] ? (systemSetting["POWER-DISCOUNT-" + getStore("vip")].valueDouble) : 0) * Amount * parseFloat(TH.split("T")[0])).toFixed(4)}</div>
                                    </div>
                                    :
                                    null
                                }
                                <div className="buyItem">
                                    <div>{languageInfo["PlanDuration" + getStore("language")]}</div>
                                    <div style={{ marginLeft: 8 }}>{productInfo.productSustainTime}{languageInfo["Days" + getStore("language")]}{productInfo.outputType === "FIL" ? "+180" + languageInfo["Days" + getStore("language")] : ""}</div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <div style={{ color: '#687898', padding: 16, borderRadius: 12, background: '#fdfdfd', marginLeft: 24, minWidth: 400 }}>
                    <div style={{ background: '#ffffff', borderRadius: 12, padding: 16 }}>
                        <div style={{ fontSize: 20,borderBottom: '2px dashed #f7f8fa', paddingBottom: 12, marginBottom: 20,marginTop: 10, position: 'relative' }}>
                            <div>{languageInfo["PayText22" + getStore("language")]}</div>
                        </div>

                        <div style={{ fontSize: 16, paddingBottom: 12, marginTop: 20, position: 'relative' }}>
                            <div>{productInfo.productName}{languageInfo["Plan" + getStore("language")]}/{productInfo.productSustainTime}{languageInfo["Days" + getStore("language")]}{productInfo.outputType === "FIL" ? "+180" + languageInfo["Days" + getStore("language")] : ""}/{productInfo.outputType === "ALEO" ? productInfo.productHistory.split("/")[2] + "c/s" : TH}</div>
                        </div>
                        <div className="flexBetween">
                            <span>{returnUnit()} {productInfo.productPrice * parseFloat(TH.split("T")[0])} {returnCoin()}</span>
                            <span>x {Amount}</span>
                        </div>

                        {productInfo.productPower ?
                            <div style={{fontSize: 16, paddingBottom: 12, marginTop: 20, position: 'relative'}}>
                                {getStore("language") === 'CN' ?
                                    <div>用電 {Time} 日</div>
                                    :
                                    <div>{Time} day(s) of electricity</div>
                                }
                            </div>
                            :
                            null
                        }
                        {productInfo.productPower ?
                            <div className="flexBetween">
                                <span>$ {productInfo.productPower * Time * parseFloat(TH.split("T")[0])}</span>
                                <span>x {Amount}</span>
                            </div>
                            :
                            null
                        }
                        <div style={{ height: 20,borderBottom: '2px dashed #f7f8fa', marginBottom: 24,marginTop: 12, position: 'relative' }}>

                        </div>
                        <div className="flexBetween">
                            <span style={{ fontWeight: 600 }}>{languageInfo["CalculatorText4" + getStore("language")]}:</span>
                            <span>{returnUnit()}{productInfo.productPrice * Amount * parseFloat(TH.split("T")[0])} {returnCoin()}</span>
                        </div>
                        {productInfo.productPower ?
                            <div className="flexBetween" style={{marginTop: 24}}>
                                <span style={{fontWeight: 600}}>{languageInfo["CalculatorText5" + getStore("language")]}:</span>
                                <span>${productInfo.productPower * Time * Amount * parseFloat(TH.split("T")[0])}</span>
                            </div>
                            :
                            null
                        }
                        <div className="flexBetween" style={{ marginTop: 24 }}>
                            <span style={{ fontWeight: 600 }}>{languageInfo["PayText19" + getStore("language")]}:</span>
                            <span>{returnUnit()}{productInfo.productPower * Time* (systemSetting["POWER-DISCOUNT-" + getStore("vip")] ? (systemSetting["POWER-DISCOUNT-"+ getStore("vip")].valueDouble) : 0) * Amount * parseFloat(TH.split("T")[0])} {returnCoin()}</span>
                        </div>
                        <div className="flexBetween" style={{ marginTop: 24 }}>
                            <span style={{ fontWeight: 600 }}>{languageInfo["PayText20" + getStore("language")]}:</span>
                            <span>{returnUnit()}{productInfo.productPrice * Amount * parseFloat(TH.split("T")[0]) + productInfo.productPower * Time * Amount * parseFloat(TH.split("T")[0]) - productInfo.productPower * Time* (systemSetting["POWER-DISCOUNT-" + getStore("vip")] ? (systemSetting["POWER-DISCOUNT-"+ getStore("vip")].valueDouble) : 0) * Amount * parseFloat(TH.split("T")[0])} {returnCoin()}</span>
                        </div>
                        <div
                            onClick={() => {
                                if ((productInfo.productType === "FIL" ? getStore('Assets').fil : getStore('Assets').commission) > (productInfo.productPrice * Amount * parseFloat(TH.split("T")[0]) + productInfo.productPower * Time * Amount * parseFloat(TH.split("T")[0]) - productInfo.productPower * Time* (systemSetting["POWER-DISCOUNT-" + getStore("vip")] ? (systemSetting["POWER-DISCOUNT-"+ getStore("vip")].valueDouble) : 0) * Amount * parseFloat(TH.split("T")[0]))) {
                                    appServices.userBuyBtcProduct(productInfo.productId, productInfo.outputType === "ALEO" ? "1T" : TH ,TimeType, Amount, Time,productInfo.productType === "FIL" ? "FIL" : "")
                                } else {
                                    stationDefault.notify('setPostError', { deposit: true, message: languageInfo["PostError9997" + getStore("language")] });
                                }
                            }}
                            className="canClick"
                            style={{ background: '#8833FF', color: '#ffffff', padding: 12, borderRadius: 24, margin: '36px 36px 16px', fontWeight: 600,textAlign: 'center', opacity: getStore('Assets').commission > (productInfo.productPrice * Amount * parseFloat(TH.split("T")[0]) + productInfo.productPower * Time * Amount * parseFloat(TH.split("T")[0]) - productInfo.productPower * Time* (systemSetting["POWER-DISCOUNT-" + getStore("vip")] ? (systemSetting["POWER-DISCOUNT-"+ getStore("vip")].valueDouble) : 0) * Amount * parseFloat(TH.split("T")[0])) ? 1 : 0.7 }}
                        >
                            {languageInfo["PayText21" + getStore("language")]}
                        </div>
                    </div>
                </div>
            </div>
            <div  style={{ background: '#F7F8FA', height: 80, width: '100%',position: 'absolute', left: 0, bottom: -80 }}>
            </div>
        </div>
    );
}

export default Buy;