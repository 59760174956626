import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;


function BottomFooter(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [systemSetting, setSystemSetting] = useState(() => {
        return getStore('systemSetting');
    });
    const [tlOpen, setTlOpen] = useState(false);
    const [vxOpen, setVXOpen] = useState(false);

    const jumpPage = (src) => {
        history.push(src);
        document.getElementById("root").scrollTo(0,0);
        stationDefault.notify('setBannerIndex', src);
    }

    useMiracleObserver(
        'getSystemSetting',
        (key, value, store) => {
            if (key === 'getSystemSetting') {
                setSystemSetting(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    return (
        <div className="webShow" style={{ textAlign: 'left', background: 'black', color: '#ffffff' }}>
            <div>
                <div style={{ maxWidth: 960, margin: 'auto', padding: '48px 20px 0px', boxSizing: 'border-box', width: '100%', position: 'relative' }}>
                    <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', lineHeight: '40px', marginTop: 30 }}>
                        <div className="bottomItem">
                            <div style={{ color: '#ffffff', fontWeight: 'bold', marginBottom: 12 }}>{languageInfo["Products" + getStore("language")]}</div>
                            <div className="canClick" onClick={() => { jumpPage('/mine'); }}>{languageInfo["Products1" + getStore("language")]}</div>
                            <div className="canClick" onClick={() => { jumpPage('/aleo'); }}>Aleo</div>
                            <div className="canClick" onClick={() => { jumpPage('/calculator'); }}>{languageInfo["Products3" + getStore("language")]}</div>
                        </div>
                        <div className="bottomItem">
                            <div style={{ color: '#ffffff', fontWeight: 'bold', marginBottom: 12 }}>{languageInfo["Information" + getStore("language")]}</div>
                            <div className="canClick" onClick={() => { jumpPage('/about'); }}>{languageInfo["Information1" + getStore("language")]}</div>
                            <div className="canClick" onClick={() => { jumpPage('/contact'); }}>{languageInfo["Information2" + getStore("language")]}</div>
                            <div className="canClick" onClick={() => { jumpPage('/business'); }}>{languageInfo["Information3" + getStore("language")]}</div>
                        </div>
                        <div className="bottomItem">
                            <div style={{ color: '#ffffff', fontWeight: 'bold', marginBottom: 12 }}>{languageInfo["Support" + getStore("language")]}</div>
                            <div className="canClick" onClick={() => { jumpPage('/contact'); }}>{languageInfo["Support3" + getStore("language")]}</div>
                            <div className="canClick" onClick={() => { jumpPage('/privacy'); }}>{languageInfo["Support4" + getStore("language")]}</div>
                            <div className="canClick" onClick={() => { jumpPage('/termsandconditions'); }}>{languageInfo["Support5" + getStore("language")]}</div>
                        </div>
                        <div className="bottomItem">
                            <div style={{ color: '#ffffff', fontWeight: 'bold', marginBottom: 12 }}>{languageInfo["ExchangeRate" + getStore("language")]}</div>

                            <div>1 BTC = {systemSetting && systemSetting[`BTC-USD`] ? systemSetting[`BTC-USD`].valueDouble : '?'} USD</div>
                            <div>1 ETH = {systemSetting && systemSetting[`ETH-USD`] ? systemSetting[`ETH-USD`].valueDouble : '?'} USD</div>
                            <div>1 FIL = {systemSetting && systemSetting[`FIL-USD`] ? systemSetting[`FIL-USD`].valueDouble : '?'} USD</div>
                            <div>1 KAS = {systemSetting && systemSetting[`KAS-USD`] ? systemSetting[`KAS-USD`].valueDouble : '?'} USD</div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                        <img
                            style={{ height: 30 }}
                            alt=""
                            src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MintSatWhite.png"
                        />
                    </div>
                    <div style={{ textAlign: 'center', margin: '20px', lineHeight: 1.5 }}>
                        <div><span>{languageInfo["Company" + getStore("language")]}</span>DownTown Capital Limited</div>
                        <div><span>{languageInfo["BusinessLicense" + getStore("language")]}</span>UEN202121794E</div>
                        <div><span>{languageInfo["CompanyAddress" + getStore("language")]}</span>5001 BEACH ROAD#07-37 GOLDEN MILE COMPLEX SINGAPORE</div>
                        <div>USA MSB Registration Number: 31000192509981</div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', padding: 24 }}>
                        <a target="_blank" style={{ height: 20, marginRight: 24, color: '#ffffff', textDecoration: 'none' }} href="https://twitter.com/Mintsat_Offical">
                            <img
                                style={{ height: '100%' }}
                                src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/bottom3icon.png"
                                alt=""
                            />
                        </a>
                        {/*<a target="_blank" style={{ height: 20, marginRight: 24, color: '#ffffff', textDecoration: 'none' }} href="">
                            <img
                                style={{ height: '100%' }}
                                src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/bottom2icon.png"
                                alt=""
                            />
                        </a>*/}
                        {/*
                        <div onClick={() => { setTlOpen(true) }} style={{ height: 20, marginRight: 24, color: '#ffffff',  cursor: 'pointer' }}>
                            <img
                                style={{ height: '100%' }}
                                src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/bottom1icon.png"
                                alt=""
                            />
                        </div>
                        */}
                        <div onClick={() => { setVXOpen(true) }} style={{ height: 20, marginRight: 24, color: '#ffffff',  cursor: 'pointer' }}>
                            <img
                                style={{ height: '100%' }}
                                src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/bottom6icon.png"
                                alt=""
                            />
                        </div>
                        {/*<a target="_blank" style={{ height: 20, marginRight: 24, color: '#ffffff', textDecoration: 'none' }} href="">
                            <img
                                style={{ height: '100%' }}
                                src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/bottom4icon.png"
                                alt=""
                            />
                        </a>
                        <a  target="_blank" style={{ height: 20, marginRight: 24, color: '#ffffff', textDecoration: 'none' }} href="">
                            <img
                                style={{ height: '100%' }}
                                src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/bottom5icon.png"
                                alt=""
                            />
                        </a>*/}
                    </div>
                </div>
            </div>

            <div onClick={() => { setTlOpen(false) }} className="scaleOpen" style={{ pointerEvents: tlOpen ? 'all' : 'none', background:  tlOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 12 }}>
                <div
                    style={{
                        transitionDuration: '0.5s', opacity: tlOpen ? 1 : 0, transform: tlOpen ? 'scale(1)' :'scale(0.8)',
                        position: 'relative', display: 'flex', flexDirection: 'column',
                        width: 320, height: 320, marginTop: 50 }}
                >
                    <div className="flexCenter" style={{ height: "100%", justifyContent: 'center' }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/mintsatTL.png" alt="" style={{ width: "100%" }} />
                    </div>
                </div>
            </div>
            <div onClick={() => { setVXOpen(false) }} className="scaleOpen" style={{ pointerEvents: vxOpen ? 'all' : 'none', background:  vxOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 12 }}>
                <div
                    style={{
                        transitionDuration: '0.5s', opacity: vxOpen ? 1 : 0, transform: vxOpen ? 'scale(1)' :'scale(0.8)',
                        position: 'relative', display: 'flex', flexDirection: 'column',
                        width: 320, height: 320, marginTop: 50 }}
                >
                    <div className="flexCenter" style={{ height: "100%", justifyContent: 'center' }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/mintsatVX.png" alt="" style={{ width: "100%" }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BottomFooter;