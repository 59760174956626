import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Assets(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [assets, setAssets] = useState(() => {
        return getStore('Assets');
    });
    const [ethScore, setEthScore] = useState(() => {
        return getStore('ethScore');
    });
    const [productIncomeList, setProductIncomeList] = useState(() => {
        return getStore('productIncomeList');
    });
    const [productIncomeCount, setProductIncomeCount] = useState(() => {
        return getStore('productIncomeCount');
    });
    const [IncomeLimit, setIncomeLimit] = useState(0);
    const [ethIncomeList, setEthIncomeList] = useState(() => {
        return getStore('ethIncomeList');
    });
    const [rechargeList, setRechargeList] = useState(() => {
        return getStore('rechargeList');
    });
    const [buyObj, setBuyObj]  = useState(() => {
        return getStore('buyObj');
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [ethOpen, setETHOpen] = useState(false);
    const [powerOpen, setPowerOpen] = useState(false);
    const [USDTOpen, setUSDTOpen] = useState(false);
    const [type, setType] = useState("btc");

    useMiracleObserver(
        'productIncomeCount,getAccountAssets,buyObj,queryProductIncomeList,ethIncomeList,getAccountStoreIn,getETHScoreSuccess',
        (key, value, store) => {
            if (key === 'getAccountAssets') {
                setAssets(value);
            } else if (key === 'buyObj') {
                setBuyObj(value);
            } else if (key === 'queryProductIncomeList') {
                setProductIncomeList(value);
                setProductIncomeCount(getStore("productIncomeCount"));
            } else if (key === 'ethIncomeList') {
                setEthIncomeList(value);
            } else if (key === "getAccountStoreIn") {
                setRechargeList(value);
            } else if (key === 'getETHScoreSuccess') {
                setEthScore(value)
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    const queryProductIncomeList = (type, size) => {
        setType(type);
        setIncomeLimit(size || 0);

        const query = {};
        query[type] = true;
        query.size = (size * 20) || 0;
        appServices.queryProductIncomeList(query);
    }

    return (
        <div className="userContent">
            <div className="userTitle">{languageInfo["assets" + getStore("language")]}</div>

            <div
                onClick={() => {
                    setModalOpen(true);
                    queryProductIncomeList("btc");
                }}
                className="userAssets" style={{ background: '#9cbdff' }}
            >
                <div className="flexCenter">
                    <div className="flexCenter" style={{ width: 40, height: 30, marginRight: 12, justifyContent: 'center' }}>
                        <img alt="" style={{ height: 30 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserBTC.png" />
                    </div>
                    <div>BTC</div>
                </div>
                <div>{((assets.btc + assets.frozenBtc) + "").replace(/^(.*\..{6}).*$/,"$1")}</div>
            </div>
            <div
                onClick={() => {
                    setModalOpen(true);
                    queryProductIncomeList("fil");
                }}
                className="userAssets" style={{ background: '#9cd6ff' }}
            >
                <div className="flexCenter">
                    <div className="flexCenter" style={{ width: 40, height: 30, marginRight: 12, justifyContent: 'center' }}>
                        <img alt="" style={{ height: 30 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserFIL.png" />
                    </div>
                    <div>FIL</div>
                </div>
                <div>{((assets.fil || 0) + "").replace(/^(.*\..{4}).*$/,"$1")}</div>
            </div>

            {/*<div
                onClick={() => {
                    setETHOpen(true)
                    appServices.queryETHIncomeList();
                }}
                className="userAssets" style={{ background: '#9cd6ff' }}
            >
                <div className="flexCenter">
                    <div className="flexCenter" style={{ width: 40, height: 30, marginRight: 12, justifyContent: 'center' }}>
                        <img alt="" style={{ height: 30 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserETH.png" />
                    </div>
                    <div>ETH</div>
                </div>
                <div>{((assets.eth + ethScore) + "").replace(/^(.*\..{4}).*$/,"$1")}</div>
            </div>*/}
            <div
                onClick={() => {
                    setModalOpen(true);
                    queryProductIncomeList("aleo");
                }}
                className="userAssets" style={{ background: '#a39cff' }}
            >
                <div className="flexCenter">
                    <div className="flexCenter" style={{ width: 40, height: 30, marginRight: 12, justifyContent: 'center' }}>
                        <img alt="" style={{ height: 30 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserALEO.png" />
                    </div>
                    <div>Aleo(Coinbase)</div>
                </div>
                <div>{(assets.aleo + "").replace(/^(.*\..{2}).*$/,"$1")}</div>
            </div>
            <div
                onClick={() => {
                    setModalOpen(true);
                    queryProductIncomeList("kas");
                }}
                className="userAssets" style={{ background: '#a39cff' }}
            >
                <div className="flexCenter">
                    <div className="flexCenter" style={{ width: 40, height: 30, marginRight: 12, justifyContent: 'center' }}>
                        <img alt="" style={{ height: 30 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserKas.png" />
                    </div>
                    <div>Kaspa</div>
                </div>
                <div>{(assets.kas + "").replace(/^(.*\..{2}).*$/,"$1")}</div>
            </div>

            <div
                onClick={() => {
                    setUSDTOpen(true)
                    appServices.getAccountStoreIn();
                }}
                className="userAssets" style={{ background: '#9cafff' }}
            >
                <div className="flexCenter">
                    <div className="flexCenter" style={{ width: 40, height: 30, marginRight: 12, justifyContent: 'center' }}>
                        <img alt="" style={{ height: 30 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserUSDT.png" />
                    </div>
                    <div>USDT</div>
                </div>
                <div>{(assets.commission + "").replace(/^(.*\..{2}).*$/,"$1")}</div>
            </div>

            <div
                onClick={() => {
                    setPowerOpen(true)
                    queryProductIncomeList("btc");
                }}
                className="userAssets" style={{ background: '#a39cff' }}
            >
                <div className="flexCenter">
                    <div className="flexCenter" style={{ width: 40, height: 30, marginRight: 12, justifyContent: 'center' }}>
                        <img alt="" style={{ height: 30 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserUSDT.png" />
                    </div>
                    <div>{languageInfo["ElectricityUSDT" + getStore("language")]}</div>
                </div>
                <div>{(assets.UsdtPower + "").replace(/^(.*\..{2}).*$/,"$1")}</div>
            </div>

            <div className="scaleOpen" style={{ pointerEvents: modalOpen ? 'all' : 'none', background:  modalOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: modalOpen ? 1 : 0, transform: modalOpen ? 'scale(1)' :'scale(0.8)',
                        width: 640,
                        height: "60%",
                        background: '#ffffff',
                        marginTop: 0,
                        padding: '48px 20px 20px 30px',
                        fontSize: 14
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    />
                    <div className="scrollbar" style={{ paddingRight: 12 }}>
                        <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden", marginTop: 16 }}>
                            <div className="flexCenter" style={{ height: 56, background: '#faf9fe', color: '#a3a3a3' }}>
                                <div style={{ width: "15%" }}>{languageInfo["DateTime" + getStore("language")]}</div>
                                <div style={{ width: "20%" }}>{languageInfo["ProductName" + getStore("language")]}</div>
                                <div style={{ width: "35%" }}>{languageInfo["Return" + getStore("language")]}</div>
                                <div style={{ width: "30%" }}>{languageInfo["ManageFee" + getStore("language")]}</div>
                            </div>
                            {productIncomeList.map(item => (
                                <div key={`userIncomeList${item.incomeId}`} className="flexCenter canClick" style={{ height: 56, color: '#1d1d27', borderTop: "2px solid #faf9fe" }}>
                                    <div style={{ width: "15%" }}>{item.incomeTime.split("T")[0]}</div>
                                    <div style={{ width: "20%" }}>{buyObj[item.buyId] ? buyObj[item.buyId].productName : ""}</div>
                                    <div style={{ width: "35%" }}>
                                        {item.incomeAmount != item.powerBtc ?
                                            <span style={{ marginLeft: 12 }}>+{parseFloat(item.incomeAmount-item.powerBtc).toFixed(6)} {item.incomeIsEqual === "ALEO" ? "Aleo(Coinbase)" : item.incomeIsEqual === "income_is_equal" ? "BTC" : item.incomeIsEqual}</span>
                                            :
                                            <span style={{ color: '#f77e00' }}>{languageInfo["AutomaticDeduction" + getStore("language")]}{parseFloat(item.powerBtc).toFixed(6)} BTC</span>
                                        }
                                    </div>
                                    <div style={{ width: "30%" }}><span>{parseFloat(item.productManage).toFixed(6)}</span>{item.incomeIsEqual === "ALEO" ? "Aleo(Coinbase)" : item.incomeIsEqual === "income_is_equal" ? "BTC" : item.incomeIsEqual}</div>
                                </div>
                            ))}
                        </div>
                        <div className="flexBetween" style={{ marginTop: 16 }}>
                            <div></div>
                            <div className="flexCenter" style={{ border: '2px solid #faf9fe', borderRadius: 8 }}>
                                <div
                                    onClick={() => {
                                        if (IncomeLimit > 0) {
                                            queryProductIncomeList(type, IncomeLimit - 1);
                                        }
                                    }}
                                    className="flexCenter canClick" style={{ width: 60, height: 45, justifyContent: 'center', opacity: IncomeLimit > 0 ? 1 : 0.5 }}
                                >
                                    {languageInfo['PreviousPage' + getStore("language")]}
                                </div>
                                <div className="flexCenter" style={{ width: 60, height: 45, justifyContent: 'center', borderLeft: '2px solid #faf9fe', borderRight: '2px solid #faf9fe' }}>
                                    <div>{IncomeLimit + 1}</div>
                                </div>
                                <div
                                    onClick={() => {
                                        if (IncomeLimit < (productIncomeCount / 20 - 1)) {
                                            queryProductIncomeList(type, IncomeLimit + 1);
                                        }
                                    }}
                                    className="flexCenter canClick" style={{ width: 60, height: 45, justifyContent: 'center', opacity: IncomeLimit < (productIncomeCount / 20 - 1) ? 1 : 0.5 }}
                                >
                                    {languageInfo['NextPage' + getStore("language")]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="scaleOpen" style={{ pointerEvents: ethOpen ? 'all' : 'none', background:  ethOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: ethOpen ? 1 : 0, transform: ethOpen ? 'scale(1)' :'scale(0.8)',
                        width: 640,
                        height: "60%",
                        background: '#ffffff',
                        marginTop: 0,
                        padding: '48px 20px 20px 30px',
                        fontSize: 14
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setETHOpen(false);
                        }}
                    />
                    <div className="scrollbar" style={{ paddingRight: 12 }}>
                        <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden", marginTop: 16 }}>
                            <div className="flexCenter" style={{ height: 56, background: '#faf9fe', color: '#a3a3a3' }}>
                                <div style={{ width: "20%" }}>{languageInfo["DateTime" + getStore("language")]}</div>
                                <div style={{ width: "20%" }}>{languageInfo["RateReturn" + getStore("language")]}</div>
                                <div style={{ width: "60%" }}>{languageInfo["Return" + getStore("language")]}</div>
                            </div>
                            {ethIncomeList.map(item => (
                                <div key={`ethIncomeList${item.ethScoreDailyId}`} className="flexCenter canClick" style={{ height: 56, color: '#1d1d27', borderTop: "2px solid #faf9fe" }}>
                                    <div style={{ width: "20%" }}>{item.thawTime.split("T")[0]}</div>
                                    <div style={{ width: "20%" }}>{item.ethYield * 100}%</div>
                                    <div style={{ width: "60%" }}>
                                        <span>+{parseFloat(item.thawAmount).toFixed(6)} ETH</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="scaleOpen" style={{ pointerEvents: powerOpen ? 'all' : 'none', background:  powerOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: powerOpen ? 1 : 0, transform: powerOpen ? 'scale(1)' :'scale(0.8)',
                        width: 640,
                        height: "60%",
                        background: '#ffffff',
                        marginTop: 0,
                        padding: '48px 20px 20px 30px',
                        fontSize: 14
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setPowerOpen(false);
                        }}
                    />
                    <div className="scrollbar" style={{ paddingRight: 12 }}>
                        <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden", marginTop: 16 }}>
                            <div className="flexCenter" style={{ height: 56, background: '#faf9fe', color: '#a3a3a3' }}>
                                <div style={{ width: "20%" }}>{languageInfo["OrderDate" + getStore("language")]}</div>
                                <div style={{ width: "20%" }}>{languageInfo["OrderID" + getStore("language")]}</div>
                                <div style={{ width: "60%" }}>{languageInfo["Electricity" + getStore("language")]}</div>
                            </div>
                            {productIncomeList.map(item => ( item.powerAmount ?
                                <div key={`powerIncomeList${item.incomeId}`} className="flexCenter canClick" style={{ height: 56, color: '#1d1d27', borderTop: "2px solid #faf9fe" }}>
                                    <div style={{ width: "20%" }}>{item.incomeTime.split("T")[0]}</div>
                                    <div style={{ width: "20%" }}>ID:{item.buyId}</div>
                                    <div style={{ width: "60%"  }}>
                                        {item.powerAmount != item.discountPower ?
                                            <span style={{ marginRight: 12 }}>{(((item.powerAmount-item.discountPower) * -1) + "").replace(/^(.*\..{2}).*$/,"$1")} USDT</span>
                                            :
                                            null
                                        }
                                        {item.powerBtc !== 0 ?
                                            <span style={{ color: '#f77e00' }}>{languageInfo["AutomaticDeduction" + getStore("language")]} {parseFloat(item.powerBtc).toFixed(6)} BTC ({(item.discountPower + "").replace(/^(.*\..{2}).*$/,"$1")} USDT)</span>
                                            :
                                            null
                                        }
                                    </div>
                                </div> : null
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="scaleOpen" style={{ pointerEvents: USDTOpen ? 'all' : 'none', background:  USDTOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: USDTOpen ? 1 : 0, transform: USDTOpen ? 'scale(1)' :'scale(0.8)',
                        width: 640,
                        height: "60%",
                        background: '#ffffff',
                        marginTop: 0,
                        padding: '48px 20px 20px 30px',
                        fontSize: 14
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setUSDTOpen(false);
                        }}
                    />
                    <div className="scrollbar" style={{ paddingRight: 12 }}>
                        <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden", marginTop: 16 }}>
                            <div className="flexCenter" style={{ height: 56, background: '#faf9fe', color: '#a3a3a3' }}>
                                <div style={{ width: "20%" }}>{languageInfo["DateTime" + getStore("language")]}</div>
                                <div style={{ width: "60%" }}>{languageInfo["Changes" + getStore("language")]}</div>
                            </div>
                            {rechargeList.map(item => ( item.inAssetsType === "USDT" ?
                                <div key={`rechargeList${item.ethScoreDailyId}`} className="flexCenter canClick" style={{ height: 56, color: '#1d1d27', borderTop: "2px solid #faf9fe" }}>
                                    <div style={{ width: "20%" }}>{item.inTime.split("T")[0]}</div>
                                    <div style={{ width: "60%" }}>
                                        <span>+{(item.inAmount + "").replace(/^(.*\..{6}).*$/,"$1")} USDT</span>
                                    </div>
                                </div> : null
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Assets;