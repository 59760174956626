import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function ALEO(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });


    const toALEO = () => {
        const select = JSON.parse(JSON.stringify(getStore('minSelect')));
        select.type = "ALEO";
        initStore(select, 'minSelect');
        history.push('/mine');
    }

    return (
        <div className="mobilePaddingTop">
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ALEOBanner.jpg" alt="" className="banner ALEOBanner" />

                <div className="pageBanner">
                    <div style={{ fontWeight: 500, fontSize: 20, width: '70%',color: '#b683fc', letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginBottom: 12 }}>
                        {languageInfo["AleoNotice" + getStore("language")]}
                    </div>
                    <div className="BannerFont" style={{ fontWeight: 700, fontSize: getStore("language") === 'CN' ? 56 : 48, letterSpacing:  getStore("language") === 'CN' ? 6 : 0 }}>
                        {languageInfo["AleoTitle" + getStore("language")]}
                    </div>
                    <div className="BannerFont" style={{ fontWeight: 500, fontSize: 20,lineHeight: getStore("language") === 'CN' ? "32px" : "24px", letterSpacing: getStore("language") === 'CN' ? 2 : 0, margin: "30px 0", color: '#858585' }}>
                        {languageInfo["AleoInfo1" + getStore("language")]}
                    </div>
                    <div className="flexCenter">
                        <div className="CardBtn" onClick={toALEO} style={{ background: '#8833ff' }}>
                            <div>{languageInfo["AleoGo" + getStore("language")]}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="content" style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', flexWrap: 'wrap', padding: '3% 0' }}>
                <div className="ALEOCard" style={{ width: "42%", margin: 16 }}>
                    <div className="CardTop">{languageInfo["AleoCardTop1" + getStore("language")]}</div>
                    <div className="ALEOCardContent">
                        <div>
                            <span className="positionRelative">
                                {languageInfo["AleoCardTitle1" + getStore("language")]}
                                <img alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketMouse.png" style={{ position: 'absolute' , right: '-32px', bottom: 0, height: 18 }} />
                            </span>
                        </div>
                        <div style={{ color: "#919193" }}>{languageInfo["AleoCardRemark1" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div className="flexCenter" style={{ justifyContent: 'center', paddingBottom: 24 }}>
                        <a target="_blank" href="https://www.aleo.studio/" style={{ textDecoration: 'none' }}>
                            <div className="CardBtn">
                                <div style={{ fontSize: 13 }}>{languageInfo["AleoCardBtn1" + getStore("language")]}</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="ALEOCard" style={{ width: "42%", margin: 16 }}>
                    <div className="CardTop">{languageInfo["AleoCardTop2" + getStore("language")]}</div>
                    <div className="ALEOCardContent">
                        <div className="positionRelative">
                            <span>{languageInfo["AleoCardTitle2" + getStore("language")]}</span>
                            <img alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketMouse.png" style={{ position: 'absolute' , right: 20, bottom: '-20px', height: 18 }} />
                        </div>
                        <div style={{ color: "#919193" }}>{languageInfo["AleoCardRemark2" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div className="flexCenter" style={{ justifyContent: 'center', paddingBottom: 24 }}>
                        <a target="_blank" href="https://aleo.pm/" style={{ textDecoration: 'none' }}>
                            <div className="CardBtn" style={{ background: '#8833ff' }}>
                                <div style={{ fontSize: 13 }}>{languageInfo["AleoCardBtn2" + getStore("language")]}</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="ALEOCard">
                    <div className="CardCircle" style={{ background: '#fec200' }}></div>
                    <div className="ALEOCardContent">
                        <div>{languageInfo["AleoCardTitle3" + getStore("language")]}</div>
                        <div style={{ color: "#919193" }}>{languageInfo["AleoCardRemark3" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div className="ALEOCardContent" style={{ fontSize: 14 }}>{languageInfo["AleoCardBtn2" + getStore("language")]}</div>
                </div>
                <div className="ALEOCard">
                    <div className="CardCircle" style={{ background: '#fe782f' }}></div>
                    <div className="ALEOCardContent">
                        <div>{languageInfo["AleoCardTitle4" + getStore("language")]}</div>
                        <div style={{ color: "#919193" }}>{languageInfo["AleoCardRemark4" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div className="ALEOCardContent" style={{ fontSize: 14 }}>{languageInfo["AleoCardBtn4" + getStore("language")]}</div>
                </div>
                <div className="ALEOCard">
                    <div className="CardCircle" style={{ background: '#cd7ad6' }}></div>
                    <div className="ALEOCardContent">
                        <div>{languageInfo["AleoCardTitle5" + getStore("language")]}</div>
                        <div style={{ color: "#919193" }}>{languageInfo["AleoCardRemark5" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div className="ALEOCardContent" style={{ fontSize: 14 }}>{languageInfo["AleoCardBtn5" + getStore("language")]}</div>
                </div>
            </div>
        </div>
    );
}

export default ALEO;