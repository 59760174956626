import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Contact(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [remark, setRemark] = useState('');

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', margin: '72px 15%' }}>
                <div style={{  lineHeight: '24px',fontSize: 14, color: '#1d1d27',width: '48%' }}>
                    <div style={{ fontSize: 36, fontWeight: 600 }}>{languageInfo["ContactTitle" + getStore("language")]}</div>
                    <div style={{ color: '#7c7c7d', margin: '36px 0' }}>{languageInfo["ContactInfo" + getStore("language")]}</div>
                    <div>{languageInfo["ContactText1" + getStore("language")]}</div>
                    <input
                        placeholder={languageInfo["ContactText3" + getStore("language")]}
                        value={name}
                        onInput={(e)=> {
                            setName(e.target.value);
                        }}
                        className="contactInput canClick"
                    />
                    <div>{languageInfo["ContactText4" + getStore("language")]}</div>
                    <input
                        placeholder={languageInfo["ContactText5" + getStore("language")]}
                        value={email}
                        onInput={(e)=> {
                            setEmail(e.target.value);
                        }}
                        className="contactInput canClick"
                    />
                    <div>{languageInfo["ContactText6" + getStore("language")]}</div>
                    <input
                        placeholder={languageInfo["ContactText7" + getStore("language")]}
                        value={phone}
                        onInput={(e)=> {
                            setPhone(e.target.value);
                        }}
                        className="contactInput canClick"
                    />
                    <div>{languageInfo["ContactText8" + getStore("language")]}</div>
                    <textarea
                        placeholder={languageInfo["ContactText9" + getStore("language")]}
                        value={remark}
                        onInput={(e)=> {
                            setRemark(e.target.value);
                        }}
                        className="contactInput canClick"
                    />
                    <div
                        style={{ width: '100%', borderRadius: 48, fontSize: 18, textAlign: 'center' }}
                        className="loginBtn canClick"
                        onClick={() => {
                            if ((email || phone) && remark) {
                                appServices.postMessage(name,email,phone,remark);
                                setName('');
                                setEmail('');
                                setPhone('');
                                setRemark('')
                            }
                        }}
                    >
                        {languageInfo["ContactText10" + getStore("language")]}
                    </div>
                </div>
                {getStore("language") === 'CN' ?
                    <div style={{ width: '48%', color: '#7c7c7d', background: '#f8f8f8', padding: 36, borderRadius: 16, boxSizing: 'border-box', margin: '16px 0 80px' }}>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0' }}>不如給我們發一封電子郵件？</div>
                        <div style={{ fontSize: 14, lineHeight: '24px' }}>
                            <div>通過電子郵件聯絡我們的品牌、銷售或支援團隊 ：</div>
                            <div><span style={{ color: '#1d1d27' }}>mintsatbusiness@gmail.com</span></div>

                            <div style={{ color: '#1d1d27', marginTop: 24 }}>網上關注</div>
                            <div>在我們的社交渠道上關注我們，了解最新消息和優惠</div>

                            <div style={{ color: '#1d1d27', marginTop: 24 }}>Twitter | Telegram | Medium | Youtube</div>
                        </div>
                    </div>
                    :
                    <div style={{ width: '48%', color: '#7c7c7d', background: '#f8f8f8', padding: 36, borderRadius: 16, boxSizing: 'border-box', margin: '16px 0 80px' }}>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0' }}>Rather send us an email?</div>
                        <div style={{ fontSize: 14, lineHeight: '24px' }}>
                            <div>Contact our brand, sales, or support teams by emailing:</div>
                            <div><span style={{ color: '#1d1d27' }}>mintsatbusiness@gmail.com</span></div>

                            <div style={{ color: '#1d1d27', marginTop: 24 }}>Follow online</div>
                            <div>Follow us on our social channels to keep up-to-date with the latest news and offers.</div>

                            <div style={{ color: '#1d1d27', marginTop: 24 }}>Twitter | Telegram | Medium | Youtube</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Contact;