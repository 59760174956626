import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function NoticeList(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [noticeList, setNoticeList] = useState(() => {
        return getStore('NoticeList') || [];
    });
    useMiracleObserver(
        'NoticeList',
        (key, value, store) => {
            if (key === 'NoticeList') {
                setNoticeList(value);
            }
        }
    )
    return (
        <div>
            <div style={{padding: "64px 24px", margin: '72px 15%',borderRadius: 16, background: '#1d1d27', color: '#ffffff' }}>
                <div style={{ textAlign: 'center', fontSize: 28, fontWeight: 'bold',letterSpacing: '2px' }}>
                    {languageInfo["Announcement" + getStore("language")]}
                </div>
                <div style={{ textAlign: 'center', fontSize: 20, color: '#ebebeb', marginTop: 10 }}>
                    {languageInfo["AnnouncementRemark" + getStore("language")]}
                </div>
            </div>
            <div style={{ margin: '72px 15%' }}>
                {noticeList.map((item, index) => (
                    <div
                        onClick={() => {
                            stationDefault.notify('notice', item);
                            history.push('/notice');
                            document.getElementById("root").scrollTo(0,0);
                        }}
                        key={`noticePage${item.id}`}
                        className="displayFlex canClick"
                        style={index !== noticeList.length - 1 ? { borderBottom: '1px solid #cccccc', paddingBottom: 12 } : { paddingBottom: 12 }}
                    >
                        {JSON.parse(item.message).image ?
                            <img
                                style={{ height: 100,marginRight: 8, objectFit: 'cover', minWidth: 140 }}
                                alt=""
                                src={JSON.parse(item.message).image}
                            />
                            :
                            null
                        }
                        <div
                            className="noticeList"
                        >
                            <div style={{ color: '#333333', fontSize: 24 }}>{JSON.parse(item.message).title}</div>
                            <div style={{ color: '#999999', fontSize: 16, margin: '16px 0' }}>{JSON.parse(item.message).remark}</div>
                            <div style={{ color: '#1b1b1b', fontSize: 16 }}>{new Date(item.noticeTime).toDateString()}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NoticeList;