import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Calculator(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [day, setDay] = useState(360);
    const [amount, setAmount] = useState(1);
    const [price, setPrice] = useState(0);
    const [powerT, setPowerT] = useState(0);
    const [serverPower, setServerPower] = useState(0);
    const [BTC, setBTC] = useState({
        price: getStore('systemSetting') && getStore('systemSetting')[`BTC-USD`] ? getStore('systemSetting')[`BTC-USD`].valueDouble : 0,
        swap:getStore('systemSetting') && getStore('systemSetting')[`DAILY-OUTPUT`] ? getStore('systemSetting')[`DAILY-OUTPUT`].valueDouble : 0,
    });


    useMiracleObserver(
        'getSystemSetting',
        (key, value, store) => {
            if (key === 'getSystemSetting') {
                setBTC({
                    price:value[`BTC-USD`] ? value[`BTC-USD`].valueDouble : 0,
                    swap:value[`DAILY-OUTPUT`] ? value[`DAILY-OUTPUT`].valueDouble : 0,
                });
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    return (
        <div>
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/CalculatorBanner.jpg" alt="" className="banner" />

                <div className="positionAbsolute" style={{ width: '60%', left: '10%', padding: 0, top: '25%', height: '75%' }}>
                    <div style={{ fontWeight: 700, fontSize: getStore("language") === 'CN' ? 56 : 40, width: '100%',letterSpacing:  getStore("language") === 'CN' ? 6 : 0 }}>
                        {languageInfo["CalculatorTitle" + getStore("language")]}
                    </div>
                    <div style={{ fontWeight: 500, fontSize: 24, width: '60%',letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginTop: 30 }}>
                        {languageInfo["CalculatorInfo" + getStore("language")]}
                    </div>
                </div>
            </div>

            <div style={{ background: '#f8f8f8', padding: 36, borderRadius: 16, boxSizing: 'border-box', margin: '36px 10%' }}>
                <div style={{ background: '#ffffff', padding: 36, borderRadius: 16, boxSizing: 'border-box' }}>
                    <div>{languageInfo["CalculatorText1" + getStore("language")]}</div>
                    <div className="flexBetween" style={{ margin: '24px 0' }}>
                        <div style={{ width: 300 }}>{languageInfo["CalculatorText2" + getStore("language")]}</div>
                        <input
                            style={{ flex: 1, margin: 0 }}
                            value="BTC"
                            disabled
                            className="contactInput canClick"
                        />
                    </div>
                    <div className="flexBetween" style={{ margin: '24px 0' }}>
                        <div style={{ width: 300 }}>{languageInfo["CalculatorText3" + getStore("language")]}</div>
                        <div className="flexCenter" style={{ flex: 1, marginRight: 20, position: 'relative' }}>
                            <input
                                style={{ flex: 1, margin: 0 }}
                                value={day}
                                type="number"
                                onInput={(e)=> {
                                    setDay(parseFloat(e.target.value));
                                }}
                                className="contactInput canClick"
                            />
                            <div style={{ position: 'absolute', right: 36  }}>{languageInfo["Days" + getStore("language")]}</div>
                        </div>
                        <input
                            style={{ flex: 1, margin: 0 }}
                            value={amount}
                            type="number"
                            onInput={(e)=> {
                                setAmount(parseFloat(e.target.value));
                            }}
                            className="contactInput canClick"
                        />
                        <div style={{ position: 'absolute', right: 36  }}>TH/s</div>
                    </div>
                    <div className="flexBetween" style={{ margin: '24px 0px 8px' }}>
                        <div style={{ width: 300 }}>{languageInfo["CalculatorText4" + getStore("language")]}</div>
                        <input
                            style={{ flex: 1, margin: 0 }}
                            value={price}
                            type="number"
                            onInput={(e)=> {
                                setPrice(parseFloat(e.target.value));
                            }}
                            className="contactInput canClick"
                        />
                        <div style={{ position: 'absolute', right: 36  }}>$</div>
                    </div>
                    <div className="flexBetween">
                        <div style={{ width: 300 }}></div>
                        <div style={{ color: '#c6c6c6', flex: 1 }}>{price ? price / BTC.price : 0}BTC</div>
                    </div>
                    <div className="flexBetween" style={{ margin: '24px 0px 8px' }}>
                        <div style={{ width: 300 }}>{languageInfo["CalculatorText5" + getStore("language")]}</div>
                        <input
                            style={{ flex: 1, margin: 0 }}
                            value={powerT}
                            type="number"
                            onInput={(e)=> {
                                setPowerT(parseFloat(e.target.value));
                            }}
                            className="contactInput canClick"
                        ></input>
                        <div style={{ position: 'absolute', right: 36  }}>$/T/{languageInfo["Days" + getStore("language")]}</div>
                    </div>
                    <div className="flexBetween">
                        <div style={{ width: 300 }}></div>
                        <div style={{ color: '#c6c6c6', flex: 1 }}>{powerT ? powerT / BTC.price : 0}BTC</div>
                    </div>
                    <div className="flexBetween" style={{ marginTop: 24 }}>
                        <div style={{ width: 300 }}>{languageInfo["CalculatorText6" + getStore("language")]}</div>
                        <div className="flexCenter" style={{ flex: 1, marginRight: 20, position: 'relative' }}>
                            <input
                                style={{ flex: 1, margin: 0 }}
                                value={languageInfo["CalculatorText21" + getStore("language")]}
                                disabled
                                className="contactInput canClick"
                            />
                        </div>
                        <input
                            style={{ flex: 1, margin: 0 }}
                            value={serverPower}
                            type="number"
                            onInput={(e)=> {
                                setServerPower(parseFloat(e.target.value));
                            }}
                            className="contactInput canClick"
                        />
                        <div style={{ position: 'absolute', right: 36  }}>%</div>
                    </div>
                </div>

                {/*<div
                    style={{ width: '80%', borderRadius: 48, fontSize: 18, textAlign: 'center' }}
                    className="loginBtn canClick"
                >
                    计算
                </div>*/}
                <div style={{ background: '#ffffff', padding: 36, borderRadius: 16, boxSizing: 'border-box', margin: '24px 0' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', color: '#1d1d27' }}>
                        <div style={{ width: '48%',padding: 24, borderRadius: 16, boxSizing: 'border-box' }}>
                            <div style={{ fontWeight: 600, color: '#1d1d27', paddingBottom: 16 }}>{languageInfo["CalculatorText12" + getStore("language")]}</div>
                            <div className="flexBetween" style={{ marginBottom: 16 }}>
                                <div>{languageInfo["CalculatorText13" + getStore("language")]}</div>
                                <div>${price}</div>
                            </div>
                            <div className="flexBetween" style={{ marginBottom: 16 }}>
                                <div>{languageInfo["CalculatorText14" + getStore("language")]}</div>
                                <div>${powerT * amount * day}</div>
                            </div>
                            <div className="flexBetween" style={{ marginBottom: 16 }}>
                                <div>{languageInfo["CalculatorText15" + getStore("language")]}</div>
                                <div>${powerT * amount * day + price}</div>
                            </div>
                        </div>
                        <div style={{ width: '48%', background: '#fef3ea', padding: 24, borderRadius: 16, boxSizing: 'border-box' }}>
                            <div style={{ fontWeight: 600, color: '#1d1d27', paddingBottom: 16 }}>{languageInfo["CalculatorText16" + getStore("language")]}</div>
                            <div className="flexBetween" style={{ marginBottom: 16 }}>
                                <div>{languageInfo["CalculatorText17" + getStore("language")]}</div>
                                <div>{parseInt(BTC.swap* BTC.price <= powerT ? (powerT * day * amount + price) / (BTC.swap * amount * BTC.price) : (price) / ((BTC.swap * BTC.price - powerT) * amount),10)}<span style={{ marginLeft: 4 }}>{languageInfo["Days" + getStore("language")]}</span></div>
                            </div>
                            <div className="flexBetween" style={{ marginBottom: 16 }}>
                                <div>{languageInfo["CalculatorText18" + getStore("language")]}</div>
                                <div>${price ? parseFloat(((BTC.swap * amount * BTC.price * day * (100 - (serverPower || 0)) - (powerT * day * amount + price))) / 100).toFixed(2) : 0}</div>
                            </div>
                            <div className="flexBetween" style={{ marginBottom: 16 }}>
                                <div>{languageInfo["CalculatorText19" + getStore("language")]}</div>
                                <div>{price ? parseFloat(((BTC.swap * amount * BTC.price * day) - (powerT * day * amount + price)) / price * 100).toFixed(2) : 0}%</div>
                            </div>
                        </div>
                    </div>

                    <div
                        onClick={() => {
                            history.push('mine');
                            document.getElementById("root").scrollTo(0,0);
                            stationDefault.notify('setBannerIndex', 'mine');
                        }}
                        style={{ width: '80%', borderRadius: 48, fontSize: 18, textAlign: 'center' }}
                        className="loginBtn canClick"
                    >
                        {languageInfo["CalculatorText20" + getStore("language")]}
                    </div>
                </div>
                {getStore("language") === 'CN' ?
                    <div style={{ color: '#555555', lineHeight: 1.5 }}>
                        <div style={{ color: '#1d1d27', fontWeight: 600, margin: "12px 0" }}>免責聲明</div>
                        <div>以上所有數據均基於當前的幣價（${BTC.price}）的計算。沒有考慮未來上述數據可能的變化情況。數據來源為 <a href="http://pool.btc.com" style={{ color: '#a566fd' }}>http://pool.btc.com</a></div>
                        <div style={{ color: '#1d1d27', fontWeight: 600, margin: "12px 0" }}>備註</div>
                        <div>{`1. 歷史回本天數=礦機共享服務費/{礦機共享服務規格*（歷史產出/規格單位/天-電費/規格單位/天）}；`}</div>
                        <div>2. 歷史淨收益=（套餐週期-歷史回本天數）*礦機共享服務規格*（歷史產出/規格單位/天*分成比例-電費/規格單位/天）；</div>
                        <div>3. 歷史產出率=歷史淨收益/礦機共享服務費；</div>
                        <div>4. 分成比例是指客戶分得的部分。經典模式的分成比例為100%，加速模式的分成比例一般在100%以下。具體比例值請套餐的詳情頁；</div>
                        <div>5. 在歷史產出率的計算公式中，我們以礦機共享服務費的成本作為總成本。</div>
                    </div>
                    :
                    <div style={{ color: '#555555', lineHeight: 1.5 }}>
                        <div style={{ color: '#1d1d27', fontWeight: 600, margin: "12px 0" }}>Disclaimer</div>
                        <div>The data above is based on the current price(${BTC.price}). It does not consider possible future changes to the data above. The data is sourced from <a href="http://pool.btc.com" style={{ color: '#a566fd' }}>http://pool.btc.com</a></div>
                        <div style={{ color: '#1d1d27', fontWeight: 600, margin: "12px 0" }}>Notes</div>
                        <div>{`1. Static Breakeven days = hashrate fee / {hashrate amount * (static output/hashrate unit/day - electricity fee/hashrate unit/day) };`}</div>
                        <div>2. Static net revenue = (Plan's duration - static breakeven days) * hashrate amount * (static output/hashrate unit/day * share ratio - electricity fee/hashrate unit/day);</div>
                        <div>3. Static revenue rate = static net revenue / hashrate fee;</div>
                        <div>4.The share ratio refers to the part that the customer receives. The share ratio of the classic mode is 100%, and the share of the accelerator mode is generally less than 100%. For the specific ratio value, please refer to detail page of Plan;</div>
                        <div>5.In the calculation formula of the Static revenue rate, we take the cost of hashrate fee as the total cost.</div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Calculator;