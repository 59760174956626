import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function UserWithdraw(props) {
    let history = useHistory();

    const [accountAddress] = useState(() => {
        return getStore('accountAddress');
    });
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [assets, setAssets] = useState(() => {
        return getStore('Assets');
    });
    const [systemSetting] = useState(() => {
        return getStore('systemSetting');
    });
    const [ethScore] = useState(() => {
        return getStore('ethScore');
    });
    const [coin, setCoin] = useState("BTC");
    const [chain, setChain] = useState("4");
    const [Amount, setAmount] = useState("");
    const [code, setCode] = useState("");
    const [googleCode, setGoogleCode] = useState("");
    const [googleOpen, setGoogleOpen] = useState(false);
    const [googleConfirmOpen, setGoogleConfirmOpen] = useState(false);
    const [Address, setAddress] = useState("");
    const [withdrawList, setWithdrawList] = useState([]);
    const [withdrawTime, setWithdrawTime] = useState(true);

    useMiracleObserver(
        'withdrawalCoinSuccess,getAccountAssets,getAccountStoreOut',
        (key, value, store) => {
            if (key === 'withdrawalCoinSuccess') {
                if (value) {
                    setGoogleOpen(true);
                    setGoogleCode(value);
                    document.getElementById('googleFirstScan').innerText = "";
                    const qart = new window.QRCode(document.getElementById('googleFirstScan'),`otpauth://totp/${document.getElementById("emailInput").value}?secret=${value}&issuer=Mintsat` );
                } else {
                    stationDefault.notify('setPostError', { message: languageInfo["PleaseWait" + getStore("language")] });

                    setAmount(0);
                    appServices.getAccountAssets();
                    appServices.getAccountStoreOut();
                }
            } else if (key === 'getAccountAssets') {
                setAssets(value);
            } else if (key === 'getAccountStoreOut') {
                setWithdrawList(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    const postWithdraw = () => {
        if (withdrawTime && Amount > 0 && chain !== "" && Address !== "" && (coin === "KAS" ? assets.kas : coin === "FIL" ? assets.fil : coin === "BTC" ? assets.btc : coin === "ETH" ? assets.eth : coin === "USDT" ? assets.commission : 0) >= Amount) {
            setWithdrawTime(false);
            stationDefault.notify('setPostError', { message: languageInfo["Submitting" + getStore("language")] });

            setTimeout(() => {
                setWithdrawTime(true);
            }, 3000);
            appServices.withdrawalCoin(coin, Amount, chain, Address, code);
        }
    }

    return (
        <div className="userContent">
            <div className="userTitle">{languageInfo["withdraw" + getStore("language")]}</div>

            <div style={{ padding: '12px 4%' }}>
                <div className="flexBetween" style={{ background: '#f5f3fe', margin: '8px', padding: '12px 36px' }}>
                    <div>{languageInfo["WithdrawCoin" + getStore("language")]}</div>
                    <div className="flexCenter" style={{ fontSize: 16 }}>
                        <div
                            onClick={() => {
                                setCoin("BTC");
                                setChain("4");
                                setAddress("")
                            }}
                            className="flexCenter canClick"
                            style={{ marginLeft: 36 }}
                        >
                            {coin === "BTC" ?
                                <div style={{ background: '#8833ff', width: 14, height: 14, borderRadius: "50%", marginRight: 8 }}></div>
                                :
                                <div className="rateCircle" style={{ borderColor: '#8833ff' }}></div>
                            }
                            <div>BTC</div>
                        </div>
                        <div
                            onClick={() => {
                                setCoin("USDT")
                                setChain("");
                                setAddress("")
                            }}
                            className="flexCenter canClick" style={{ marginLeft: 36 }}
                        >
                            {coin === "USDT" ?
                                <div style={{ background: '#8833ff', width: 14, height: 14, borderRadius: "50%", marginRight: 8 }}></div>
                                :
                                <div className="rateCircle" style={{ borderColor: '#8833ff' }}></div>
                            }
                            <div>USDT</div>
                        </div>
                        {/*<div
                            onClick={() => {
                                setCoin("ETH");
                                setChain("6");
                                setAddress("")
                            }}
                            className="flexCenter canClick"
                            style={{ marginLeft: 36 }}
                        >
                            {coin === "ETH" ?
                                <div style={{ background: '#8833ff', width: 14, height: 14, borderRadius: "50%", marginRight: 8 }}></div>
                                :
                                <div className="rateCircle" style={{ borderColor: '#8833ff' }}></div>
                            }
                            <div>ETH</div>
                        </div>*/}
                        <div
                            onClick={() => {
                                    setCoin("FIL");
                                    setChain("8");
                                    setAddress("")
                                }}
                                className="flexCenter canClick"
                                style={{ marginLeft: 36 }}
                            >
                            {coin === "FIL" ?
                                <div style={{ background: '#8833ff', width: 14, height: 14, borderRadius: "50%", marginRight: 8 }}></div>
                                :
                                <div className="rateCircle" style={{ borderColor: '#8833ff' }}></div>
                            }
                            <div>FIL</div>
                        </div>
                        <div
                            onClick={() => {
                                setCoin("KAS");
                                setChain("9");
                                setAddress("")
                            }}
                            className="flexCenter canClick"
                            style={{ marginLeft: 36 }}
                        >
                            {coin === "KAS" ?
                                <div style={{ background: '#8833ff', width: 14, height: 14, borderRadius: "50%", marginRight: 8 }}></div>
                                :
                                <div className="rateCircle" style={{ borderColor: '#8833ff' }}></div>
                            }
                            <div>KAS</div>
                        </div>
                    </div>
                </div>

                <div className="canClick flexCenter" style={{ position: 'relative', opacity: 1, height: 50, marginLeft: 40 }}>
                    <span style={{ color: '#8833ff' }}>{["","ERC20","Polygon","TRC20","Bitcoin","BSC","ERC20","ALEO","FIL","KAS"][parseInt(chain || 0, 10)] || languageInfo["SelectLink" + getStore("language")]}</span>
                    <img style={{ marginLeft: 8, marginBottom: 2 }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/languageDown.png" />

                    {coin === "USDT" ?
                        <div className="hoverShow" style={{ boxShadow: "0 0 4px #ccc",position: 'absolute', top: 0,left: -16, width: 100, marginTop: 50, fontSize: 16 }}>
                            <div className="list" onClick={() => { setChain("1"); setAddress(""); }} style={{ padding: '12px 16px' }}>ERC20</div>
                            {/*<div className="list" onClick={() => { setChain("2"); setAddress(accountAddress.ethAddress); }} style={{ padding: '12px 16px' }}>Polygon</div>*/}
                            <div className="list" onClick={() => { setChain("3"); setAddress(""); }} style={{ padding: '12px 16px' }}>TRC20</div>
                            {/*<div className="list" onClick={() => { setChain("4"); }} style={{ padding: '12px 16px' }}>BTC</div>*/}
                            <div className="list" onClick={() => { setChain("5"); setAddress(""); }} style={{ padding: '12px 16px' }}>BEP20</div>
                            {/*<div className="list" onClick={() => { setChain("6"); }} style={{ padding: '12px 16px' }}>ETH-ETH</div>*/}
                            {/*<div className="list" onClick={() => { setChain("7"); }} style={{ padding: '12px 16px' }}>FIL</div>*/}
                        </div>
                        :
                        null
                    }
                </div>

                <div style={{ color: '#687898', fontWeight: 'bold', display: 'flex', alignItems: 'center',  background: '#ffffff', borderRadius: 24, border: '1px solid', marginTop: 8 }}>
                    <input
                        placeholder= {languageInfo["InputWithdraw" + getStore("language")]}
                        type="number"
                        style={{ border: 'none', fontWeight: 600,fontSize: 17, flex: 1, padding: "12px 40px", background: 'transparent', color: '#687898', width: 100, boxSizing: 'border-box', borderRadius: 24 }}
                        onInput={(e)=> {
                            setAmount(e.target.value);
                        }}
                        value={Amount}
                    />
                </div>
                <div style={{ color: '#666666', marginLeft: 40, marginTop: 8 }}>{languageInfo["Minimum" + getStore("language")]}:{coin === "KAS" ? (systemSetting["WITHDRAWAL-MIN-KAS"] ? systemSetting["WITHDRAWAL-MIN-KAS"].valueDouble : '?') : coin === "FIL" ? (systemSetting["WITHDRAWAL-MIN-FIL"] ? systemSetting["WITHDRAWAL-MIN-FIL"].valueDouble : '?') : coin === "BTC" ? (systemSetting["WITHDRAWAL-MIN-BTC"] ? systemSetting["WITHDRAWAL-MIN-BTC"].valueDouble : '?') : coin === "ETH" ? (systemSetting["WITHDRAWAL-MIN-ETH"] ? systemSetting["WITHDRAWAL-MIN-ETH"].valueDouble : '?') : coin === "USDT" ? (systemSetting["WITHDRAWAL-MIN-USDT"] ? systemSetting["WITHDRAWAL-MIN-USDT"].valueDouble : '?') : 0}{coin}</div>
                <div style={{ color: '#666666', marginLeft: 40, marginTop: 8 }}>{languageInfo["WithdrawQuantity" + getStore("language")]}:{coin === "KAS" ? assets.kas : coin === "ALEO" ? assets.aleo : coin === "FIL" ? (assets.fil + "").replace(/^(.*\..{4}).*$/,"$1") : coin === "BTC" ? (assets.btc + "").replace(/^(.*\..{6}).*$/,"$1") : coin === "ETH" ? assets.eth : coin === "USDT" ? (assets.commission + "").replace(/^(.*\..{4}).*$/,"$1") : 0}{coin} <span onClick={() => { setAmount(coin === "ALEO" ? assets.aleo : coin === "KAS" ? assets.kas : coin === "BTC" ? assets.btc : coin === "ETH" ? assets.eth : coin === "USDT" ? assets.commission : 0) }} className="canClick" style={{ marginLeft: 8, color: '#8833ff' }}>MAX</span></div>
                {coin === "ETH" && ethScore > 0 ?
                    <div style={{ color: '#666666', marginLeft: 40, marginTop: 8 }}>{ethScore}ETH{languageInfo["PledgeAmount" + getStore("language")]}</div>
                    : null}
                {coin === "BTC" && assets.frozenBtc > 0 ?
                    <div style={{ color: '#666666', marginLeft: 40, marginTop: 8 }}>{(assets.frozenBtc + "").replace(/^(.*\..{6}).*$/,"$1")}BTC{languageInfo["NotAvailable" + getStore("language")]}</div>
                    : null}
                <div style={{ color: '#687898', fontWeight: 'bold', display: 'flex', alignItems: 'center',  background: '#ffffff', borderRadius: 24, border: '1px solid', marginTop: 16 }}>
                    <input
                        placeholder={languageInfo["googleCode" + getStore("language")]}
                        style={{ border: 'none', fontWeight: 600,fontSize: 17, flex: 1, padding: "12px 40px", background: 'transparent', color: '#687898', width: 100, boxSizing: 'border-box', borderRadius: 24 }}
                        value={code}
                        onInput={(e)=> {
                            setCode(e.target.value);
                        }}
                        className="inputStyle canClick"
                    />
                    {googleCode !== '' ?
                        <div
                            className="canClick newHomeBtn"
                            onClick={() => {
                                setGoogleOpen(true);
                            }}
                            style={{width: 100, padding: 0, borderRadius: 4, lineHeight: '36px', fontSize: 13, margin: "0 16px", textAlign: 'center'}}
                        >
                            <span>{languageInfo["copyKey" + getStore("language")]}</span>
                        </div>
                        :
                        null
                    }
                </div>
                <div style={{ color: '#666666', marginLeft: 40, marginTop: 8 }}><span style={{ color: '#8833ff' }} className="canClick" onClick={() => {  appServices.withdrawalCoin(coin, 0, chain, Address, code); }}>{languageInfo["googleCodeNoticeA" + getStore("language")]}</span>{languageInfo["googleCodeNoticeB" + getStore("language")]}</div>
                <div style={{ color: '#687898', fontWeight: 'bold', display: 'flex', alignItems: 'center',  background: '#ffffff', borderRadius: 24, border: '1px solid', marginTop: 16 }}>
                    <input
                        placeholder={languageInfo["ReceivingAddress" + getStore("language")]}
                        style={{ border: 'none', fontWeight: 600,fontSize: 17, flex: 1, padding: "12px 40px", background: 'transparent', color: '#687898', width: 100, boxSizing: 'border-box', borderRadius: 24 }}
                        onInput={(e)=> {
                            setAddress(e.target.value);
                        }}
                        value={Address}
                    />
                </div>
                <div className="flexCenter" style={{ justifyContent: 'center' }}>
                    <div
                        onClick={postWithdraw}
                        className="canClick"
                        style={{ opacity: withdrawTime && Amount > 0 && chain !== "" && Address !== "" && (coin === "KAS" ? assets.kas : coin === "FIL" ? assets.fil : coin === "BTC" ? assets.btc : coin === "ETH" ? assets.eth : coin === "USDT" ? assets.commission : 0) >= Amount ? 1 : 0.3,background: '#8833FF', color: '#ffffff', padding: "12px 20px",fontSize: 18, borderRadius: 24, margin: 24, minWidth: 120, textAlign: 'center' }}
                    >
                        {languageInfo["Confirm" + getStore("language")]}
                    </div>
                </div>


                <div style={{ margin: '24px 8px 8px', padding: '12px 36px' }}>
                    <div>{languageInfo["WithdrawCoin" + getStore("language")]}</div>
                </div>
                <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden" }}>
                    <div className="flexCenter" style={{ height: 56, paddingLeft: 40, background: '#faf9fe', color: '#a3a3a3' }}>
                        <div style={{ width: "20%" }}>{languageInfo["OrderDate" + getStore("language")]}</div>
                        <div style={{ width: "20%" }}>{languageInfo["OrderAmount" + getStore("language")]}</div>
                        <div style={{ width: "20%" }}>
                            <span style={{ marginLeft: 4 }}>{languageInfo["OrderStatus" + getStore("language")]}</span>
                        </div>
                        <div style={{ width: "40%" }}>{languageInfo["Remark" + getStore("language")]}</div>
                    </div>
                    {withdrawList.map((item => (
                        <div key={`withdrawList${item.id}`} className="flexCenter" style={{ height: 56, paddingLeft: 40, color: '#1d1d27' }}>
                            <div style={{ width: "20%" }}>{item.outTime.split("T")[0]}</div>
                            <div style={{ width: "20%" }}>{(item.outAmount + "").replace(/^(.*\..{6}).*$/,"$1")}{item.outAssetsType}</div>
                            <div style={{ width: "20%" }}>
                                <span style={{ padding: '4px 12px', borderRadius: 12, color: '#ffffff', background: item.outStatus === "agree" ? "#9ee517" : item.outStatus === "Unaudited" ? "#ffba00" : "#e85600" }}>{languageInfo[item.outStatus + getStore("language")]}</span>
                            </div>
                            <div style={{ width: "40%" }}>{item.outHashValue.length > 1 ? item.outHashValue : ""}</div>
                        </div>
                    )))}
                </div>
            </div>

            <div className="scaleOpen" style={{ pointerEvents: googleOpen ? 'all' : 'none', background:  googleOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: googleOpen ? 1 : 0, transform: googleOpen ? 'scale(1)' :'scale(0.8)',
                        width: 500,
                        height: 360,
                        background: '#ffffff'
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setGoogleOpen(false);
                            setGoogleConfirmOpen(true);
                        }}
                    />
                    <div className="flexCenter">
                        <div style={{ fontWeight: 600 }}>{languageInfo["RegistrationSuccess" + getStore("language")]}</div>
                    </div>

                    <div style={{ textAlign: 'left', marginTop: 20 }}>
                        {languageInfo["AuthenticatorFirst" + getStore("language")]}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                        <div
                            style={{ width: '100%', borderRadius: 48, marginRight: 16 }}
                            className="loginBtn canClick"
                            onClick={() => {
                                window.open("https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=US")
                            }}
                        >
                            Android
                        </div>
                        <div
                            style={{ width: '100%', borderRadius: 48 }}
                            className="loginBtn canClick"
                            onClick={() => {
                                window.open("https://apps.apple.com/cn/app/google-authenticator/id388497605")
                            }}
                        >
                            IOS
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            window.open("https://amintsat.s3.ap-southeast-1.amazonaws.com/google.pdf")
                        }}
                        style={{ textAlign: 'left', marginBottom: 20, cursor: 'pointer', color: '#8833ff' }}
                    >
                        {languageInfo["AuthenticatorGoogle" + getStore("language")]}
                    </div>
                    <div style={{ textAlign: 'left' }}>
                        {languageInfo["AuthenticatorSecond" + getStore("language")]}
                    </div>
                    <div id="googleFirstScan" className="scanCode"></div>
                    <div className="AppInput">
                        <div style={{ flex: 1, fontWeight: 700, textAlign: 'left' }}>{googleCode}</div>
                        <textarea style={{ opacity: 0, height: 1, padding: 0 , border: 0, position: 'absolute',top: 0, left: 0 }} id="googleCode" readOnly="readonly">Copy Success</textarea>

                        <div
                            className="canClick flexCenter"
                            onClick={() => {
                                var e = document.getElementById("googleCode");
                                e.value = googleCode;
                                e.select(); //
                                document.execCommand("Copy");
                            }}
                        >
                            <span style={{ color: '#8833ff' }}>{languageInfo["copyKey" + getStore("language")]}</span>
                            <img
                                className="canClick"
                                style={{  width: 20, height: 20, padding: 4 }}
                                alt=""
                                src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/Copy.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="scaleOpen" style={{ pointerEvents: googleConfirmOpen ? 'all' : 'none', background:  googleConfirmOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: googleConfirmOpen ? 1 : 0, transform: googleConfirmOpen ? 'scale(1)' :'scale(0.8)',
                        width: 500,
                        background: '#ffffff'
                    }}
                >
                    <div className="flexCenter">
                        <div style={{ fontWeight: 600 }}>{languageInfo["GoogleConfirm" + getStore("language")]}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            style={{ width: '100%', borderRadius: 48, marginRight: 16 }}
                            className="loginBtn canClick"
                            onClick={() => {
                                setGoogleConfirmOpen(false);
                            }}
                        >
                            {languageInfo["GoogleConfirm1" + getStore("language")]}
                        </div>
                        <div
                            style={{ width: '100%', borderRadius: 48 }}
                            className="loginBtn canClick"
                            onClick={() => {
                                setGoogleConfirmOpen(false);
                                setGoogleOpen(true);
                            }}
                        >
                            {languageInfo["GoogleConfirm2" + getStore("language")]}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default UserWithdraw;