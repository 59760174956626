import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function FIL(props) {
    let history = useHistory();
    const [mobileOpen, setMobileOpen] = useState(false);

    const [languageInfo] = useState(() => {
        // setTimeout(() => {
        //     setMouseWheel()
        // },500)
        return getStore('languageInfo');
    });


    const toFIL = () => {
        const select = JSON.parse(JSON.stringify(getStore('minSelect')));
        select.type = "FIL";
        initStore(select, 'minSelect');
        history.push('/mine');
    }

    // const setMouseWheel = () => {
    //     if (document.getElementById("FILMine")) {
    //         try {
    //             document.getElementById("FILMine").contentWindow.document.onmousewheel=function(event){
    //                 event.preventDefault();
    //             };
    //         } catch (e) {
    //
    //         }
    //     }
    // }

    return (
        <div className="mobilePaddingTop" style={mobileOpen ? { paddingTop: 0 } : {}}>
            <div className="FILMine">
                <div className={"homeVideo" + (mobileOpen ? " FILIFrame" : " FILMine")}>
                    <iframe
                        frameBorder="0"
                        id="FILMine"
                        title="FIL Mine"
                        width="100%"
                        height="100%"
                        src="https://amintsat.s3.ap-southeast-1.amazonaws.com/room/index.html"
                    >
                    </iframe>
                </div>
                <div className="FILBack mobileShow" onClick={() => { setMobileOpen(false) }} style={{ opacity: mobileOpen ? 1 : 0 }}>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/BackScreen.png" alt="" />
                </div>
                {!mobileOpen ?
                    <div className="mobileShow" onClick={() => { setMobileOpen(true) }} style={{  zIndex: 26, position: 'absolute', bottom: 20, right: 20, width: 80, height: 80 }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/FullScreen.png" alt="" style={{ width: 80, height: 80 }} />
                    </div>
                    :
                    null
                }
            </div>
            <div style={{ position: 'relative', textAlign: 'center', lineHeight: 0 }}>
                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/FILbanner.jpg" alt="" className="banner FILBanner" />

                <div className="pageBanner">
                    <div style={{ fontWeight: 500, fontSize: 20, width: '70%',color: '#b683fc', letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginBottom: 12 }}>
                        {languageInfo["FILTitle" + getStore("language")]}
                    </div>
                    <div className="BannerFont" style={{ fontWeight: 700, fontSize: getStore("language") === 'CN' ? 56 : 48,letterSpacing:  getStore("language") === 'CN' ? 6 : 0 }}>
                        {languageInfo["FILNotice" + getStore("language")]}
                    </div>
                    <div className="BannerFont" style={{ fontWeight: 500, fontSize: 20, lineHeight: getStore("language") === 'CN' ? "32px" : 1.2,letterSpacing: getStore("language") === 'CN' ? 1 : 0, margin: "30px 0", color: '#858585' }}>
                      {languageInfo["FILInfo1" + getStore("language")]}
                    </div>
                    <div className="flexCenter">
                        <div className="CardBtn" onClick={toFIL} style={{ background: '#8833ff' }}>
                            <div>{languageInfo["FILGo" + getStore("language")]}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="content" style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', flexWrap: 'wrap', padding: '3% 0' }}>
                <div className="ALEOCard">
                    <div className="CardTop">{languageInfo["FILCardTop1" + getStore("language")]}</div>
                    <div className="ALEOCardContent">
                        <div>
                            <span className="positionRelative">
                                {languageInfo["FILCardTitle1" + getStore("language")]}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ALEOCard">
                    <div className="CardTop">{languageInfo["FILCardTop2" + getStore("language")]}</div>
                    <div className="ALEOCardContent">
                        <div className="positionRelative">
                            <span>{languageInfo["FILCardTitle2" + getStore("language")]}</span>
                        </div>
                    </div>
                </div>
                <div className="ALEOCard">
                    <div className="CardTop">{languageInfo["FILCardTop3" + getStore("language")]}</div>
                    <div className="ALEOCardContent">
                        <div className="positionRelative">
                            <span>{languageInfo["FILCardTitle3" + getStore("language")]}</span>
                        </div>
                    </div>
                </div>
                <div className="ALEOCard" style={{ width: "42%", margin: 16 }}>
                    <div className="CardCircle" style={{ background: '#fe782f' }}></div>
                    <div style={{ height: 30 }}></div>
                    <div className="ALEOCardContent">
                        <div style={{ color: "#919193" }}>{languageInfo["FILCardTop4" + getStore("language")]}</div>
                    </div>
                </div>
                <div className="ALEOCard" style={{ width: "42%", margin: 16 }}>
                    <div className="CardCircle" style={{ background: '#cd7ad6' }}></div>
                    <div style={{ height: 30 }}></div>
                    <div className="ALEOCardContent">
                        <div style={{ color: "#919193" }}>{languageInfo["FILCardTop5" + getStore("language")]}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FIL;