import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Business(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [amount, setAmount] = useState('');

    return (
        <div>
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/BusinessBanner.jpg" alt="" className="banner" />

                <div className="positionAbsolute" style={{ width: '60%', left: '10%', padding: 0, top: '25%' }}>
                    <div style={{ fontWeight: 700, fontSize: getStore("language") === 'CN' ? 56 : 40, width: getStore("language") === 'CN' ? '65%' : '80%',letterSpacing:  getStore("language") === 'CN' ? 6 : 0 }}>
                        {languageInfo["BusinessTitle" + getStore("language")]}
                    </div>
                    <div style={{ fontWeight: 500, fontSize: 24, width: '60%',letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginTop: 30 }}>
                        {languageInfo["BusinessInfo" + getStore("language")]}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'start', textAlign: 'center', fontWeight: 'bold', marginTop: 60 }}>
                        <div  onClick={() => {
                            history.push("contact");
                            document.getElementById("root").scrollTo(0,0);
                            stationDefault.notify('setBannerIndex', "contact");
                        }} className="canClick" style={{ background: '#252B2B', color: '#ffffff', padding: 12, borderRadius: 32, minWidth: 100, marginRight: 24 }}>{languageInfo["Information2" + getStore("language")]}</div>
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', padding: "32px 24px", margin: '72px 15%' }}>
                <div style={{ width: '45%', fontSize: 28, fontWeight: 'bold' }}>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/BusinessNFT.png" alt="" style={{ width: '80%' }} />
                </div>
                <div style={{ width: '55%', letterSpacing: getStore("language") === "CN" ?'3px' : 0 }}>
                    <div style={{ color: '#1d1d27', fontSize: 36, fontWeight: 600 }}>{languageInfo["BusinessText1" + getStore("language")]}</div>
                    <div style={{ color: '#8f8f90', marginTop: 24, fontSize: getStore("language") === "CN" ? 24 : 18, lineHeight: getStore("language") === "CN" ?'40px' : '24px' }}>{languageInfo["BusinessText2" + getStore("language")]}</div>
                </div>
            </div>
            {getStore("language") === 'CN' ?
                <div style={{padding: "32px", margin: '72px 15%',borderRadius: 16, background: '#1d1d27', color: '#ffffff' }}>
                    <div className="flexBetween">
                        <div style={{ fontSize: 36, fontWeight: 600 }}>四个合作可供选择</div>
                        <div style={{ flex: 1, textAlign: 'center', fontSize: 13 }}>成為這四類合作的合作彩伴，享受優惠和折扣。</div>
                    </div>
                    <div style={{ background: '#3a3b47',borderRadius: 16, margin: '36px 0', padding: "12px 36px" }}>
                        <div><h3 >渠道推廣合作</h3></div>
                        <div>
                            <h6 >用於（但不限於）:</h6>
                            <div  className="businessList">
                                <span>· 礦池</span>
                                <span>· 交易所</span>
                                <span>· 錢包</span>
                                <span>· 媒體</span>
                                <span>· 論壇</span>
                                <span>· 社交媒體</span>
                                <span>· 互聯網金融平台，及其他；</span>
                                <span>· 廣告聯盟</span>
                            </div>
                            <div >
                                <p>這種合作以CPS廣告導流為主，渠道合作夥伴提供廣告位，向Mintsat官網或活動頁導流</p>
                                <p>常用的展現方式包括：</p>
                                <div  className="businessList" style={{ flexDirection: 'column' }}>
                                    <span>· 橫幅廣告位、鏈接或導航欄</span>
                                    <span>· 套餐計劃動態展示窗</span>
                                    <span>· 網盟動態廣告</span>
                                </div>
                                <p>按銷售額的比例獲取代理奖励回報。</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: '#3a3b47',borderRadius: 16, margin: '36px 0', padding: "12px 36px" }}>
                        <div>
                            <div className="head" ><h3 >銷售代理合作</h3> </div>
                            <div className="describe" ><h6 >用於（但不限於）:</h6>
                                <div className="businessList" >
                                    <span >· 礦池</span>
                                    <span >· 交易所</span>
                                    <span >· 錢包</span>
                                    <span >· 媒體</span>
                                    <span >· 論壇</span>
                                    <span >· 社交媒體</span>
                                    <span >· 互聯網金融平台</span>
                                    <span >· 廣告聯盟</span>
                                    <span >· 傳統行業的分銷商，及其他；</span>
                                    <span >· 擁有用戶資源的個人代理商</span>
                                </div>
                                <div className="businessList" style={{ flexDirection: 'column' }}>
                                    <p >合作可以採取多種形式:</p>
                                    <div>1.線上代理：銷售代理在自己的站點直接售賣Mintsat的產品，由Mintsat提供技術支持，幫助搭建分銷站。代理商可以使用Mintsat或雙品牌，在自己的站點上設置銷售渠道進行礦機共享服務推薦。用戶可以在代理的站點上完成訂單。</div>
                                    <div>2.線下代理：銷售代理直接售賣Mintsat的產品，獲得分銷回報。代理商可以通過個人專屬鏈接售賣產品，Mintsat向代理商支付佣金。</div>
                                    <p >按銷售額的比例獲取代理奖励回報。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: '#3a3b47',borderRadius: 16, margin: '36px 0', padding: "12px 36px 36px" }}>
                        <div><h3 >大客戶代理</h3></div>
                        <div>這些合作對象主要是（但不限於）擁有大客戶資源的企業或個人代理。</div>
                        <div>銷售代理直接售賣Mintsat官網的商品，直接獲得分銷回報。</div>
                        <div>按銷售額比例獲取大客戶奖励回報。</div>
                    </div>
                    <div style={{ background: '#3a3b47',borderRadius: 16, margin: '36px 0', padding: "12px 36px 36px" }}>
                        <div><h3 >其他形式的合作</h3></div>
                        <div>礦場合作：誠邀全球優質礦場合作礦機共享服務的接入、礦機託管等業務，礦場可獲得正向現金流和更快速地回本週期，Mintsat將提供強大的礦機共享調度技術的支持服務。</div>
                        <div>礦池合作：誠邀全球優質礦池合作接入，輕鬆獲得更多礦機共享服務的接入，同時為用戶提供更多選擇。</div>
                        <div>交易所平台合作：誠邀交易所做支付方式的對接合作，為交易所帶來流動性的同時，為用戶提供更優支付便利。</div>
                    </div>
                </div>
                :
                <div style={{padding: "32px", margin: '72px 15%',borderRadius: 16, background: '#1d1d27', color: '#ffffff' }}>
                    <div className="flexBetween">
                        <div style={{ fontSize: 36, fontWeight: 600 }}>There are four types of partnerships available</div>
                        <div style={{ flex: 1, textAlign: 'center', fontSize: 13 }}>Become a partner with one of these four types of available partnerships to enjoy benefits and discounts.</div>
                    </div>
                    <div style={{ background: '#3a3b47',borderRadius: 16, margin: '36px 0', padding: "12px 36px" }}>
                        <div><h3 >Promotional partnerships</h3></div>
                        <div>
                            <h6 >Intended for (but not limited to):</h6>
                            <div  className="businessList">
                                <span>· Mining pools</span>
                                <span>· Exchanges</span>
                                <span>· Wallet</span>
                                <span>· Media outlets</span>
                                <span>· Forums</span>
                                <span>· Social media</span>
                                <span>· Online finance platforms, and more;</span>
                                <span>· Advertising networks</span>
                            </div>
                            <div >
                                <p>Promotions for this partnership primarily take the form of CPS ads, with the partner providing the placement and directing traffic to the Mintsat website or an agreed-upon event page.</p>
                                <p>Frequent display methods include:</p>
                                <div  className="businessList" style={{ flexDirection: 'column' }}>
                                    <span>· Banner advertising placement, link, or navigation bar</span>
                                    <span>· Plan status window display</span>
                                    <span>· Network affiliate advertising</span>
                                </div>
                                <p>Commissions are paid based on sales.</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: '#3a3b47',borderRadius: 16, margin: '36px 0', padding: "12px 36px" }}>
                        <div>
                            <div className="head" ><h3 >Sales agent partnerships</h3> </div>
                            <div className="describe" ><h6 >Intended for (but not limited to):</h6>
                                <div className="businessList" >
                                    <span >· Mining pools</span>
                                    <span >· Exchanges</span>
                                    <span >· Wallet</span>
                                    <span >· Media outlets</span>
                                    <span >· Forums</span>
                                    <span >·  Social media</span>
                                    <span >· Online finance platforms</span>
                                    <span >· Advertising networks</span>
                                    <span >· Traditional industry distributors, and more;</span>
                                    <span >· Personal agents with user resources</span>
                                </div>
                                <div className="businessList" style={{ flexDirection: 'column' }}>
                                    <p >Partnerships can take several forms:</p>
                                    <div>1.Online agents: sales agents sell Mintsat products directly through their own sites, with Mintsat providing technical support to set up the distribution site. Agents can set up sales channels for referral mining on their own website, using Mintsat or two-party branding. Users can complete the order on the agent's own site.</div>
                                    <div>2.Offline agents: sales agents sell Mintsat products directly and receive direct distribution returns. Agents can sell products via exclusive personal links, with Mintsat paying a brokerage fee to the agent.</div>
                                    <p >Commissions are paid based on sales.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: '#3a3b47',borderRadius: 16, margin: '36px 0', padding: "12px 36px 36px" }}>
                        <div><h3 >VIP agent partnerships</h3></div>
                        <div>These partnerships are primarily for (but not limited to) businesses or personal representatives of VIPS.</div>
                        <div>In these partnerships, sales agents directly sell Mintsat products and achieve a referral of the immediate returns.</div>
                        <div>Commissions are based on VIP customer sales.</div>
                    </div>
                    <div style={{ background: '#3a3b47',borderRadius: 16, margin: '36px 0', padding: "12px 36px 36px" }}>
                        <div><h3 >Other forms of partnerships</h3></div>
                        <div>Mining farm partnerships: We welcome superior mining operations from around the world to join us in Cloud Hashrate Sharing or provision of mining management services. Mining operations can obtain positive cash flows and faster return cycles, while Mintsat provides referral dispatching support.</div>
                        <div>Mining pool partnerships: We welcome cooperation with outstanding mining pools. Through a partnership with Mintsat, pools can access greater computing resources, while users can enjoy more mining options.</div>
                        <div>Exchange platform partnerships: We welcome partnerships with exchanges, offering them greater fluidity along with added payment convenience and support for their users.</div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Business;