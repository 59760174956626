import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Cloud(props) {
    let history = useHistory();
    const [adviceIndex, setAdviceIndex] = useState("全部");

    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/CloudSoon.png" alt="" style={{ padding: 120 }} />
            </div>
        </div>
    );
}

export default Cloud;