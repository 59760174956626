import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function UserSetting(props) {
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    let history = useHistory();
    const [bindOpen, setBindOpen] = useState(0);
    const [accountAddress, setAccountAddress] = useState(() => {
        return getStore('accountAddress');
    });
    const [bindAddress, setBindAddress] = useState("");
    const [googleCode, setGoogleCode] = useState("");
    const [googleOpen, setGoogleOpen] = useState(false);


    useMiracleObserver(
        'getAccountAddress,bindSuccess,withdrawalCoinSuccess',
        (key, value, store) => {
            if (key === 'getAccountAddress') {
                setAccountAddress(value);
            } else if (key === 'bindSuccess') {
                setBindAddress("");
                appServices.getAccountAddress();
            } else if (key === 'withdrawalCoinSuccess') {
                if (value) {
                    setGoogleOpen(true);
                    setGoogleCode(value);
                    document.getElementById('googleFirstScan').innerText = "";
                    const qart = new window.QRCode(document.getElementById('googleFirstScan'),`otpauth://totp/${document.getElementById("emailInput").value}?secret=${value}&issuer=Mintsat` );
                    appServices.getAccountAssets();
                }
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    const getAddress = () => {
        if (window.ethereum) {
            window.ethereum.request({method: 'eth_requestAccounts'}).then(function(addresses) {
                setBindAddress( addresses[0]);
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    return (
        <div className="userContent">
            <div className="userTitle">{languageInfo["user" + getStore("language")]}</div>
            <div style={{ padding: '4% 12%' }}>
                <div className="flexBetween" style={{ height: 60, borderBottom: '1px solid #cccccc' }}>
                    <div className="flexCenter">
                        <div style={{ width: 130 }}>{languageInfo["Password" + getStore("language")]}</div>
                        <span style={{ color: '#c3c3c3' }}>{languageInfo["HasSet" + getStore("language")]}</span>
                    </div>
                </div>
                <div className="flexBetween" style={{ height: 60, borderBottom: '1px solid #cccccc' }}>
                    <div className="flexCenter">
                        <div style={{ width: 130 }}>{languageInfo["googleKey" + getStore("language")]}</div>
                        <span style={{ color: '#c3c3c3' }}>{languageInfo[(getStore("Assets").totpSecret ? "HasSet" : "NotSet" ) + getStore("language")]}</span>
                    </div>
                    {!getStore("Assets").totpSecret ?
                        <div
                            onClick={() => {
                                appServices.withdrawalCoin("BTC", 0, "BTC", "", "");
                            }}
                            className="canClick"
                            style={{ background: '#8833FF', color: '#ffffff', padding: 8, borderRadius: 24, margin: 10, minWidth: 120, textAlign: 'center' }}
                        >
                            {languageInfo["ToBind" + getStore("language")]}
                        </div>
                        :
                        null
                    }
                </div>
                <div className="flexBetween" style={{ height: 60, borderBottom: '1px solid #cccccc' }}>
                    <div className="flexCenter">
                        <div style={{ width: 130 }}>{languageInfo["Email" + getStore("language")]}</div>
                        <span style={{ color: '#c3c3c3' }}>{getStore('email')}</span>
                    </div>
                </div>
                <div className="flexBetween" style={{ height: 60, borderBottom: '1px solid #cccccc' }}>
                    <div className="flexCenter">
                        <div style={{ width: 130 }}>ERC20 {languageInfo["Address" + getStore("language")]}</div>
                        <span style={{ color: '#c3c3c3' }}>{accountAddress.ethAddress || languageInfo["NotSet" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            getAddress();
                            setBindOpen(1);
                        }}
                        className="canClick"
                        style={{ background: '#8833FF', color: '#ffffff', padding: 8, borderRadius: 24, margin: 10, minWidth: 120, textAlign: 'center' }}
                    >
                        {languageInfo["ToBind" + getStore("language")]}
                    </div>
                </div>
                <div className="flexBetween" style={{ height: 60, borderBottom: '1px solid #cccccc' }}>
                    <div className="flexCenter">
                        <div style={{ width: 130 }}>BEP20 {languageInfo["Address" + getStore("language")]}</div>
                        <span style={{ color: '#c3c3c3' }}>{accountAddress.bscAddress || languageInfo["NotSet" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            getAddress();
                            setBindOpen(5);
                        }}
                        className="canClick"
                        style={{ background: '#8833FF', color: '#ffffff', padding: 8, borderRadius: 24, margin: 10, minWidth: 120, textAlign: 'center' }}
                    >
                        {languageInfo["ToBind" + getStore("language")]}
                    </div>
                </div>
                <div className="flexBetween" style={{ height: 60, borderBottom: '1px solid #cccccc' }}>
                    <div className="flexCenter">
                        <div style={{ width: 130 }}>TRC20 {languageInfo["Address" + getStore("language")]}</div>
                        <span style={{ color: '#c3c3c3' }}>{accountAddress.trcAddress || languageInfo["NotSet" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            getAddress();
                            setBindOpen(3);
                        }}
                        className="canClick"
                        style={{ background: '#8833FF', color: '#ffffff', padding: 8, borderRadius: 24, margin: 10, minWidth: 120, textAlign: 'center' }}
                    >
                        {languageInfo["ToBind" + getStore("language")]}
                    </div>
                </div>
                <div className="flexBetween" style={{ height: 60, borderBottom: '1px solid #cccccc' }}>
                    <div className="flexCenter">
                        <div style={{ width: 130 }}>BTC {languageInfo["Address" + getStore("language")]}</div>
                        <span style={{ color: '#c3c3c3' }}>{accountAddress.btcAddress || languageInfo["NotSet" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            getAddress();
                            setBindOpen(2);
                        }}
                        className="canClick"
                        style={{ background: '#8833FF', color: '#ffffff', padding: 8, borderRadius: 24, margin: 10, minWidth: 120, textAlign: 'center' }}
                    >
                        {languageInfo["ToBind" + getStore("language")]}
                    </div>
                </div>
                <div className="flexBetween" style={{ height: 60 }}>
                    <div className="flexCenter">
                        <div style={{ width: 130 }}>FIL {languageInfo["Address" + getStore("language")]}</div>
                        <span style={{ color: '#c3c3c3' }}>{accountAddress.filAddress || languageInfo["NotSet" + getStore("language")]}</span>
                    </div>
                    <div
                        onClick={() => {
                            getAddress();
                            setBindOpen(4);
                        }}
                        className="canClick"
                        style={{ background: '#8833FF', color: '#ffffff', padding: 8, borderRadius: 24, margin: 10, minWidth: 120, textAlign: 'center' }}
                    >
                        {languageInfo["ToBind" + getStore("language")]}
                    </div>
                </div>
            </div>
            <div className="scaleOpen" style={{ pointerEvents: googleOpen ? 'all' : 'none', background:  googleOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: googleOpen ? 1 : 0, transform: googleOpen ? 'scale(1)' :'scale(0.8)',
                        width: 500,
                        height: 360,
                        background: '#ffffff'
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setGoogleOpen(false);
                        }}
                    />
                    <div className="flexCenter">
                        <div style={{ fontWeight: 600 }}>{languageInfo["RegistrationSuccess" + getStore("language")]}</div>
                    </div>

                    <div style={{ textAlign: 'left', marginTop: 20 }}>
                        {languageInfo["AuthenticatorFirst" + getStore("language")]}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                        <div
                            style={{ width: '100%', borderRadius: 48, marginRight: 16 }}
                            className="loginBtn canClick"
                            onClick={() => {
                                window.open("https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=US")
                            }}
                        >
                            Android
                        </div>
                        <div
                            style={{ width: '100%', borderRadius: 48 }}
                            className="loginBtn canClick"
                            onClick={() => {
                                window.open("https://apps.apple.com/cn/app/google-authenticator/id388497605")
                            }}
                        >
                            IOS
                        </div>
                    </div>
                    <div style={{ textAlign: 'left' }}>
                        {languageInfo["AuthenticatorSecond" + getStore("language")]}
                    </div>
                    <div id="googleFirstScan" className="scanCode"></div>
                    <div className="AppInput">
                        <div style={{ flex: 1, fontWeight: 700, textAlign: 'left' }}>{googleCode}</div>
                        <textarea style={{ opacity: 0, height: 1, padding: 0 , border: 0, position: 'absolute',top: 0, left: 0 }} id="googleCode" readOnly="readonly">Copy Success</textarea>

                        <div
                            className="canClick flexCenter"
                            onClick={() => {
                                var e = document.getElementById("googleCode");
                                e.value = googleCode;
                                e.select(); //
                                document.execCommand("Copy");
                            }}
                        >
                            <span style={{ color: '#8833ff' }}>{languageInfo["copyKey" + getStore("language")]}</span>
                            <img
                                className="canClick"
                                style={{  width: 20, height: 20, padding: 4 }}
                                alt=""
                                src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/Copy.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="scaleOpen" style={{ pointerEvents: bindOpen !== 0 ? 'all' : 'none', background:  bindOpen !== 0 ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: bindOpen !== 0 ? 1 : 0, transform: bindOpen !== 0 ? 'scale(1)' :'scale(0.8)',
                        width: 400,
                        height: 160,
                        background: '#ffffff'
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setBindOpen(0);
                        }}
                    />
                    <div className="flexCenter">
                        <div style={{ fontWeight: 600 }}>{languageInfo["BindWallet" + getStore("language")]}</div>
                    </div>
                    <div className="AppInput">
                        <div style={{ flex: 1, borderRadius: 8, position: 'relative' }}>
                            {!bindAddress ?
                                <div style={{ padding: '0 12px',color: '#c3c3c3',opacity: 0.5, position: 'absolute', display: 'flex', alignItems: 'center', height: '100%', top: 0, pointerEvents: 'none' }}>
                                    <span style={{ fontSize: 16 }}>{languageInfo["inputAddress" + getStore("language")]}</span>
                                </div>
                                :
                                null
                            }

                            <input
                                id="emailInput"
                                value={bindAddress}
                                onInput={(e)=> {
                                    setBindAddress(e.target.value);
                                }}
                                className="inputStyle canClick"
                            />
                        </div>
                    </div>

                    <div
                        style={{ width: '100%', borderRadius: 48 }}
                        className="loginBtn canClick"
                        onClick={() => {
                            if (bindAddress && bindOpen === 1) {
                                setBindOpen(0)
                                appServices.bindAccountETH(bindAddress)
                            } else if (bindAddress && bindOpen === 2) {
                                setBindOpen(0)
                                appServices.bindAccountBTC(bindAddress)
                            } else if (bindAddress && bindOpen === 3) {
                                setBindOpen(0)
                                appServices.bindAccountTRX(bindAddress)
                            } else if (bindAddress && bindOpen === 4) {
                                setBindOpen(0)
                                appServices.bindAccountFIL(bindAddress)
                            } else if (bindAddress && bindOpen === 5) {
                                setBindOpen(0)
                                appServices.bindAccountBSC(bindAddress)
                            }
                        }}
                    >
                        {languageInfo["Sure" + getStore("language")]}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default UserSetting;