import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function UserElectricity(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [assets, setAssets] = useState(() => {
        return getStore('Assets');
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [Amount, setAmount] = useState("");
    const [productIncomeList, setProductIncomeList] = useState(() => {
        return getStore('productIncomeList');
    });
    const [systemSetting] = useState(() => {
        return getStore('systemSetting');
    });
    const [TransPower, setTransPower] = useState([]);

    useMiracleObserver(
        'transferUSDSuccess,getAccountAssets,queryProductIncomeList,TransPower',
        (key, value, store) => {
            if (key === 'transferUSDSuccess') {
                appServices.getAccountAssets();
                appServices.queryLog("TransPower");
            } else if (key === 'getAccountAssets') {
                setAssets(value);
            } else if (key === 'queryProductIncomeList') {
                setProductIncomeList(value);
            } else if (key === "TransPower") {
                setTransPower(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    return (
        <div className="userContent">
            <div className="userTitle">{languageInfo["electricity" + getStore("language")]}</div>
            <div className="flexBetween" style={{ background: '#f5f3fe', margin: '8px', padding: '12px 36px' }}>
                <div>{languageInfo["ElectricityBalance" + getStore("language")]}</div>
                <div>
                    {assets.UsdtPower}USDT
                </div>
            </div>

            <div className="flexCenter" style={{  margin: '0 8px', padding: '8px 36px',alignItems: 'start' }}>
                <div style={{ background: '#8833ff', width: 8, height: 8, marginTop: 8, borderRadius: "50%" }}></div>
                <div style={{ marginLeft: 8, color: '#666666' }}>{languageInfo["ElectricityNotice" + getStore("language")].replace("X", systemSetting["USURY"] ? systemSetting["USURY"].valueDouble * 100 : '?')}</div>
            </div>
            <div className="flexCenter" style={{ justifyContent: 'center' }}>
                <div
                    onClick={() => {
                        setModalOpen(true)
                    }}
                    className="canClick"
                    style={{ background: '#8833FF', color: '#ffffff', padding: "12px 20px",fontSize: 18, borderRadius: 24, margin: 24, minWidth: 120, textAlign: 'center' }}
                >
                    {languageInfo["Recharge" + getStore("language")]}
                </div>
            </div>
            <div style={{ margin: '24px 8px 8px' }}>
                <div style={{ fontSize: 18, padding: '12px 20px' }}>{languageInfo["RechargeRecord" + getStore("language")]}</div>
                <div className="flexBetween" style={{ color: '#c3c3c3', marginTop: 12 }}>
                    <div style={{ width: 8, height: 8, borderRadius: "50%", marginRight: 12 }}></div>
                    <div style={{ width: "20%" }}>{languageInfo["OrderDate" + getStore("language")]}</div>
                    <div style={{ flex: 1 }}>{languageInfo["Electricity" + getStore("language")]}</div>
                </div>
                {TransPower.map(item => ( item.inCoin ?
                        <div key={`productIncomeList${item.incomeId}`} className="flexBetween" style={{ color: '#c3c3c3', marginTop: 12 }}>
                            <div style={{ width: 8, height: 8, borderRadius: "50%", marginRight: 12 }}></div>
                            <div style={{ width: "20%" }}>{item.logTime.split("T")[0]}</div>
                            <div style={{ flex: 1 }}>+{item.inCoin} USDT</div>
                        </div>
                        :
                        null
                ))}
            </div>
            <div style={{ margin: '24px 8px 8px' }}>
                <div style={{ fontSize: 18, padding: '12px 20px' }}>{languageInfo["ElectricityBill" + getStore("language")]}</div>
                <div className="flexBetween" style={{ color: '#c3c3c3', marginTop: 12 }}>
                    <div style={{ width: 8, height: 8, borderRadius: "50%", marginRight: 12 }}></div>
                    <div style={{ width: "20%" }}>{languageInfo["OrderDate" + getStore("language")]}</div>
                    <div style={{ width: "15%" }}>{languageInfo["OrderID" + getStore("language")]}</div>
                    <div style={{ flex: 1 }}>{languageInfo["Electricity" + getStore("language")]}</div>
                </div>
                {productIncomeList.map(item => ( item.powerAmount ?
                    <div key={`productIncomeList${item.incomeId}`} className="flexBetween" style={{ color: '#c3c3c3', marginTop: 12 }}>
                        <div style={{ width: 8, height: 8, borderRadius: "50%", marginRight: 12 }}></div>
                        <div style={{ width: "20%" }}>{item.incomeTime.split("T")[0]}</div>
                        <div style={{ width: "15%" }}>ID:{item.buyId}</div>
                        <div style={{ flex: 1 }}>
                            {item.powerAmount != item.discountPower ?
                                <span style={{ marginRight: 12 }}>{(((item.powerAmount-item.discountPower) * -1) + "").replace(/^(.*\..{2}).*$/,"$1")} USDT</span>
                                :
                                null
                            }
                            {item.powerBtc !== 0 ?
                                <span style={{ color: '#f77e00' }}>{languageInfo["AutomaticDeduction" + getStore("language")]} {parseFloat(item.powerBtc).toFixed(6)} BTC ({(item.discountPower + "").replace(/^(.*\..{2}).*$/,"$1")} USDT)</span>
                                :
                                null
                            }
                        </div>
                    </div>
                    :
                    null
                ))}
            </div>

            <div className="scaleOpen" style={{ pointerEvents: modalOpen ? 'all' : 'none', background:  modalOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: modalOpen ? 1 : 0, transform: modalOpen ? 'scale(1)' :'scale(0.8)',
                        width: 360,
                        height: 220,
                        background: '#ffffff',
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    />
                    <div className="flexCenter">
                        <div style={{ fontWeight: 600 }}>{languageInfo["RechargeElectricity" + getStore("language")]}</div>
                    </div>
                    <div className="AppInput">
                        <div style={{ flex: 1, borderRadius: 8, position: 'relative' }}>
                            {!Amount ?
                                <div style={{ padding: '0 12px',color: '#c3c3c3',opacity: 0.5, position: 'absolute', display: 'flex', alignItems: 'center', height: '100%', top: 0, pointerEvents: 'none' }}>
                                    <span style={{ fontSize: 16 }}>{languageInfo["InputElectricityUSDT" + getStore("language")]}</span>
                                </div>
                                :
                                null
                            }

                            <input
                                type="number"
                                id="emailInput"
                                value={Amount}
                                onInput={(e)=> {
                                    setAmount(e.target.value);
                                }}
                                className="inputStyle canClick"
                            />
                        </div>
                    </div>
                    <div style={{ color: '#666666', marginTop: 8, textAlign: 'left', fontSize: 16 }}>{languageInfo["RechargeableQuantity" + getStore("language")]}:{assets.commission} U<span className="canClick" onClick={() => { setAmount(assets.commission) }} style={{ marginLeft: 8, color: '#8833ff' }}>MAX</span></div>

                    <div
                        style={{ width: '100%', borderRadius: 48 }}
                        className="loginBtn canClick"
                        onClick={() => {
                            if (Amount > 0) {
                                appServices.transferUSD(Amount);
                                setModalOpen(false);
                            }
                        }}
                    >
                        {languageInfo["Recharge" + getStore("language")]}
                    </div>
                    <div className="canClick" style={{ fontSize: 16, color: '#828282', marginTop: 20 }}>{languageInfo["WalletRecharge" + getStore("language")]}</div>
                </div>
            </div>
        </div>
    );
}

export default UserElectricity;