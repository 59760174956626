import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Privacy(props) {
    let history = useHistory();
    return (
        <div>
            <div className="contentPage">
                {getStore("language") === 'CN' ?
                    <div className="textContent">
                        <div>
                            歡迎使用Mintsat！
                            <br />
                            <br />
                            我們尊重用戶和網站訪問者（統稱為“您”）的隱私，並承諾盡力保護您的線上隱私。本隱私政策概述了Mintsat及其關聯實體（以下稱為“Mintsat”或“我們”）管理、收集、維護和處理使用者個人資料的流程。
                            我們可能不時更新本隱私政策。我們會在此頁面上發佈新的隱私政策來通知您任何變更。我們建議您定期查看此隱私政策來瞭解變更。本隱私政策的變更自其發佈在此網頁之時生效。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>一、資訊收集</div>
                        <div>
                            我們可能會使用您的個人資料來提供和改進Mintsat向您提供的礦機共用服務（“服務”），並改善您在本網站上的使用體驗。“個人資料”是指與被識別或可識別的人有關的任何資訊。當您為使用服務創建帳戶時，我們會收集您提供給我們的資訊。我們還使用各種技術來收集和存儲資訊，包括Cookie、圖元標籤、本機存放區（如流覽器網路存儲或應用程式資料緩存、資料庫和伺服器日誌）。
                        </div>
                        <div>
                            您使用服務或本網站，即表示您同意我們按照本隱私政策收集和使用資訊。除非本隱私政策另有規定，否則本隱私政策中使用的術語與我們的服務協定具有相同的含義。
                        </div>
                        <div>1、註冊</div>
                        <div>
                            創建帳戶時，需要您提供資料，包括您的電子郵箱，電話，密碼或其他資訊。
                        </div>
                        <div>
                            如果您拒絕提供上述資訊，我們將無法向您提供服務。
                        </div>
                        <div>2、支付資訊</div>
                        <div>
                            您的支付資訊將發送至我們的協力廠商支付處理商，我們存儲您的加密錢包位址，我們可以通過我們的協力廠商支付處理商訪問並保留使用者資訊。
                        </div>
                        <div>3、日誌資訊</div>
                        <div>
                            Mintsat會在您訪問我們的網站和登錄您的帳戶時收集您的流覽器發送的資訊（“日誌資料”）。此日誌資料可能包括以下資訊：您的電腦的互聯網協定（“IP”）位址、流覽器類型、流覽器版本、您訪問的服務的頁面、存取時間和日期、在這些頁面上花費的時間和其他統計資料。此外，我們可能會使用Google
                            Analytics等協力廠商服務來收集、監控和分析此類資訊，以提升我們服務的功能性。這些協力廠商服務提供者有自己的隱私政策來說明他們如何使用此類資訊。這些協力廠商服務提供者只能在代表我們執行任務所需時查看使用您的個人資料。
                        </div>
                        <div>4、消息推送</div>
                        <div>
                            我們提供的消息推送功能由協力廠商提供技術支援。為了實現消息推送的即時性，以及實現基於用戶畫像的智慧推送和基於線下位置資訊的應景推送功能，我們需要收集、使用您的下列資訊：
                        </div>
                        <div>
                            （1）創建使用者標識：為創建推送服務所需的使用者標識，我們可能需要收集以下設備資訊：如IMEI、Android ID、設備序號、MAC、Android Advertisement
                            ID、IMSI等。上述資訊將被用於生成唯一的推送目標id，即CID。
                        </div>
                        <div>
                            （2）提高推送能力：為提供更好的推送服務和體驗，如使用廠商系統推送能力等，我們可能需要收集以下設備資訊：設備廠商、設備品牌、設備型號、Android版本、Rom資訊。
                        </div>
                        <div>
                            （3）智慧推送功能：為實現基於用戶畫像進行精細化推送運營，為您推薦更符合您需要的推送內容，我們可能需要收集以下應用資訊：APP安裝清單、APP活躍清單資訊。
                        </div>
                        <div>5、我們如何使用您的資訊</div>
                        <div>
                            我們可能會使用您提供的個人資訊用於下列目的：
                        </div>
                        <div>
                            （1）向您展示我們的網站及其內容;
                        </div>
                        <div>
                            （2）將您識別為我們系統中的使用者;
                        </div>
                        <div>（3）處理您的訂單;</div>
                        <div>（4）提供客戶服務;</div>
                        <div>（5）回應您的要求;</div>
                        <div>（6）為您提供產品更新;</div>
                        <div>（7）改善我們的網站;</div>
                        <div>
                            （8）發送與我們產品相關的新聞通訊，調查，優惠和其他宣傳材料;
                        </div>
                        <div>（9）與您溝通;</div>
                        <div>
                            （10）管理風險並保護本網站，我們的服務和您免受欺詐;
                        </div>
                        <div>
                            （11）遵守所有適用的法律和法規，並執行本網站和Mintsat服務協定;
                        </div>
                        <div>（12）以及其他征得您同意的目的。</div>
                        <div>6、關於Cookie的資訊</div>
                        <div>
                            為了使您獲得更好的網站訪問體驗，我們使用“Cookie”來允許本網站識別您的流覽器並存儲使用者偏好和其他資訊。如果您的流覽器或流覽器附加服務允許，您可修改對Cookie的接受程度或拒絕我們的Cookie。有關詳情，請參見AboutCookies.org。但如果您這麼做，在某些情況下可能會影響您訪問我們網站的體驗或無法使用我們部分的服務。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>二、協力廠商服務提供者</div>
                        <div>我們可能會聘請協力廠商公司為我提供業務相關服務。這些協力廠商只能在為我們提供服務的期間訪問您的個人資料，並且有義務不得透露或將其用於任何其他目的。</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>三、如果您是歐洲經濟區（ EEA）的居民</div>
                        <div>如果您是歐洲經濟區（EEA）的居民，根據適用法律法規您擁有一些特定的資料保護權利。我們的目標是採取合理的措施，允許您更正，修改，刪除或限制您的個人資料的使用。如果您希望瞭解我們持有的關於您的個人資料以及您希望將您的個人資訊從我們的系統中刪除，請與我們聯繫。</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>四、資訊安全</div>
                        <div>
                            雖然我們致力於保護我們的網站和服務，但您有責任保護和維護您的個人資訊安全，並驗證我們維護的有關您的個人資訊是否準確和最新。您必須防止未經授權訪問您的帳戶。使用共用電腦時請務必登出，並且不要向未經授權的人員披露您的帳戶密碼和任何其他帳戶註冊資訊。
                            <br />
                            然而，沒有一個通過網際網路傳輸的方法或電子存儲方法是100％安全的。因此，在我們盡力保護您的個人資訊的同時，我們無法保證其絕對的安全性。我們無法保證您傳輸到我們網站和/或通過我們的服務的個人資訊的安全性。任何個人資訊的傳輸均由您自行承擔風險。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>五、國際資料傳輸</div>
                        <div>通過提交您的資訊，您同意您的資訊（包括個人資料）可能會傳輸和保存到您所在國家/地區管轄範圍之外的電腦和系統。若發生該等傳輸，我們會盡力確保您的資訊得到和您所在國家/地區規定的程度基本一致的保護。</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>六、協力廠商連結</div>
                        <div>我們的服務可能包含連結指向非我們運營的協力廠商網站。如果您點擊協力廠商連結，您將被引導至該協力廠商網站。本隱私政策不適用於其他協力廠商網站。我們強烈建議您查看所訪問的網站的隱私政策。Mintsat無法管控任何協力廠商網站或服務的內容和隱私政策，也不承擔任何責任。</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>七、兒童隱私</div>
                        <div>
                            我們不會故意從13歲以下的人士收集或維護我們網站上的個人資料，並且我們網站的任何部分都不會針對13歲以下的人士。如果您未滿13歲，請不要以任何方式使用或訪問本網站。
                            <br />
                            當我們得知我們無意中收集了13歲以下的人士的個人資料時，我們會根據適用法律法規進行刪除或採取其他合適的措施。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>八、個人資料使用目的變更</div>
                        <div>
                            如果我們決定將您的個人資料用於本隱私政策聲明以外的任何其他目的，我們將進行通知並為您提供一個有效的退出途徑，供您選擇不讓自己的個人資料用於本隱私政策規定外 的其他目的。
                            <br />
                            我們可能會向您發送有關新服務和新活動的電子郵件。您可以在我們發送的每封電子郵件中選擇“取消訂閱”功能來拒絕接收Mintsat的宣傳性電子郵件。但是，您同意使用服務的使用者應繼續接收與服務相關的其他重要電子郵件。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>九、資訊保存時間</div>
                        <div>除非適用法律法規有存留要求，我們只會在達成本政策所述目的所需的期限內保留和存儲您的個人資訊。在法律允許的情況下，我們可能會存儲一定的個人資訊用於備案或通過法律途徑保障自身的權益。</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>十、如何瞭解，獲取，更改或刪除您的個人資訊</div>
                        <div>
                            如果您想確認我們正在處理您的資料，訪問我們可能擁有的有關您的個人資料，更正我們收集的您的個人資訊，或刪除我們收集的您的個人資訊，您可以使用本隱私政策規定的聯繫方式與我們聯繫。
                            <br />
                            對我們而言，確保我們收集的有關您的個人資訊的準確性，完整性和最新性非常重要。如果您發現您的資訊不準確或已過時，請告知我們，我們會進行相應的更改。但是，如果我們認為更改會違反任何法律或法律要求，或導致資訊不正確，我們可能無法接受更改資訊的請求。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>十、如何瞭解，獲取，更改或刪除您的個人資訊</div>
                        <div>如果您對本隱私政策有任何疑問，請發送電子郵件至 <a href="mailto:mintsatbusiness@gmail.com">mintsatbusiness@gmail.com</a> 與我們聯繫。</div>
                    </div>
                    :
                    <div className="textContent">
                        <div>
                            Welcome to Mintsat！
                            <br />
                            <br />
                            We respect the privacy of our users and website visitors (collectively, "you") and are committed to protecting your online privacy. This Privacy Policy outlines the process by which Mintsat and its associated entities (hereafter referred to as "Mintsat" or "we") manage, collect, maintain and process user personal data.
                            <br />
                            We may update this Privacy Policy from time to time. We will post a new privacy policy on this page to notify you of any changes. We encourage you to periodically review this privacy policy to understand the changes. Changes to this Privacy Policy are effective as of the time they are posted on this page.
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>I Information collection</div>
                        <div>
                            We may use your personal data to provide and improve the mining
                            machine sharing services ("Services") that Mintsat provides to you
                            and to improve your experience on this website. "Personal Data"
                            means any information relating to a person identified or
                            identifiable. When you create an account for using the service, we
                            collect the information you provide to us. We also use a variety of
                            techniques to collect and store information, including cookies,
                            pixel tags, local storage (such as browser network storage or
                            application data cache, database and server logs).
                        </div>
                        <div>
                            By using the Service or this Site, you consent to the collection and
                            use of information in accordance with this Privacy Policy. Unless
                            otherwise stated in this Privacy Policy, the terms used in this
                            Privacy Policy have the same meaning as our Service Agreement.
                        </div>
                        <div>1. Registration</div>
                        <div>
                            When you create an account, you will need to provide data, including
                            your email address, phone number, password or other information.
                        </div>
                        <div>
                            If you refuse to provide the above information, we will not be able
                            to provide you with the service.
                        </div>
                        <div>2. Payment information</div>
                        <div>
                            Your payment information will be sent to our third party payment
                            processor. We store your encrypted wallet address. We can access and
                            retain user information through our third party payment processor.
                        </div>
                        <div>3. Log information</div>
                        <div>
                            Mintsat collects information ("log data") sent by your browser when
                            you visit our website and log into your account. This log data may
                            include the following information: your computer's Internet Protocol
                            ("IP") address, browser type, browser version, page of services you
                            visit, access time and date, time spent on these pages, and more
                            Statistical data. In addition, we may use third-party services such
                            as Google Analytics to collect, monitor, and analyze such
                            information to enhance the functionality of our services. These
                            third-party service providers have their own privacy policies that
                            explain how they use this information. These third-party service
                            providers can only view the use of your personal data when needed to
                            perform tasks on our behalf.
                        </div>
                        <div>4. Push Notifications</div>
                        <div>
                            The message push function provided by us is supported by a third
                            party. To achieve the real-time performance of message push, and
                            realize the intelligent push based on user portrait and the view
                            push function based on offline position information, the following
                            information needs to be collected and used:
                        </div>
                        <div>
                            （1）User ID creation: To create the user ID required for the push
                            service, we probably need to collect the following device
                            information: IMEI, Android ID, device serial number, MAC, Android
                            Advertisement ID, IMSI, etc. The abovementioned information will be
                            used to generate a unique push target id, namely the CID.
                        </div>
                        <div>
                            （2）Push capabilities improvement: In order to enhance push
                            services and experiences, such as using vendor system push
                            capabilities, we may need to collect the following device
                            information: device manufacturers, device brands, device models,
                            Android versions, and Rom information.
                        </div>
                        <div>
                            （3）Intelligent Push Function: In order to conduct the refined push
                            operation based on the user's portrait, and recommend the push
                            content that meets your needs more, we may need to collect the
                            following application information: APP installation list and APP
                            active list information.
                        </div>
                        <div>5. How we use your information</div>
                        <div>
                            We may use the personal information you provide for the following
                            purposes:
                        </div>
                        <div>
                            (1) Show you our website and its content;
                        </div>
                        <div>
                            (2) Identify you as a user in our system;
                        </div>
                        <div>(3) Process your order;</div>
                        <div>(4) Provide customer service;</div>
                        <div>(5) Respond to your request;</div>
                        <div>(6) Provide you with product updates;</div>
                        <div>(7) Improve our website;</div>
                        <div>
                            (8) Send newsletters, surveys, offers and other promotional
                            materials related to our products;
                        </div>
                        <div>(9) Communicate with you;</div>
                        <div>
                            (10) Manage risk and protect this website, our services and you from
                            fraud;
                        </div>
                        <div>
                            (11) Comply with all applicable laws and regulations and enforce
                            this website and the Mintsat Service Agreement;
                        </div>
                        <div>(12) Other purposes for your consent.</div>
                        <div>6. Information about cookies</div>
                        <div>
                            In order to give you a better website access experience, we use
                            "cookies" to allow this website to identify your browser and store
                            user preferences and other information. You may modify the
                            acceptance of cookies or reject our cookies if your browser or
                            browser additional services allow it. For more information, see
                            AboutCookies.org. However, if you do this, in some cases it may
                            affect your experience of visiting our website or the inability to
                            use some of our services.
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>II Third party service provider</div>
                        <div>We may hire a third-party company to provide business-related services to me. These third parties may only access your personal data while providing services to us and are obliged not to disclose or use them for any other purpose.</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>III If you are a resident of the European Economic Area (EEA)</div>
                        <div>If you are a resident of the European Economic Area (EEA), you have certain data protection rights in accordance with applicable laws and regulations. Our goal is to take reasonable steps to allow you to correct, modify, delete or limit the use of your personal data. If you would like to know about the personal data we hold about you and you wish to remove your personal information from our system, please contact us.</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>IV Information security</div>
                        <div>
                            While we are committed to protecting our websites and services, you are responsible for protecting and maintaining the security of your personal information and verifying that the personal information we maintain about you is accurate and up-to-date. You must prevent unauthorized access to your account. Be sure to log out when using a shared computer and do not disclose your account password and any other account registration information to unauthorized people.
                            <br />
                            However, none of the methods or electronic storage methods transmitted over the Internet are 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security. We cannot guarantee the security of your personal information transmitted to our website and/or through our services. Any personal information transmitted at your own risk.
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>V International data transmission</div>
                        <div>By submitting your information, you agree that your information, including personal data, may be transferred and stored to computers and systems outside of the jurisdiction of your country. In the event of such transmissions, we will endeavour to ensure that your information is protected to the same extent as is specified in your country.</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>VI Third party links</div>
                        <div>Our services may include links to third party websites that are not operated by us. If you click on a third party link, you will be directed to the third party website. This Privacy Policy does not apply to other third party websites. We strongly encourage you to review the privacy policy of the websites you visit. Mintsat cannot control the content and privacy policies of any third party website or service and is not responsible for it.</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>VII Children's privacy</div>
                        <div>
                            We do not knowingly collect or maintain personal data on our website from people under the age of 13, and no part of our website will target individuals under the age of 13. If you are under the age of 13, please do not use or access this website in any way.
                            <br />
                            When we learn that we have inadvertently collected personal data from individuals under the age of 13, we will remove or take other appropriate measures in accordance with applicable laws and regulations.
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>VIII Change of personal data usage purpose</div>
                        <div>
                            If we decide to use your personal data for any purpose other than this Privacy Policy Statement, we will notify you and provide you with a valid exit path for you to choose not to use your personal data outside of this Privacy Policy. Other purposes.
                            <br />
                            We may send you emails about new services and new events. You can refuse to receive promotional emails from Mintsat by selecting the "Unsubscribe" feature in each email we send. However, you agree that users of the Service should continue to receive other important emails related to the Service.
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>IX Information retention time</div>
                        <div>Unless there is a retention requirement in applicable laws and regulations, we will only retain and store your personal information for the period of time required to achieve the purposes stated in this policy. Where permitted by law, we may store certain personal information for filing or legal protection of our rights.</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>X How to understand, acquire, change or delete your personal information</div>
                        <div>
                            If you wish to confirm that we are processing your data, accessing any personal data we may have about you, correcting any personal information we collect, or deleting your personal information we collect, you may use the contact details set forth in this Privacy Policy. Ways to contact us.
                            <br />
                            It is important for us to ensure that the accuracy, completeness and up-to-dateness of the personal information we collect about you is important. If you find that your information is inaccurate or out of date, please let us know and we will make the appropriate changes. However, we may not be able to accept requests to change information if we believe that the change will violate any legal or legal requirements or result in incorrect information.
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>XI How to contact us</div>
                        <div>If you have any questions about this Privacy Policy, please send an email to <a href="mailto:mintsatbusiness@gmail.com">mintsatbusiness@gmail.com</a></div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Privacy;