import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function UserDeposit(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [depositType, setDepositType] = useState(0);
    const [rechargeETH, setRechargeETH] = useState({});
    const [rechargeList, setRechargeList] = useState([]);
    const [coin, setCoin] = useState(getStore("productInfo").productType === "FIL" ? "FIL" : "USDT");
    const [depositChain, setDepositChain] = useState(getStore("productInfo").productType === "FIL" ? "FIL" : "");
    const [bindOpen, setBindOpen] = useState(0);
    const [bindAddress, setBindAddress] = useState("");
    const [chainOpen, setChainOpen] = useState(false);
    const [systemSetting] = useState(() => {
        return getStore('systemSetting');
    });
    const [accountAddress, setAccountAddress] = useState(() => {
        return getStore('accountAddress');
    });

    useMiracleObserver(
        'getRechargeETH,getAccountStoreIn,getAccountAddress,bindSuccess',
        (key, value, store) => {
            if (key === 'getAccountAddress') {
                setAccountAddress(value);
            } else if (key === 'bindSuccess') {
                setBindAddress("");
                appServices.getAccountAddress();
            } else if (key === 'getRechargeETH') {
                setRechargeETH(value);
                StartTimeInterval(value);

                if (value.address && document.getElementById('RechargeAddress')) {
                    document.getElementById('RechargeAddress').innerText = "";
                    const qart = new window.QRCode(document.getElementById('RechargeAddress'), value.address);
                }
            } else if (key === "getAccountStoreIn") {
                setRechargeList(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );


    const StartTimeInterval = (value) => {
        if (window.timeInterval) {
            clearInterval(window.timeInterval);
        }
        window.timeInterval = setInterval(() => {
            if (document.getElementById("endTime")) {
                if (new Date().getTime() < new Date(value.laterTime).getTime()) {
                    var itime = new Date(value.laterTime).getTime() -  new Date().getTime();
                    var isecond = parseInt(itime/1000);
                    var modeDay = isecond%(24*60*60);
                    var modeHour = modeDay%(60*60);
                    var newMinute = parseInt(modeHour/60);
                    var modeMinute = modeHour%60;
                    var newSecond = parseInt(modeMinute);

                    document.getElementById("endTime").innerHTML = newMinute+" : "+newSecond;
                } else {
                    setRechargeETH({});
                    clearInterval(window.timeInterval);
                }
            }
        }, 1000);
    }

    const DepositChainChange = (value,dType) => {
        if (document.getElementById('RechargeAddress')) {
            document.getElementById('RechargeAddress').innerText = "";
        }

        setDepositChain(value);
        setChainOpen(false);
        if (dType === 1 || (depositType === 1 && dType !== 0)) {
            if (value === "ERC20") {
                appServices.rechargeETH("ETH", "USDT")
            } else if (value === "BEP20") {
                appServices.rechargeETH("BNB", "USDT")
            } else if (value === "TRC20") {
                appServices.rechargeETH("TRC", "USDT")
            } else if (value === "Polygon") {
                appServices.rechargeETH("MATIC", "USDT")
            } else if (value === "FIL") {
                appServices.rechargeETH("FIL", "FIL")
            }
        } else if (document.getElementById('RechargeAddress')) {
            if (value === "FIL") {
                if (accountAddress.filAddress) {
                    const qart = new window.QRCode(document.getElementById('RechargeAddress'), systemSetting['FIL-ADDRESS'].valueString);
                } else {
                    setBindOpen(4);
                }
            } else if (value === "ERC20") {
                if (accountAddress.ethAddress) {
                    const qart = new window.QRCode(document.getElementById('RechargeAddress'), systemSetting['ETH-ADDRESS'].valueString);
                } else {
                    setBindOpen(1);
                }
            } else if (value === "TRC20") {
                if (accountAddress.trcAddress) {
                    const qart = new window.QRCode(document.getElementById('RechargeAddress'), systemSetting['TRC-ADDRESS'].valueString);
                } else {
                    setBindOpen(3);
                }
            } else if (value === "BEP20") {
                if (accountAddress.bscAddress) {
                    const qart = new window.QRCode(document.getElementById('RechargeAddress'), systemSetting['ETH-ADDRESS'].valueString);
                } else {
                    setBindOpen(5);
                }
            }
        }
    }

    return (
        <div className="userContent">
            <div className="userTitle">{languageInfo["deposit" + getStore("language")]}</div>

            <div style={{ padding: '12px 4%' }}>
                <div className="flexBetween">
                    <div className="flexCenter">
                        <div
                            onClick={() => {
                                setDepositType(0);
                                setRechargeETH({});
                                DepositChainChange(depositChain === "FIL" ? "FIL" : "", 0);
                            }}
                            className="userTab canClick"
                            style={{ borderRadius: '8px 0 0 8px', borderRight: 0, paddingLeft: 36 }}
                        >
                            <div style={{ height: 3 }}></div>
                            <div style={{ color:  depositType === 0 ? "#222" : '#888' }}>{languageInfo["WalletRecharge" + getStore("language")]}</div>
                            <div style={{ height: 3, background: depositType === 0 ? '#be8fff' : 'transparent', borderRadius: 3 }}></div>
                        </div>
                        <div
                            onClick={() => {
                                setDepositType(1);
                                DepositChainChange(depositChain === "FIL" ? "FIL" : "", 1);
                            }}
                            className="userTab canClick"
                            style={{ borderRadius: '0 8px 8px 0', borderLeft: 0, paddingRight: 36 }}
                        >
                            <div style={{ height: 3 }}></div>
                            <div style={{ color:  depositType === 1 ? "#222" : '#888' }}>{languageInfo["PublicAddressRecharge" + getStore("language")]}</div>
                            <div style={{ height: 3, background: depositType === 1 ? '#be8fff' : 'transparent', borderRadius: 3 }}></div>
                        </div>
                    </div>
                    {coin === "USDT" ?
                        <div className="canClick flexCenter" style={{ position: 'relative', opacity: 1, height: 50, justifyContent: 'right'  }}>
                            <span style={{ color: '#8833ff' }}>{ depositChain || languageInfo["SelectLink" + getStore("language")]}</span>
                            <img style={{ marginLeft: 8, marginBottom: 2 }} alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/languageDown.png" />

                            <div className="hoverShow depositOpen" style={chainOpen ? { display: 'initial' } : { }}>
                                <div className="list" onClick={() => { DepositChainChange("ERC20"); }} style={{ padding: '12px 16px' }}>ERC20</div>
                                <div className="list" onClick={() => { DepositChainChange("BEP20"); }} style={{ padding: '12px 16px' }}>BEP20</div>
                                {/*<div className="list" onClick={() => { DepositChainChange("Polygon"); }} style={{ padding: '12px 16px' }}>Polygon</div>*/}
                                <div className="list" onClick={() => { DepositChainChange("TRC20"); }} style={{ padding: '12px 16px' }}>TRC20</div>
                            </div>
                        </div>
                        :
                        coin === "FIL" ?
                            <div className="canClick flexCenter" style={{ position: 'relative', opacity: 1, height: 50, justifyContent: 'right'  }}>
                                <span style={{ color: '#8833ff' }}>FIL</span>
                            </div>
                            : null
                    }
                </div>
                <div className="flexBetween" style={{ background: '#f5f3fe', margin: '24px 8px 8px', padding: '12px 36px' }}>
                    <div>{languageInfo["RechargeCoin" + getStore("language")]}</div>
                    <div className="flexCenter" style={{ fontSize: 16 }}>
                        <div
                            onClick={() => {
                                setCoin("USDT")
                                DepositChainChange("")
                            }}
                            className="flexCenter canClick" style={{ marginLeft: 36 }}
                        >
                            {coin === "USDT" ?
                                <div style={{ background: '#8833ff', width: 14, height: 14, borderRadius: "50%", marginRight: 8 }}></div>
                                :
                                <div className="rateCircle" style={{ borderColor: '#8833ff' }}></div>
                            }
                            <div>USDT</div>
                        </div>
                        <div
                            onClick={() => {
                                setCoin("FIL");
                                DepositChainChange("FIL")
                            }}
                            className="flexCenter canClick"
                            style={{ marginLeft: 36 }}
                        >
                            {coin === "FIL" ?
                                <div style={{ background: '#8833ff', width: 14, height: 14, borderRadius: "50%", marginRight: 8 }}></div>
                                :
                                <div className="rateCircle" style={{ borderColor: '#8833ff' }}></div>
                            }
                            <div>FIL</div>
                        </div>
                        {/*<div
                            onClick={() => {
                                setCoin("ETH");
                                appServices.rechargeETH("ETH", "ETH")
                            }}
                            className="flexCenter canClick"
                            style={{ marginLeft: 36 }}
                        >
                            {coin === "ETH" ?
                                <div style={{ background: '#8833ff', width: 14, height: 14, borderRadius: "50%", marginRight: 8 }}></div>
                                :
                                <div className="rateCircle" style={{ borderColor: '#8833ff' }}></div>
                            }
                            <div>ETH</div>
                        </div>*/}
                    </div>
                </div>

                {depositType === 0 ?
                    <div className="flexBetween"
                         style={{background: '#f5f3fe', margin: '12px 8px 8px', padding: '12px 36px'}}>
                        <div>{depositChain}{languageInfo["TransferAddress" + getStore("language")]}</div>
                        <div
                            onClick={() => {
                                const open = (coin === "FIL" ? 4 : depositChain === "ERC20" ? 1 : depositChain === "TRC20" ? 3 : depositChain === "BEP20" ? 5 : 0);
                                if (open) {
                                    setBindOpen(open);
                                } else {
                                    setChainOpen(true)
                                }
                            }}
                            className="canClick flexCenter"
                        >
                            {coin === "FIL" ?
                                <span style={{color: '#8833ff'}}>{accountAddress.filAddress || languageInfo['PleaseBindAddress' + getStore('language')] }</span>
                                : depositChain === "BEP20" ?
                                    <span style={{color: '#8833ff'}}>{accountAddress.bscAddress || languageInfo['PleaseBindAddress' + getStore('language')] }</span>
                                    : depositChain === "ERC20" ?
                                        <span style={{color: '#8833ff'}}>{accountAddress.ethAddress || languageInfo['PleaseBindAddress' + getStore('language')] }</span>
                                        : depositChain === "TRC20" ?
                                            <span style={{color: '#8833ff'}}>{accountAddress.trcAddress || languageInfo['PleaseBindAddress' + getStore('language')] }</span>
                                            : null
                            }
                            <span style={{color: '#8833ff', marginLeft: 12 }}>{depositChain === "" ? languageInfo['SelectLink' + getStore('language')] : languageInfo['EditAddress' + getStore('language')]}</span>
                        </div>
                    </div>
                    :
                    null
                }
                {depositType === 1 || (depositChain === "FIL" && accountAddress.filAddress) || (depositChain === "TRC20" && accountAddress.trcAddress) || (depositChain === "BEP20" && accountAddress.bscAddress) || (depositChain === "ERC20" && accountAddress.ethAddress) ?
                    <div className="flexBetween" style={{ background: '#f5f3fe', margin: '12px 8px 8px', padding: '12px 36px' }}>
                        <div>{depositChain}{languageInfo["ForwardingAddress" + getStore("language")]}</div>
                        <div
                            className="canClick flexCenter"
                            onClick={() => {
                                const value = depositType === 0 ? coin === "FIL" ? systemSetting['FIL-ADDRESS'].valueString : depositChain === "TRC20" ? systemSetting['TRC-ADDRESS'].valueString : depositChain ? systemSetting['ETH-ADDRESS'].valueString : '': rechargeETH.address;
                                if (value) {
                                    var e = document.getElementById("googleCode");
                                    e.value = value ;
                                    e.select(); //
                                    document.execCommand("Copy");
                                } else {
                                    setChainOpen(true)
                                }
                            }}
                        >
                            {depositType === 0 ?
                                <span style={{ color: '#8833ff' }}>{coin === "FIL" ? systemSetting['FIL-ADDRESS'].valueString || languageInfo['PostError9996' + getStore('language')] : depositChain === "TRC20" ? systemSetting['TRC-ADDRESS'].valueString || languageInfo['PostError9996' + getStore('language')] : depositChain ? systemSetting['ETH-ADDRESS'].valueString || languageInfo['PostError9996' + getStore('language')] : languageInfo["PleaseSelectLink" + getStore("language")]}</span>
                                :
                                <span style={{ color: '#8833ff' }}>{rechargeETH.address || languageInfo["PleaseSelectLink" + getStore("language")]}</span>
                            }
                            <img
                                className="canClick"
                                style={{  width: 20, height: 20, padding: 4, marginLeft: 12 }}
                                alt=""
                                src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/Copy.png"
                            />
                        </div>
                    </div>
                    :
                    null
                }
                <div style={{ minHeight: 80 }}>
                    <div className="flexCenter" style={{ justifyContent: 'center' }}>
                        <div id="RechargeAddress" className="scanCode" style={{ margin: '20px' }}></div>
                        {depositType === 0 ?
                            <div>
                                {(depositChain === "FIL" && accountAddress.filAddress) || (depositChain === "TRC20" && accountAddress.trcAddress) || (depositChain === "BEP20" && accountAddress.bscAddress) || (depositChain === "ERC20" && accountAddress.ethAddress) ? languageInfo['PleaseBindAddressDeposit' + getStore("language")] : ""}
                            </div>
                            :
                            depositChain ?
                            <div style={{  margin: '8px 8px 24px', padding: '8px 36px' }}>
                                <div style={{ color: '#111111', fontSize: 18, margin: '16px 0' }}>{languageInfo["TimeLeft" + getStore("language")]}  <span id="endTime"> 00:00</span></div>
                                <div style={{ color: '#666666' }}>{languageInfo["PleaseWaitAddress" + getStore("language")]}</div>
                            </div> : null
                        }
                    </div>
                    <div style={{ width: '100%', height: 1, background: '#dfdfdf' }}></div>
                </div>

                <div style={{ margin: '24px 8px 8px', padding: '12px 36px' }}>
                    <div>{languageInfo["RechargeRecord" + getStore("language")]}</div>
                    {rechargeList.map((item => (
                        <div className="flexBetween" style={{ color: '#c3c3c3', marginTop: 12 }}>
                            <div className="flexCenter">
                                <div style={{ background: '#8833ff', width: 8, height: 8, borderRadius: "50%" }}></div>
                                <div style={{ marginLeft: 12 }}>{item.inTime}</div>
                            </div>
                            <div><span>{(item.inAmount + "").replace(/^(.*\..{6}).*$/,"$1")}</span>{item.inAssetsType}</div>
                        </div>
                    )))}
                </div>
            </div>
            <div className="scaleOpen" style={{ pointerEvents: bindOpen !== 0 ? 'all' : 'none', background:  bindOpen !== 0 ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: bindOpen !== 0 ? 1 : 0, transform: bindOpen !== 0 ? 'scale(1)' :'scale(0.8)',
                        width: 400,
                        height: 160,
                        background: '#ffffff'
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setBindOpen(0);
                        }}
                    />
                    <div className="flexCenter">
                        <div style={{ fontWeight: 600 }}>{languageInfo["BindWallet" + getStore("language")]}</div>
                    </div>
                    <div className="AppInput">
                        <div style={{ flex: 1, borderRadius: 8, position: 'relative' }}>
                            {!bindAddress ?
                                <div style={{ padding: '0 12px',color: '#c3c3c3',opacity: 0.5, position: 'absolute', display: 'flex', alignItems: 'center', height: '100%', top: 0, pointerEvents: 'none' }}>
                                    <span style={{ fontSize: 16 }}>{languageInfo["inputAddress" + getStore("language")]}</span>
                                </div>
                                :
                                null
                            }

                            <input
                                id="emailInput"
                                value={bindAddress}
                                onInput={(e)=> {
                                    setBindAddress(e.target.value);
                                }}
                                className="inputStyle canClick"
                            />
                        </div>
                    </div>

                    <div
                        style={{ width: '100%', borderRadius: 48 }}
                        className="loginBtn canClick"
                        onClick={() => {
                            if (bindAddress && bindOpen === 1) {
                                setBindOpen(0)
                                appServices.bindAccountETH(bindAddress)
                            } else if (bindAddress && bindOpen === 2) {
                                setBindOpen(0)
                                appServices.bindAccountBTC(bindAddress)
                            } else if (bindAddress && bindOpen === 3) {
                                setBindOpen(0)
                                appServices.bindAccountTRX(bindAddress)
                            } else if (bindAddress && bindOpen === 4) {
                                setBindOpen(0)
                                appServices.bindAccountFIL(bindAddress)
                            } else if (bindAddress && bindOpen === 5) {
                                setBindOpen(0)
                                appServices.bindAccountBSC(bindAddress)
                            }
                        }}
                    >
                        {languageInfo["Sure" + getStore("language")]}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default UserDeposit;