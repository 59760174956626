import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';

import stations from './stations';
import appServices from "./services";
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Mine(props) {
    let history = useHistory();

    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [productList, setProductList] = useState(() => {
        return getStore('productList');
    });
    const [searchOpen, setSearchOpen] = useState(false);
    const [minSelect, setMinSelect] = useState(() => {
        return getStore('minSelect');
    });

    const updateSelect = (type) => {
        const select = JSON.parse(JSON.stringify(minSelect));
        select[type] = !select[type];
        setMinSelect(select);
    }
    const updateVale = (type, value) => {
        const select = JSON.parse(JSON.stringify(minSelect));
        select[type] = value;
        if (type === "type") {
            select.mode = "";
        }
        setMinSelect(select);
        appServices.queryProductList(select);
    }

    useMiracleObserver(
        'productList,getSystemSetting',
        (key, value, store) => {
            if (key === 'productList') {
                setProductList(value)
            }
        },
        SubscribeMatchType.ContainsNotify,
    );


    return (
        <div className="mobilePaddingTop" style={{ background: '#F7F8FA', position: 'relative' }}>
            <div className="content">
                <div className="displayFlex">
                    <div onClick={() => { updateVale("type", "BTC") }} className="mineTab" style={{ background: minSelect.type === "BTC" ? 'linear-gradient(277deg,#313545,#121118)' : '#ffffff', opacity:  minSelect.type === "BTC" ? 1 : 0.5, color: minSelect.type === "BTC" ? "#ffffff" : "#333333" }}>
                        <div className="displayFlex">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerBTC.png" alt="" />
                            <div className="MineFont">BTC</div>
                        </div>
                    </div>
                    <div onClick={() => { updateVale("type", "ALEO") }} className="mineTab" style={{ background: minSelect.type === "ALEO" ? 'linear-gradient(277deg,#313545,#121118)' : '#ffffff', opacity:  minSelect.type === "ALEO" ? 1 : 0.5, color: minSelect.type === "ALEO" ? "#ffffff" : "#333333" }}>
                        <div className="displayFlex">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerALEO.png" alt="" />
                            <div className="MineFont">Aleo</div>
                        </div>
                    </div>
                    <div onClick={() => { updateVale("type", "FIL") }} className="mineTab" style={{ background: minSelect.type === "FIL" ? 'linear-gradient(277deg,#313545,#121118)' : '#ffffff', opacity:  minSelect.type === "FIL" ? 1 : 0.5, color: minSelect.type === "FIL" ? "#ffffff" : "#333333" }}>
                        <div className="displayFlex">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerFIL.png" alt="" />
                            <div className="MineFont">FIL</div>
                        </div>
                    </div>
                </div>

                {/*<div className="webShow" style={{ borderRadius: 12, width: '100%', overflow: 'hidden', fontWeight: 'bold', fontSize: 14 }}>
                    <div className="displayFlex" style={{ width: '100%', background: '#ffffff', color: '#687898' }}>
                        <div style={{ margin: '16px 20px', width: 120 }}>{languageInfo["Merchants" + getStore("language")]}</div>
                        <div className="displayFlex mineSelect canClick" style={{ background: '#8833FF', color: '#ffffff' }}>
                            <div style={{ width: 120, textAlign: 'center' }}>{languageInfo["Merchants1" + getStore("language")]}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerSelect.png" alt="" style={{ height: 16, paddingLeft: 12 }} />
                        </div>
                    </div>
                    <div className="displayFlex" style={{ width: '100%', background: '#ffffff', color: '#687898', borderTop: '2px dashed #f7f8fa' }}>
                        <div style={{ margin: '16px 20px', width: 120 }}>{languageInfo["PlanMode" + getStore("language")]}</div>
                        <div onClick={() => { updateVale("mode", "") }} className="displayFlex mineSelect canClick" style={!minSelect.mode ? { background: '#8833FF', color: '#ffffff' } : {}}>
                            <div style={{ width: 120, textAlign: 'center' }}>{languageInfo["All" + getStore("language")]}</div>
                            <div className="rateCircle">
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerSelect.png" alt="" style={{ width: 16, display: !minSelect.mode ? 'block' : 'none' }} />
                            </div>
                        </div>
                        <div onClick={() => { updateVale("mode", "SPEED") }}  className="displayFlex mineSelect canClick" style={minSelect.mode === "SPEED" ? { background: '#8833FF', color: '#ffffff' } : {}}>
                            <div style={{ width: 120, textAlign: 'center' }}>{languageInfo["PlanMode1" + getStore("language")]}</div>
                            <div className="rateCircle">
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerSelect.png" alt="" style={{ width: 16, display: minSelect.mode === "SPEED"  ? 'block' : 'none' }} />
                            </div>
                        </div>
                        <div onClick={() => { updateVale("mode", "NORMAL") }}  className="displayFlex mineSelect canClick" style={minSelect.mode === "NORMAL" ? { background: '#8833FF', color: '#ffffff' } : {}}>
                            <div style={{ width: 120, textAlign: 'center' }}>{languageInfo["PlanMode2" + getStore("language")]}</div>
                            <div className="rateCircle">
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerSelect.png" alt="" style={{ width: 16, display: minSelect.mode === "NORMAL"  ? 'block' : 'none' }} />
                            </div>
                        </div>
                    </div>
                </div>*/}
                <div className="displayFlex minePage">
                    {productList.map((item, index) => (
                        <div className="webShow mineList" key={`productList${item.productId}`}>
                            <div className="displayFlex" style={{ borderBottom: '2px dashed #f7f8fa', justifyContent: 'center', paddingBottom: 12 }}>
                                <div style={{ fontSize: 14, fontWeight: 'bold' }}>{item.productName} {languageInfo["Plan" + getStore("language")]}</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerHot.png" alt="" style={{ height: 15, paddingLeft: 12 }} />
                            </div>
                            <div style={{ fontSize: 15, paddingTop: 20, paddingBottom: 12, fontWeight: 'bold'  }}>{item.productSustainTime} {languageInfo["Days" + getStore("language")]}</div>
                            <div style={{ fontSize: 13, paddingBottom: 12  }}>{languageInfo["RateAmount" + getStore("language")]}</div>

                            <div className="rateList">
                                {item.outputType === "ALEO" ?
                                    <div
                                        className="rateAmount canClick"
                                        style={{  background: '#e3f6f5', margin: 'auto' }}
                                    >
                                        <div className="rateCircle" style={{ borderColor: '#ffffff' }}>
                                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/PowerSelect.png" alt="" style={{ width: 14, display: 'block' }} />
                                        </div>
                                        <div style={{ flex: 1 }}>{item.productHistory.split("/")[2]} c/s</div>
                                    </div>
                                    : item.productContent.split("/").map((TH,startT) => (
                                    <div
                                        onClick={() => {
                                            let list = productList.map((mine,z) => {
                                                if (z === index) {
                                                    mine.TH = TH;
                                                }
                                                return mine;
                                            });
                                            setProductList(list);
                                        }}
                                        className="rateAmount canClick"
                                        style={{  background: TH === item.TH || (startT === 0 && !item.TH) ? '#e3f6f5' : '#ffffff', margin: item.productContent.split("/").length === 1 ? "auto" : "3% 2%" }}
                                    >
                                        <div className="rateCircle" style={{ borderColor: TH === item.TH || (startT === 0 && !item.TH) ? '#ffffff' : '#687898' }}>
                                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/PowerSelect.png" alt="" style={{ width: 14, display: TH === item.TH || (startT === 0 && !item.TH) ? 'block' : 'none' }} />
                                        </div>
                                        <div style={{ flex: 1 }}>{TH}{item.outputType === "FIL" ? "" : "H/s"}</div>
                                    </div>
                                ))}
                            </div>
                            <div style={{ fontSize: 13, fontWeight: 'bold', paddingTop: 20, paddingBottom: 12  }}>{languageInfo["Estimated" + getStore("language")]}</div>
                            <div className="flexCenter" style={{ borderTop: '2px dashed #f7f8fa', padding: '24px 0', justifyContent: 'center' }}>
                                <div
                                    style={{ width: '50%', background: '#E3E3E3', position: 'relative', height: 8, borderRadius: '20px' }}
                                >
                                    <div style={{ background: '#8833FF',transitionDuration: '0.5s', width: `${parseFloat(new Date().getTime() < new Date(item.productStart).getTime() ? 0 : item.productAmount === 0 ? 100 : item.productHistory.split("/")[3] || 0).toFixed(2)}%`, height: '100%',position: 'absolute', left: 0, borderRadius: 20  }}>

                                    </div>
                                </div>
                                <div style={{ fontSize: 13, marginLeft: 8 }}>
                                    <span>{languageInfo["Sold" + getStore("language")]}:</span>
                                    <span>{new Date().getTime() < new Date(item.productStart).getTime() ? 0 : item.productAmount === 0 ? 100 : item.productHistory.split("/")[3] || 0}</span>
                                    <span>%</span>
                                </div>
                            </div>
                            <div
                                onClick={
                                    () => {
                                        if (item.productAmount !== 0) {
                                            initStore(item.TH || item.productContent.split("/")[0], "TH");
                                            initStore(item, 'productInfo')
                                            initStore(minSelect, 'minSelect')
                                            history.push(`/project/${item.productId}`);
                                            document.getElementById("root").scrollTo(0,0);
                                        }
                                    }}
                                className="canClick webShow"
                                style={{ boxShadow: '0px 0px 2px #ccc', padding: 12, borderRadius: 24 }}
                            >
                                {languageInfo[(item.productAmount !== 0 ? "CheckDetails" : "SoldOut") + getStore("language")]}
                            </div>
                            <div style={{ fontSize: 13, margin: '8px 0', paddingTop: 12 }}>
                                <span>{languageInfo["By" + getStore("language")]}</span>
                                <span style={{ background: '#F77E00', color: '#ffffff', borderRadius: 8, padding: '2px 6px 3px', margin: 6 }}>{languageInfo["Self" + getStore("language")]}</span>
                                <span>{languageInfo["MintSat" + getStore("language")]}</span>
                            </div>
                            <div style={{ fontSize: 13, margin: '16px 0' }}>{languageInfo["Comparative" + getStore("language")]}</div>
                            {item.productType !== "FIL" ?
                                <div style={{ fontSize: 14, margin: '8px 0', fontWeight: 'bold' }}>{languageInfo["Hashrate" + getStore("language")]} ${(item.productPrice / item.productSustainTime).toFixed(4)}{item.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]} </div>
                                :
                                null
                            }
                            {item.productPower ?
                                <div style={{ fontSize: 14, margin: '8px 0', fontWeight: 'bold' }}>{languageInfo["Electricity" + getStore("language")]} ${item.productPower}{item.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}</div>
                                :
                                null
                            }
                            <div style={{ fontSize: 14, margin: '8px 0', fontWeight: 'bold', color: '#f77e00' }}>{languageInfo["Output" + getStore("language")]}{item.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]} {item.productHistory.split("/")[0]}</div>
                        </div>
                    ))}
                    {
                        productList.map((item, index) => (
                            <div key={`product${item.productId}`} className="suanLiList mobileShow" onClick={() => {history.push('/download');document.getElementById("root").scrollTo(0,0);}}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontWeight: "bold", padding: '4px 12px' }}>
                                    <img src={item.outputType === "FIL" ? "https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerFIL.png" : item.outputType === "ALEO" ? "https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerALEO.png" : "https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerBTC.png"} style={{ height: 64 }} />
                                    <div style={{ flex: 1 }}>
                                        <div style={{ margin: "0 12px", padding: "6px 0" }}>{item.productName}{languageInfo["Plan" + getStore("language")]}</div>
                                        <div style={{ margin: "0 12px",display: 'flex', alignItems: 'center', marginBottom: 12 }}>
                                            <div>{item.productSustainTime}{languageInfo["Days" + getStore("language")]}</div>
                                            <span style={{ padding: '0 12px' }}>·</span>
                                            {item.outputType === "ALEO" ?
                                                <div>{item.productHistory.split("/")[2]} c/s</div>
                                                :
                                                <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                                    <div>{item.TH || item.productContent.split("/")[0]}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div style={{ height: "1px", background: '#f4f4f4', marginLeft: 12, marginRight: 20 }} />
                                <div style={{ padding: '4px 12px', display: 'flex', alignItems: 'start', flexWrap: 'wrap' }}>
                                    {item.productType !== "FIL" ?
                                        <div style={{ margin: '8px 0', width: '50%' }}>
                                            <div style={{ marginBottom: 6 }}>{languageInfo["Hashrate" + getStore("language")]}</div>
                                            <div style={{ color: '#8733FE', fontWeight: 'bold' }}>{item.productType === "FIL" ? "" : "$"}{(item.productPrice / item.productSustainTime).toFixed(4)}{item.productType === "FIL" ? "FIL" : ""}{item.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}</div>
                                        </div>
                                        :
                                        null
                                    }
                                    {item.productPower ?
                                        <div style={{ margin: '8px 0', width: '50%' }}>
                                            <div style={{ marginBottom: 6 }}>{languageInfo["Electricity" + getStore("language")]}</div>
                                            <div style={{ color: '#8733FE', fontWeight: 'bold' }}>${item.productPower}{item.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}</div>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{ margin: '8px 0' }}>
                                        <div style={{ marginBottom: 6 }}>{languageInfo["Output" + getStore("language")]}</div>
                                        <div style={{ color: '#8733FE', fontWeight: 'bold' }}>{item.productHistory.split("/")[0]}{item.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}</div>
                                    </div>
                                    <div className="flexCenter" style={{ padding: '12px 0', justifyContent: 'start', width: '100%' }}>
                                        <div
                                            style={{ width: '50%', background: '#E3E3E3', position: 'relative', height: 8, borderRadius: '20px' }}
                                        >
                                            <div style={{ background: '#8833FF',transitionDuration: '0.5s', width: `${parseFloat(new Date().getTime() < new Date(item.productStart).getTime() ? 0 : item.productAmount === 0 ? 100 : item.productHistory.split("/")[3] || 0).toFixed(2)}%`, height: '100%',position: 'absolute', left: 0, borderRadius: 20  }}>

                                            </div>
                                        </div>
                                        <div style={{ marginLeft: 8 }}>
                                            <span>{languageInfo["Sold" + getStore("language")]}:</span>
                                            <span>{new Date().getTime() < new Date(item.productStart).getTime() ? 0 : item.productAmount === 0 ? 100 : item.productHistory.split("/")[3] || 0}</span>
                                            <span>%</span>
                                        </div>
                                    </div>

                                    <div
                                        className="canClick mobileShow"
                                        style={{ boxShadow: '0px 0px 2px #ccc', padding: 12, borderRadius: 24 }}
                                    >
                                        {languageInfo["download" + getStore("language")]}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className="mineList" style={{ background: 'transparent' }}></div>
                </div>
                <div style={{  color: '#95a0b7', textAlign: 'center' }}>
                    <div style={{ margin: 12 }}>{languageInfo["PriceAdjustment" + getStore("language")]}</div>
                    <div style={{ margin: 12 }}>{languageInfo["PriceAdjustmentRemark" + getStore("language")]}</div>
                </div>
            </div>


            <div  style={{ background: '#F7F8FA', height: 80, width: '100%',position: 'absolute', left: 0, bottom: -80 }}>

            </div>
        </div>
    );
}

export default Mine;