import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Notice(props) {
    let history = useHistory();

    const [notice, setNotice] = useState(() => {
        return getStore('notice') || {};
    });
    useMiracleObserver(
        'notice',
        (key, value, store) => {
            if (key === 'notice') {
                setNotice(value);
            }
        }
    )
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', margin: '72px 15%' }}>
            {notice.message ?
                <div style={{ width: '100%', color: '#333333', background: '#f8f8f8', padding: 36, borderRadius: 16, boxSizing: 'border-box', margin: '16px 0 80px',fontSize: 16, lineHeight: '24px' }}>
                    <div style={{ fontSize: 20,color: '#1b1b1b', fontWeight: 'bold', textAlign: 'center', padding: 16 }}>{JSON.parse(notice.message).title}</div>
                    <div style={{ fontSize: 16,color: '#999', fontWeight: 'bold', textAlign: 'center', paddingBottom: 16 }}>{new Date(notice.noticeTime).toDateString()}</div>
                    <hr />
                    {JSON.parse(notice.message).image ?
                        <div style={{textAlign: 'center', paddingTop: 12}}>
                            <img
                                style={{maxWidth: "100%"}}
                                alt=""
                                src={JSON.parse(notice.message).image}
                            />
                        </div>
                        :
                        null
                    }
                    <pre style={{ whiteSpace: 'pre-line' }}>
                            {JSON.parse(notice.message).detail}
                        </pre>
                </div>
                :
                null
            }
        </div>
    );
}

export default Notice;