import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function About(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [amount, setAmount] = useState('');

    return (
        <div>
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutBanner.png" alt="" className="banner" />

                <div className="positionAbsolute" style={{ width: '60%', left: '10%', padding: 0, top: '25%' }}>
                    <div style={{ fontWeight: 700, fontSize: getStore("language") === 'CN' ? 56 : 40, width: getStore("language") === 'CN' ? '65%' : '80%',letterSpacing:  getStore("language") === 'CN' ? 6 : 0 }}>
                        {languageInfo["AboutTitle" + getStore("language")]}
                    </div>
                    <div style={{ fontWeight: 500, fontSize: 24, width: '60%',letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginTop: 30 }}>
                        {languageInfo["AboutInfo" + getStore("language")]}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'start', textAlign: 'center', fontWeight: 'bold', marginTop: 60 }}>
                        <div onClick={() => {
                            history.push("contact");
                            document.getElementById("root").scrollTo(0,0);
                            stationDefault.notify('setBannerIndex', "contact");
                        }} className="canClick" style={{ background: '#252B2B', color: '#ffffff', padding: 12, borderRadius: 32, minWidth: 100, marginRight: 24 }}>{languageInfo["Information2" + getStore("language")]}</div>
                    </div>
                </div>
            </div>

            <div style={{ fontSize: 48, color: '#1d1d27', fontWeight: 'bold',textAlign: 'center',width: 720, margin: "120px auto 0" }}>{languageInfo["AboutText1" + getStore("language")]}</div>

            <div className="content" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontWeight: 'bold', padding: '32px 0%' }}>
                <div style={{ width: '50%', textAlign: 'center', margin: '30px 0' }}>
                    <div className="choicesImg">
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutInfo4.png" alt="" style={{ height: 200 }} />
                    </div>
                    <div style={{ color: '#1d1d27', fontSize: 18, margin: 16 }}>{languageInfo["AboutText2" + getStore("language")]}</div>
                    <div style={{ color: '#8f8f90', width: '80%', margin: 'auto' }}>{languageInfo["AboutText3" + getStore("language")]}</div>
                </div>
                <div style={{ width: '50%', textAlign: 'center', margin: '30px 0' }}>
                    <div className="choicesImg">
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutInfo3.png" alt="" style={{ height: 200 }} />
                    </div>
                    <div style={{ color: '#1d1d27', fontSize: 18, margin: 16 }}>{languageInfo["AboutText4" + getStore("language")]}</div>
                    <div style={{ color: '#8f8f90', width: '80%', margin: 'auto' }}>{languageInfo["AboutText5" + getStore("language")]}</div>
                </div>
                <div style={{ width: '50%', textAlign: 'center', margin: '30px 0' }}>
                    <div className="choicesImg">
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutInfo2.png" alt="" style={{ height: 200 }} />
                    </div>
                    <div style={{ color: '#1d1d27', fontSize: 18, margin: 16 }}>{languageInfo["AboutText6" + getStore("language")]}</div>
                    <div style={{ color: '#8f8f90', width: '80%', margin: 'auto' }}>{languageInfo["AboutText7" + getStore("language")]}</div>
                </div>
                <div style={{ width: '50%', textAlign: 'center', margin: '30px 0' }}>
                    <div className="choicesImg">
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutInfo1.png" alt="" style={{ height: 200 }} />
                    </div>
                    <div style={{ color: '#1d1d27', fontSize: 18, margin: 16 }}>{languageInfo["AboutText8" + getStore("language")]}</div>
                    <div style={{ color: '#8f8f90', width: '80%', margin: 'auto' }}>{languageInfo["AboutText9" + getStore("language")]}</div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', padding: "32px 24px", margin: '72px 15%',borderRadius: 16, background: '#1d1d27', color: '#ffffff' }}>
                <div style={{ width: '49%', fontSize: 28, fontWeight: 'bold',letterSpacing: '2px' }}>
                    {languageInfo["AboutText10" + getStore("language")]}
                </div>
                <div style={{ width: '50%' }}>
                    {languageInfo["AboutText11" + getStore("language")]}
                </div>
            </div>
            <div className="threeCard">
                <div style={{ textAlign: 'center', fontSize: 28, fontWeight: 'bold',letterSpacing: '2px' }}>
                    {languageInfo["AboutText12" + getStore("language")]}
                </div>
                <div className="flexCenter" style={{ padding: "48px 0 80px", textAlign: "center" }}>
                    <div style={{ flex: 1 }}></div>
                    <div className="flexCenter canClick" onClick={() => { if (getStore('AccountId')) { history.push('/assets'); document.getElementById("root").scrollTo(0,0); stationDefault.notify('setBannerIndex', "/assets"); } else { stationDefault.notify('setLoginOpen', 1); } }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutHelp1.png" alt="" style={{ width: 80, padding: '0 16px' }} />
                        <div style={{ position: 'absolute', top: 100, width: '200%', left: '-50%' }}>  {languageInfo["AboutText13" + getStore("language")]}</div>
                    </div>
                    <div style={{ width: '24%', height: 1, borderBottom: '1px dashed #666666' }}></div>
                    <div className="flexCenter canClick" onClick={() => { history.push('/mine'); document.getElementById("root").scrollTo(0,0); }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutHelp2.png" alt="" style={{ width: 80, padding: '0 16px' }} />
                        <div style={{ position: 'absolute', top: 100, width: '200%', left: '-50%' }}>  {languageInfo["AboutText14" + getStore("language")]}</div>
                    </div>
                    <div style={{ width: '24%', height: 1, borderBottom: '1px dashed #666666' }}></div>
                    <div className="flexCenter canClick" onClick={() => { if (getStore('AccountId')) { history.push('/withdraw'); document.getElementById("root").scrollTo(0,0); stationDefault.notify('setBannerIndex', "/withdraw"); } else { stationDefault.notify('setLoginOpen', 2); } }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutHelp3.png" alt="" style={{ width: 80, padding: '0 16px' }} />
                        <div style={{ position: 'absolute', top: 100, width: '200%', left: '-50%' }}>  {languageInfo["AboutText15" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                </div>
            </div>
        </div>
    );
}

export default About;