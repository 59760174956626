import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Download(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [mobileLeft, setMobileLeft] = useState(0);
    const [touchStartX, setTouchStartX] = useState(0);
    const [isMove, setIsMove] = useState(false);

    const touchstart = (e) => {
        setTouchStartX(e.touches[0].clientX);
        setIsMove(true);
    }
    const touchMove = (e) => {
        if (isMove) {
            if (e.touches[0].clientX - touchStartX > 200 && e.touches[0].clientX > touchStartX) {
                if (mobileLeft >= 1) {
                    setMobileLeft(mobileLeft - 1);
                    setIsMove(false);
                }
            } else if (touchStartX - e.touches[0].clientX > 200 && touchStartX > e.touches[0].clientX) {
                if (mobileLeft <= (getStore("language") === "CN" ? 5 : 4)) {
                    setMobileLeft(mobileLeft + 1)
                    setIsMove(false);
                }
            }
        }
    }
    const touchEnd = () => {
        setIsMove(false);
        setTouchStartX(0);
    }

    return (
        <div className="mobilePaddingTop">
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/downloadBg.jpg" alt="" className="banner downloadBanner" />

                <div className="positionAbsolute downloadPage">
                    <div style={{ fontWeight: 700, fontSize: getStore("language") === 'CN' ? 56 : 40, width: '100%',letterSpacing:  getStore("language") === 'CN' ? 6 : 0 }}>
                        {languageInfo["download1" + getStore("language")]}
                    </div>
                    <div style={{ marginLeft: 24 }}>
                        <div style={{ position: 'relative', fontWeight: 700, fontSize: 24, width: '100%',letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginTop: 30 }}>
                            <div style={{ width: 12, height: 12, borderRadius: '50%', background: '#a268e5', position: 'absolute', top: 12, left: -24 }}></div>
                            <span>{languageInfo["download2" + getStore("language")]}</span>
                        </div>
                        <div style={{ fontWeight: 500, fontSize: 24, width: '100%',letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginTop: 8 }}>
                            {languageInfo["download3" + getStore("language")]}
                        </div>
                        <div style={{ position: 'relative', fontWeight: 700, fontSize: 24, width: '100%',letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginTop: 30 }}>
                            <div style={{ width: 12, height: 12, borderRadius: '50%', background: '#a268e5', position: 'absolute', top: 12, left: -24 }}></div>
                            <span>{languageInfo["download4" + getStore("language")]}</span>
                        </div>
                        <div style={{ fontWeight: 500, fontSize: 24, width: '100%',letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginTop: 8 }}>
                            {languageInfo["download5" + getStore("language")]}
                        </div>
                        <div style={{ position: 'relative', fontWeight: 700, fontSize: 24, width: '100%',letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginTop: 30 }}>
                            <div style={{ width: 12, height: 12, borderRadius: '50%', background: '#a268e5', position: 'absolute', top: 12, left: -24 }}></div>
                            <span>{languageInfo["download6" + getStore("language")]}</span>
                        </div>
                        <div style={{ fontWeight: 500, fontSize: 24, width: '100%',letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginTop: 8 }}>
                            {languageInfo["download7" + getStore("language")]}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'start', textAlign: 'center', fontWeight: 'bold', marginTop: 40 }}>
                        <a target="_blank" href="https://amintsat.s3.ap-southeast-1.amazonaws.com/mintsat_v1.0.apk" className="canClick downloadBtn">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AndroidDownload.png" alt="" style={{ height: 24, marginRight: 8 }} />
                            <div style={{ flex: 1, fontSize: 16 }}>{languageInfo["androidDownload" + getStore("language")]}</div>
                        </a>
                        <a target="_blank" href="https://amintsat.s3.ap-southeast-1.amazonaws.com/mintsat.mobileconfig" className="canClick downloadBtn">
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/IOSDownload.png" alt="" style={{ height: 24, marginRight: 8 }} />
                            <div style={{ flex: 1, fontSize: 16 }}>{languageInfo["IOSDownload" + getStore("language")]}</div>
                        </a>
                    </div>
                </div>
            </div>
            {getStore("language") === "CN" ?
                <div className="stepBlock mobileShow" onTouchStart={touchstart} onTouchEnd={touchEnd} onTouchMove={touchMove}>
                    <div className="stepList" style={{ left: mobileLeft * -100 + "%", width: "700vw" }}>
                        <div className="stepMobile">
                            <div className="FontStep">{appServices.traditionalized("1.点击“允许”")}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep1CN.png" alt="" style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">{appServices.traditionalized("2.点击“关闭”")}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep2CN.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">{appServices.traditionalized("3.在设置中打开“已下载描述文件”")}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep3CN.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">{appServices.traditionalized("4.点击“安装”")}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep4CN.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">{appServices.traditionalized("5.点击“下一步”")}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep5CN.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">{appServices.traditionalized("6.点击“安装”")}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep6CN.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">{appServices.traditionalized("7.点击“完成”，MintSat已完成安装")}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep7CN.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                    </div>
                    <img onClick={() => { if (mobileLeft >= 1) setMobileLeft(mobileLeft - 1) }} style={{ position: 'absolute', left: 12, width: 60,transform: 'scaleX(-1)', opacity: mobileLeft <= 0 ? 0.5 : 1 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/mobileRight.png" alt="" />

                    <img onClick={() => { if (mobileLeft <= 5) setMobileLeft(mobileLeft + 1) }} style={{ position: 'absolute', right: 12, width: 60, opacity: mobileLeft >= 6 ? 0.5 : 1 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/mobileRight.png" alt="" />
                </div>
                :
                <div className="stepBlock mobileShow" onTouchStart={touchstart} onTouchEnd={touchEnd} onTouchMove={touchMove}>
                    <div className="stepList" style={{ left: mobileLeft * -100 + "%" }}>
                        <div className="stepMobile">
                            <div className="FontStep">1. Click Allow</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep1.png" alt="" style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">2. Click Close</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep2.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">3. Open Settings,and then click General</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep3.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">4. Open VPN & Device Management</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep4.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">5. Click Install</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep6.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                        <div className="stepMobile">
                            <div className="FontStep">6. Click Install</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep5.png" alt=""  style={{ width: '80%' }}/>
                        </div>
                    </div>
                    <img onClick={() => { if (mobileLeft >= 1) setMobileLeft(mobileLeft - 1) }} style={{ position: 'absolute', left: 12, width: 60,transform: 'scaleX(-1)', opacity: mobileLeft <= 0 ? 0.5 : 1 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/mobileRight.png" alt="" />

                    <img onClick={() => { if (mobileLeft <= 4) setMobileLeft(mobileLeft + 1) }} style={{ position: 'absolute', right: 12, width: 60, opacity: mobileLeft >= 5 ? 0.5 : 1 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/mobileRight.png" alt="" />
                </div>
            }

            {getStore("language") === "CN" ?
                <div className="webShow" style={{ width: '100vw', height: '100vh' }}>
                    <div className="stepWeb">
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">{appServices.traditionalized("1.点击“允许”")}</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep1CN.png" alt="" style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">{appServices.traditionalized("2.点击“关闭”")}</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep2CN.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">{appServices.traditionalized("3.在设置中打开“已下载描述文件”")}</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep3CN.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">{appServices.traditionalized("4.点击“安装”")}</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep4CN.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">{appServices.traditionalized("5.点击“下一步”")}</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep5CN.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">{appServices.traditionalized("6.点击“安装”")}</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep6CN.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">{appServices.traditionalized("7.点击“完成”，MintSat已完成安装")}</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep7CN.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="webShow" style={{ width: '100vw', height: '100vh' }}>
                    <div className="stepWeb">
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">1. Click Allow</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep1.png" alt="" style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">2. Click Close</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep2.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">3. Open Settings,and then click General</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep3.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">4. Open VPN & Device Management</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep4.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">5. Click Install</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep6.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                        <div className="step">
                            <div style={{ width: "120%" }}>
                                <div className="stepFont">6. Click Install</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/iosStep5.png" alt=""  style={{ width: '100%' }}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Download;