import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function ETHPage(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [ethPro, setEthPro] = useState(() => {
        return getStore('ethPro');
    });
    const [assets, setAssets] = useState(() => {
        return getStore('Assets');
    });
    const [ethScore, setEthScore] = useState(() => {
        return getStore('ethScore');
    });
    const [ethScoreDaily, setEthScoreDaily] = useState(() => {
        return getStore('ethScoreDaily');
    });
    const [systemSetting, setSystemSetting] = useState(() => {
        return getStore('systemSetting');
    });
    const [Amount, setAmount] = useState("");

    useMiracleObserver(
        'getETHProSuccess,getAccountAssets,userPledgeETHSuccess,getETHScoreSuccess,getSystemSetting,getETHScoreDailySuccess',
        (key, value, store) => {
            if (key === 'getETHProSuccess') {
                setEthPro(value);
            } else if (key === 'getAccountAssets') {
                setAssets(value);
            } else if (key === 'userPledgeETHSuccess') {
                appServices.getAccountAssets();
                appServices.getAccountVip();
                appServices.getETHScore();
            } else if (key === 'getETHScoreSuccess') {
                setEthScore(value)
            } else if (key === 'getSystemSetting') {
                setSystemSetting(value);
            } else if (key === 'getETHScoreDailySuccess') {
                setEthScoreDaily(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    return (
        <div style={{ background: '#f7f8fa' }}>
            <div className="content" style={{ padding: 48 }}>
                <div style={{ display: 'flex', alignItems: 'center',justifyContent: 'space-around', background: '#ffffff', borderRadius: 8, padding: '24px 8%' }}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ background: '#e3f6f5', color: '#687898', padding: '8px 12px', borderRadius: 12, minWidth: 90, fontWeight: 700 }}>ETH {languageInfo["PledgeAmount" + getStore("language")]}</div>
                        <div style={{ color: '#687898', fontWeight: 'bold', marginTop: 12, fontSize: 24 }}>{ethScore}</div>
                    </div>
                    <div style={{ width: 0,height: 60, borderLeft: '2px dashed #ececec' }}></div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ background: '#e3f6f5', color: '#687898', padding: '8px 12px', borderRadius: 12, minWidth: 90, fontWeight: 700 }}>{languageInfo["RateReturn" + getStore("language")]}</div>
                        <div style={{ color: '#687898', fontWeight: 'bold', marginTop: 12, fontSize: 24 }}>{ethPro.ethYield * 100}%</div>
                    </div>
                    <div style={{ width: 0,height: 60, borderLeft: '2px dashed #ececec' }}></div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ background: '#e3f6f5', color: '#687898', padding: '8px 12px', borderRadius: 12, minWidth: 90, fontWeight: 700 }}>{languageInfo["Accumulated" + getStore("language")]}</div>
                        <div style={{ color: '#687898', fontWeight: 'bold', marginTop: 12, fontSize: 24 }}>{ethScoreDaily}</div>
                    </div>
                </div>
                <div style={{ color: '#687898', fontWeight: 'bold', display: 'flex', alignItems: 'center',  background: '#ffffff', borderRadius: "8px 8px 0 0px", padding: '20px 2%', marginTop: 16 }}>
                    <input
                        placeholder={languageInfo["StakingInput" + getStore("language")]}
                        type="number"
                        style={{ border: 'none', fontWeight: 600,fontSize: 17, flex: 1, padding: "8px 24px", background: 'transparent', color: '#687898', width: 100, boxSizing: 'border-box' }}
                        onInput={(e)=> {
                            setAmount(e.target.value);
                        }}
                        value={Amount}
                    />
                    <span style={{ margin: '0 50px' }}>ETH</span>
                </div>
                <div style={{ color: '#687898', fontWeight: 'bold', display: 'flex', alignItems: 'center',  background: '#ffffff', borderRadius: "0px 0px 8px 8px", padding: '20px 4%', borderTop: '1px dashed #ececec'  }}>
                    <div>{languageInfo["MyBalance" + getStore("language")]} {assets.eth || 0} ETH</div>
                    <div style={{ flex: 1 }}></div>
                    <div onClick={() => { if (assets.eth > 0) { appServices.userPledgeETH(assets.eth) } }} className="canClick" style={{ background: assets.eth > 0 ? '#8833ff' : '#b783ff', color: '#ffffff', padding: 12, borderRadius: 32, minWidth: 100, marginRight: 24, textAlign: 'center' }}>{languageInfo["BalanceAllIn" + getStore("language")]}</div>
                    <div onClick={() => { if (Amount > 0 && Amount <= assets.eth) { appServices.userPledgeETH(Amount) } }} className="canClick" style={{ background: Amount > 0 && Amount <= assets.eth ? '#8833ff' : '#b783ff', color: '#ffffff', padding: 12, borderRadius: 32, minWidth: 100, marginRight: 24, textAlign: 'center' }}>{languageInfo["Staking" + getStore("language")]}</div>
                </div>
                {/*<div style={{ width: '70%', textAlign: 'center', margin: '48px auto' }}>
                    <div style={{ position: 'relative' }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ETHVIP1.png" alt="" style={{ width: '100%' }} />
                        <div className="positionAbsolute flexCenter" style={{ justifyContent: 'space-around', paddingRight: '12%' }}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/eth1.jpg" alt="" style={{ height: '100%' }} />
                            <div style={{ fontWeight: 600, color: '#ffffff', fontSize: 32, margin: 20, width: 150 }}>
                                <div>{systemSetting["PLEDAGE-1"] ? systemSetting["PLEDAGE-1"].valueDouble : '?'}-{systemSetting["PLEDAGE-2"] ? systemSetting["PLEDAGE-2"].valueDouble : '?'}</div>
                                <div>ETH</div>
                            </div>
                            {getStore("language") === 'CN' ?
                                <div style={{ fontWeight: 600, color: '#ffffff', fontSize: 24, margin: 20 }}>
                                    <div>{languageInfo["ServiceFee" + getStore("language")]} {systemSetting["MANAGE-DISCOUNT-1"] ? (1 - systemSetting["MANAGE-DISCOUNT-1"].valueDouble) * 10 : '?'} 折</div>
                                    <div>{languageInfo["Electricity" + getStore("language")]} {systemSetting["POWER-DISCOUNT-1"] ? (1 - systemSetting["POWER-DISCOUNT-1"].valueDouble) * 10 : '?'} 折</div>
                                </div>
                                :
                                <div style={{ fontWeight: 600, color: '#ffffff', fontSize: 24, margin: 20 }}>
                                    <div>{languageInfo["ServiceFee" + getStore("language")]} {systemSetting["MANAGE-DISCOUNT-1"] ? (systemSetting["MANAGE-DISCOUNT-1"].valueDouble) * 100 : '?'} %</div>
                                    <div>{languageInfo["Electricity" + getStore("language")]} {systemSetting["POWER-DISCOUNT-1"] ? (systemSetting["POWER-DISCOUNT-1"].valueDouble) * 100 : '?'} %</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ position: 'relative', marginTop: 16 }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ETHVIP2.png" alt="" style={{ width: '100%' }} />
                        <div className="positionAbsolute flexCenter" style={{ justifyContent: 'space-around', paddingRight: '12%' }}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/eth2.jpg" alt="" style={{ height: '100%' }} />
                            <div style={{ fontWeight: 600, color: '#ffffff', fontSize: 32, margin: 20, width: 150 }}>
                                <div>{systemSetting["PLEDAGE-2"] ? systemSetting["PLEDAGE-2"].valueDouble : '?'}-{systemSetting["PLEDAGE-3"] ? systemSetting["PLEDAGE-3"].valueDouble : '?'}</div>
                                <div>ETH</div>
                            </div>
                            {getStore("language") === 'CN' ?
                                <div style={{ fontWeight: 600, color: '#ffffff', fontSize: 24, margin: 20 }}>
                                    <div>{languageInfo["ServiceFee" + getStore("language")]} {systemSetting["MANAGE-DISCOUNT-2"] ? (1 - systemSetting["MANAGE-DISCOUNT-2"].valueDouble) * 10 : '?'} 折</div>
                                    <div>{languageInfo["Electricity" + getStore("language")]} {systemSetting["POWER-DISCOUNT-2"] ? (1 - systemSetting["POWER-DISCOUNT-2"].valueDouble) * 10 : '?'} 折</div>
                                </div>
                                :
                                <div style={{ fontWeight: 600, color: '#ffffff', fontSize: 24, margin: 20 }}>
                                    <div>{languageInfo["ServiceFee" + getStore("language")]} {systemSetting["MANAGE-DISCOUNT-2"] ? (systemSetting["MANAGE-DISCOUNT-2"].valueDouble) * 100 : '?'} %</div>
                                    <div>{languageInfo["Electricity" + getStore("language")]} {systemSetting["POWER-DISCOUNT-2"] ? (systemSetting["POWER-DISCOUNT-2"].valueDouble) * 100 : '?'} %</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ position: 'relative', marginTop: 16 }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ETHVIP3.png" alt="" style={{ width: '100%' }} />
                        <div className="positionAbsolute flexCenter" style={{ justifyContent: 'space-around', paddingRight: '12%' }}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/eth3.jpg" alt="" style={{ height: '110%', margin: '0 -1%' }} />
                            <div style={{ fontWeight: 600, color: '#ffffff', fontSize: 32, margin: 20, width: 150 }}>
                                <div>{systemSetting["PLEDAGE-3"] ? systemSetting["PLEDAGE-3"].valueDouble : '?'}</div>
                                <div>ETH</div>
                            </div>
                            {getStore("language") === 'CN' ?
                                <div style={{ fontWeight: 600, color: '#ffffff', fontSize: 24, margin: 20 }}>
                                    <div>{languageInfo["ServiceFee" + getStore("language")]} {systemSetting["MANAGE-DISCOUNT-3"] ? (1 - systemSetting["MANAGE-DISCOUNT-3"].valueDouble) * 10 : '?'} 折</div>
                                    <div>{languageInfo["Electricity" + getStore("language")]} {systemSetting["POWER-DISCOUNT-3"] ? (1 - systemSetting["POWER-DISCOUNT-3"].valueDouble) * 10 : '?'} 折</div>
                                </div>
                                :
                                <div style={{ fontWeight: 600, color: '#ffffff', fontSize: 24, margin: 20 }}>
                                    <div>{languageInfo["ServiceFee" + getStore("language")]} {systemSetting["MANAGE-DISCOUNT-3"] ? (systemSetting["MANAGE-DISCOUNT-3"].valueDouble) * 100 : '?'} %</div>
                                    <div>{languageInfo["Electricity" + getStore("language")]} {systemSetting["POWER-DISCOUNT-3"] ? (systemSetting["POWER-DISCOUNT-3"].valueDouble) * 100 : '?'} %</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    );
}

export default ETHPage;