import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import appServices from './services';
import './App.css';

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function NFTOrder(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [walletAddress, setWalletAddress] = useState(getStore("walletAddress"));


    const buyNft = () => {
        if (walletAddress) {
            appServices.mintNFT();
        } else {
            appServices.getWalletAddress();
        }
    }

    useMiracleObserver(
        'walletAddress',
        (key, value, store) => {
            if (key === 'walletAddress') {
                setWalletAddress(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    return (
        <div style={{ background: '#F7F8FA', position: 'relative', top: -80, paddingTop: 120 }}>
            <div className="content" style={{ paddingTop: 40, background: '#ffffff', padding: 24, boxSizing: 'border-box' }}>
                <div className="flexCenter" style={{ paddingBottom: 24, alignItems: 'start' }}>
                    <div style={{  background: '#FCFCFC', borderRadius: 20, overflow: "hidden", textAlign: 'center', color: '#687898', width: '30%'  }}>
                        <img src="/image/NFTMiner.png" alt="" style={{ width: "100%" }} />
                    </div>
                    <div style={{ width: "40%", color: '#687898', marginLeft: 20, height: '100%', padding: 20 }}>
                        <div style={{ fontSize: 20, fontWeight: 'bold' }}>{languageInfo["NFTPlan1" + getStore("language")]}</div>

                        <div className="flexBetween" style={{ width: '100%', background: '#ffffff', color: '#687898', marginTop: 12 }}>
                            <div style={{ minWidth: 80 }}>{languageInfo["Quantity" + getStore("language")]}</div>
                            <div className="displayFlex" style={{ background: '#ffffff', boxShadow: '0px 0px 4px #dfdfdf', padding: 8, borderRadius: 24, margin: 10 }}>
                                <div style={{ width: 60, textAlign: 'center' }}>1</div>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MintsatArrow.png" alt="" style={{ height: 8, paddingRight: 8, display: 'block' }} />
                            </div>
                        </div>
                        <div className="flexBetween" style={{ width: '100%', background: '#ffffff', color: '#687898' }}>
                            <div style={{ minWidth: 80 }}>{languageInfo["ItemCost" + getStore("language")]}</div>
                            <div className="displayFlex" style={{ padding: 8 }}>
                                <img src="/image/NFTETH.png" alt="" style={{ height: 36 }} />
                            </div>
                        </div>
                        <div className="flexBetween" style={{ width: '100%', background: '#ffffff', color: '#687898', paddingBottom: 12 }}>
                            <div style={{ minWidth: 80 }}>{languageInfo["Fees" + getStore("language")]}</div>
                            <div className="displayFlex" style={{ padding: 8, margin: 10 }}>
                                {languageInfo["Calculated" + getStore("language")]}
                            </div>
                        </div>
                        <div className="flexBetween" style={{ width: '100%', background: '#ffffff', color: '#687898', borderTop: '2px dashed #e3e4e7', paddingTop: 12 }}>
                            <div style={{ minWidth: 80 }}>{languageInfo["PayText18" + getStore("language")]}</div>
                            <div className="displayFlex" style={{ padding: 8, margin: 10 }}>
                                ETH
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "40%", color: '#687898', marginLeft: 20, height: '100%', padding: 20 }}>
                        <div style={{ fontSize: 20, fontWeight: 'bold' }}>{languageInfo["ConnectWallet" + getStore("language")]}</div>
                        <div style={{ padding: "12px 0 24px", fontSize: 14 }}>{languageInfo["transferred" + getStore("language")]}</div>
                        <div
                            onClick={buyNft}
                            className="canClick displayFlex"
                            style={{
                                background: '#8833FF', color: '#ffffff', padding: 8, borderRadius: 24, width: "100%", alignItems: 'center', justifyContent: 'center'
                            }}
                        >
                            <img src="/image/NFTWallet.png" alt="" style={{ height: 20, marginRight: 8 }} />
                            <span>{languageInfo[(!walletAddress ? "Connect" : "Buy") + getStore("language")]}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div  style={{ background: '#F7F8FA', height: 80, width: '100%',position: 'absolute', left: 0, bottom: -80 }}>
            </div>
        </div>
    );
}

export default NFTOrder;