import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function UserInvite(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [inviteIncome, setInviteIncome] = useState(() => {
        return getStore('inviteIncome');
    });
    const [vipInvite, setVipInvite] = useState(() => {
        return getStore('vipInvite');
    });
    const [systemSetting, setSystemSetting] = useState(() => {
        return getStore('systemSetting');
    });
    const [copyOpen, setCopyOpen] = useState(false);

    useMiracleObserver(
        'queryVipInvite,getInviteIncome,getSystemSetting',
        (key, value, store) => {
            if (key === 'queryVipInvite') {
                setVipInvite(value);
            } else if (key === 'getInviteIncome') {
                setInviteIncome(value);
            } else if (key === 'getSystemSetting') {
                setSystemSetting(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    const CopyOpen = () => {
        var e = document.getElementById("googleCode");
        e.value = window.location.origin + "/?invite=" + vipInvite.inviteCode;
        e.select(); //
        document.execCommand("Copy");

        setCopyOpen(true);
        document.getElementById('inviteScan').innerText = "";
        const qart = new window.QRCode(document.getElementById('inviteScan'), window.location.origin + "/?invite=" + vipInvite.inviteCode);

        let imgEle1 = document.querySelectorAll(".inviteImage")[0];
        let resEle = document.querySelector(".inviteCanvas");
        var context = resEle.getContext("2d");
        resEle.width = 1080;
        resEle.height = 1920;
        context.globalAlpha = 1.0;
        context.drawImage(imgEle1, 0, 0,1080,1920);
        setTimeout(() => {
            let imgEle2 = qart._el.children[1];
            context.drawImage(imgEle2, 792, 1598,216,216);
        },300)
    }

    return (
        <div className="userContent">
            <div className="inviteBanner">
                <div style={{ marginBottom: 12, fontSize: 32 }}>{languageInfo["InviteTitle" + getStore("language")]}</div>
                <div>{languageInfo["InviteRemark" + getStore("language")]}</div>
                <div
                    style={{ textAlign: 'center' }}
                    className="inviteBtn canClick"
                    onClick={() => {
                        history.push('/mine');

                        stationDefault.notify('setBannerIndex', '/mine');
                    }}
                >
                    {languageInfo["CalculatorText20" + getStore("language")]}
                </div>

                <img
                    className="canClick"
                    style={{ position: "absolute",top: "10%", right: "5%", height: "80%"  }}
                    alt=""
                    src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/inviteIcon.png"
                />
            </div>
            <div className="inviteBanner2">
                <div>
                    <div>{languageInfo["InviteVIP" + getStore("language")]}</div>
                    <div>
                        <img src={`https://amintsat.s3.ap-southeast-1.amazonaws.com/image/VIP${getStore("vip") || 1}.png`} alt="" style={{ width: 100, marginTop: 10 }} />
                    </div>
                </div>
                <div>
                    <div className="inviteTap">{languageInfo["InvitedPeople" + getStore("language")]}</div>
                    <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 32 }}>{vipInvite.inviteAmount || 0}</span></div>

                    {systemSetting[`INVITE-POWER-RATE-${getStore("vip") + 1}`] ?
                        <div style={{color: '#1d1d27', fontSize: 14, marginTop: 8 }}>{languageInfo["InviteMore" + getStore("language")]}{systemSetting[`INVITE-${getStore("vip") + 1}`] ? systemSetting[`INVITE-${getStore("vip") + 1}`].valueDouble - vipInvite.inviteAmount : '?'}{languageInfo["InviteLevel" + getStore("language")]}</div>
                        :
                        null
                    }
                </div>
                <div>
                    <div className="inviteTap">{languageInfo["ElectricityBillRebate" + getStore("language")]}</div>
                    <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 32 }}>{(vipInvite.uIncome + "").replace(/^(.*\..{1}).*$/,"$1")}</span>USDT</div>

                    <div style={{color: '#1d1d27', fontSize: 14, marginTop: 8 }}>{languageInfo["InviteLink" + getStore("language")]}</div>
                </div>

                <img
                    className="canClick"
                    style={{ position: "absolute",top: 0, right: 0, height: "100%"  }}
                    alt=""
                    src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/inviteIcon3.png"
                />
            </div>
            <div>
                <div className="flexCenter" style={{ alignItems: 'stretch' }}>
                    <div className="orderCard">
                        <div style={{ color: '#a3a3a3', fontSize: 14 }}>{languageInfo["ServiceFeeRebate" + getStore("language")]}</div>
                        <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{(vipInvite.btcIncome + "").replace(/^(.*\..{4}).*$/,"$1")}</span>BTC</div>
                        <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{(vipInvite.filIncome + "").replace(/^(.*\..{4}).*$/,"$1")}</span>FIL</div>
                    </div>
                    <div className="orderCard">
                        <div style={{ color: '#a3a3a3', fontSize: 14 }}>{languageInfo["CurrentLevel" + getStore("language")]}</div>
                        <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{vipInvite.inviteAmount === 0 ? 0 : systemSetting[`INVITE-POWER-RATE-${getStore("vip")}`] ? parseInt(systemSetting[`INVITE-POWER-RATE-${getStore("vip")}`].valueDouble * 100,10) : '?'}%</span>{languageInfo["ElectricityBillRebate" + getStore("language")]}</div>
                        <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{vipInvite.inviteAmount === 0 ? 0 : systemSetting[`INVITE-MANAGE-RATE-${getStore("vip")}`] ? parseInt(systemSetting[`INVITE-MANAGE-RATE-${getStore("vip")}`].valueDouble * 100, 10)  : '?'}%</span>{languageInfo["ServiceFeeRebate" + getStore("language")]}</div>
                        {/*<div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{systemSetting[`POWER-DISCOUNT-${getStore("vip")}`] ?  parseInt(systemSetting[`POWER-DISCOUNT-${getStore("vip")}`].valueDouble * 100, 10) : '?'}%</span>{languageInfo["ElectricityBillReduction" + getStore("language")]}</div>*/}
                        {/*<div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{systemSetting[`MANAGE-DISCOUNT-${getStore("vip")}`] ?  parseInt(systemSetting[`MANAGE-DISCOUNT-${getStore("vip")}`].valueDouble * 100, 10)  : '?'}%</span>{languageInfo["ServiceFeeReduction" + getStore("language")]}</div>*/}
                    </div>
                    {systemSetting[`INVITE-POWER-RATE-${getStore("vip") + 1}`] ?
                        <div className="orderCard">
                            <div style={{ color: '#a3a3a3', fontSize: 14 }}>{languageInfo["NextLevel" + getStore("language")]}</div>
                            <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{systemSetting[`INVITE-POWER-RATE-${getStore("vip") + 1}`] ?  parseInt(systemSetting[`INVITE-POWER-RATE-${getStore("vip") + 1}`].valueDouble * 100, 10) : '?'}%</span>{languageInfo["ElectricityBillRebate" + getStore("language")]}</div>
                            <div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{systemSetting[`INVITE-MANAGE-RATE-${getStore("vip") + 1}`] ?  parseInt(systemSetting[`INVITE-MANAGE-RATE-${getStore("vip") + 1}`].valueDouble * 100, 10)  : '?'}%</span>{languageInfo["ServiceFeeRebate" + getStore("language")]}</div>
                            {/*<div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{systemSetting[`POWER-DISCOUNT-${getStore("vip") + 1}`] ?  parseInt(systemSetting[`POWER-DISCOUNT-${getStore("vip") + 1}`].valueDouble * 100, 10) : '?'}%</span>{languageInfo["ElectricityBillReduction" + getStore("language")]}</div>*/}
                            {/*<div style={{ color: '#1d1d27', marginTop: 8 }}><span style={{ marginRight: 8, fontSize: 24 }}>{systemSetting[`MANAGE-DISCOUNT-${getStore("vip") + 1}`] ?  parseInt(systemSetting[`MANAGE-DISCOUNT-${getStore("vip") + 1}`].valueDouble * 100, 10)  : '?'}%</span>{languageInfo["ServiceFeeReduction" + getStore("language")]}</div>*/}
                        </div>
                        :
                        null
                    }
                </div>

                <div className="flexBetween" style={{ background: '#f5f3fe', margin: '12px 8px 8px', padding: '12px 36px' }}>
                    <div>{languageInfo["InvitationLink" + getStore("language")]}</div>
                    <div
                        className="canClick flexCenter"
                        onClick={CopyOpen}
                    >
                        <span style={{ color: '#8833ff' }}>{window.location.origin}/?invite={vipInvite.inviteCode}</span>
                        <img
                            className="canClick"
                            style={{  width: 20, height: 20, padding: 4, marginLeft: 12 }}
                            alt=""
                            src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/Copy.png"
                        />
                    </div>
                </div>
                {/*<div className="flexCenter" style={{  margin: '0 8px', padding: '8px 36px',alignItems: 'start' }}>
                    <div style={{ background: '#8833ff', width: 8, height: 8, marginTop: 8, borderRadius: "50%" }}></div>
                    <div style={{ marginLeft: 8, color: '#666666' }}>1.{languageInfo["Registering" + getStore("language")]}</div>
                </div>*/}
                <div className="flexCenter" style={{  margin: '0 8px', padding: '8px 36px',alignItems: 'start' }}>
                    <div style={{ background: '#8833ff', width: 8, height: 8, marginTop: 8, borderRadius: "50%" }}></div>
                    <div style={{ marginLeft: 8, color: '#666666' }}>1.{languageInfo["GetShare" + getStore("language")]}</div>
                </div>
                <div className="flexCenter" style={{  margin: '0 8px 24px', padding: '8px 36px',alignItems: 'start' }}>
                    <div style={{ background: '#8833ff', width: 8, height: 8, marginTop: 8, borderRadius: "50%" }}></div>
                    <div style={{ marginLeft: 8, color: '#666666' }}>2.{languageInfo["GetPower" + getStore("language")]}</div>
                </div>
                <div className="flexCenter" style={{  margin: '0 8px', padding: '8px 36px',alignItems: 'start' }}>
                    <div style={{ marginLeft: 8, color: '#666666' }}>{languageInfo["GetPowerTable" + getStore("language")]}</div>
                </div>
                <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden", margin: "8px 36px" }}>
                    <div className="flexCenter" style={{ height: 56, paddingLeft: 40, background: '#faf9fe', color: '#a3a3a3' }}>
                        <div style={{ width: "30%" }}></div>
                        {/*<div style={{ width: "25%" }}>{languageInfo["ElectricityBillReduction" + getStore("language")]}</div>*/}
                        <div style={{ width: "35%" }}>{languageInfo["ElectricityBillRebate" + getStore("language")]}</div>
                        {/*<div style={{ width: "30%" }}>{languageInfo["ServiceFeeReduction" + getStore("language")]}</div>*/}
                        <div style={{ width: "35%" }}>{languageInfo["ServiceFeeRebate" + getStore("language")]}</div>
                    </div>
                    <div className="flexCenter" style={{ height: 56, paddingLeft: 40, color: '#1d1d27' }}>
                        <div style={{ width: "30%" }}>{languageInfo['People'+ getStore("language")].replace("X", systemSetting && systemSetting[`INVITE-1`] ? systemSetting[`INVITE-1`].valueDouble : "?")}</div>
                        {/*<div style={{ width: "25%" }}>
                            {getStore("language") === 'CN' ?
                            <span>{systemSetting["POWER-DISCOUNT-1"] ? parseFloat((1 - systemSetting["POWER-DISCOUNT-1"].valueDouble) * 10).toFixed(1) : '?'} 折</span>
                            :
                            <span>{systemSetting["POWER-DISCOUNT-1"] ? parseInt(systemSetting["POWER-DISCOUNT-1"].valueDouble * 100, 10) : '?'} %</span>
                            }
                        </div>*/}
                        <div style={{ width: "35%" }}>{systemSetting[`INVITE-POWER-RATE-1`] ? systemSetting[`INVITE-POWER-RATE-1`].valueDouble * 100 : '?'}%</div>
                        {/*<div style={{ width: "30%" }}>
                            {getStore("language") === 'CN' ?
                                <span>{systemSetting["MANAGE-DISCOUNT-1"] ? parseFloat((1 - systemSetting["MANAGE-DISCOUNT-1"].valueDouble) * 10).toFixed(1) : '?'} 折</span>
                                :
                                <span>{systemSetting["MANAGE-DISCOUNT-1"] ? parseInt(systemSetting["MANAGE-DISCOUNT-1"].valueDouble * 100, 10) : '?'} %</span>
                            }
                        </div>*/}
                        <div style={{ width: "35%" }}>{systemSetting[`INVITE-MANAGE-RATE-1`] ? systemSetting[`INVITE-MANAGE-RATE-1`].valueDouble * 100 : '?'}%</div>
                    </div>
                    <div className="flexCenter" style={{ height: 56, paddingLeft: 40, color: '#1d1d27' }}>
                        <div style={{ width: "30%" }}>{languageInfo['People'+ getStore("language")].replace("X", systemSetting && systemSetting[`INVITE-2`] ? systemSetting[`INVITE-2`].valueDouble : "?")}</div>
                        {/*<div style={{ width: "25%" }}>
                            {getStore("language") === 'CN' ?
                                <span>{systemSetting["POWER-DISCOUNT-2"] ? parseFloat((1 - systemSetting["POWER-DISCOUNT-2"].valueDouble) * 10).toFixed(1) : '?'} 折</span>
                                :
                                <span>{systemSetting["POWER-DISCOUNT-2"] ? parseInt(systemSetting["POWER-DISCOUNT-2"].valueDouble * 100, 10) : '?'} %</span>
                            }
                        </div>*/}
                        <div style={{ width: "35%" }}>{systemSetting[`INVITE-POWER-RATE-2`] ? systemSetting[`INVITE-POWER-RATE-2`].valueDouble * 100 : '?'}%</div>
                        {/*<div style={{ width: "30%" }}>
                            {getStore("language") === 'CN' ?
                                <span>{systemSetting["MANAGE-DISCOUNT-2"] ? parseFloat((1 - systemSetting["MANAGE-DISCOUNT-2"].valueDouble) * 10).toFixed(1) : '?'} 折</span>
                                :
                                <span>{systemSetting["MANAGE-DISCOUNT-2"] ? parseInt(systemSetting["MANAGE-DISCOUNT-2"].valueDouble * 100, 10) : '?'} %</span>
                            }
                        </div>*/}
                        <div style={{ width: "35%" }}>{systemSetting[`INVITE-MANAGE-RATE-2`] ? systemSetting[`INVITE-MANAGE-RATE-2`].valueDouble * 100 : '?'}%</div>
                    </div>
                    <div className="flexCenter" style={{ height: 56, paddingLeft: 40, color: '#1d1d27' }}>
                        <div style={{ width: "30%" }}>{languageInfo['People'+ getStore("language")].replace("X", systemSetting && systemSetting[`INVITE-3`] ? systemSetting[`INVITE-3`].valueDouble : "?")}</div>
                        {/*<div style={{ width: "25%" }}>
                            {getStore("language") === 'CN' ?
                                <span>{systemSetting["POWER-DISCOUNT-3"] ? parseFloat((1 - systemSetting["POWER-DISCOUNT-3"].valueDouble) * 10).toFixed(1) : '?'} 折</span>
                                :
                                <span>{systemSetting["POWER-DISCOUNT-3"] ? parseInt(systemSetting["POWER-DISCOUNT-3"].valueDouble * 100, 10) : '?'} %</span>
                            }
                        </div>*/}
                        <div style={{ width: "35%" }}>{systemSetting[`INVITE-POWER-RATE-3`] ? systemSetting[`INVITE-POWER-RATE-3`].valueDouble * 100 : '?'}%</div>
                        {/*<div style={{ width: "30%" }}>
                            {getStore("language") === 'CN' ?
                                <span>{systemSetting["MANAGE-DISCOUNT-3"] ? parseFloat((1 - systemSetting["MANAGE-DISCOUNT-3"].valueDouble) * 10).toFixed(1) : '?'} 折</span>
                                :
                                <span>{systemSetting["MANAGE-DISCOUNT-3"] ? parseInt(systemSetting["MANAGE-DISCOUNT-3"].valueDouble * 100, 10) : '?'} %</span>
                            }
                        </div>*/}
                        <div style={{ width: "35%" }}>{systemSetting[`INVITE-MANAGE-RATE-3`] ? systemSetting[`INVITE-MANAGE-RATE-3`].valueDouble * 100 : '?'}%</div>
                    </div>
                    <div className="flexCenter" style={{ height: 56, paddingLeft: 40, color: '#1d1d27' }}>
                        <div style={{ width: "30%" }}>{languageInfo['People'+ getStore("language")].replace("X", systemSetting && systemSetting[`INVITE-4`] ? systemSetting[`INVITE-4`].valueDouble : "?")}</div>
                        {/*<div style={{ width: "25%" }}>
                            {getStore("language") === 'CN' ?
                                <span>{systemSetting["POWER-DISCOUNT-4"] ? parseFloat((1 - systemSetting["POWER-DISCOUNT-4"].valueDouble) * 10).toFixed(1) : '?'} 折</span>
                                :
                                <span>{systemSetting["POWER-DISCOUNT-4"] ? parseInt(systemSetting["POWER-DISCOUNT-4"].valueDouble * 100, 10) : '?'} %</span>
                            }
                        </div>*/}
                        <div style={{ width: "35%" }}>{systemSetting[`INVITE-POWER-RATE-4`] ? systemSetting[`INVITE-POWER-RATE-4`].valueDouble * 100 : '?'}%</div>
                        {/*<div style={{ width: "30%" }}>
                            {getStore("language") === 'CN' ?
                                <span>{systemSetting["MANAGE-DISCOUNT-4"] ? parseFloat((1 - systemSetting["MANAGE-DISCOUNT-4"].valueDouble) * 10).toFixed(1) : '?'} 折</span>
                                :
                                <span>{systemSetting["MANAGE-DISCOUNT-4"] ? parseInt(systemSetting["MANAGE-DISCOUNT-4"].valueDouble * 100, 10) : '?'} %</span>
                            }
                        </div>*/}
                        <div style={{ width: "35%" }}>{systemSetting[`INVITE-MANAGE-RATE-4`] ? systemSetting[`INVITE-MANAGE-RATE-4`].valueDouble * 100 : '?'}%</div>
                    </div>
                </div>
                {/*<div className="flexCenter" style={{  margin: '0 8px 24px', padding: '8px 36px' }}>
                    <div style={{ width: '100%', height: 1, background: '#dfdfdf' }}></div>
                </div>*/}
                <div style={{ margin: '24px 8px 8px', padding: '12px 36px' }}>
                    <div>{languageInfo["CommissionDetails" + getStore("language")]}</div>
                    {inviteIncome.map(item => ( item.invitePowerIncome !== 0 || item.inviteManageIncome !== 0 ?
                        <div key={`inviteIncome${item.id}`} className="flexBetween" style={{ color: '#c3c3c3', marginTop: 12 }}>
                            <div className="flexCenter">
                                <div style={{ background: '#8833ff', width: 8, height: 8, borderRadius: "50%", marginRight: 8 }}></div>
                                <div>{languageInfo["ElectricityBillRebate" + getStore("language")]}<span>+{(item.invitePowerIncome + "").replace(/^(.*\..{2}).*$/,"$1")}</span>USDT</div>
                            </div>
                            <div>{languageInfo["ServiceFeeRebate" + getStore("language")]}<span>+{(item.inviteManageIncome + "").replace(/^(.*\..{6}).*$/,"$1")}</span>BTC</div>
                        </div>
                        :
                        null
                    ))}
                </div>
            </div>

            <div onClick={() => { setCopyOpen(false) }} className="scaleOpen" style={{ pointerEvents: copyOpen ? 'all' : 'none', background:  copyOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: copyOpen ? 1 : 0, transform: copyOpen ? 'scale(1)' :'scale(0.8)',
                        width: 300,
                        padding: 0,
                        background: '#ffffff',
                        marginTop: 0,
                        lineHeight: 0
                    }}
                >
                    <canvas className="inviteCanvas">

                    </canvas>
                    <img
                        className="inviteImage"
                        style={{ width: 306, position: 'absolute', opacity: 0 }}
                        src={'https://amintsat.s3.ap-southeast-1.amazonaws.com/image/inviteBill' + getStore("language") + '.jpg'}
                        alt=""
                    />
                    <div id="inviteScan" className="inviteCode"></div>
                </div>
            </div>
        </div>
    );
}

export default UserInvite;