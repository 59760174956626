import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";
import appService from "./services";

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Kas(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [kasList,setKasList] = useState([
        {
            coins_24h: 16370.266533871221,
            currency_code: "kas",
            currency_price: 0.042139008754347014,
            difficulty: 7949161771689992,
            display_currency_code: "KAS",
            hashrate: 8300000000000,
            hashrate_unit_str: "T",
            hashrate_unit_value: 8.3,
            id: 335678,
            name: "蚂蚁KS3",
            power: 3188,
            status_comment: "",
        },
        {
            coins_24h: 9861.606345705553,
            currency_code: "kas",
            currency_price: 0.042139008754347014,
            difficulty: 7949161771689992,
            display_currency_code: "KAS",
            hashrate: 5000000000000,
            hashrate_unit_str: "T",
            hashrate_unit_value: 5,
            id: 350363,
            name: "冰河KS3L",
            power:3200,
            status_comment: ""
        },
        {
            coins_24h: 3944.6425382822217,
            currency_code: "kas",
            currency_price: 0.042139008754347014,
            difficulty: 7949161771689992,
            display_currency_code: "KAS",
            hashrate: 2000000000000,
            hashrate_unit_str: "T",
            hashrate_unit_value: 2,
            id: 330959,
            name: "冰河KS2",
            power: 1200,
            status_comment: ""
        },
        {
            coins_24h: 1972.3212691411109,
            currency_code: "kas",
            currency_price: 0.042139008754347014,
            difficulty: 7949161771689992,
            display_currency_code: "KAS",
            hashrate: 1000000000000,
            hashrate_unit_str: "T",
            hashrate_unit_value: 1,
            id: 330960,
            name: "冰河KS1",
            power: 600,
            status_comment: ""
        },
        {
            coins_24h: 197.2321269141111,
            currency_code: "kas",
            currency_price: 0.042139008754347014,
            difficulty: 7949161771689992,
            display_currency_code: "KAS",
            hashrate: 100000000000,
            hashrate_unit_str: "G",
            hashrate_unit_value: 100,
            id: 345446,
            name: "冰河KS0",
            power: 65,
            status_comment: "",
        }
    ])


    const toContact = () => {
        history.push('/contact');
        document.getElementById("root").scrollTo(0,0);
    }
    useMiracleObserver(
        'setMinerList',
        (key, value, store) => {
            if (key === 'setMinerList') {
                setKasList(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    return (
        <div className="mobilePaddingTop kasPage">
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/KaspaBanner.jpg" alt="" className="banner ALEOBanner" />

                <div className="pageBanner">
                    <div style={{ fontWeight: 500, fontSize: 20, width: '70%',color: '#b683fc', letterSpacing: getStore("language") === 'CN' ? 2 : 0, marginBottom: 12 }}>
                        {languageInfo["KasNotice" + getStore("language")]}
                    </div>
                    <div className="BannerFont" style={{ fontWeight: 700, fontSize: getStore("language") === 'CN' ? 56 : 48, letterSpacing:  getStore("language") === 'CN' ? 6 : 0 }}>
                        {languageInfo["KasTitle" + getStore("language")]}
                    </div>
                    <div className="BannerFont" style={{ fontWeight: 500, fontSize: 20,lineHeight: getStore("language") === 'CN' ? "32px" : "24px", letterSpacing: getStore("language") === 'CN' ? 2 : 0, margin: "30px 0", color: '#858585' }}>
                        {languageInfo["KasInfo1" + getStore("language")]}
                    </div>
                    <div className="flexCenter">
                        <div className="CardBtn" onClick={toContact} style={{ background: '#8833ff' }}>
                            <div>{languageInfo["Information2" + getStore("language")]}</div>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/ProjectArrow.png" alt="" style={{ height: 12, marginLeft: 8 }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="kasMiner">
                <div className="kasBlock">
                    <div className="kasTable">
                        <div className="TableHead">
                            <div style={{ width: 120 }}>{languageInfo["KasTable1" + getStore("language")]}</div>
                            <div style={{ width: 120 }}>{languageInfo["KasTable2" + getStore("language")]}</div>
                            <div style={{ width: 120 }}>{languageInfo["KasTable3" + getStore("language")]}</div>
                            <div style={{ width: 120 }}>{languageInfo["KasTable4" + getStore("language")]}</div>
                            <div style={{ width: 120 }}>{languageInfo["KasTable5" + getStore("language")]}</div>
                            <div style={{ width: 180 }}>{languageInfo["KasTable6" + getStore("language")]}</div>
                            <div style={{ width: 150 }}>{languageInfo["KasTable7" + getStore("language")]}</div>
                            <div style={{ width: 150 }}>{languageInfo["KasTable8" + getStore("language")]}</div>
                            <div style={{ width: 150 }}>{languageInfo["KasTable9" + getStore("language")]}</div>
                        </div>
                        {kasList.map(item => (
                            <div className="TableContent">
                                <div style={{ width: 120 }}>{appService.traditionalized(item.name)}</div>
                                <div style={{ width: 120 }}>{item.display_currency_code}</div>
                                <div style={{ width: 120 }}>{item.hashrate_unit_value} {item.hashrate_unit_str}</div>
                                <div style={{ width: 120 }}>{item.power} W</div>
                                <div style={{ width: 120 }}>{parseInt(item.power / item.hashrate_unit_value)} W/{item.hashrate_unit_str}</div>
                                <div style={{ width: 180 }}>{parseFloat(item.coins_24h).toFixed(6)} {item.display_currency_code}</div>
                                <div style={{ width: 150 }}>$ {parseFloat(parseInt(item.coins_24h * item.currency_price * 100) / 100).toFixed(2)}</div>
                                <div style={{ width: 150 }}>$ {parseFloat(parseInt(0.11 * 24 * item.power / 10) / 100).toFixed(2)}</div>
                                <div style={{ width: 150 }}>$ {parseFloat(parseInt((item.coins_24h * item.currency_price - 0.11 * 24 * item.power / 1000) * 100) / 100).toFixed(2)}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kas;