import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Market(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });

    return (
        <div className="content">
            <div style={{ fontSize: 48, color: '#1d1d27', fontWeight: 'bold',textAlign: 'center', marginTop: 120 }}>{languageInfo["MarketTitle" + getStore("language")]}</div>
            <div style={{ width: '70%', margin: '32px auto', position: 'relative' }}>
                <div style={{ paddingTop: 16 }}>
                    {languageInfo["MarketTitle1" + getStore("language")]}
                </div>
                <div style={{ paddingTop: 16 }}>
                    {languageInfo["MarketTitle2" + getStore("language")]}
                </div>
                <div style={{ paddingTop: 16 }}>
                    {languageInfo["MarketTitle3" + getStore("language")]}
                </div>
                <div className="positionAbsolute">
                    <img style={{ width: '60%', marginLeft: '10%', position: 'relative', bottom: 110 }} src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketLine.png" alt="" />
                </div>
            </div>
            <div style={{ fontSize: 48, color: '#1d1d27', fontWeight: 'bold',textAlign: 'center', marginTop: 120, position: 'relative' }}>
                <img alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketMouse.png" style={{ position: 'absolute' , right: '36%', bottom: -20 }} />
                <div>{languageInfo["MarketService" + getStore("language")]}</div>
            </div>
            <div style={{ fontSize: 24, color: '#8f8f90', margin: 24,textAlign: 'center', position: 'relative' }}>
                <div>{languageInfo["MarketServiceRemark" + getStore("language")]}</div>
            </div>
            <div className="content" style={{ display: 'flex', alignItems: 'center',justifyContent: 'center', flexWrap: 'wrap', fontWeight: 'bold', padding: '32px 0%' }}>
                <div style={{ width: '30%', textAlign: 'center', margin: '60px 0', position: 'relative' }}>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MarketInfo6.png" alt="" />
                    <div style={{ color: '#1d1d27', fontSize: 18, margin: 16 }}>{languageInfo["MarketService1" + getStore("language")]}</div>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketRight.png" style={{ position: 'absolute', width: 30, right: -15, top: 15 }} alt="" />
                </div>
                <div style={{ width: '30%', textAlign: 'center', margin: '60px 0', position: 'relative' }}>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MarketInfo5.png" alt="" />
                    <div style={{ color: '#1d1d27', fontSize: 18, margin: 16 }}>{languageInfo["MarketService2" + getStore("language")]}</div>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketRight.png" style={{ position: 'absolute', width: 30, right: -15, top: 15 }} alt="" />
                </div>
                <div style={{ width: '30%', textAlign: 'center', margin: '60px 0', position: 'relative' }}>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MarketInfo2.png" alt="" />
                    <div style={{ color: '#1d1d27', fontSize: 18, margin: 16 }}>{languageInfo["MarketService3" + getStore("language")]}</div>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketRight.png" style={{ position: 'absolute', width: 30,left: '45%', bottom: -80, transform: 'rotate(90deg)' }} alt="" />
                </div>
                <div style={{ width: '30%', textAlign: 'center', margin: '60px 0', position: 'relative' }}>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MarketInfo4.png" alt="" />
                    <div style={{ color: '#1d1d27', fontSize: 18, margin: 16 }}>{languageInfo["MarketService4" + getStore("language")]}</div>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketRight.png" style={{ position: 'absolute', width: 30, right: -15, top: 15, transform: 'rotate(180deg)' }} alt="" />
                </div>
                <div style={{ width: '30%', textAlign: 'center', margin: '60px 0', position: 'relative' }}>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MarketInfo3.png" alt="" />
                    <div style={{ color: '#1d1d27', fontSize: 18, margin: 16 }}>{languageInfo["MarketService5" + getStore("language")]}</div>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketRight.png" style={{ position: 'absolute', width: 30, right: -15, top: 15, transform: 'rotate(180deg)' }} alt="" />
                </div>
                <div style={{ width: '30%', textAlign: 'center', margin: '60px 0', position: 'relative' }}>
                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MarketInfo1.png" alt="" />
                    <div style={{ color: '#1d1d27', fontSize: 18, margin: 16 }}>{languageInfo["MarketService6" + getStore("language")]}</div>
                </div>
            </div>
            <div style={{ fontSize: 48, color: '#1d1d27', fontWeight: 'bold',textAlign: 'center', marginTop: 120 }}>{languageInfo["ComingSoon" + getStore("language")]}</div>
            <div style={{ marginTop: 40, marginBottom: 80, display: 'flex', justifyContent: 'center' }}>
                <div style={{ background: '#8833FF', color: '#ffffff', borderRadius: 32, minWidth: 160, padding: 12, textAlign: 'center' }}>{languageInfo["ComingSoonRemark" + getStore("language")]}</div>
            </div>
        </div>
    );
}

export default Market;