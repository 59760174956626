import { initStore } from 'miracle-observer';
import appService from './services';
initStore(window.location.pathname.indexOf('/') >= 0 ? '/' + window.location.pathname.split('/')[1] : window.location.pathname, 'bannerIndex');
initStore(window.location.href.indexOf("invite=") >= 0 ? window.location.href.split("invite=")[1].split("&")[0] : "", 'inviteCode') ;
initStore("", 'TH');
initStore("", 'Token');
initStore("", 'walletAddress');
initStore([
    {
        id: 34,
        price: "1073.00",
        TH: 50,
        THLst: [50, 100, 200, 500],
        day: "3.60"
    },
    {
        id: 54,
        price: "2146.00",
        TH: 100,
        THLst: [50, 100, 200, 500],
        day: "7.20"
    },
    {
        id: 57,
        price: "4292.00",
        TH: 200,
        THLst: [50, 100, 200, 500],
        day: "14.40"
    },
    {
        id: 24,
        price: "10730.00",
        TH: 500,
        THLst: [50, 100, 200, 500],
        day: "36.00"
    }
], 'mineList');
initStore({
    Miner1EN: 'S19',
    Miner1CN: appService.traditionalized('S19'),
    Plan1EN: "Classic Cloud Hashrate Plan",
    Plan1CN: appService.traditionalized("經典礦機共享套餐"),
    CloudEN: "Cloud Hashrate",
    CloudCN: appService.traditionalized("矿机共享服务"),
    MobileCloudEN: "Cloud Hashrate",
    MobileCloudCN: appService.traditionalized("共享矿机"),
    HostingEN: "Cloud Hosting",
    HostingCN: appService.traditionalized("云托管"),
    MarketEN: "Hashrate Market",
    MarketCN: appService.traditionalized("三方市场"),
    InstitutionalEN: "Institutional",
    InstitutionalCN: appService.traditionalized("机构服务"),
    BuyEN: "Buy Now",
    BuyCN: appService.traditionalized("立即购买"),
    LearnEN: "Learn More",
    LearnCN: "Learn",
    WhyEN: 'Why Choices MintSat?',
    WhyCN: appService.traditionalized('为什么选择MintSat?'),
    WhyRemarkEN: 'Trustable digital assets mining machine\nsharing service platform',
    WhyRemarkCN: appService.traditionalized('值得信赖的数字资产矿机共享服务平台'),
    Service1EN: 'Multi-brand miner suppliers',
    Service1CN: appService.traditionalized('主流礦機品牌供貨'),
    Service1RemarkEN: 'Quality support offered by\nmainstream miner brands.',
    Service1RemarkCN: appService.traditionalized('主流礦機品牌在數字貨幣算力市場中具有較高的市場份額和知名度，能夠滿足大眾的需求。'),
    Service2EN: 'Global compliance mining farms',
    Service2CN: appService.traditionalized('全球合規礦場'),
    Service2RemarkEN: 'High quality mining farms in North\nAmerica, Europe etc.',
    Service2RemarkCN: appService.traditionalized('MintSat已取得美國金融監管機構執照及礦場所在地政府支持，規避政策風險，保護賬戶資產安全。'),
    Service3EN: 'Safeguarded by Mintsat Core software',
    Service3CN: appService.traditionalized('安全程序支持'),
    Service3RemarkEN: 'Experience a one-stop management system for maximum efficiency and revenue.',
    Service3RemarkCN: appService.traditionalized('由Mintsat Core軟件提供保障，體驗一體化後台管理系統。'),
    Service4EN: 'Professional intelligent O&M',
    Service4CN: appService.traditionalized('專業智能化運維'),
    Service4RemarkEN: 'Expert team 7*24h operation\nand maintenance.',
    Service4RemarkCN: appService.traditionalized('全球專家團隊7*24h運維，最大限度地提高效率和收益。'),
    CompleteEN: 'Complete Solution, More Choices',
    CompleteCN: appService.traditionalized('全面服务，更多选择'),
    CompleteRemarkEN: 'Provide large customized mining solutions\nfor professional Institutions',
    CompleteRemarkCN: appService.traditionalized('致力于专业投资者提供大额定制化矿机共享服务解决方案'),
    Complete1EN: 'Cloud Hashrate',
    Complete1CN: appService.traditionalized('矿机共享服务'),
    Complete1RemarkEN: 'Reliable&authentic mining',
    Complete1RemarkCN: appService.traditionalized('真实矿机共享，可信赖'),
    Complete2EN: 'Cloud Hosting',
    Complete2CN: appService.traditionalized('云托管'),
    Complete2RemarkEN: 'ETH Staking',
    Complete2RemarkCN: appService.traditionalized('ETH质押挖矿，穿越牛熊'),
    Complete3EN: 'Aleo',
    Complete3CN: appService.traditionalized('Aleo'),
    Complete3RemarkEN: 'Optimization of algorithmic software, many times higher than the official version.',
    Complete3RemarkCN: appService.traditionalized('優化算法，產出高於官方版本'),
    Complete4RemarkEN: 'A variety of plans to choose from, easy and fast to enjoy Fil calculator service',
    Complete4RemarkCN: appService.traditionalized('多種方案可選，輕鬆快捷享受Fil算力服務'),
    Complete5EN: 'Institution',
    Complete5CN: appService.traditionalized('机构服务'),
    Complete5RemarkEN: 'Dedicated Customization',
    Complete5RemarkCN: appService.traditionalized('定制化礦機服務，專業省心'),
    Complete6RemarkEN: 'AI intelligent quantification, flexible strategies to maximize benefits',
    Complete6RemarkCN: appService.traditionalized('AI智能量化，靈活策略最大化收益'),
    Complete7EN: 'Kaspa',
    Complete7CN: appService.traditionalized('Kaspa'),
    Complete7RemarkEN: 'A POW chain based on the GHOSTDAG protocol Project Introduction',
    Complete7RemarkCN: appService.traditionalized('基於GHOSTDAG協議的POW鏈'),
    SelectPlansEN: 'Select Plans',
    SelectPlansCN: appService.traditionalized('选购套餐'),
    NowEN: 'Stake Now',
    NowCN: appService.traditionalized('在线质押'),
    InquiryEN: 'Customization Inquiry',
    InquiryCN: appService.traditionalized('咨询定制'),
    ProjectIntroductionEN: 'Project Introduction',
    ProjectIntroductionCN: appService.traditionalized('項目介紹'),
    ExperienceNowEN: 'Experience Now',
    ExperienceNowCN: appService.traditionalized('立即體驗'),
    StartEN: '“Simply click to start mining“',
    StartCN: appService.traditionalized('“轻松一点 一键可享矿机共享服务”'),
    StartRemarkEN: 'Anytime, anywhere, grab the package, manage the proceeds',
    StartRemarkCN: appService.traditionalized('随时随地 抢套餐 管收益'),
    Start1EN: 'Purchase\nMining Rigs',
    Start1CN: appService.traditionalized('采购矿机'),
    Start2EN: 'Delivery to the\nMining Farms',
    Start2CN: appService.traditionalized('运至矿场'),
    Start3EN: 'Connecting to\nMining Pools',
    Start3CN: appService.traditionalized('接通矿池'),
    Start4EN: 'Operation and\nMaintenance',
    Start4CN: appService.traditionalized('运维管理'),
    Step1EN: 'User Selects Services\nand Places Orders',
    Step1CN: appService.traditionalized('选购服务并下单'),
    Step2EN: 'MintSat',
    Step2CN: appService.traditionalized('比特铸造'),
    Step3EN: 'Payouts Directly from\nthe Mining Pools',
    Step3CN: appService.traditionalized('从矿池接受数字资产'),
    ProductsEN: 'Products',
    ProductsCN: appService.traditionalized('产品'),
    Products1EN: 'Cloud Hashrate',
    Products1CN: appService.traditionalized('矿机共享服务'),
    Products2EN: 'Cloud Hosting',
    Products2CN: appService.traditionalized('云托管服务'),
    Products3EN: 'Mining Calculator',
    Products3CN: appService.traditionalized('计算器'),
    InformationEN: 'Information',
    InformationCN: appService.traditionalized('信息'),
    Information1EN: 'About Us',
    Information1CN: appService.traditionalized('关于我们'),
    Information2EN: 'Contact Us',
    Information2CN: appService.traditionalized('联系我们'),
    Information3EN: 'Business Cooperation',
    Information3CN: appService.traditionalized('商务合作'),
    SupportEN: 'Support',
    SupportCN: appService.traditionalized('支持'),
    Support1EN: 'Business Cooperation',
    Support1CN: appService.traditionalized('商务合作'),
    Support2EN: 'Help Center',
    Support2CN: appService.traditionalized('帮助中心'),
    Support3EN: 'Submit a ticket',
    Support3CN: appService.traditionalized('提交工单'),
    Support4EN: 'Privacy',
    Support4CN: appService.traditionalized('隐私条款'),
    Support5EN: 'Terms of Use',
    Support5CN: appService.traditionalized('用户条款'),
    ExchangeRateEN: 'Exchange Rate',
    ExchangeRateCN: appService.traditionalized('主要汇率'),
    CustomerServiceEN: '“Institutional Customer Service”',
    CustomerServiceCN: appService.traditionalized('“机构服务”'),
    CustomerService1EN: 'Large Hashrate Order\nCustomization',
    CustomerService1CN: appService.traditionalized('大额算力定制化'),
    CustomerService2EN: 'All-in-One Cloud Hosting',
    CustomerService2CN: appService.traditionalized('矿机托管一体化'),
    CustomerService3EN: 'Compliant Procurement',
    CustomerService3CN: appService.traditionalized('合规采购'),
    CustomerService4EN: 'Exclusive Consultant',
    CustomerService4CN: appService.traditionalized('专属顾问'),
    IntroductionEN: '“Service Introduction”',
    IntroductionCN: appService.traditionalized('“服务介绍”'),
    Introduction1EN: 'MintSat has now fully launched institutional services, focusing on providing a range of digital currency services to various enterprise institutional clients and large retail customers',
    Introduction1CN: appService.traditionalized('MintSat比特鑄造現已全面推出機構服務，專注為各類企業機構客戶和零售大客戶提供一系列數字貨幣服務'),
    Introduction2EN: 'Multi-mode, multi-strategy computing power packages: MintSat offers multiple computing power packages for popular digital currencies in the market, meeting the needs of different customers. These packages adopt multiple strategies and can achieve the best returns under different market conditions.',
    Introduction2CN: appService.traditionalized('多模式、多策略算力套餐：MintSat提供市場熱門數字貨幣的多種算力套餐，滿足不同客戶的需求。這些套餐採用多種策略，能夠在不同的市場情況下獲得最佳回報。'),
    Introduction3EN: 'Comprehensive integrated cloud hosting services: MintSat provides comprehensive integrated cloud hosting services such as mining machine sales, mine selection, mining machine hosting, real-time mining data display, and electricity bill settlement. Customers can easily host their mining machines on MintSat\'s cloud platform and enjoy safe, stable, and efficient services.',
    Introduction3CN: appService.traditionalized('全方位一體化的雲託管服務：MintSat提供礦機出售、礦場甄選、礦機託管、挖礦實時數據展示、電費結算等全方位一體化的雲託管服務。客戶可以輕鬆地將自己的礦機託管在MintSat的雲平台上，享受安全、穩定、高效的服務。'),
    ContactEN: '“Contact Us”',
    ContactCN: appService.traditionalized('“联系我们”'),
    ContactMailEN: 'E-mail:mintsatbusiness@gmail.com',
    ContactMailCN: appService.traditionalized('邮箱：mintsatbusiness@gmail.com'),
    MarketTitleEN: 'MintSat ”Hashrate Market”Officially Launched ',
    MarketTitleCN: appService.traditionalized('MintSat ”三方市场” 正式上线'),
    MarketTitle1EN: 'MintSat first launched ”Hashrate Market”-- a new mode of hashrate service, by introducing third-party hashrate merchants, MintSat provides users with dual option to have access to mining products.',
    MarketTitle1CN: appService.traditionalized('”三方市场“ 是 MintSat 平台引入外部商家矿机共享服务产品的新型服务模式，打造“MintSat自营套餐产品”+“外部商家套餐产品”线上双选平台，开启矿机共享服务新选择。'),
    MarketTitle2EN: 'With third-party hashrate providers (i.e. merchants) enter MintSat and sell their hashrate products. users purchasing such products, the payment goes directly into the merchant account, and the merchant offers hashrate and after-sale services.',
    MarketTitle2CN: appService.traditionalized('商家入驻平台并发布套餐商品，用户购置套餐产品时，商家直接收款，并提供矿机共享服务及售后，矿机产出由矿池直接打款。'),
    MarketTitle3EN: 'In ”Hashrate Market”, MintSat is only a sales channel for this product. The hashrate of the product is provided to you by the third-party hashrate provider, and the merchant is responsible to you for the hashrate products it provides. Therefore, third-party risks associated with such products that are beyond MintSat’s control cannot be ruled out.',
    MarketTitle3CN: appService.traditionalized('”三方市场“ 模式中，MintSat 仅作为产品代销渠道，具体套餐产品由三方商家提供，且商家对于其产品及售后服务负责，不排除存在非MintSat可控的第三方风险。'),
    MarketServiceEN: '”Hashrate Market” Service Process',
    MarketServiceCN: appService.traditionalized('”三方市场” 服务流程'),
    MarketServiceRemarkEN: 'New Choices of sharing service for mining machine',
    MarketServiceRemarkCN: appService.traditionalized('开启矿机共享服务新选择'),
    MarketService1EN: 'Merchants Enter MintSat',
    MarketService1CN: appService.traditionalized('三方商家入驻比特铸造'),
    MarketService2EN: 'User Buys Merchant\nHashrate Products',
    MarketService2CN: appService.traditionalized('用户购买商家矿机共享服务'),
    MarketService3EN: 'Payment Goes to\nthe Merchant',
    MarketService3CN: appService.traditionalized('商家收款'),
    MarketService4EN: 'User Receives Earnings',
    MarketService4CN: appService.traditionalized('用户收到收益'),
    MarketService5EN: 'Payouts Directly from\nthe Mining Pools',
    MarketService5CN: appService.traditionalized('矿池打款'),
    MarketService6EN: 'Merchant Offers Hashrate\nand After-sale Services',
    MarketService6CN: appService.traditionalized('商家提供矿机共享及售后服务'),
    ComingSoonEN: 'More Hashrate Merchants Coming Soon',
    ComingSoonCN: appService.traditionalized('入驻商家陆续加入中'),
    ComingSoonRemarkEN: 'Applications for Membership',
    ComingSoonRemarkCN: appService.traditionalized('申请加入'),

    AllEN: 'All',
    AllCN: appService.traditionalized('全部'),
    SoldEN: 'Sold',
    SoldCN: appService.traditionalized('已售'),
    CheckDetailsEN: 'Check Details',
    CheckDetailsCN: appService.traditionalized('查看详情'),
    ViewMoreEN: 'View More',
    ViewMoreCN: appService.traditionalized('更多筛选'),
    MoreEN: 'View More',
    MoreCN: appService.traditionalized('查看更多'),
    HideEN: 'Hide',
    HideCN: appService.traditionalized('收起'),
    DaysEN: 'Days',
    DaysCN: appService.traditionalized('天'),
    ByEN: 'Provided by',
    ByCN: appService.traditionalized('由'),
    SelfEN: 'Self-run',
    SelfCN: appService.traditionalized('自营'),
    MintSatEN: 'MintSat',
    MintSatCN: appService.traditionalized('MintSat提供'),
    ServiceByEN: 'Hashrate and after-sales services provided by',
    ServiceByCN: appService.traditionalized('由'),
    MintSatServiceEN: 'MintSat, Estimated starting in 24 hrs',
    MintSatServiceCN: appService.traditionalized('MintSat提供服务和售后，预计 24小时之内 开始生效'),
    MerchantsEN: 'Merchants:',
    MerchantsCN: appService.traditionalized('服务商家:'),
    Merchants1EN: 'MintSat Self-run',
    Merchants1CN: appService.traditionalized('MintSat 自营'),
    PlanModeEN: 'Plan Mode:',
    PlanModeCN: appService.traditionalized('套餐模式:'),
    PlanMode1EN: 'Acceleration',
    PlanMode1CN: appService.traditionalized('加速'),
    PlanMode2EN: 'Classic',
    PlanMode2CN: appService.traditionalized('经典'),
    MinerEN: 'Miner:',
    MinerCN: appService.traditionalized('矿机机型:'),
    PlanDurationEN: 'Plan Duration:',
    PlanDurationCN: appService.traditionalized('套餐周期:'),
    DurationEN: 'Plan Duration',
    DurationCN: appService.traditionalized('套餐周期'),
    googleCodeNoticeAEN: 'For the first withdrawal, you need to bind the Google verifier.',
    googleCodeNoticeACN: appService.traditionalized('首次提现需绑定google验证器，'),
    googleCodeNoticeBEN: 'If you forget the key, please contact the customer service',
    googleCodeNoticeBCN: appService.traditionalized('忘记密钥请联系客服'),
    GoogleConfirmEN: 'Have you downloaded the Google authentication and obtained a real-time verification code',
    GoogleConfirmCN: appService.traditionalized('您是否已经下载身份验证器并获得实时验证码'),
    GoogleConfirm1EN: 'Confirm',
    GoogleConfirm1CN: appService.traditionalized('确认'),
    GoogleConfirm2EN: 'No, Continue binding',
    GoogleConfirm2CN: appService.traditionalized('不，继续绑定'),
    AuthenticatorGoogleEN: 'Tutorial on Using Authenticators',
    AuthenticatorGoogleCN: appService.traditionalized('身份验证器使用教程'),
    PlanEN: 'Cloud Hashrate',
    PlanCN: appService.traditionalized('共享服务'),
    NFTPlan1EN: 'KAS Collectibles Pre-Batch',
    NFTPlan1CN: appService.traditionalized('KAS 收藏品提前批'),
    NFTPlan2EN: 'KAS Collectibles Batch-1#',
    NFTPlan2CN: appService.traditionalized('KAS 收藏品第一批'),
    ItemCostEN: 'Item Cost',
    ItemCostCN: appService.traditionalized('项目成本'),
    FeesEN: 'Fees',
    FeesCN: appService.traditionalized('费用'),
    CalculatedEN: 'Calculated at payment',
    CalculatedCN: appService.traditionalized('付款时计算'),
    ConnectWalletEN: 'Connect Your Wallet',
    ConnectWalletCN: appService.traditionalized('连接您的钱包'),
    transferredEN: 'Your purchase will be transferred to this wallet.',
    transferredCN: appService.traditionalized('您的购买将转移到此钱包。'),
    NFTHomeTitleEN: 'Limited Time Special Deal, Get Mintsat KAS NFTs at a Discount!',
    NFTHomeTitleCN: appService.traditionalized('限时特惠， 算力NFT低价抢购！'),
    NFTHomeInfoEN: 'Dear Users,We\'re excited to let you know that we\'re celebrating a big achievement on our NFT platform. To mark this occasion, we\'re offering a special discount for the next week. This is your chance to get high-performance Hashrate NFTs at an amazing price!Don\'t miss out!',
    NFTHomeInfoCN: appService.traditionalized('亲爱的用户，我们激动地宣布，为了庆祝算力NFT平台的里程碑，我们将在未来的一周内举行一场不可错过的打折促销活动。现在是您获得高性能算力NFT的绝佳机会，以前所未有的价格！'),
    NFTBannerItems1EN: 'Universally Collectible NFT',
    NFTBannerItems1CN: appService.traditionalized('Opensea 普遍收藏的 NFT'),
    NFTBannerItems2EN: 'CaaS Hashing 0.1TH',
    NFTBannerItems2CN: appService.traditionalized('CaaS 哈希 0.1TH'),
    NFTBannerItems3EN: 'Ethereum Network',
    NFTBannerItems3CN: appService.traditionalized('以太坊网络'),
    NFTBannerItems4EN: 'Discord Membership Channels',
    NFTBannerItems4CN: appService.traditionalized('Discord 会员频道'),
    NFTBannerItems5EN: 'Tradeable',
    NFTBannerItems5CN: appService.traditionalized('可交易'),
    NFTBannerItems6EN: 'Meta-experience',
    NFTBannerItems6CN: appService.traditionalized('元体验'),
    NFTContent1EN: 'Comprehensive Annualized',
    NFTContent1CN: appService.traditionalized('综合年化'),
    NFTContent2EN: 'Rate of return',
    NFTContent2CN: appService.traditionalized('回报率'),
    NFTContent3EN: 'Total NFT',
    NFTContent3CN: appService.traditionalized('总共 NFT'),
    NFTBuildEN: 'Mint',
    NFTBuildCN: appService.traditionalized('铸造'),
    NFTQuestion1EN: 'What are Mintsat KAS NFTs?',
    NFTQuestion1CN: appService.traditionalized('什么是KAS NFT?'),
    NFTAnswer1EN: 'Mintsat has produced a limited batch(es) of 500 non-fungible  KAS tokens as collectibles.',
    NFTAnswer1CN: appService.traditionalized('Mintsat生产了限量500个不可替代的 KAS 代币作为收藏品,'),
    NFTAnswer101EN: '',
    NFTAnswer101CN: appService.traditionalized('前50个被铸造的NFT将会以更加优惠的价格出售。'),
    NFTQuestion2EN: 'How do l buy the NFT and what do l need to purchase it?',
    NFTQuestion2CN: appService.traditionalized('如何购买会员NFT以及购买需要什么?'),
    NFTAnswer2EN: 'Please ensure you are on the Ethereum Mainnet network  with your wallet and connected to the proper address.',
    NFTAnswer2CN: appService.traditionalized('请确保您的钱包位于ETH主网上并连接到正确的地址。'),
    NFTQuestion3EN: 'How long am l able to mint and when does the mint close?',
    NFTQuestion3CN: appService.traditionalized('我可以铸造多长时间以及铸造厂何时关闭?'),
    NFTAnswer3EN: 'NFTs will be able to be purchased the month of September 2023 and are available until completely being minted.',
    NFTAnswer3CN: appService.traditionalized('NFT 将于2023年11月开始购买，并在完全铸造完成之前可用。'),
    NFTAnswer301EN: 'A new batch will be available after Mintsat establishes and confirmsavailability &amp; inventory of high performance computing resources.',
    NFTAnswer301CN: appService.traditionalized('Mintsat建立并确认可用性后，将提供新批次的产品。高性能计算资源库存。'),
    NFTQuestion4EN: 'What are the  KAS NFT used for?',
    NFTQuestion4CN: appService.traditionalized('KAS NFT 有何用途?'),
    NFTAnswer4EN: 'While by themselves, they are simply collectible art, for Mintsat Members, our customers derive services from holding KAS NFTs. ',
    NFTAnswer4CN: appService.traditionalized('虽然它们本身只是收藏艺术品，但对于持有者来说，我们的客户通过持有KAS NFT 获得服务。'),
    NFTAnswer401EN: 'Each KAS NFT, provides FM Members access to0.1THs per NFT from FM\'s KAS hardware and hosting services.',
    NFTAnswer401CN: appService.traditionalized('每个KAS NFT 都允许会员通过 Mintsat的 KAS 硬件和托管服务访问每个NFT 等于0.1TH的算力。'),
    NFTQuestion5EN: 'When does the KAS NFT expire?',
    NFTQuestion5CN: appService.traditionalized('KAS NFT 何时到期?'),
    NFTAnswer5EN: 'KAS NFTs are held by minters/customers/holders and do not have a specified expiration date.',
    NFTAnswer5CN: appService.traditionalized('FM KAS NFT 由铸币者/客户/持有者持有，没有指定的到期日期。'),
    NFTQuestion6EN: 'Can l exchange/trade my KAS NFTs?',
    NFTQuestion6CN: appService.traditionalized('我可以交易我的KAS NFT吗?'),
    NFTAnswer6EN: 'Yes. You can exchange your membership NFT on any applicable and trusted 3rd party trading platform (ie.OpenSea).',
    NFTAnswer6CN: appService.traditionalized('是的。您可以在任何适用且值得信赖的第三方交易平台(即 OpenSea)上交易您的会员NFT。'),
    NFTQuestion7EN: 'What do l need to do after buying a KAS NFT?',
    NFTQuestion7CN: appService.traditionalized('购买KAS NFT后我需要做什么?'),
    NFTAnswer7EN: 'Hold it in your Ethereum-compatible wallet. The wallet containing that NFT  will obtain exclusive benefits via our Website, Discord, and Metaverse.Wallets that hold this NFT can claim earnings by registering and linking their website accounts.',
    NFTAnswer7CN: appService.traditionalized('将其放在钱包中。包含该 NFT的钱包将通过注册并绑定后我们的网站账号来领取'),
    NFTAnswer701EN: '',
    NFTAnswer701CN: appService.traditionalized('收益，同时加入Discord获得独家福利。'),
    PermanentlyEN: 'Permanently Valid',
    PermanentlyCN: appService.traditionalized('永久有效'),
    ConnectEN: 'Connect Wallet',
    ConnectCN: appService.traditionalized('连接钱包'),
    ShareEN: 'Manage Ratio',
    ShareCN: appService.traditionalized('管理费率'),
    ManageFeeEN: 'Manage Fee',
    ManageFeeCN: appService.traditionalized('管理费'),
    ShareQuestion1EN: 'After recovering the costs, you will receive ',
    ShareQuestion1CN: appService.traditionalized('回本後，您將獲得'),
    ShareQuestion3EN: 'You will receive ',
    ShareQuestion3CN: appService.traditionalized('您將獲得'),
    ShareQuestion2EN: ' of the net profit.',
    ShareQuestion2CN: appService.traditionalized('的产出。'),
    EstimatedEN: 'Estimated starting in 24 hrs',
    EstimatedCN: appService.traditionalized('预计 24小时之内 开始生效'),
    RateAmountEN: 'Hash Rate Amount',
    RateAmountCN: appService.traditionalized('矿机共享服务规格'),
    BeginEN: 'Start immediately',
    BeginCN: appService.traditionalized('立即开始'),
    AnnouncementEN: 'Announcement',
    AnnouncementCN: appService.traditionalized('公告'),
    MoreAnnouncementEN: 'More Announcement',
    MoreAnnouncementCN: appService.traditionalized('更多公告'),
    AnnouncementRemarkEN: 'Stay updated with the latest offers and never miss important announcements.',
    AnnouncementRemarkCN: appService.traditionalized('隨時了解最新優惠，不錯過重要公告。'),
    MyBalanceEN: 'My balance',
    MyBalanceCN: appService.traditionalized('我的余额'),
    BannerTitleEN: 'Supercharge your mining with MintSat immediately',
    BannerTitleCN: appService.traditionalized('立即用比特铸造激发您的挖矿潜能'),
    BannerInfoEN: 'Mining is ready to start with the trusted digital asset mining machine sharing service platform',
    BannerInfoCN: appService.traditionalized('挖矿已经准备就绪，可以开始使用值得信赖的数字资产矿机共享服务平台'),
    CalculatorTitleEN: 'Calculate how much you could start earning',
    CalculatorTitleCN: appService.traditionalized('計算您可以開始賺多少錢'),
    CalculatorInfoEN: 'Use the calculator to see how much you could start earning today.',
    CalculatorInfoCN: appService.traditionalized('用計算器看看今天開始您可以賺多少錢。'),
    CalculatorText1EN: 'CALCULATOR',
    CalculatorText1CN: appService.traditionalized('計算器'),
    CalculatorText2EN: 'Cryptocurrency',
    CalculatorText2CN: appService.traditionalized('加密貨幣'),
    CalculatorText3EN: 'Plan\'s Duration & Hashrate Amount',
    CalculatorText3CN: appService.traditionalized('套餐週期和礦機共享服務規格'),
    CalculatorText4EN: 'Hashrate fee',
    CalculatorText4CN: appService.traditionalized('礦機共享服務費'),
    CalculatorText5EN: 'Electricity fee',
    CalculatorText5CN: appService.traditionalized('電費'),
    CalculatorText6EN: 'Mode of Plan',
    CalculatorText6CN: appService.traditionalized('套餐模式'),
    CalculatorText7EN: 'Calculate',
    CalculatorText7CN: appService.traditionalized('計算'),
    CalculatorText8EN: 'Classic Mode',
    CalculatorText8CN: appService.traditionalized('经典模式'),
    CalculatorText9EN: 'Difficulty',
    CalculatorText9CN: appService.traditionalized('難度'),
    CalculatorText10EN: 'Network hashrate',
    CalculatorText10CN: appService.traditionalized('全網算力'),
    CalculatorText11EN: 'BTC/USD',
    CalculatorText11CN: appService.traditionalized('BTC/USD'),
    CalculatorText12EN: 'Fees',
    CalculatorText12CN: appService.traditionalized('費用'),
    CalculatorText13EN: 'Total hashrate fee',
    CalculatorText13CN: appService.traditionalized('總礦機共享服務費'),
    CalculatorText14EN: 'Total electricity fee',
    CalculatorText14CN: appService.traditionalized('總電費'),
    CalculatorText15EN: 'Total fee',
    CalculatorText15CN: appService.traditionalized('總費用'),
    CalculatorText16EN: 'Breakeven & Revenue',
    CalculatorText16CN: appService.traditionalized('回本和收益'),
    CalculatorText17EN: 'Static Breakeven Day',
    CalculatorText17CN: appService.traditionalized('歷史回本日'),
    CalculatorText18EN: 'Static Net Revenue',
    CalculatorText18CN: appService.traditionalized('歷史淨收益'),
    CalculatorText19EN: 'Static Revenue Rate',
    CalculatorText19CN: appService.traditionalized('歷史產出率'),
    CalculatorText20EN: 'View mining plans',
    CalculatorText20CN: appService.traditionalized('查看礦機共享服務'),
    CalculatorText21EN: 'Accelerator Mode',
    CalculatorText21CN: appService.traditionalized('加速模式'),
    AboutTitleEN: 'About us',
    AboutTitleCN: appService.traditionalized('關於我們'),
    AboutInfoEN: 'We\'re a world-leading provider of digital mining services for popular PoW cryptocurrencies.',
    AboutInfoCN: appService.traditionalized('我們是世界領先的流行 PoW 加密貨幣數字挖掘服務供應商。'),
    AboutText1CN: appService.traditionalized('自2018年以來，我們一直在提供數字挖礦解決方案'),
    AboutText1EN: 'We\'ve been providing digital mining solutions since 2018',
    AboutText2CN: appService.traditionalized('挖礦服務的世界領先者'),
    AboutText2EN: 'World-leader in mining services',
    AboutText3CN: appService.traditionalized('我們於行業領先的挖礦服務受到全球用戶的一致讚賞。'),
    AboutText3EN: 'Our industry-leading mining services are widely appreciated by users across the globe.',
    AboutText4CN: appService.traditionalized('我們處理一切，讓你高枕無憂'),
    AboutText4EN: 'We handle everything, so you don\'t have to',
    AboutText5CN: appService.traditionalized('無需安裝或運行自己的挖礦軟件和硬件； 我們代表您託管一切'),
    AboutText5EN: 'No need to install or run your own mining software and hardware; we host everything on your behalf',
    AboutText6CN: appService.traditionalized('全球礦場網絡'),
    AboutText6EN: 'A global network of mining farms',
    AboutText7CN: appService.traditionalized('我們在全球擁有 5 個礦場，成員遍佈 70 多個國家/地區'),
    AboutText7EN: 'We have 5 mining farms worldwide and members across over 70 countries/regions',
    AboutText8CN: appService.traditionalized('行業領先的加密貨幣'),
    AboutText8EN: 'popular PoW cryptocurrencies',
    AboutText9CN: appService.traditionalized('我們通過廣泛的礦場網絡提供 10 多種行業領先的可挖掘加密貨幣'),
    AboutText9EN: 'We offer 10+ industry-leading, mineable cryptocurrencies through our extensive network of mining farms.',
    AboutText10CN: appService.traditionalized('我們與頂級礦機製造商建立戰略合作夥伴關係'),
    AboutText10EN: 'We have strategic partnerships with top miner manufacturers',
    AboutText11CN: appService.traditionalized('作為一個礦機共享平台，Mintsat與礦機製造商和知名礦池如BTC.com、ViaBTC、Foundry、AntPool、F2Pool和BTC.top建立了戰略聯盟。與我們合作，您將從以下特點受益： \n\n可追溯的礦機\n從礦池中直接支付和灵活的計劃\n真实性和透明度\n安全\n方便\n24/7全天候運作\n\n我們的會員遍布70多個國家和地區，每月訪問量超過3,000,000次，礦埸分布在欧洲和北美，您將加入全球會員群，享受一流的碱機共享服務。'),
    AboutText11EN: 'As a Hash power platform, Mintsat has strategic partnerships with top miner manufacturers, as well as renowned mining pools like BTC.com, ViaBTC, Foundry, AntPool, F2Pool and BTC.top. With us, you\'ll benefit from features like:\n\n· Traceable miners\n· Direct payouts from pools and flexible plans\n· Authenticity and transparency\n· Security\n· Convenience\n· 24/7 operation\n\nWith members across more than 70 countries and regions, over 3,000,000 websites visit each month, and mining farms deployed across Europe and North America, you\'ll be joining a global membership-base with a first-in-class miner sharing service.',
    AboutText12CN: appService.traditionalized('三個簡單的步驟，挖掘熱門數字貨幣'),
    AboutText12EN: 'Mine popular cryptocurrencies in three simple steps',
    AboutText13CN: appService.traditionalized('創建一個帳戶'),
    AboutText13EN: 'Create an account',
    AboutText14CN: appService.traditionalized('選擇您的挖礦計劃'),
    AboutText14EN: 'Select your mining plan',
    AboutText15CN: appService.traditionalized('開始賺錢'),
    AboutText15EN: 'Start earning',
    AboutText16EN: 'Get started today',
    AboutText16CN: appService.traditionalized('就從今天開始'),
    ContactTitleEN: 'Contact us',
    ContactTitleCN: appService.traditionalized('聯絡我們'),
    ContactInfoEN: 'Let us know how we can help!',
    ContactInfoCN: appService.traditionalized('告訴我們要怎樣幫助你！'),
    ContactText1EN: 'First name',
    ContactText1CN: appService.traditionalized('用戶名'),
    ContactText2EN: 'Family name',
    ContactText2CN: appService.traditionalized(''),
    ContactText3EN: 'First name',
    ContactText3CN: appService.traditionalized('请输入您的用户名'),
    ContactText4EN: 'Email',
    ContactText4CN: appService.traditionalized('电子邮件'),
    ContactText5EN: 'Please enter your Email',
    ContactText5CN: appService.traditionalized('请输入您的电子邮件'),
    ContactText6EN: 'Phone number',
    ContactText6CN: appService.traditionalized('電話號碼'),
    ContactText7EN: 'Please enter phone number',
    ContactText7CN: appService.traditionalized('请输入您的电话号码'),
    ContactText8EN: 'Message',
    ContactText8CN: appService.traditionalized('信息'),
    ContactText9EN: 'Enter a description',
    ContactText9CN: appService.traditionalized('输入描述...'),
    ContactText10EN: 'Send Message',
    ContactText10CN: appService.traditionalized('发送信息'),
    BusinessTitleEN: 'Business Cooperation',
    BusinessTitleCN: appService.traditionalized('商務合作'),
    BusinessInfoEN: 'Join us as we deliver exceptional mining opportunities to a global audience and create a foundation for sustainable revenue through crypto mining.',
    BusinessInfoCN: appService.traditionalized('加入我們，我們為全球客戶提供卓越的礦機共享服務機會，並通過加密貨幣礦機共享服務為可持續收入奠定基礎。'),
    BusinessText1EN: 'About Mintsat business cooperation',
    BusinessText1CN: appService.traditionalized('關於Mintsat商務合作'),
    BusinessText2EN: 'Mintsat is a leading, global cloud hashrate sharing platform, providing the advantages of industry-leading mining rigs, favourable fee structure, and direct payouts from mining pools. We make mining easy by sparing others the need to buy, install and operate their own mining equipment. Since launching, we\'ve gained global recognition as a leader in the industry through our efficient and convenient mining services and steadily climbing number of users worldwide.',
    BusinessText2CN: appService.traditionalized('Mintsat是全球領先的礦機共享平台，具有行業領先的礦機供應、優惠的費用結構和礦池直接打款等優勢。我們極大簡化了礦機共享服務，省去購買、安裝、運營礦機等過程。自上線以來，通過高效便捷的的礦機共享服務，我們的全球用戶數量穩步攀升，獲得了全球認可，成為行業領導者。'),
    PayText1CN: appService.traditionalized('繳電費'),
    PayText1EN: 'Pay for Electricity',
    PayText2CN: appService.traditionalized('首次支付天數'),
    PayText2EN: 'First payment days',
    PayText3CN: appService.traditionalized('其他'),
    PayText3EN: 'Others',
    PayText4CN: appService.traditionalized('後續支付方式'),
    PayText4EN: 'Subsequent payment method',
    PayText5CN: appService.traditionalized('余额支付'),
    PayText5EN: 'Manual payment',
    PayText6CN: appService.traditionalized('收益自動支付'),
    PayText6EN: 'Auto-Pay with Revenue',
    PayText7CN: appService.traditionalized('1、初始用電付費天數用完後，請按時繳電費。停繳電費將影響挖礦操作和產出'),
    PayText7EN: '1. After the Initial electricity payment days run out, please pay for the electricity on time. Pause of electricity payments will impact the mining operation and output;',
    PayText8CN: appService.traditionalized('2. 下單成功後，您可以隨時切換到「使用收益自動支付」或「余额支付」模式。'),
    PayText8EN: '2. After successfully placing the order, you can switch to the「Auto-Pay with Revenue」or「Manual payment」mode at any time.',
    PayText9CN: appService.traditionalized('套餐信息'),
    PayText9EN: 'Plan Info',
    PayText10CN: appService.traditionalized('產出類型'),
    PayText10EN: 'Currency',
    PayText11CN: appService.traditionalized('服務規格'),
    PayText11EN: 'Amount',
    PayText12CN: appService.traditionalized('週期'),
    PayText12EN: 'Duration',
    PayText13CN: appService.traditionalized('預計開始生效時間'),
    PayText13EN: 'Estimated starting time',
    PayText14CN: appService.traditionalized('價格'),
    PayText14EN: 'Price',
    PayText15CN: appService.traditionalized('價格/單位'),
    PayText15EN: 'Price/unit',
    PayText16CN: appService.traditionalized('商家'),
    PayText16EN: 'Merchant',
    PayText17CN: appService.traditionalized('數量'),
    PayText17EN: 'Quantity',
    PayText18CN: appService.traditionalized('小計'),
    PayText18EN: 'Subtotal',
    PayText19CN: appService.traditionalized('折扣'),
    PayText19EN: 'Discount',
    PayText20CN: appService.traditionalized('总计'),
    PayText20EN: 'Total',
    PayText21CN: appService.traditionalized('提交訂單'),
    PayText21EN: 'Submit order',
    PayText22CN: appService.traditionalized('訂單摘要'),
    PayText22EN: 'Order summary',
    ErrorCN: appService.traditionalized('呃，出错了，请稍后重试'),
    ErrorEN: 'Error, please try again later',
    PostError1008CN: appService.traditionalized('操作过于频繁'),
    PostError1008EN: 'Too frequent operation',
    PostError1012CN: appService.traditionalized('邮箱已存在'),
    PostError1012EN: 'Email already exist',
    PostError2017CN: appService.traditionalized('邮箱不存在'),
    PostError2017EN: 'Email does not exist',
    PostError2004CN: appService.traditionalized('google验证码错误'),
    PostError2004EN: 'Google verification code error',
    PostError2005CN: appService.traditionalized('账号密码不正确'),
    PostError2005EN: 'Account password is incorrect',
    PostError2002CN: appService.traditionalized('邮箱验证码错误'),
    PostError2002EN: 'Email verification code error',
    PostError10001CN: appService.traditionalized('之前已绑定过密钥'),
    PostError10001EN: 'Key has been bound before',
    PostError9999CN: appService.traditionalized('无效的地址'),
    PostError9999EN: 'Invalid address',
    PostError9998CN: appService.traditionalized('地址已被其他账号绑定'),
    PostError9998EN: 'The address has been bound by another account',
    PostError9997CN: appService.traditionalized('余额不足'),
    PostError9997EN: 'Insufficient balance',
    PostError9996CN: appService.traditionalized('公共地址繁忙，请稍后再试'),
    PostError9996EN: 'The public address is busy, please try again later',
    PostError9995CN: appService.traditionalized('购买失败'),
    PostError9995EN: 'Failed purchase',
    PostError9994CN: appService.traditionalized('资产余额不足'),
    PostError9994EN: 'Insufficient Assets',
    PostError9993CN: appService.traditionalized('数量必须大于最小值'),
    PostError9993EN: 'Quantity must be greater than minimum',
    PostError9992CN: appService.traditionalized('天数必须大于最小值'),
    PostError9992EN: 'The number of days must be greater than the minimum value',
    PostError9991CN: appService.traditionalized('算力必须大于最小值'),
    PostError9991EN: 'Hashrate must be greater than the minimum value',
    PostError9990CN: appService.traditionalized('当前套餐不在可购买时间'),
    PostError9990EN: 'The current package is not available for purchase',
    PostError9989CN: appService.traditionalized('不存在这条算力'),
    PostError9989EN: 'This computing power does not exist',
    PostError9988CN: appService.traditionalized('不存在这条套餐'),
    PostError9988EN: 'This plan does not exist',
    PostError9987CN: appService.traditionalized('ETH不足'),
    PostError9987EN: 'Not enough ETH',
    PostError9986CN: appService.traditionalized('状态错误'),
    PostError9986EN: 'Status error',
    PostError9985CN: appService.traditionalized('质押池已锁定'),
    PostError9985EN: 'Staking pool is locked',
    PostError9984CN: appService.traditionalized('找不到质押池'),
    PostError9984EN: 'Staking pool not found',
    PostError9983CN: appService.traditionalized('ETH必须大于最小值'),
    PostError9983EN: 'ETH must be greater than minimum',
    PostError9982CN: appService.traditionalized('提现失败'),
    PostError9982EN: 'Withdrawal failed',
    PostError9981CN: appService.traditionalized('请输入正确的数额'),
    PostError9981EN: 'Please enter the correct amount',
    PostError9980CN: appService.traditionalized('余额不足'),
    PostError9980EN: 'Insufficient balance',
    PostError9978CN: appService.traditionalized('请输入密钥'),
    PostError9978EN: 'please input google key',
    PostError9976CN: appService.traditionalized('谷歌验证码错误'),
    PostError9976EN: '2FA code error',
    InputEmailEN: 'Enter your Email address',
    InputEmailCN: appService.traditionalized('输入你的电子邮箱开始'),
    downloadEN: 'Download APP',
    downloadCN: appService.traditionalized('下载APP'),
    loginEN: 'Log in',
    loginCN: appService.traditionalized('登录'),
    androidDownloadEN: 'Android Download',
    androidDownloadCN: appService.traditionalized('安卓下载'),
    IOSDownloadEN: 'IOS Download',
    IOSDownloadCN: appService.traditionalized('IOS下载'),
    download1EN: 'Download the App today',
    download1CN: appService.traditionalized('立即下载应用程序'),
    download2EN: 'One-click mining',
    download2CN: appService.traditionalized('一鍵挖礦'),
    download3EN: 'Effortlessly order long and short-term mining plans with low thresholds and low fees.',
    download3CN: appService.traditionalized('輕鬆訂購低門檻和低費用的長期和短期挖礦計劃。'),
    download4EN: 'Instant notifications',
    download4CN: appService.traditionalized('即時通知'),
    download5EN: 'Never miss important announcements and offers; you’ll be instantly notified if anything comes up.',
    download5CN: appService.traditionalized('不錯過重要的公告和優惠；如果有任何事情發生，您會立即收到通知。'),
    download6EN: 'Track your revenue',
    download6CN: appService.traditionalized('跟踪您的營業收入'),
    download7EN: 'Keep track of the hashrate and income of all your mining pools with real-time and historical data for each account.',
    download7CN: appService.traditionalized('使用每個賬戶的實時和歷史數據跟踪所有礦池的算力和營收。'),
    ComparativeEN: '- Comparative analysis -',
    ComparativeCN: appService.traditionalized('- 对比分析 -'),
    HashrateEN: 'Hashrate Fee',
    HashrateCN: appService.traditionalized('矿机共享服务费'),
    HashrateQuestionEN: 'The hashrate fee needs to be paid in one time when purchasing',
    HashrateQuestionCN: appService.traditionalized('購買時需要一次性支付礦機共享服務費'),
    ServiceFeeEN: 'Service Fee',
    ServiceFeeCN: appService.traditionalized('服务费'),
    RateReturnEN: 'rate of return',
    RateReturnCN: appService.traditionalized('收益率'),
    ReturnEN: 'return',
    ReturnCN: appService.traditionalized('收益'),
    PledgeAmountEN: 'Staking amount',
    PledgeAmountCN: appService.traditionalized('质押量'),
    StakingEN: 'Staking',
    StakingCN: appService.traditionalized('质押'),
    StakingInputEN: 'Please enter the amount of staking ETH',
    StakingInputCN: appService.traditionalized('请输入质押ETH数量'),
    BalanceAllInEN: 'Balance all-in',
    BalanceAllInCN: appService.traditionalized('余额全投'),
    AccumulatedEN: 'accumulated income',
    AccumulatedCN: appService.traditionalized('累积收益'),
    ElectricityEN: 'Electricity',
    ElectricityCN: appService.traditionalized('电费'),
    ElectricityTypeEN: 'Electricity Type',
    ElectricityTypeCN: appService.traditionalized('电费方式'),
    ElectricityQuestionEN: 'The electricity fee can be paid in installments after the plan\'s activation. Bitdeer shall have the right to adjust the electricity fee of the Service Plan from time to time according to the actual electricity price of the mining site where the miners are located.',
    ElectricityQuestionCN: appService.traditionalized('電費可以在套餐激活後分期支付。比特铸造有權根據礦機所在礦場的實際電價不時調整服務套餐的電費。'),
    ElectricityBalanceEN: 'Electricity USDT Balance',
    ElectricityBalanceCN: appService.traditionalized('电费余额'),
    ElectricityNoticeEN: 'After arrears, Hyundai Electric collects X% payment fees',
    ElectricityNoticeCN: appService.traditionalized('电费欠款后，每天都会收X%滞纳金'),
    ElectricityBillEN: 'Electricity Bill',
    ElectricityBillCN: appService.traditionalized('电费记录'),
    BuySuccess1EN: 'Dear Mintsat User',
    BuySuccess1CN: appService.traditionalized('親愛的Mintsat用戶'),
    BuySuccess2EN: 'Thank you for your support and love. If you have any other questions, please contact the official customer service of the platform',
    BuySuccess2CN: appService.traditionalized('感謝您的支持與厚愛，若有其他問題，歡迎聯系平台官方客服'),
    BuySuccess3EN: 'You successfully purchased ',
    BuySuccess3CN: appService.traditionalized('您已成功购买'),
    RechargeEN: 'Deposit',
    RechargeCN: appService.traditionalized('充值'),
    RechargeElectricityEN: 'Deposit Electricity',
    RechargeElectricityCN: appService.traditionalized('充值电费'),
    RechargeableQuantityEN: 'Rechargeable Quantity',
    RechargeableQuantityCN: appService.traditionalized('可充数量'),
    WalletRechargeEN: 'Web3 Wallet Deposit',
    WalletRechargeCN: appService.traditionalized('Web3钱包充值'),
    InputElectricityUSDTEN: 'Please enter the amount of Electricity USDT',
    InputElectricityUSDTCN: appService.traditionalized('请输入充值电费USDT'),
    SelectLinkEN: 'Select Chain Type',
    SelectLinkCN: appService.traditionalized('选择链路类型'),
    PleaseSelectLinkEN: 'Please Select Chain Type',
    PleaseSelectLinkCN: appService.traditionalized('请选择链路类型'),
    PublicAddressRechargeEN: 'Centralized Exchange Deposit',
    PublicAddressRechargeCN: appService.traditionalized('中心化交易所提币充值'),
    RechargeCoinEN: 'Deposit Coin',
    RechargeCoinCN: appService.traditionalized('充值币种'),
    TransferAddressEN: 'Transfer-out address',
    TransferAddressCN: appService.traditionalized('转出地址'),
    ForwardingAddressEN: 'Deposit Address',
    ForwardingAddressCN: appService.traditionalized('转入地址'),
    EditAddressEN: 'Change address',
    EditAddressCN: appService.traditionalized('更换地址'),
    PleaseBindAddressEN: 'Please use the bound wallet address',
    PleaseBindAddressCN: appService.traditionalized('请绑定转出地址'),
    PleaseBindAddressDepositEN: 'Please use the bound wallet address to transfer token to the transfer-in address',
    PleaseBindAddressDepositCN: appService.traditionalized('请使用绑定的钱包地址向转入地址转账'),
    PleaseWaitAddressEN: 'Please wait patiently after the recharge is successful',
    PleaseWaitAddressCN: appService.traditionalized('充值成功后请耐心等待'),
    TimeLeftEN: 'Time Left',
    TimeLeftCN: appService.traditionalized('剩余时间'),
    RechargeRecordEN: 'Deposit Record',
    RechargeRecordCN: appService.traditionalized('充值记录'),
    WithdrawCoinEN: 'Withdraw Coin',
    WithdrawCoinCN: appService.traditionalized('提现币种'),
    WithdrawQuantityEN: 'Withdraw Quantity',
    WithdrawQuantityCN: appService.traditionalized('可提数量'),
    InputWithdrawEN: 'Please enter the amount of withdraw quantity',
    InputWithdrawCN: appService.traditionalized('请输入提币数量'),
    NotAvailableEN: ' NOT available, electricity fee must be charged ',
    NotAvailableCN: appService.traditionalized(' 不可提，需缴纳电费'),
    ReceivingAddressEN: 'Receiving Address',
    ReceivingAddressCN: appService.traditionalized('收币地址'),
    OrderAmountEN: 'Order Amount',
    OrderAmountCN: appService.traditionalized('订单金额'),
    OrderStatusEN: 'Order Status',
    OrderStatusCN: appService.traditionalized('订单状态'),
    ConfirmEN: 'Confirm',
    ConfirmCN: appService.traditionalized('确认'),
    SubmittingEN: 'Submitting application',
    SubmittingCN: appService.traditionalized('正在提交申请'),
    PleaseWaitEN: 'Please wait, under review',
    PleaseWaitCN: appService.traditionalized('请等待，正在审核中'),
    agreeEN: 'Agree',
    agreeCN: appService.traditionalized('已通过'),
    refuseEN: 'Refuse',
    refuseCN: appService.traditionalized('已拒绝'),
    UnauditedEN: 'Unaudited',
    UnauditedCN: appService.traditionalized('审核中'),
    InviteTitleEN: 'Invitation rewards are coming your way',
    InviteTitleCN: appService.traditionalized('邀請獎勵正向您奔來'),
    InviteRemarkEN: 'Share a super discount with friends and let the BTC,FIL,USDT rebates roll in!',
    InviteRemarkCN: appService.traditionalized('與朋友分享超級折扣，贏 BTC,FIL,USDT 滾滾返利！'),
    InviteVIPEN: 'Your current level',
    InviteVIPCN: appService.traditionalized('您當前的等级'),
    InviteLinkEN: 'Use your invitation link and earn rewards by inviting friends and family',
    InviteLinkCN: appService.traditionalized('使用邀請鏈接邀請親朋好友拿獎勵'),
    InvitedPeopleEN: 'Invited People',
    InvitedPeopleCN: appService.traditionalized('已邀请的人'),
    PeopleEN: 'Invited X People',
    PeopleCN: appService.traditionalized('邀请X人'),
    CurrentLevelEN: 'Current Level',
    CurrentLevelCN: appService.traditionalized('当前等级'),
    NextLevelEN: 'Next Level',
    NextLevelCN: appService.traditionalized('下个等级'),
    InviteMoreEN: 'Invite ',
    InviteMoreCN: appService.traditionalized('再邀请'),
    InviteLevelEN: ' more person buy cloud hashrate to upgrade',
    InviteLevelCN: appService.traditionalized('人购买矿机共享服务后升级'),
    ElectricityBillRebateEN: 'Electricity Bill Rebate',
    ElectricityBillRebateCN: appService.traditionalized('电费返佣'),
    ServiceFeeRebateEN: 'Service Fee Rebate',
    ServiceFeeRebateCN: appService.traditionalized('服务费返佣'),
    ElectricityBillReductionEN: 'Electricity Bill Discount',
    ElectricityBillReductionCN: appService.traditionalized('电费折扣'),
    ServiceFeeReductionEN: 'Service Fee Discount',
    ServiceFeeReductionCN: appService.traditionalized('服务费折扣'),
    CommissionDetailsEN: 'Commission Details',
    CommissionDetailsCN: appService.traditionalized('奖励明细'),
    InvitationLinkEN: 'Invitation link',
    InvitationLinkCN: appService.traditionalized('邀请链接'),
    RegisteringEN: 'Registering through the invitation link, the invited user will receive a computing power contract',
    RegisteringCN: appService.traditionalized('通过邀请链接注册，所邀用户可获赠一条矿机共享服务'),
    GetShareEN: 'You can get the non-gifted cloud hashrate electricity output share of the invited users',
    GetShareCN: appService.traditionalized('可以获得所邀请用户的非赠送矿机共享服务电费分成'),
    GetPowerEN: 'You can get the non-gifted cloud hashrate service fee output share of the invited users',
    GetPowerCN: appService.traditionalized('可以获得所邀请用户的非赠送矿机共享服务服务费分成'),
    GetPowerTableEN: 'Details of electricity and service fee refund',
    GetPowerTableCN: appService.traditionalized('服务费以及电费返佣明细'),
    VIP1EN: 'copper',
    VIP1CN: appService.traditionalized('铜'),
    VIP2EN: 'silver',
    VIP2CN: appService.traditionalized('银'),
    VIP3EN: 'gold',
    VIP3CN: appService.traditionalized('金'),
    VIP4EN: 'diamond',
    VIP4CN: appService.traditionalized('钻石'),
    assetsEN: 'My Assets',
    assetsCN: appService.traditionalized('我的资产'),
    messageEN: 'My Message',
    messageCN: appService.traditionalized('我的消息'),
    freeEN: 'Free',
    freeCN: appService.traditionalized('免费'),
    userEN: 'Account Settings',
    userCN: appService.traditionalized('账号设置'),
    hashrateEN: 'Cloud Hashrate',
    hashrateCN: appService.traditionalized('矿机共享服务'),
    orderEN: 'My Order',
    orderCN: appService.traditionalized('我的套餐'),
    electricityEN: 'My Electricity',
    electricityCN: appService.traditionalized('我的电费'),
    depositEN: 'My Deposit',
    depositCN: appService.traditionalized('我的充值'),
    withdrawEN: 'My Withdraw',
    withdrawCN: appService.traditionalized('我的提现'),
    inviteEN: 'My Invite',
    inviteCN: appService.traditionalized('我的邀请'),
    ElectricityUSDTEN: 'Electricity USDT',
    ElectricityUSDTCN: appService.traditionalized('电费 USDT'),
    OutputEN: 'Static Output',
    OutputCN: appService.traditionalized('历史产出'),
    OutEN: 'Output',
    OutCN: appService.traditionalized('产出'),
    OutputQuestionEN: 'Average static output in the past 30 days, this data is provided by BTC.com with the FPPS payment mode.',
    OutputQuestionCN: appService.traditionalized('近30天的平均歷史產出，此數據由 BTC.com 提供，採用FPPS支付方式。'),
    RevenueEN: 'Static Revenue Rate',
    RevenueCN: appService.traditionalized('历史产出率'),
    RemarkEN: 'Remark',
    RemarkCN: appService.traditionalized('备注'),
    PriceAdjustmentEN: '- Price adjustment statement -',
    PriceAdjustmentCN: appService.traditionalized('- 价格调整声明 -'),
    PriceAdjustmentRemarkEN: 'As a real computing power service platform, MintSat the plan\'s price will be dynamically adjusted according to market supply and demand and mining difficulty to ensure that the plan\'s price is competitive in the market.',
    PriceAdjustmentRemarkCN: appService.traditionalized('作为真实矿机共享服务平台，Mintast的套餐价格会根据市场情况、需求和难度进行动态调整，以保证套餐价格在市场上具有竞争力。'),

    MinimumEN: 'Minimum quantity',
    MinimumCN: appService.traditionalized('最低数量'),
    QuantityEN: 'Miner Quantity',
    QuantityCN: appService.traditionalized('矿机数量'),
    MinerInfoEN: '- Miner Info -',
    MinerInfoCN: appService.traditionalized('矿机信息'),
    MinerInfo1EN: 'Break the limit, show style',
    MinerInfo1CN: appService.traditionalized('打破极限 方显风范'),
    MinerInfo2EN: 'Hashrate',
    MinerInfo2CN: appService.traditionalized('礦機共享服務'),
    MinerInfo3EN: 'Power',
    MinerInfo3CN: appService.traditionalized('矿机功率'),
    MinerInfo4EN: 'Efficiency',
    MinerInfo4CN: appService.traditionalized('能效比'),
    MinerInfo5EN: 'Solid State Drive',
    MinerInfo5CN: appService.traditionalized('固态硬盘'),
    MinerInfo6EN: 'System',
    MinerInfo6CN: appService.traditionalized('系统'),
    CurrencyEN: 'Currency',
    CurrencyCN: appService.traditionalized('产出类型'),
    MinerStakeEN: 'Including Pledge Coins',
    MinerStakeCN: appService.traditionalized('包括質押幣'),
    StakeEN: 'Pledge Coins',
    StakeCN: appService.traditionalized('質押幣'),
    StakeDuration1EN: 'The hashrate sharing service will be provided for ',
    StakeDuration1CN: appService.traditionalized('礦機共享服務將提供'),
    StakeDuration2EN: ' days and the revenue will be released linearly for 180 days.',
    StakeDuration2CN: appService.traditionalized('天，收益將線性發放180天。'),
    StakeFilQuestionEN: 'The package includes the estimated amount of prepaid pledge, and the pledge currency will be returned to you within 3 natural days after the service ends.',
    StakeFilQuestionCN: appService.traditionalized('该套餐包括预估的预付质押数量，质押币将在服务结束后3个自然日内退还给您。'),
    AmountEN: 'Amount',
    AmountCN: appService.traditionalized('服务规格'),
    RestockingNoticeEN: 'Coming Soon',
    RestockingNoticeCN: appService.traditionalized('敬请期待'),
    NoticeEN: 'The Plan will be Running in Compliance Mining Farms Loacted in Texas, Washington,Tennessee US and in Norway',
    NoticeCN: appService.traditionalized('商品生效时运行矿场：美国田纳西/华盛顿/德州及挪威等地合规矿场'),
    EstimatedTimeEN: 'Estimated starting time:',
    EstimatedTimeCN: appService.traditionalized('预计开始时间:'),
    StartTimeEN: 'Starting time',
    StartTimeCN: appService.traditionalized('开始时间'),
    EndTimeEN: 'Ending time',
    EndTimeCN: appService.traditionalized('结束时间'),
    EstimatedTime1EN: '24 hrs',
    EstimatedTime1CN: appService.traditionalized('24小时之内'),
    PayoutPoolEN: 'Payout Pool:',
    PayoutPoolCN: appService.traditionalized('打币矿池:'),
    PayoutPool1EN: 'Third-Party Pool',
    PayoutPool1CN: appService.traditionalized('第三方矿池'),
    PayoutCycleEN: 'Payout Cycle:',
    PayoutCycleCN: appService.traditionalized('打币周期:'),
    PayoutCycle1EN: 'Daily the soonest',
    PayoutCycle1CN: appService.traditionalized('最快周期为每日'),
    RuntimeEN: 'Run Time:',
    RuntimeCN: appService.traditionalized('服务时长:'),
    HashrateAmountEN: 'Hashrate:',
    HashrateAmountCN: appService.traditionalized('矿机:'),
    RuntimeQuestionEN: 'If an electricity interruption incurs during the service period, the mining time will be extended based on a 1:1 ratio.',
    RuntimeQuestionCN: appService.traditionalized('如果在服務期間發生停電，礦機共享服務時間將按1：1的比例延長。'),
    OnlyEN: '(For reference only)',
    OnlyCN: appService.traditionalized('(仅供参考)'),
    PlanDetailsEN: 'Plan Details',
    PlanDetailsCN: appService.traditionalized('套餐详情'),
    StaticAnalysisEN: 'Static Analysis',
    StaticAnalysisCN: appService.traditionalized('历史产出分析'),
    BreakevenDayEN: 'Static Breakeven Days',
    BreakevenDayCN: appService.traditionalized('历史回本天数'),
    PlanDurationDaysEN: 'Days of Plan Duration',
    PlanDurationDaysCN: appService.traditionalized('套餐周期天数'),
    WelcomeEN: 'Welcome to',
    WelcomeCN: appService.traditionalized('欢迎来到'),
    emailInputEN: 'please input your Email',
    emailInputCN: appService.traditionalized('请输入您的电子邮件'),
    codeInputEN: 'please input authentication code',
    codeInputCN: appService.traditionalized('请输入验证码'),
    SendCodeEN: 'Send Code',
    SendCodeCN: appService.traditionalized('发送'),
    googleCodeEN: 'google code',
    googleCodeCN: appService.traditionalized('请输入google验证码'),
    googleKeyEN: 'google key',
    googleKeyCN: appService.traditionalized('google密钥'),
    SettingPasswordEN: 'Setting login password',
    SettingPasswordCN: appService.traditionalized('设置登录密码'),
    NewPasswordEN: 'Please enter the new password',
    NewPasswordCN: appService.traditionalized('请输入新的登录密码'),
    inputPasswordEN: 'Please enter the password',
    inputPasswordCN: appService.traditionalized('请输入登录密码'),
    PasswordEN: 'Password',
    PasswordCN: appService.traditionalized('登录密码'),
    EmailEN: 'Email',
    EmailCN: appService.traditionalized('邮箱账号'),
    WalletEN: 'Wallet',
    WalletCN: appService.traditionalized('钱包'),
    AddressEN: 'Address',
    AddressCN: appService.traditionalized('地址'),
    NotSetEN: 'Not Set',
    NotSetCN: appService.traditionalized('未设定'),
    HasSetEN: 'Has Been Set',
    HasSetCN: appService.traditionalized('已设定'),
    ToBindEN: 'To Bind',
    ToBindCN: appService.traditionalized('去绑定'),
    ChangesEN: 'changes',
    ChangesCN: appService.traditionalized('变化'),
    SureEN: 'Sure',
    SureCN: appService.traditionalized('确定'),
    inputAddressEN: 'please input your address',
    inputAddressCN: appService.traditionalized('请输入您的钱包地址'),
    BindWalletEN: 'Bind Wallet',
    BindWalletCN: appService.traditionalized('绑定钱包'),
    TotalPowerEN: 'Total Power',
    TotalPowerCN: appService.traditionalized('总算力'),
    ServingEN: 'Active',
    ServingCN: appService.traditionalized('服务中'),
    CompletedEN: 'Completed',
    CompletedCN: appService.traditionalized('已完成'),
    NoStartEN: 'Not Started',
    NoStartCN: appService.traditionalized('未开始'),
    StopEN: 'Stop Service',
    StopCN: appService.traditionalized('终止合约'),
    SoldOutEN: 'Sold Out',
    SoldOutCN: appService.traditionalized('已售罄'),
    OverEN: 'Over',
    OverCN: appService.traditionalized('已结束'),
    PackageAllocationEN: 'Pool Allocation',
    PackageAllocationCN: appService.traditionalized('套餐分配'),
    OrderDateEN: 'Order Date',
    OrderDateCN: appService.traditionalized('订单日期'),
    DateTimeEN: 'Date',
    DateTimeCN: appService.traditionalized('日期'),
    OrderIDEN: 'Order ID',
    OrderIDCN: appService.traditionalized('订单ID'),
    ProductNameEN: 'Product Name',
    ProductNameCN: appService.traditionalized('产品名称'),
    TotalOrderEN: 'Total Power',
    TotalOrderCN: appService.traditionalized('订单算力'),
    PreviousPageEN: 'Previous',
    PreviousPageCN: appService.traditionalized('上一页'),
    NextPageEN: 'Next',
    NextPageCN: appService.traditionalized('下一页'),
    AutomaticDeductionEN: 'Automatic Deduction',
    AutomaticDeductionCN: appService.traditionalized('自动抵扣'),
    MoreOutputDataEN: 'More Output Data',
    MoreOutputDataCN: appService.traditionalized('更多产出数据'),
    CumulativeNetOutputEN: 'Cumulative Net Output',
    CumulativeNetOutputCN: appService.traditionalized('累积净产出'),
    CloudHashrateOrderEN: 'Cloud Hashrate Order',
    CloudHashrateOrderCN: appService.traditionalized('矿机共享订单'),
    AutoRenewElectricityOrderEN: 'Auto-RenewElectricityOrder',
    AutoRenewElectricityOrderCN: appService.traditionalized('自动缴费电力订单'),
    TotalOutputEN: 'Total Output',
    TotalOutputCN: appService.traditionalized('总产出'),
    IssuedEN: 'Issued',
    IssuedCN: appService.traditionalized('已发放'),
    LockupEN: 'Lockup',
    LockupCN: appService.traditionalized('锁仓'),
    noAccountEN: 'no account yet',
    noAccountCN: appService.traditionalized('还没有账号'),
    SignUpNowEN: 'Sign up now',
    SignUpNowCN: appService.traditionalized('立即注册'),
    ResetPasswordEN: 'Reset Password',
    ResetPasswordCN: appService.traditionalized('忘记密码'),
    RegisterEN: 'Register',
    RegisterCN: appService.traditionalized('注册'),
    LoginEN: 'Login',
    LoginCN: appService.traditionalized('登录'),
    PasswordLoginEN: 'Password Login',
    PasswordLoginCN: appService.traditionalized('密码登录'),
    RegistrationSuccessEN: 'Please add Google two-step verification code',
    RegistrationSuccessCN: appService.traditionalized('请添加谷歌两步验证代码'),
    AuthenticatorFirstEN: 'Step 1: Download Google Authenticator.',
    AuthenticatorFirstCN: appService.traditionalized('第一步：下载谷歌身份验证器'),
    AuthenticatorSecondEN: 'Step 2: Click "Scan QR code" on the app page.',
    AuthenticatorSecondCN: appService.traditionalized('第二步：点击扫描二维码,扫描下方的二维码添加'),
    AndroidDownloadEN: 'Android Download',
    AndroidDownloadCN: appService.traditionalized('安卓下载'),
    copyKeyEN: 'copy key',
    copyKeyCN: appService.traditionalized('复制密钥'),
    PersonalCenterEN: 'Personal center',
    PersonalCenterCN: appService.traditionalized('个人中心'),
    HomepageEN: 'Homepage',
    HomepageCN: appService.traditionalized('个人主页'),
    SignOutEN: 'Sign out',
    SignOutCN: appService.traditionalized('退出登录'),
    FILNoticeEN: 'Fil FEVM is coming',
    FILNoticeCN: appService.traditionalized('Fil FEVM来了'),
    FILTitleEN: 'Filecoin is a decentralized storage system.',
    FILTitleCN: appService.traditionalized('Filecoin 项目是一个去中心化的存储系统。'),
    FILInfo1EN: 'Fil introduces the incentive layer for the IPFS protocol and implements open data services. Filecoin is a distributed storage network based on blockchain technology with advantages such as high security, efficiency, decentralization, cost savings, and a complete ecosystem. It uses an economic incentive mechanism to encourage nodes to join the network and provide storage services, reducing storage costs and making storage space more fair and transparent.',
    FILInfo1CN: appService.traditionalized('為 IPFS 協議引入了激勵層，並實現了數據的開放服務。Filecoin是一种基于区块链技术的分布式存储网络，具有高度安全、高效、去中心化、节约成本和完备的生态系统等优势和特点。它采用经济激励机制促进节点加入网络并提供存储服务，降低存储成本，同时使得存储空间更加公平和透明。它可以用于存储各种类型的文件，具有广泛的应用前景。'),
    KasNoticeEN: 'Birth of the GHOSTDAG Protocol',
    KasNoticeCN: appService.traditionalized('GHOSTDAG 協議的誕生'),
    KasTitleEN: 'Promising Solution to the Impossible Triangle',
    KasTitleCN: appService.traditionalized('有望解決不可能三角的 POW 鏈'),
    KasInfo1EN: 'Kaspa introduced the PHANTOM protocol, which is a permissionless ledger protocol based on Proof of Work, extending Satoshi Nakamoto\'s defined blockchain to a directed acyclic graph (BLOCKDAG). PHANTOM can reference multiple previous blocks, provide a total order of all blocks and transactions, and output a set of consistently accepted transactions. It supports high block rates, eliminates orphaned blocks, all while maintaining a secure Proof of Work environment.',
    KasInfo1CN: appService.traditionalized('Kaspa 提出了 PHANTOM 協議，這是一種基於工作量證明的無許可分類賬協議，它將中本聰所定義區塊鏈推廣至有向無環圖(BLOCKDAG)。 PHANTOM 可以引用多個前區塊，提供所有區塊和交易的總排序，並輸出一組一致的已被接受的交易。能夠支持高出塊率、消除孤塊，同時保持安全的工作量證明環境。'),
    KasTable1EN: "Miner",
    KasTable1CN: appService.traditionalized("矿机型号"),
    KasTable2EN: "Coins",
    KasTable2CN: appService.traditionalized("可挖币种"),
    KasTable3EN: "Hashrate",
    KasTable3CN: appService.traditionalized("可挖币种"),
    KasTable4EN: "Power Consumption",
    KasTable4CN: appService.traditionalized("矿机功耗"),
    KasTable5EN: "Unit Power",
    KasTable5CN: appService.traditionalized("单位功耗"),
    KasTable6EN: "Produced(24h)",
    KasTable6CN: appService.traditionalized("日产币数"),
    KasTable7EN: "Revenue(24h)",
    KasTable7CN: appService.traditionalized("日产值"),
    KasTable8EN: "Daily Cost",
    KasTable8CN: appService.traditionalized("日电费"),
    KasTable9EN: "Profits (24h)",
    KasTable9CN: appService.traditionalized("日净收益"),


    AleoNoticeEN: 'Zero-Knowledge is Finally Here.',
    AleoNoticeCN: appService.traditionalized('零知识终于来了。'),
    AleoTitleEN: 'Where Applications Become Private.',
    AleoTitleCN: appService.traditionalized('在这里，应用程序成为私有的'),
    AleoInfo1EN: 'Aleo is a blockchain-based privacy platform that allows users to protect their privacy and data security through zero-knowledge proof technology. Aleo aims to provide users with a decentralized application platform where they can build applications and obtain privacy protection. Aleo uses zk-SNARKs technology to ensure user data security, which is a zero-knowledge proof technology that can ensure data is not leaked or tampered with during transmission and storage.',
    AleoInfo1CN: appService.traditionalized('Aleo是一个基于区块链的隐私保护平台，它可以让用户通过零知识证明技术保护他们的隐私和数据安全。Aleo旨在为用户提供一种去中心化的应用程序平台，用户可以在这个平台上构建应用程序并获得隐私保护。Aleo使用zk-SNARKs技术来保证用户的数据安全，这是一种零知识证明技术，可以保证数据在传输和存储过程中不会被泄露或篡改。'),
    AleoCardTop1EN: 'Developer tooling made just for you.',
    AleoCardTop1CN: appService.traditionalized('专门为你制作的开发人员工具。'),
    AleoCardTitle1EN: 'Build with Aleo.',
    AleoCardTitle1CN: appService.traditionalized('用Aleo构建。'),
    AleoCardRemark1EN: 'World-class infrastructure built for you and your team.',
    AleoCardRemark1CN: appService.traditionalized('为你和你的团队建立世界级的基础设施。'),
    AleoCardBtn1EN: 'Start Programming',
    AleoCardBtn1CN: appService.traditionalized('开始编程'),
    AleoGoEN: 'View mining plans',
    AleoGoCN: '查看礦機共享服務',
    FILGoEN: 'View mining plans',
    FILGoCN: '查看礦機共享服務',
    AleoCardTop2EN: 'A complete pipeline for your application.',
    AleoCardTop2CN: appService.traditionalized('为你的应用提供完整的管道。'),
    AleoCardTitle2EN: 'Deploy with Confidence.',
    AleoCardTitle2CN: appService.traditionalized('适用于您的应用程序的完整管道。'),
    AleoCardRemark2EN: 'From IDE to blockchain and everything in between.',
    AleoCardRemark2CN: appService.traditionalized('充满信心的部署。从IDE到区块链以及两者之间的一切。'),
    AleoCardBtn2EN: 'Explore Packages',
    AleoCardBtn2CN: appService.traditionalized('探索套餐'),
    AleoCardTitle3EN: 'Develop with Leo.',
    AleoCardTitle3CN: appService.traditionalized('与 Leo 一起开发。'),
    AleoCardRemark3EN: 'Write your app using our programming language, with ease.',
    AleoCardRemark3CN: appService.traditionalized('使用我们的编程语言，轻松地编写您的应用程序。'),
    AleoCardBtn3EN: 'No cryptography needed. ',
    AleoCardBtn3CN: appService.traditionalized('无需密码学知识。'),
    AleoCardTitle4EN: 'Iterate blazingly fast.',
    AleoCardTitle4CN: appService.traditionalized('迭代速度极快。'),
    AleoCardRemark4EN: 'Use our platform to compile and test, frustration-free.',
    AleoCardRemark4CN: appService.traditionalized('使用我们的平台来编译和测试，无忧无虑。'),
    AleoCardBtn4EN: 'Compile on supercharged provers. ',
    AleoCardBtn4CN: appService.traditionalized('编译增压证明。'),
    AleoCardTitle5EN: 'Deploy to blockchain.',
    AleoCardTitle5CN: appService.traditionalized('部署到区块链上。'),
    AleoCardRemark5EN: 'Launch your shiny new app in less time than ever.',
    AleoCardRemark5CN: appService.traditionalized('用更短的时间内推出您闪亮的新应用程序。'),
    FILCardTop1EN: 'Newly generated FIL',
    FILCardTop1CN: appService.traditionalized('FIL挖矿'),
    FILCardTitle1EN: 'The newly generated FIL in the Filecoin network mainly comes from storage rewards and mining rewards, which will gradually be released to the node accounts participating in mining.',
    FILCardTitle1CN: appService.traditionalized('新产生的FIL：Filecoin网络中的新产生的FIL主要来源于存储奖励和矿工奖励，这些FIL会逐渐释放到参与挖矿的节点账户中。'),
    FILCardTop2EN: 'Mining revenue lock-up',
    FILCardTop2CN: appService.traditionalized('挖矿收益锁定'),
    FILCardTitle2EN: 'In order to prevent malicious miners from obtaining revenue by attacking the network, the Filecoin network stipulates that the FIL rewards obtained from mining must be locked up for a certain period of time, known as the mining revenue lock-up period. During this period, nodes cannot transfer or use the FIL rewards obtained from mining.',
    FILCardTitle2CN: appService.traditionalized('为了防止恶意矿工通过攻击网络来获得收益，Filecoin网络规定挖矿获得的FIL奖励需要先进行一定时间的锁定，称为挖矿收益锁定期。在这个期间内，节点无法转移或使用挖矿获得的FIL奖励。'),
    FILCardTop3EN: 'Unlocking mechanism',
    FILCardTop3CN: appService.traditionalized('解锁机制'),
    FILCardTitle3EN: 'After the mining revenue lock-up period ends, the FIL rewards obtained from mining will be unlocked and can be freely transferred or used. The specific unlocking time depends on the amount of FIL obtained by the node and the length of the mining revenue lock-up period, which is usually 180 days.',
    FILCardTitle3CN: appService.traditionalized('挖矿收益锁定期结束后，挖矿获得的FIL奖励将解锁，并可以自由转移或使用。解锁机制的具体时间取决于节点获得的FIL数量和挖矿收益锁定期的长度，通常为180天。'),
    FILCardTop4EN: 'Fil EVM enables developers to build decentralized applications (dApps) based on Filecoin. Unlike traditional Filecoin mining, FIL EVM mining is mainly to support the operation and interaction of these dApps, rather than directly mining Filecoin tokens.',
    FILCardTop4CN: appService.traditionalized('Fil EVM它可以让开发者构建基于Filecoin的去中心化应用程序（dApps）。与传统的Filecoin挖矿不同，FIL EVM挖矿主要是为了支持这些dApps的运行和交互，而不是直接挖掘Filecoin代币。'),
    FILCardTop5EN: 'The introduction of FIL EVM brings more developers and innovation to the Filecoin ecosystem. They can use popular smart contract programming languages such as Solidity to build various Filecoin-based dApps, such as distributed storage markets, data backup and recovery, encrypted wallets, and more. These dApps will provide users with more features and choices, increase the usage and liquidity of the Filecoin ecosystem, and thereby increase the value and influence of Filecoin.',
    FILCardTop5CN: appService.traditionalized('FIL EVM的引入为Filecoin生态系统带来了更多的开发者和创新，他们可以使用Solidity等流行的智能合约编程语言，构建各种基于Filecoin的dApps，如分布式存储市场、数据备份和恢复、加密钱包等。这些dApps将为用户提供更多的功能和选择，增加Filecoin生态系统的使用和流动性，从而提高Filecoin的价值和影响力。'),

    CompanyEN: 'Company:',
    CompanyCN: appService.traditionalized('公司名称'),
    CompanyAddressEN: 'Address:',
    CompanyAddressCN: appService.traditionalized('公司地址：'),
    BusinessLicenseEN: 'Business license number:',
    BusinessLicenseCN: appService.traditionalized('营业执照号码：'),

    DisclaimersEN: 'Disclaimers',
    DisclaimersCN: appService.traditionalized('免责声明'),
    Disclaimers1TitleEN: 'Legal Statement',
    Disclaimers1TitleCN: appService.traditionalized('法律声明'),
    Disclaimers1DetailEN: 'The services provided by MintSat are not available to the following (each, a "Restricted Person"): 1) residents in the following countries or regions: the United States, Mainland China, Crimea, Cuba, Iran, North Korea and Syria; or 2) any entities or individuals that are restricted under applicable trade sanctions and export compliance laws. or 3) any entities or individuals that are restricted under MintSat’s compliance obligations and internal risk control policies. The above list is not exhaustive. Before using the services provided by MintSat, please confirm that you are not a Restricted Person. In the event that you are a Restricted Person and you use the services provided by MintSat, all legal risks and liabilities caused by or arising from such usage shall be entirely borne by you, and MintSat shall have the right to refuse to provide services to you, and you shall not be entitled to ask MintSat to provide any refund.',
    Disclaimers1DetailCN: appService.traditionalized('MintSat提供的服务不适用于以下（下称“受限人士“）: 1）美国、中国大陆、克里米亚、古巴、伊朗、朝鲜和叙利亚的居民；及2）根据适用的贸易制裁和出口合规法律，受到限制的任何实体或个人；及 3）其他不符合 MintSat合规义务和/或内部风控政策的任何实体或个人。上述名录可能并不穷尽。在使用MintSat提供的服务前，请确认您不属于受限人士。如您属于受限人士且您使用MintSat提供的服务，由此导致的所有法律风险和责任将完全由您独立承担，同时MintSat有权拒绝向您提供服务，您无权要求MintSat退还任何款项。'),
    Disclaimers2TitleEN: 'Non-Refundable',
    Disclaimers2TitleCN: appService.traditionalized('无法退款'),
    Disclaimers2DetailEN: "As specified in our terms of use, once an order has been paid, it is unchangeable and non-refundable.",
    Disclaimers2DetailCN: appService.traditionalized('按照协议规定，一旦您购买完成，将不支持更换或退款。'),
    Disclaimers3TitleEN: 'Pricing',
    Disclaimers3TitleCN: appService.traditionalized('定价规则'),
    Disclaimers3DetailEN: "MintSat's products are all priced in USD. The products can be paid in numerous digital currencies and the payment amount is subject to the real-time exchange rate.",
    Disclaimers3DetailCN: appService.traditionalized('MintSat商品均以美金定价，支持多种数字货币支付方式，支付金额以实时汇率为准。矿机共享服务套餐的定价会根据数字货币价格等多因素调整。实际购买价格以用户付款时为准，后续即使价格发生变化，MintSat也不会对这个订单进行差价补偿。'),
    Disclaimers4TitleEN: 'Computer Power Fluctuations',
    Disclaimers4TitleCN: appService.traditionalized('服务波动说明'),
    Disclaimers4DetailEN: "Fluctuations in computing power are inevitable. MintSat will not be responsible for fluctuations caused by unstable networks, network failures, miner performance, etc.",
    Disclaimers4DetailCN: appService.traditionalized('MintSat提供的套餐对应的为真实服务，真实服务由于网络、矿机功率等不稳定因素会导致波动，MintSat不承诺100%稳定运行。'),
    Disclaimers5TitleEN: 'Uncontrollable Risks',
    Disclaimers5TitleCN: appService.traditionalized('不可控风险声明'),
    Disclaimers5DetailEN: "MintSat will not be responsible for any results that arise from uncontrollable risks, including but not limited t natural disasters such as floods, volcanic eruptions, earthquakes, landslides, fires, storms, other weather events that can be assessed at the unforeseen or higher, government actions or directives, city-level grid power supply incidents as well as social actions or conflicts such as war, strikes, riots, etc.",
    Disclaimers5DetailCN: appService.traditionalized('MintSat不对以下不可控风险所造成的损失负责：不能预见、不能避免或不能克服的客观事件，包括自然灾害如洪水、火山爆发、地震、山崩、火灾、被相关政府部门评定为百年不遇级别以上的风暴和恶劣气候等，政府行为和政府指令，城市级别的电网供电事故，以及社会异常事件如战争、罢工、动乱等。'),
    Disclaimers6TitleEN: 'Fair Use of Computing Power',
    Disclaimers6TitleCN: appService.traditionalized('使用范围声明'),
    Disclaimers6DetailEN: "Any misuse of computing power purchased on MintSat, such as attacking a network, is strictly prohibited. MintSat reserves the right to revoke your computing power plan if any illegal activity occurs.",
    Disclaimers6DetailCN: appService.traditionalized('用户不得将MintSat套餐提供的服务用于攻击网络等违法违规行为，否则MintSat有权强行收回您的套餐服务。'),


    FAQEN: 'FAQ',
    FAQCN: appService.traditionalized('常见问题'),
    FAQ1TitleEN: '1. How many parts does the Computing Power Plan\'s cost consist of?',
    FAQ1TitleCN: appService.traditionalized('1、矿机共享服务套餐的费用有几部分？'),
    FAQ1DetailEN: 'The cost of Computing Power Plan consists of two parts: computing power service fee (abbreviated as hashrate fee) and electricity and operation and maintenance fees (abbreviated as electricity fee). When placing an order, you need to pay the hashrate fee and prepay the first electricity fee (at least 10 days ), the second electricity fee needs to be paid in advance before the first electricity fee is exhausted, and so on, the interruption of the electricity fee will affect Plan\'s operation and mining output.',
    FAQ1DetailCN: appService.traditionalized('矿机共享服务套餐的费用由矿机共享服务费和电力及运维费(简称电费)两部分构成，下单时需要支付矿机共享服务费并预交第一笔电费(至少10天)，第二笔电费需要在第一笔电费用完之前预先交纳，以此类推，电费中断将会影响套餐运行及套餐产出'),
    FAQFILEN: 'The cost of Computing Power Plan is separated into computing power service fee (abbreviated as hashrate fee) and pledge coins. When placing the order, only the plan fee needs to be paid. The pledge coins in the plan will be returned to the users 3 natural days of the service ending.',
    FAQFILCN: appService.traditionalized('礦機共享服務套餐的費用由礦機共享服務費和質押币兩部分構成，下單時支付套餐費即可。在服務結束的3個自然日後，套餐内包含的質押币會返還給用戶。'),
    FAQ2TitleEN: '2. How to calculate the mining revenue of the Plan?',
    FAQ2TitleCN: appService.traditionalized('2、如何计算套餐的产出收益？'),
    FAQ2DetailEN: 'MintSat does not make any commitment to the future revenue of the Plan, but provides a static calculation method as a reference. The static calculation method assumes that the future cryptocurrency price, the difficulty of the entire network, and the block reward are all static and unchanged to estimate the mining revenue and other indicator data.',
    FAQ2DetailCN: appService.traditionalized('MintSat不对套餐的未来收益作为任何承诺，但提供了一种历史产出计算法作为参考，历史产出计算法是假定未来的数字货币价格、全网难度、区块奖励都静止不变的情况下来估算套餐的产出收益等指标数据。'),
    FAQ3TitleEN: '3. What is the purchase process of Computing Power Plan?',
    FAQ3TitleCN: appService.traditionalized('3、矿机共享服务套餐的购买流程是怎样的？'),
    FAQ3DetailEN: 'The purchase process is as follows: select a Plan → select cryptocurrency → select the first electricity days → select subsequent payment method → ​​submit order → complete payment in full within 6 hours.',
    FAQ3DetailCN: appService.traditionalized('购买流程如下：选择套餐 → 选择产出类型 → 选择首次电费天数 → 选择后续交纳方式 → 提交订单→ 下单后6小时内完成全额支付。'),
    FAQ4DetailEN: 'Different mining pools are different in terms of minimum payment threshold, revenue distribution method, and supported mining cryptocurrencies.',
    FAQ4DetailCN: appService.traditionalized('不同矿池在最小打款门槛、收益分配方式，支持的挖矿币种等方面有所不同'),
    FAQ6TitleEN: '4. What payment methods are supported now?',
    FAQ6TitleCN: appService.traditionalized('4、云挖矿套餐支持哪些支付方式？'),
    FAQ6DetailEN: 'Currently supported payment methods are: cryptocurrency payment (USDT(ERC20)/USDT(TRON)), you can Choices the payment method by yourself, please pay attention to sufficient transfer fees.',
    FAQ6DetailCN: appService.traditionalized('目前支持的支付方式有：数字资产支付(USDT(ERC20)/USDT(TRON))，您可自行选择支付方式，请注意支付足够的转账手续费。'),
    FAQ7TitleEN: '5. After the Plan starts to mine, how to check the real-time hashrate and payment status of the Plan?',
    FAQ7TitleCN: appService.traditionalized('5、套餐开始生效后，如何查看套餐实时算力及打款情况？'),
    FAQ7DetailEN: 'After the Plan becomes active, MintSat will connect the hashrate to mining pool; after having mined the cryptocurrencies, the mining pool will send directly to your assets; you can go through \'Cloud Hashrate → More Output Data → Data Source\' to view real-time hashrate and output payment records.',
    FAQ7DetailCN: appService.traditionalized('套餐生效后，MintSat会将套餐算力接入到矿池；挖出数字货币后，矿池会直接打款至您的资产；可通过「矿机共享服务→ 更多产出记录 → 数据来源」进入矿池页面去查看实时算力和矿池打款记录'),
    FAQ8TitleEN: '6. What is the difference between the Classic Mode Plan and the Accelerator Mode Plan?',
    FAQ8TitleCN: appService.traditionalized('6、经典套餐和加速套餐有什么区别？'),
    FAQ8DetailEN: 'In the classic mode, you will get all cryptocurrencies output in the entire mining duration; in the accelerator mode, you can recover the cost faster, and after the cost is recovered, you will get the proportion of the net profit of the mining output.',
    FAQ8DetailCN: appService.traditionalized('在经典模式下，您将获得整个服务周期全部数字资产产出；在加速模式下，您可以更快回收成本，收回成本后，您将按比例获得矿机共享服务的净收益部分'),

    DateEN: 'Data, Indicator, and Formula',
    DateCN: appService.traditionalized('数据、指标和公式说明'),
    Date1EN: '1. Static Analysis is an analysis method that assumes the future data will remain unchanged. In another word, the revenue rate and breakeven days will be calculated assuming that indicators like the price of the cryptocurrency, network-wide difficulty, and blockchain reward will remain unchanged in the future;',
    Date1CN: appService.traditionalized('1、历史分析是一种对历史数据进行科学分析的方法，即在对法币价格、全网难度、区块奖励进行历史数据分析的情况下计算收益率和回本天数等指标；'),
    Date2EN: '2. Static Breakeven Days = Hashrate Fee / {(Hashrate Amount * (Static Output / Hashrate Unit / Day – Electricity Fee / Hashrate Unit / Day)};',
    Date2CN: appService.traditionalized('2、历史回本天数 = 矿机共享服务费 / { 矿机共享服务规格 * (历史产出/规格单位/天 - 电费/规格单位/天) } ;'),
    Date3EN: 'Static Revenue Rate = Static Net Profit / Hashrate Fee. Static Net Profit = (Plan Duration - Static Breakeven Days) * Hashrate Amount * (Static Output / Hashrate Unit / Day – Electricity Fee/Hashrate Unit /Day) * Share Ratio;',
    Date3CN: appService.traditionalized('3、历史产出率 = 历史净收益 / 矿机共享服务费；历史净收益 = (周期 - 历史回本天数) * 矿机共享服务规格 * (历史产出/规格单位/天 - 电费/规格单位/天) * 分成比例；'),
    Date4EN: '4. The output data comes from the mining pool BTC.com and is for reference only.',
    Date4CN: appService.traditionalized('4、产出数据来源于 BTC.com 矿池，仅供参考。MintSat不参与收益的分配与划拨，且不对收益有任何承诺。'),
}, 'languageInfo');
initStore("CN", 'language');
initStore(0, 'projectId');
initStore("10T", 'projectTH');
initStore({}, 'Assets');
initStore(0, 'vip');
initStore("", 'email');
initStore("", 'AccountId');
initStore({}, 'ethPro');
initStore({"ETH-ADDRESS":{},"TRC-ADDRESS": {},"FIL-ADDRESS":{}}, 'systemSetting');
initStore(0, 'ethScore');
initStore(0, 'ethScoreDaily');
initStore({}, 'accountAddress');
initStore([], 'productList');
initStore([], 'productBuyList');
initStore([], 'productIncomeList');
initStore(0, 'productIncomeCount');
initStore({}, 'productSum');
initStore({}, 'vipInvite');
initStore({}, 'buyObj');
initStore([], 'inviteIncome');
initStore([], 'ethIncomeList');
initStore([], 'rechargeList');
initStore([], 'MessageList');
initStore(0, 'UnreadCount');
initStore(0, 'noticeMove');
initStore([], 'NoticeList');
initStore({
    productContent: "",
    productHistory: "",
}, 'productInfo');
initStore({
    mode: false,
    model: false,
    productSustainTime: null,
    type: "BTC"
}, 'minSelect');