import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function MessageList(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [messageList, setMessageList] = useState(() => {
        return getStore('MessageList') || [];
    });
    useMiracleObserver(
        'MessageList,language',
        (key, value, store) => {
            if (key === 'MessageList') {
                setMessageList(value);
            } else if (key === 'language') {
                appServices.getMessageList(value);
            }
        }
    )
    return (
        <div className="userContent">
            <div className="userTitle">{languageInfo["message" + getStore("language")]}</div>
            {messageList.map((item, index) => (
                <div key={`messagePage${item.id}`}>
                    <div className="messageList">
                        <div style={{ color: '#333333', fontSize: 24 }}>
                            {item.status === "Unread" ?
                                <div style={{ padding: "2px 6px", background: "#8833ff", borderRadius: 10, marginRight: 4, color: '#fff', fontSize: 13, display: "inline-block" }}>NEW</div>
                                :
                                null
                            }

                            {item.message}
                        </div>
                        <div style={{ color: '#1b1b1b', fontSize: 16 }}>{new Date(item.messageTime).toDateString()}</div>
                    </div>
                    {index !== messageList.length - 1 ?
                        <hr />
                        :
                        null
                    }
                </div>
            ))}
        </div>
    );
}

export default MessageList;