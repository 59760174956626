import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Service(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });

    return (
        <div className="content mobilePaddingTop">

            <div style={{ fontSize: 20, color: '#333333',textAlign: 'center', padding: 36 }}>
                <div style={{ paddingTop: 16 }}>{languageInfo["Introduction1" + getStore("language")]}</div>
                <div style={{ paddingTop: 16 }}>{languageInfo["Introduction2" + getStore("language")]}</div>
                <div style={{ paddingTop: 16 }}>{languageInfo["Introduction3" + getStore("language")]}</div>
                <div style={{ paddingTop: 16 }}>{languageInfo["Introduction4" + getStore("language")]}</div>
            </div>
            <div style={{ fontSize: 48, color: '#1d1d27', fontWeight: 'bold',textAlign: 'center', marginTop: 44, position: 'relative' }}>
                <span>{languageInfo["Start" + getStore("language")]}</span>
                <img alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketMouse.png" style={{ position: 'absolute' , right: '20%', bottom: -20 }} />
            </div>
            <div style={{ fontSize: 24, color: '#8f8f90', margin: 24,textAlign: 'center' }}>{languageInfo["StartRemark" + getStore("language")]}</div>
            <div style={{ textAlign: 'center', margin: '48px', paddingBottom: 32, position: 'relative' }}>
                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/HomeStep.png" alt="" style={{ width: "70%" }} />
                <div className="serviceTest" style={{ color: '#8f8f90', fontWeight: 'bold', display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: 30 }}>
                    <div style={{ width: '27%' }}>{languageInfo["Step1" + getStore("language")]}</div>
                    <div style={{ width: '27%' }}>{languageInfo["Step2" + getStore("language")]}</div>
                    <div style={{ width: '27%' }}>{languageInfo["Step3" + getStore("language")]}</div>
                </div>

                <div className="positionAbsolute">
                    <div className="serviceTest" style={{ color: '#8f8f90', fontWeight: 'bold', display: 'flex', justifyContent: 'space-around', textAlign: 'center', marginTop: 30 }}>
                        <div style={{ width: '21%', marginTop: '4%' }}>{languageInfo["Start1" + getStore("language")]}</div>
                        <div style={{ width: '23%', marginTop: '-2%'  }}>{languageInfo["Start2" + getStore("language")]}</div>
                        <div style={{ width: '22%', marginTop: '9%'  }}>{languageInfo["Start3" + getStore("language")]}</div>
                        <div style={{ width: '23%', marginTop: '2%'  }}>{languageInfo["Start4" + getStore("language")]}</div>
                    </div>
                </div>
            </div>
            <div style={{ fontSize: 48, color: '#1d1d27', fontWeight: 'bold',textAlign: 'center', position: 'relative' }}>
                <img alt="" src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/marketMouse.png" style={{ position: 'absolute' , right: '36%', bottom: -20 }} />
                <div>{languageInfo["Contact" + getStore("language")]}</div>
            </div>
            <div style={{ fontSize: 24, color: '#8f8f90', marginTop: 40, marginBottom: 80, textAlign: 'center' }}>{languageInfo["ContactMail" + getStore("language")]}</div>
            <div className="threeCard">
                <div style={{ textAlign: 'center', fontSize: 28, fontWeight: 'bold',letterSpacing: '2px' }}>
                    {languageInfo["AboutText12" + getStore("language")]}
                </div>
                <div className="flexCenter" style={{ padding: "48px 0 80px", textAlign: "center" }}>
                    <div style={{ flex: 1 }}></div>
                    <div className="flexCenter canClick" onClick={() => { if (getStore('AccountId')) { history.push('/assets'); document.getElementById("root").scrollTo(0,0); stationDefault.notify('setBannerIndex', "/assets"); } else { stationDefault.notify('setLoginOpen', 1); } }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutHelp1.png" alt="" style={{ width: 80, padding: '0 16px' }} />
                        <div style={{ position: 'absolute', top: 100, width: '200%', left: '-50%' }}>  {languageInfo["AboutText13" + getStore("language")]}</div>
                    </div>
                    <div style={{ width: '24%', height: 1, borderBottom: '1px dashed #666666' }}></div>
                    <div className="flexCenter canClick" onClick={() => { history.push('/mine'); document.getElementById("root").scrollTo(0,0); }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutHelp2.png" alt="" style={{ width: 80, padding: '0 16px' }} />
                        <div style={{ position: 'absolute', top: 100, width: '200%', left: '-50%' }}>  {languageInfo["AboutText14" + getStore("language")]}</div>
                    </div>
                    <div style={{ width: '24%', height: 1, borderBottom: '1px dashed #666666' }}></div>
                    <div className="flexCenter canClick" onClick={() => { if (getStore('AccountId')) { history.push('/withdraw'); document.getElementById("root").scrollTo(0,0); stationDefault.notify('setBannerIndex', "/withdraw"); } else { stationDefault.notify('setLoginOpen', 2); } }}>
                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/AboutHelp3.png" alt="" style={{ width: 80, padding: '0 16px' }} />
                        <div style={{ position: 'absolute', top: 100, width: '200%', left: '-50%' }}>  {languageInfo["AboutText15" + getStore("language")]}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                </div>
            </div>
        </div>
    );
}

export default Service;