import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import appServices from './services';
import './App.css';

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Project(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [questionID, setQuestionID] = useState(-1);
    const [productInfo,setMinerInfo] = useState(getStore("productInfo"));
    const [TH, setTH] = useState(getStore("TH"));


    const clickQuestion = (id) => {
        if (id === questionID) {
            setQuestionID(0)
        } else  {
            setQuestionID(id)
        }
    }

    useMiracleObserver(
        'productInfo,getSystemSetting,postAmount',
        (key, value, store) => {
            if (key === 'productInfo') {
                setMinerInfo(value);

                if (!getStore("TH")) {
                    setTH(value.productContent.split("/")[0]);
                }
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    function returnUnit() {
        if (productInfo.productType === "FIL") {
            return ""
        } else {
            return "$"
        }
    }
    
    return (
        <div style={{ background: '#F7F8FA', position: 'relative', top: -80, paddingTop: 80 }}>
            <div className="content" style={{ paddingTop: 40, background: '#ffffff', padding: 24, boxSizing: 'border-box' }}>
                <div className="flexCenter" style={{ paddingBottom: 24, alignItems: 'start' }}>
                    <div style={{  background: '#FCFCFC', borderRadius: 20, padding: 16, textAlign: 'center', color: '#687898', width: '30%'  }}>
                      <div style={{ background: '#ffffff', borderRadius: 20, paddingBottom: 12 }}>
                          <div style={{ borderBottom: '2px dashed #f7f8fa', padding: 12, fontSize: 14, fontWeight: 'bold' }}>{productInfo.productName} {languageInfo["Plan" + getStore("language")]}</div>
                          <div style={{ padding: 16 }}>{languageInfo["MinerInfo" + getStore("language")]}</div>
                          {productInfo.outputType && productInfo.outputType.indexOf("FIL") >= 0 ?
                              <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/minnerFIL.jpg" alt="" style={{ height: 120 }} /> :
                              productInfo.outputType === "ALEO" ?
                              <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/aleominner.png" alt="" style={{ height: 80, margin: 12 }} />
                              :
                              <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/minner.jpg" alt="" style={{ height: 120 }} />
                          }
                          <div style={{ padding: 16 }}>{languageInfo["MinerInfo1" + getStore("language")]}</div>
                          {productInfo.outputType && productInfo.outputType.indexOf("FIL") >= 0 ? null : productInfo.outputType === "ALEO" ?
                              <div style={{ background: '#F7F7F7', color: '#687898', fontWeight: 'bold', margin: 20 }}>
                                  <div className="flexCenter">
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0' }}>GPU</div>
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0', background: '#ffffff', margin: 1 }}>3080 * 4</div>
                                  </div>
                                  <div className="flexCenter">
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0' }}>{languageInfo["MinerInfo6" + getStore("language")]}</div>
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0', background: '#ffffff', margin: 1 }}>ubuntu20.04</div>
                                  </div>
                                  <div className="flexCenter">
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0' }}>{languageInfo["MinerInfo5" + getStore("language")]}</div>
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0', background: '#ffffff', margin: 1 }}>128G</div>
                                  </div>
                              </div>
                              :
                              <div style={{ background: '#F7F7F7', color: '#687898', fontWeight: 'bold', margin: 20 }}>
                                  <div className="flexCenter">
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0' }}>{languageInfo["MinerInfo2" + getStore("language")]}</div>
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0', background: '#ffffff', margin: 1 }}>95TH/s</div>
                                  </div>
                                  <div className="flexCenter">
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0' }}>{languageInfo["MinerInfo3" + getStore("language")]}</div>
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0', background: '#ffffff', margin: 1 }}>3200W</div>
                                  </div>
                                  <div className="flexCenter">
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0' }}>{languageInfo["MinerInfo4" + getStore("language")]}</div>
                                      <div style={{ width: '50%', textAlign: 'center', padding: '8px 0', background: '#ffffff', margin: 1 }}>34.5J/TH</div>
                                  </div>
                              </div>
                          }
                      </div>
                    </div>
                    <div style={{ flex: 1, color: '#687898', marginLeft: 20, height: '100%' }}>
                        <div>{productInfo.productName}{languageInfo["Plan" + getStore("language")]}/{productInfo.productSustainTime}{languageInfo["Days" + getStore("language")]}/{productInfo.outputType === "ALEO" ? productInfo.productHistory.split("/")[2] + "c/s" :  productInfo.outputType === "FIL" ? TH : TH + "H/s"}</div>
                        <div className="flexCenter" style={{ padding: 12, background: '#F7F7F7', justifyContent: 'start', marginTop: 20 }}>
                            <div>{languageInfo["Hashrate" + getStore("language")]}</div>
                            {productInfo.productType === "FIL" ?
                                <div style={{ color: '#f77e00', fontSize: 20, fontWeight: 'bold', marginLeft: 16 }}>{productInfo.productPrice * parseFloat(TH.split("T")[0])}<span style={{ fontSize: 14, marginLeft: 4 }}>FIL</span></div>
                                :
                                <div style={{ color: '#f77e00', fontSize: 20, fontWeight: 'bold', marginLeft: 16 }}><span style={{ fontSize: 14 }}>$</span>{productInfo.productPrice * parseFloat(TH.split("T")[0])}</div>
                            }
                            {productInfo.productPower ?
                                <div style={{marginLeft: 30}}>{languageInfo["Electricity" + getStore("language")]}</div>
                                :
                                null
                            }
                            {productInfo.productPower ?
                                <div style={{ fontWeight: 'bold', marginLeft: 16 }}>${parseFloat(productInfo.productPower * parseFloat(TH.split("T")[0])).toFixed(4)}/{languageInfo["Days" + getStore("language")]}</div>
                                :
                                null
                            }
                        </div>

                        <div className="displayFlex" style={{ width: '100%', background: '#ffffff', color: '#687898', borderTop: '2px dashed #f7f8fa' }}>
                            <div style={{ margin: '16px 20px', minWidth: 80 }}>{languageInfo["Currency" + getStore("language")]}</div>
                            <div className="displayFlex" style={{ background: '#8833FF', color: '#ffffff', padding: 8, borderRadius: 24, margin: 10 }}>
                                <div style={{ width: 100, textAlign: 'center' }}>{productInfo.productType === "FIL" ? "FIL" : productInfo.outputType}</div>
                                <div className="rateCircle" style={{ borderColor: 'transparent' }}>
                                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerSelect.png" alt="" style={{ width: 14, display: 'block', borderRadius: '50%' }} />
                                </div>
                            </div>
                        </div>
                        <div className="displayFlex" style={{ width: '100%', background: '#ffffff', color: '#687898' }}>
                            <div style={{ margin: '16px 20px', minWidth: 80 }}>{languageInfo["Amount" + getStore("language")]}</div>

                            {productInfo.outputType === "ALEO" ?
                                <div
                                    className="displayFlex canClick"
                                    style={{ background: '#8833FF' , color: '#ffffff' , padding: 8, borderRadius: 24, margin: 10, boxShadow: 'rgb(217 215 215) 2px 0px 4px' }}
                                >
                                    <div style={{ minWidth: 100, textAlign: 'center' }}>{productInfo.productHistory.split("/")[2]} c/s</div>
                                    <div className="rateCircle" style={{ borderColor: 'transparent' }}>
                                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerSelect.png" alt="" style={{ width: 14, display: 'block', borderRadius: '50%' }} />
                                    </div>
                                </div>
                                : productInfo.productContent.split("/").map((value) => (
                                <div
                                    onClick={() => {
                                        setTH(value)
                                    }}
                                    className="displayFlex canClick"
                                    style={{ background: value === TH ? '#8833FF' : '#ffffff', color: value === TH ? '#ffffff' : '#687898', padding: 8, borderRadius: 24, margin: 10, boxShadow: 'rgb(217 215 215) 2px 0px 4px' }}
                                >
                                    <div style={{ width: 100, textAlign: 'center' }}>{value}{productInfo.outputType === "FIL" ? "" : "H/s"}</div>
                                    <div className="rateCircle" style={{ borderColor: TH === value ? 'transparent' : '#687898' }}>
                                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerSelect.png" alt="" style={{ width: 14, display: TH === value ? 'block' : 'none', borderRadius: '50%' }} />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div
                            className="displayFlex" style={{ width: '100%', background: '#ffffff', color: '#687898' }}
                        >
                            <div style={{ margin: '16px 20px', minWidth: 80 }}></div>
                            <div
                                onClick={
                                    () => {
                                        if (new Date().getTime() < new Date(productInfo.productStart).getTime()) {

                                        } else if (getStore('AccountId')) {
                                            initStore(TH,"TH");
                                            history.push(`/buy/${productInfo.productId}`);
                                            document.getElementById("root").scrollTo(0,0);
                                        } else {
                                            stationDefault.notify('setLoginOpen', 2);
                                        }
                                    }}
                                className="canClick"
                                style={{
                                    background: '#8833FF', color: '#ffffff', padding: 8, borderRadius: 24, margin: 10, minWidth: 200, textAlign: 'center',
                                    opacity: new Date().getTime() < new Date(productInfo.productStart).getTime() ? 0.5 : 1
                                }}
                            >
                                {languageInfo[(new Date().getTime() < new Date(productInfo.productStart).getTime() ? "NoStart" : "Buy") + getStore("language")]}
                            </div>
                        </div>
                        {/*<div
                            className="displayFlex" style={{ width: '100%', background: '#ffffff', color: '#687898' }}
                        >
                            <div style={{ margin: '16px 20px', minWidth: 80 }}></div>
                            <div style={{ background: '#9e9e9e', color: '#ffffff', padding: 8, borderRadius: 24, margin: 10, minWidth: 200, textAlign: 'center' }}>
                                {languageInfo["RestockingNotice" + getStore("language")]}
                            </div>
                        </div>*/}

                        <div className="displayFlex" style={{ width: '100%', background: '#ffffff', color: '#687898', fontSize: 13, margin: '8px 0' }}>
                            <div style={{ margin: '16px 20px', minWidth: 80 }}></div>
                            <span>{languageInfo["ServiceBy" + getStore("language")]}</span>
                            <div style={{ background: '#F77E00', color: '#ffffff', borderRadius: 8, padding: '2px 6px 3px', margin: 6 }}>{languageInfo["Self" + getStore("language")]}</div>
                            <span>{languageInfo["MintSatService" + getStore("language")]}</span>
                        </div>
                    </div>
                </div>
                <div style={{ color: '#687898' }}>
                    <div style={{ fontSize: 20,borderBottom: '2px dashed #f7f8fa', paddingBottom: 12, marginBottom: 20,marginTop: 50, position: 'relative' }}>
                        <div>{languageInfo["PlanDetails" + getStore("language")]}</div>
                        <div style={{ position: 'absolute', background: '#8833FF', bottom: -3, height: 3, width: 48, borderRadius: 3 }}></div>
                    </div>
                    <div className="planDetail">
                        <div className="DetailItem">
                            <div>{languageInfo["PlanMode" + getStore("language")]}</div>
                            {productInfo.productType === "SPEED" ?
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerLight.png" alt="" style={{height: 14, margin: '0 8px'}}/>
                                :
                                null
                            }
                            {productInfo.productType === "SPEED" ?
                                <div>{languageInfo["PlanMode1" + getStore("language")]}</div>
                                :
                                <div>{languageInfo["PlanMode2" + getStore("language")]}</div>
                            }
                        </div>
                        <div className="DetailItem">
                            <div>{languageInfo["Share" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.productManage}%</div>
                            <div className="canClick" style={{ position: 'relative' }}>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                    <div className="question">
                                        <span>{productInfo.productType === "SPEED" ? languageInfo["ShareQuestion1" + getStore("language")] : languageInfo["ShareQuestion3" + getStore("language")]}</span>
                                        <span>{100 - productInfo.productManage}%</span>
                                        <span>{languageInfo["ShareQuestion2" + getStore("language")]}</span>
                                    </div>
                                    <div className="questionArrow"></div>
                                    <div style={{ height: 30 }}></div>
                                </div>
                            </div>
                        </div>

                        {productInfo.outputType && productInfo.outputType.indexOf("FIL") >= 0 ?
                            <div className="DetailItem">
                                <div>{languageInfo["PlanDuration" + getStore("language")]}</div>
                                <div style={{ marginLeft: 8 }}>
                                    {productInfo.productSustainTime}{languageInfo["Days" + getStore("language")]} + 180{languageInfo["Days" + getStore("language")]}
                                </div>
                                <div className="canClick" style={{ position: 'relative' }}>
                                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                    <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                        <div className="question">
                                            <span>{languageInfo["StakeDuration1" + getStore("language")]}{productInfo.productSustainTime}{languageInfo["StakeDuration2" + getStore("language")]}</span>
                                            <span onClick={() => { history.push("/filecoin"); document.getElementById("root").scrollTo(0,0); }} className="canClick" style={{ color: "#8833ff" }}>{languageInfo["More" + getStore("language")]}</span>
                                        </div>
                                        <div className="questionArrow"></div>
                                        <div style={{ height: 30 }}></div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="DetailItem">
                                <div>{languageInfo["PlanDuration" + getStore("language")]}</div>
                                <div style={{ marginLeft: 8 }}>{productInfo.productSustainTime}{languageInfo["Days" + getStore("language")]}</div>
                            </div>
                        }
                        <div className="DetailItem">
                            <div>{languageInfo["EstimatedTime" + getStore("language")]}</div>
                            <div style={{ marginLeft: 8 }}>{languageInfo["EstimatedTime1" + getStore("language")]}</div>
                        </div>

                        {productInfo.outputType === "BTC" ?
                            <div className="DetailItem">
                                <div>{languageInfo["Miner" + getStore("language")]}</div>
                                <div style={{marginLeft: 8}}>{productInfo.productName}</div>
                            </div>
                            :
                            null
                        }
                        <div className="DetailItem">
                            <div>{languageInfo["Amount" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.outputType === "ALEO" ? productInfo.productHistory.split("/")[2] + " c/s" : productInfo.outputType === "FIL" ? TH : TH + "H/s"}</div>
                        </div>
                        <div className="DetailItem">
                            <div>{languageInfo["Currency" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}> {productInfo.outputType}</div>
                        </div>
                        {productInfo.outputType && productInfo.outputType.indexOf("FIL") >= 0 ?
                            <div className="DetailItem">
                                <div>{languageInfo["MinerStake" + getStore("language")]}:</div>
                                <div style={{ marginLeft: 8 }}> {parseFloat(productInfo.productPrice * parseFloat(TH.split("T")[0]) * productInfo.returnRatio / 100).toFixed(4)}FIL</div>

                                <div className="canClick" style={{ position: 'relative' }}>
                                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                    <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                        <div className="question">
                                            <span>{languageInfo["StakeFilQuestion" + getStore("language")]}</span>
                                            <span onClick={() => { history.push("/filecoin"); document.getElementById("root").scrollTo(0,0); }} className="canClick" style={{ color: "#8833ff" }}>{languageInfo["More" + getStore("language")]}</span>
                                        </div>
                                        <div className="questionArrow"></div>
                                        <div style={{ height: 30 }}></div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="DetailItem">
                            <div>{languageInfo["PayoutCycle" + getStore("language")]}</div>
                            <div style={{ marginLeft: 8 }}>{languageInfo["PayoutCycle1" + getStore("language")]}</div>
                        </div>
                        <div className="DetailItem">
                            <div>{languageInfo["Runtime" + getStore("language")]}</div>
                            <div style={{ marginLeft: 8 }}>100%</div>
                            <div className="canClick" style={{ position: 'relative' }}>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                    <div className="question">{languageInfo["RuntimeQuestion" + getStore("language")]}</div>
                                    <div className="questionArrow"></div>
                                    <div style={{ height: 30 }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {productInfo.outputType && productInfo.outputType.indexOf("FIL") >= 0 ? null : productInfo.outputType === "ALEO" ? null
                    :
                    <div style={{ color: '#687898' }}>
                        <div style={{ fontSize: 20,borderBottom: '2px dashed #f7f8fa', paddingBottom: 12, marginBottom: 20,marginTop: 50, position: 'relative' }}>
                            <div className="flexCenter" style={{ justifyContent: 'start' }}>
                                <div>{languageInfo["StaticAnalysis" + getStore("language")]}</div>
                                <div style={{ fontSize: 13, color: '#c6c8cb', marginLeft: 8 }}>{languageInfo["Only" + getStore("language")]}</div>
                            </div>
                            <div style={{ position: 'absolute', background: '#8833FF', bottom: -3, height: 3, width: 48, borderRadius: 3 }}></div>
                        </div>
                        <div className="flexCenter" style={{ flexWrap: 'wrap', justifyContent: 'start', paddingBottom: 8 }}>
                            <div className="DetailItem">
                                <div>{languageInfo["Hashrate" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}:</div>
                                <div style={{ marginLeft: 8 }}>{returnUnit()}{(productInfo.productPrice / productInfo.productSustainTime).toFixed(4)}</div>
                            </div>
                            {productInfo.outputType === "ALEO" ?
                                <div className="DetailItem">
                                    <div>{languageInfo["Output" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}:</div>
                                    <div style={{ color: '#f77e00', fontWeight: 'bold', marginLeft: 8, fontSize: 20 }}>{productInfo.productHistory.split("/")[0]}</div>
                                </div>
                                :
                                <div className="DetailItem">
                                    <div>{languageInfo["Electricity" + getStore("language")]}/T/{languageInfo["Days" + getStore("language")]}:</div>
                                    <div style={{marginLeft: 8}}>${productInfo.productPower}</div>
                                </div>
                            }
                        </div>
                        <div className="flexCenter" style={{ background: '#F7F7F7', justifyContent: 'space-between', alignItems: 'stretch', borderRadius: 20 }}>
                            <div className="flexCenter" style={{ width: '47%', borderRadius: 20, background: '#ffffff', margin: '2% 0 2% 2%', padding: 24, justifyContent: 'center', boxSizing: 'border-box', flexWrap: 'wrap', flexDirection: 'column'  }}>
                                <div className="flexCenter" style={{ justifyContent: "center" }}>
                                    <div>{languageInfo["Output" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}</div>
                                    <div style={{ color: '#f77e00', fontWeight: 'bold', marginLeft: 8, fontSize: 20 }}>{productInfo.productHistory.split("/")[0]}</div>
                                </div>
                                <div className="flexCenter" style={{ justifyContent: 'space-around', margin: "20px 0", width: '100%' }}>
                                    <div>
                                        <div className="flexCenter" style={{ justifyContent: 'start', margin: '8px 0' }}>
                                            <div style={{ background: '#E3F6F5', width: 8, height: 8, borderRadius: '50%', marginRight: 4 }}></div>
                                            <div>{languageInfo["Hashrate" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}</div>
                                            <div className="canClick" style={{ position: 'relative' }}>
                                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                                <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                                    <div className="question">{languageInfo["HashrateQuestion" + getStore("language")]}</div>
                                                    <div className="questionArrow"></div>
                                                    <div style={{ height: 30 }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flexCenter" style={{ justifyContent: 'start', margin: '8px 0' }}>
                                            <div style={{ background: '#F8E9E6', width: 8, height: 8, borderRadius: '50%', marginRight: 4 }}></div>
                                            <div>{languageInfo["Electricity" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}</div>
                                            <div className="canClick" style={{ position: 'relative' }}>
                                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                                <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                                    <div className="question">{languageInfo["ElectricityQuestion" + getStore("language")]}</div>
                                                    <div className="questionArrow"></div>
                                                    <div style={{ height: 30 }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flexCenter" style={{ justifyContent: 'start', margin: '8px 0' }}>
                                            <div style={{ background: '#EFE7FB', width: 8, height: 8, borderRadius: '50%', marginRight: 4 }}></div>
                                            <div>{languageInfo["Output" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}</div>
                                            <div className="canClick" style={{ position: 'relative' }}>
                                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                                <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                                    <div className="question">{languageInfo["OutputQuestion" + getStore("language")]}</div>
                                                    <div className="questionArrow"></div>
                                                    <div style={{ height: 30 }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MineTable1.png" alt="" style={{ height: 100, marginLeft: 24 }} />
                                </div>
                            </div>
                            <div style={{ width: '47%', borderRadius: 20, background: '#ffffff', margin: '2% 2% 2% 0', padding: 24, boxSizing: 'border-box' }}>
                                <div className="flexCenter" style={{ justifyContent: 'center' }}>
                                    <div>{languageInfo["BreakevenDay" + getStore("language")]}</div>
                                    <div style={{ color: '#f77e00', fontWeight: 'bold', marginLeft: 8, fontSize: 20 }}>{productInfo.productHistory.split("/")[1]}</div>
                                    <div style={{ color: '#f77e00', fontWeight: 'bold', fontSize: 16, marginLeft: 4 }}>{languageInfo["Days" + getStore("language")]}</div>
                                </div>
                                <div className="flexCenter" style={{ justifyContent: 'space-around', margin: "20px 0", width: '100%' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <div className="flexCenter" style={{ margin: '8px 0', justifyContent: 'center' }}>
                                            <div>{languageInfo["BreakevenDay" + getStore("language")]}</div>
                                        </div>
                                        <div className="flexCenter" style={{ margin: '8px 0', color: '#c399ff', fontSize: 20, fontWeight: 'bold', justifyContent: 'center' }}>
                                            <div>VS</div>
                                        </div>
                                        <div className="flexCenter" style={{ margin: '8px 0', justifyContent: 'center' }}>
                                            <div>{languageInfo["PlanDurationDays" + getStore("language")]}</div>
                                        </div>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MineTable2.png" alt="" style={{ height: 100, marginLeft: 24 }} />
                                        <div className="flexCenter" style={{ fontSize: 13, position: 'absolute', right: 0, top: -18 }}>
                                            <div style={{ color: '#f77e00' }}>{productInfo.productHistory.split("/")[1]}</div>
                                            <div style={{ color: '#2ee6ca', marginLeft: 12, marginBottom: 24 }}>{productInfo.productSustainTime}</div>
                                        </div>
                                        <div className="flexCenter" style={{ fontSize: 12, position: 'absolute', right: 0, bottom: -24, width: 270, justifyContent: 'end' }}>
                                            <div style={{ color: '#f77e00' }}>{languageInfo["BreakevenDay" + getStore("language")]}</div>
                                            <div style={{ color: '#bcbbbb', marginLeft: 6 }}>{languageInfo["PlanDurationDays" + getStore("language")]}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => { clickQuestion(-1); }} className="colorClick flexCenter" style={{ justifyContent: 'start', marginTop: 24, fontSize: 18 }}>
                            <div>{languageInfo["Date" + getStore("language")]}</div>
                        </div>
                        <div className="flexCenter" style={{ margin: '8px 0 8px', fontSize: 13, padding: '8px 12px', border: '1px solid #cccccc', marginTop: 16 }}>
                            <div className="flexCenter" style={{ flexWrap: 'wrap' }}>
                                <div className="flexCenter">
                                    {languageInfo["Date1" + getStore("language")]}
                                </div>
                                <div className="flexCenter">
                                    {languageInfo["Date2" + getStore("language")]}
                                </div>
                                <div className="flexCenter">
                                    {languageInfo["Date3" + getStore("language")]}
                                </div>
                                <div className="flexCenter">
                                    {languageInfo["Date4" + getStore("language")]}
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div style={{ color: '#687898', display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                    <div style={{ fontSize: 20,borderBottom: '2px dashed #f7f8fa', paddingBottom: 12, marginBottom: 20,marginTop: 50, position: 'relative' }}>
                        <div>{languageInfo["FAQ" + getStore("language")]}</div>
                        <div style={{ position: 'absolute', background: '#8833FF', bottom: -3, height: 3, width: 48, borderRadius: 3 }}></div>
                    </div>
                    <div onClick={() => { clickQuestion(1); }} className="colorClick flexCenter" style={{ justifyContent: 'space-between', marginTop: 24 }}>
                        <div style={{ fontSize: 18 }}>{languageInfo["FAQ1Title" + getStore("language")]}</div>
                        <div className="flexCenter ArrowIcon" style={questionID === 1 ? { transform: "rotate(180deg)" } : {}}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MintsatArrow.png" alt="" style={{ height: 8 }} />
                        </div>
                    </div>
                    {questionID === 1 ?
                        <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                            {productInfo.outputType && productInfo.outputType.indexOf("FIL") >= 0 ? languageInfo["FAQFIL" + getStore("language")] : languageInfo["FAQ1Detail" + getStore("language")]}
                        </div>
                        :
                        null
                    }
                    <div onClick={() => { clickQuestion(2); }} className="colorClick flexCenter" style={{ justifyContent: 'space-between', marginTop: 24 }}>
                        <div style={{ fontSize: 18 }}>{languageInfo["FAQ2Title" + getStore("language")]}</div>
                        <div className="flexCenter ArrowIcon" style={questionID === 2 ? { transform: "rotate(180deg)" } : {}}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MintsatArrow.png" alt="" style={{ height: 8 }} />
                        </div>
                    </div>
                    {questionID === 2 ?
                        <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                            {languageInfo["FAQ2Detail" + getStore("language")]}
                        </div>
                        :
                        null
                    }
                    <div onClick={() => { clickQuestion(3); }} className="colorClick flexCenter" style={{ justifyContent: 'space-between', marginTop: 24 }}>
                        <div style={{ fontSize: 18 }}>{languageInfo["FAQ3Title" + getStore("language")]}</div>
                        <div className="flexCenter ArrowIcon" style={questionID === 3 ? { transform: "rotate(180deg)" } : {}}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MintsatArrow.png" alt="" style={{ height: 8 }} />
                        </div>
                    </div>
                    {questionID === 3 ?
                        <div className="flexCenter" style={{margin: '8px 0 8px'}}>
                            {languageInfo["FAQ3Detail" + getStore("language")]}
                        </div>
                        :
                        null
                    }
                    <div onClick={() => { clickQuestion(6); }} className="colorClick flexCenter" style={{ justifyContent: 'space-between', marginTop: 24 }}>
                        <div style={{ fontSize: 18 }}>{languageInfo["FAQ6Title" + getStore("language")]}</div>
                        <div className="flexCenter ArrowIcon" style={questionID === 6 ? { transform: "rotate(180deg)" } : {}}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MintsatArrow.png" alt="" style={{ height: 8 }} />
                        </div>
                    </div>
                    {questionID === 6 ?
                        <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                            {languageInfo["FAQ6Detail" + getStore("language")]}
                        </div>
                        :
                        null
                    }
                    <div onClick={() => { clickQuestion(7); }} className="colorClick flexCenter" style={{ justifyContent: 'space-between', marginTop: 24 }}>
                        <div style={{ fontSize: 18 }}>{languageInfo["FAQ7Title" + getStore("language")]}</div>
                        <div className="flexCenter ArrowIcon" style={questionID === 7 ? { transform: "rotate(180deg)" } : {}}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MintsatArrow.png" alt="" style={{ height: 8 }} />
                        </div>
                    </div>
                    {questionID === 7 ?
                        <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                            {languageInfo["FAQ7Detail" + getStore("language")]}
                        </div>
                        :
                        null
                    }
                    <div onClick={() => { clickQuestion(8); }} className="colorClick flexCenter" style={{ justifyContent: 'space-between', marginTop: 24 }}>
                        <div style={{ fontSize: 18 }}>{languageInfo["FAQ8Title" + getStore("language")]}</div>
                        <div className="flexCenter ArrowIcon" style={questionID === 8 ? { transform: "rotate(180deg)" } : {}}>
                            <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MintsatArrow.png" alt="" style={{ height: 8 }} />
                        </div>
                    </div>
                    {questionID === 8 ?
                        <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                            {languageInfo["FAQ8Detail" + getStore("language")]}
                        </div>
                        :
                        null
                    }
                </div>
                <div style={{ color: '#687898', display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                    <div className="flexCenter" style={{ fontSize: 20,borderBottom: '2px dashed #f7f8fa', paddingBottom: 12, marginTop: 50, marginBottom: 20, position: 'relative' }}>
                        <div>{languageInfo["Disclaimers" + getStore("language")]}</div>
                        <div style={{ position: 'absolute', background: '#8833FF', bottom: -3, height: 3, width: 48, borderRadius: 3 }}></div>
                    </div>
                    <div className="flexCenter" style={{ color: '#3e578a', fontWeight: 'bold', marginTop: 24 }}>{languageInfo["Disclaimers1Title" + getStore("language")]}</div>
                    <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                        {languageInfo["Disclaimers1Detail" + getStore("language")]}
                    </div>
                    <div className="flexCenter" style={{ color: '#3e578a', fontWeight: 'bold', marginTop: 24 }}>{languageInfo["Disclaimers2Title" + getStore("language")]}</div>
                    <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                        {languageInfo["Disclaimers2Detail" + getStore("language")]}
                    </div>
                    <div className="flexCenter" style={{ color: '#3e578a', fontWeight: 'bold', marginTop: 24 }}>{languageInfo["Disclaimers3Title" + getStore("language")]}</div>
                    <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                        {languageInfo["Disclaimers3Detail" + getStore("language")]}
                    </div>
                    <div className="flexCenter" style={{ color: '#3e578a', fontWeight: 'bold', marginTop: 24 }}>{languageInfo["Disclaimers4Title" + getStore("language")]}</div>
                    <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                        {languageInfo["Disclaimers4Detail" + getStore("language")]}
                    </div>
                    <div className="flexCenter" style={{ color: '#3e578a', fontWeight: 'bold', marginTop: 24 }}>{languageInfo["Disclaimers5Title" + getStore("language")]}</div>
                    <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                        {languageInfo["Disclaimers5Detail" + getStore("language")]}
                    </div>
                    <div className="flexCenter" style={{ color: '#3e578a', fontWeight: 'bold', marginTop: 24 }}>{languageInfo["Disclaimers6Title" + getStore("language")]}</div>
                    <div className="flexCenter" style={{ margin: '8px 0 8px' }}>
                        {languageInfo["Disclaimers6Detail" + getStore("language")]}
                    </div>
                </div>
            </div>
            <div  style={{ background: '#F7F8FA', height: 80, width: '100%',position: 'absolute', left: 0, bottom: -80 }}>
            </div>
        </div>
    );
}

export default Project;