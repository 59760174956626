import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";
import appService from "./services";

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function NFT(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });


    const toNFTOrder = () => {
        history.push('/nftOrder');
    }

    useMiracleObserver(
        'setMinerList',
        (key, value, store) => {
            if (key === 'setMinerList') {

            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    return (
        <div className="mobilePaddingTop kasPage">
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <img src="/image/NFTBanner.jpg" alt="" className="banner ALEOBanner" />

                <div className="NFTBanner">
                    <div className="flexCenter" style={{ fontSize: 32, fontWeight: 'bold', marginBottom: 8 }}>
                        <img src="/image/NFTKas.png" alt="" style={{ height: 32 }} />
                        <div style={{ margin: '0 12px' }}>{languageInfo["NFTPlan1" + getStore("language")]}</div>
                    </div>
                    <div className="flexCenter" style={{ fontSize: 24, fontWeight: 'bold' }}>
                        <img src="/image/NFTETH.png" alt="" style={{ height: 32 }} />
                        <div style={{ margin: '0 12px' }}>0.13ETH</div>
                        <img src="/image/NFTOff.png" alt="" style={{ height: 32 }} />
                    </div>
                    <div className="NFTBannerItem">
                        <div className="NFTBannerItems">
                            <img src="/image/NFTIcon.png" alt="" style={{ height: 16 }} />
                            <div style={{ margin: '0 12px' }}>{languageInfo["NFTBannerItems1" + getStore("language")]}</div>
                        </div>
                        <div className="NFTBannerItems">
                            <img src="/image/NFTIcon.png" alt="" style={{ height: 16 }} />
                            <div style={{ margin: '0 12px' }}>{languageInfo["NFTBannerItems2" + getStore("language")]}</div>
                        </div>
                        <div className="NFTBannerItems">
                            <img src="/image/NFTIcon.png" alt="" style={{ height: 16 }} />
                            <div style={{ margin: '0 12px' }}>{languageInfo["NFTBannerItems3" + getStore("language")]}</div>
                        </div>
                        <div className="NFTBannerItems">
                            <img src="/image/NFTIcon.png" alt="" style={{ height: 16 }} />
                            <div style={{ margin: '0 12px' }}>{languageInfo["NFTBannerItems4" + getStore("language")]}</div>
                        </div>
                        <div className="NFTBannerItems">
                            <img src="/image/NFTIcon.png" alt="" style={{ height: 16 }} />
                            <div style={{ margin: '0 12px' }}>{languageInfo["NFTBannerItems5" + getStore("language")]}</div>
                        </div>
                        <div className="NFTBannerItems">
                            <img src="/image/NFTIcon.png" alt="" style={{ height: 16 }} />
                            <div style={{ margin: '0 12px' }}>{languageInfo["NFTBannerItems6" + getStore("language")]}</div>
                        </div>
                    </div>
                    <div className="flexCenter">
                        <div className="CardBtn" onClick={toNFTOrder} style={{ color: '#8833ff', background: '#ffffff', justifyContent: 'center', width: 120 }}>
                            <div>{languageInfo["NFTBuild" + getStore("language")]}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="NFTContent">
                <div className="NFTItem">
                    <img src="/image/NFTContent1.png" alt="" />
                    <div className="NFTItemBlock">
                        <div>{languageInfo["NFTContent1" + getStore("language")]}</div>
                        <div>{languageInfo["NFTContent2" + getStore("language")]}</div>
                        <div className="NFTContentFont">200~300%</div>
                    </div>
                </div>
                <div className="NFTItem">
                    <img src="/image/NFTContent2.png" alt="" />
                    <div className="NFTItemBlock">
                        <div>{languageInfo["NFTContent3" + getStore("language")]}</div>
                        <div className="NFTContentFont">0/500</div>
                    </div>
                </div>
            </div>
            <div className="NFTQuestion">
                <div className="NFTQuestionItem">
                    <div className="NFTQuestionTitle">{languageInfo["NFTQuestion1" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer1" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer101" + getStore("language")]}</div>
                </div>
                <div className="NFTQuestionItem">
                    <div className="NFTQuestionTitle">{languageInfo["NFTQuestion2" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer2" + getStore("language")]}</div>
                </div>
                <div className="NFTQuestionItem">
                    <div className="NFTQuestionTitle">{languageInfo["NFTQuestion3" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer3" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer301" + getStore("language")]}</div>
                </div>
                <div className="NFTQuestionItem">
                    <div className="NFTQuestionTitle">{languageInfo["NFTQuestion4" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer4" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer401" + getStore("language")]}</div>
                </div>
                <div className="NFTQuestionItem">
                    <div className="NFTQuestionTitle">{languageInfo["NFTQuestion5" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer5" + getStore("language")]}</div>
                </div>
                <div className="NFTQuestionItem">
                    <div className="NFTQuestionTitle">{languageInfo["NFTQuestion6" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer6" + getStore("language")]}</div>
                </div>
                <div className="NFTQuestionItem">
                    <div className="NFTQuestionTitle">{languageInfo["NFTQuestion7" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer7" + getStore("language")]}</div>
                    <div>{languageInfo["NFTAnswer701" + getStore("language")]}</div>
                </div>
            </div>
        </div>
    );
}

export default NFT;