import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function Termsandconditions(props) {
    let history = useHistory();
    return (
        <div>
            <div className="contentPage">
                {getStore("language") === 'CN' ?
                    <div className="textContent">
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>一、基本事項</div>
                        <div>
                            1.1 Mintsat保留隨時自行決定修改本協定條款的權利。您有責任定期查看本協定以瞭解最新修改。本協定及其修改在 Mintsat將其公佈於Mintsat網站之時即刻生效（除非另行規定生效時間）。您在本協定及其修改發佈後繼續使用Mintsat提供的服務或Mintsat網站即表示您接受並同意該修改。
                            <br />
                            1.2 若您對本協定的修改有任何異議，或對Mintsat提供的服務和/或Mintsat網站不滿意，您應立即：（1）停止使用Mintsat提供的服務和Mintsat網站;（2）通過客服管道告知Mintsat停止服務。終止服務後，使用者使用Mintsat服務的權利立即終止。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>二、用戶註冊</div>
                        <div>
                            2.1 用戶特此向公司作出如下陳述和保證，並且該等陳述和保證將被視為於使用者每次使用Mintsat的服務時重新作出：
                        </div>
                        <div>
                            （1）如果用戶為自然人，用戶已年滿18周歲並具有完全民事行為能力；
                        </div>
                        <div>
                            （2）如若使用者為實體，使用者根據其實體所在司法管轄區的法律法規合法設立並有效存在，信譽良好。特別的，用戶還應保證不存在中華人民共和國境內居民利用該實體註冊及使用公司服務的情形；
                        </div>
                        <div>
                            （3）使用者接受並使用Mintsat提供的服務在使用者的所在國家/地區符合適用法律法規和相關政策，且不違反用戶對於任何其他協力廠商的義務；及使用者具備使用Mintsat提供的服務並履行相關義務所需的權力，並且本協定和相關服務購買訂單對使用者有效並具有約束力。
                        </div>
                        <div>
                            2.2 對於您提供的註冊資料和資訊（“註冊資訊”），您同意：
                        </div>
                        <div>
                            （1）提供合法、真實、準確和完整的註冊資訊；
                        </div>
                        <div>
                            （2）如果已提供的註冊資訊有任何變化，您將及時通知Mintsat。如果您提供的註冊資訊是非法、不真實、不準確或不完整的，您應承擔相應的全部責任和後果；
                        </div>
                        <div>
                            （3）Mintsat保留根據您提供的資訊以及Mintsat的合規義務和內部風險控制政策對帳戶施加某些限制和/或暫停或終止您使用Mintsat服務的權利；
                        </div>
                        <div>
                            （4）除非獲得Mintsat事先書面同意，否則每位用戶只能在Mintsat註冊一個帳戶；
                        </div>
                        <div>
                            （5）您在使用Mintsat提供的服務中的一些特定功能時需要輸入您的用戶名和密碼。如果您因為忘記密碼等原因無法訪問您的帳戶，Mintsat為確定帳戶真實的所有權，有權要求您提供包括身份識別資訊等在內的特定資訊，包括但不限於：身份證明、居住證明、電話號碼/電子郵件證明以及網站上的任何可識別活動資訊，例如交易ID、訂單號、提款金額等。您承諾您不會與其他人分享您的用戶名和/或密碼或允許任何其他協力廠商使用您的帳戶。對於您或任何其他協力廠商對您的帳戶或密碼的不當使用以及所導致的後果和損失，Mintsat不承擔任何責任。如果您認為您的用戶名和/或密碼被他人知曉或帳戶已被他人擅自使用，您應立即通知Mintsat。
                        </div>
                        <div>
                            2.3 您理解並認可，Mintsat有權根據相關法律法規的規定或監管部門的要求提供您的全部或部分註冊資訊。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>三、風險通知</div>
                        <div>
                            3.1
                            您理解並認可：持有、交易、投資數字資產以及使用數字資產相關的服務會涉及風險，本第3.1條列出的風險並非窮盡。這些風險以及現在或將來出現的其他風險可能會使得您無法獲得任何收益，遭受財務損失，甚至使得我們提供的服務被終止：
                        </div>
                        <div>
                            （1）使用任何數字資產或相關的產品和服務都會帶來潛在的財務、監管和其他風險。您在使用任何數字資產或相關的產品和服務前應確認您對區塊鏈技術、數字資產以及智慧合約的功能和風險方面具有充分的知識和經驗。您參與任何數字資產活動和/或使用相關服務的任何決定應基於您認為必要的獨立調查和/或獲取專業人士的諮詢。Mintsat預設您在使用本服務時是一名瞭解數字資產行業並且懂得數字資產相關產品和服務風險的專業人士，本協定以及Mintsat提供的任何服務均不應視為Mintsat向您提供投資建議；
                        </div>
                        <div>
                            （2）任何Mintsat提供的產品或服務的價值以及任何數量的任何數字資產都可能因不受Mintsat控制的許多因素而受影響，這些因素包括但不限於：挖礦難度和/或其他挖礦參數/屬性的變化，數字資產的市場價格波動，硬體（例如：礦機）過時和硬體攤銷等；
                        </div>
                        <div>
                            （3）由於數字資產的性質，任何數字資產的價值可能隨時損失其部分甚至全部價值。您在此確認知悉任何一種數字資產的價值都可能會迅速下降（甚至下降為零）。對於任何數字資產的市場價格波動所造成的任何和所有損失，Mintsat不承擔任何責任；
                        </div>
                        <div>
                            （4）Mintsat會不時對於系統進行維護或升級。您確認您並未期待Mintsat提供的服務是連續的、不中斷的。除雙方另有明確約定，否則Mintsat對於其系統的維護和升級而導致的暫停和中斷所造成的任何和所有損失，Mintsat不承擔責任；
                        </div>
                        <div>
                            （5）沒有一種身份驗證，或電腦安全技術是100%安全的。您同意承擔駭客入侵或身份盜用相關的所有風險；
                        </div>
                        <div>
                            （6）您瞭解並同意，Mintsat保留通過網站、電子郵件、客戶服務電話、手機短信和其他官方管道發佈、修改和/或提供與服務有關的資訊的權利。對於通過非Mintsat官方管道獲得的資訊，Mintsat概不負責。如果您對以Mintsat名義發送的資訊的真實性有任何疑問，請立即與我們聯繫。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>四、數字資產的使用</div>
                        <div>
                            4.1 您不得以任何適用於您的法律法規所禁止的方式開採、購買、出售、交換、持有及/或以其他方式使用任何數字資產。
                        </div>
                        <div>
                            4.2
                            在購買Mintsat提供的任一服務套餐之前，您應判斷該服務套餐是否適合您的財務能力和風險偏好。您購買Mintsat提供的任一服務套餐，即代表您認可、理解並接受與開採、購買、出售、交換、持有及/或使用數字資產相關的全部風險，包括但不限於：
                        </div>
                        <div>
                            （1）數字資產價格大幅波動：包括比特幣在內的任何數字資產的價格可能急劇下跌，甚至可能跌至為零；
                        </div>
                        <div>
                            （2）數字資產交易可能需要一段時間被確認。雖然可能性較低，但的確存在可能某些數字資產交易可能永遠無法被確認。未被確認的數字資產交易代表該等交易未完成；
                        </div>
                        <div>
                            （3）數字資產交易是不可逆轉的：如果您向任何錯誤的人或數字資產錢包位址發送任何數量的數字資產，您將無法撤銷交易或收回這些資金;
                        </div>
                        <div>
                            （4）如果您丟失或忘記訪問和使用這些數字資產所需的任何PIN或密碼，該等數字資產可能會丟失或無法被您使用;
                        </div>
                        <div>
                            （5）區塊鏈（或數字資產）技術可能存在固有的未知技術缺陷；以及
                        </div>
                        <div>
                            （6）各國家/地區可能會出臺和頒佈影響使用數字資產的新法律、規定和政策。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>五、限制和義務</div>
                        <div>
                            5.1 您不得利用Mintsat提供的服務從事任何違法活動。您不得利用Mintsat提供的服務：
                        </div>
                        <div>
                            （1）支持、煽動或參與任何恐怖主義活動；
                        </div>
                        <div>
                            （2）參與任何洗錢活動；
                        </div>
                        <div>
                            （3）捏造或歪曲事實、散佈謠言、擾亂社會秩序;
                        </div>
                        <div>
                            （4）宣傳或製作淫穢色情、暴力及/或恐怖內容；
                        </div>
                        <div>
                            （5）參與任何其他違反適用法律法規的活動。
                        </div>
                        <div>
                            5.2 您同意遵守所有適用法律法規、本協定以及Mintsat不時宣佈的與使用Mintsat網站和Mintsat提供的服務相關的所有規則和政策。
                        </div>
                        <div>
                            5.3
                            您帳戶密碼（或者其他安全設備）的安全，完全由您負責。通過您帳戶提交的申請，訂單，均被視為經您簽署並同意。由您過失導致帳戶、密碼或者其他資訊被冒用或非法使用的，我們不對產生的任何風險和損失承擔任何責任。若您懷疑您的帳戶、密碼或者其他資訊被冒用或非法使用的，您應立即通知我們。
                        </div>
                        <div>
                            5.4 您承诺購買Mintsat的產品使用的資金來源合法合規，您同意您應完全承擔通過使用Mintsat提供的服務而產生的相關稅收義務。
                        </div>
                        <div>
                            5.5 您同意並承諾您僅僅在為自己的利益使用Mintsat提供的服務，您並未代他人或為了其他協力廠商的利益而使用Mintsat提供的服務。
                        </div>
                        <div>
                            5.6 您同意在您發現或懷疑任何協力廠商使用Mintsat提供的服務存在任何欺詐或不當行為時立即與Mintsat聯繫。
                        </div>
                        <div>
                            5.7 除非Mintsat事先明確書面同意，否則您不得以任何方式在Mintsat網站註冊多個帳戶。您不得通過註冊多個帳戶以獲取利潤、獎勵或優惠券。
                        </div>
                        <div>
                            5.8 您不應參與任何可能影響Mintsat提供的服務及/或Mintsat網站安全的活動。
                        </div>
                        <div>
                            5.9 您不得使用任何方法來遮罩您的互聯網流量和IP位址（例如Proxy、Tor、VPN等）或使用其他隱藏用戶真實互聯網連接的技術服務。
                        </div>
                        <div>
                            5.10
                            在Mintsat網站上展示的任何內容的智慧財產權，包括但不限於文章、圖片、新聞、材料、網站結構、網站佈置、網站設計，除非特別說明，均由Mintsat或Mintsat的許可人（如有）完全擁有。
                            用戶應尊重Mintsat以及 Mintsat的許可人（如有）的智慧財產權。
                        </div>
                        <div>
                            5.11
                            公司特此在本協定期間，向您授予一項有限、非排他性、可撤銷、不可轉讓並且不可轉授的許可，允許您按照本協定條款使用我們提供的產品。公司或其許可方保留服務的一切權利、所有權和權益，包括所有原始程式碼、目標代碼、資料、資訊，其中體現的版權、商標、專利、發明和商業秘密，以及本協定下未明確向您授予的所有其他權利。本協定概不構成放棄公司在英屬維京群島或者任何其他管轄地智慧財產權法或者任何其他聯邦、州或外國法律項下的任何權利。
                        </div>
                        <div>
                            5.12 未經Mintsat事先書面授權，用戶不得以Mintsat的名義進行任何活動和使用Mintsat網站資訊/資料。
                        </div>
                        <div>
                            5.13 對於我們就您使用產品過程中，我們向您提供的任何資料或資訊，您特此同意：
                        </div>
                        <div>
                            a. 該等資料或資訊僅用於本協定規定的用途，並且其使用符合所有適用法律。
                        </div>
                        <div>
                            b. 除適用法律要求外，不向協力廠商傳播、傳輸、展示或者以其他方式披露或提供該等資料或資訊；以及
                        </div>
                        <div>
                            c. 未經公司事先書面同意，不改進、修改該等資料或資訊，不使用該等資料或資訊製作衍生品，亦不將該等資料或資訊與任何其他資訊組合使用。
                        </div>
                        <div>
                            5.14您在此承諾並保證您使用Mintsat提供的服務不會對Mintsat聲譽造成負面影響或使得Mintsat承擔法律責任或其他處罰，罰款及制裁。
                        </div>
                        <div>
                            5.15 您理解Mintsat提供的服務不適用於以下（下稱“受限人士”）:
                            1）中國大陸、克裡米亞地區、古巴、伊朗、朝鮮和敘利亞的居民；
                            2）根據適用的貿易制裁和出口合規法律，受到限制的任何實體或個人；及
                            3）其他不符合Mintsat合規義務和/或內部風控政策的任何實體或個人。如您使用Mintsat提供的礦機共用服務，受限人士還包括美國的居民。上述名錄可能並不窮盡。在使用Mintsat提供的服務前，請確認您不屬於受限人士。如您屬於受限人士且您使用Mintsat提供的服務，由此導致的所有法律風險和責任將完全由您獨立承擔，同時Mintsat有權拒絕向您提供服務，您無權要求Mintsat退還任何款項。
                        </div>
                        <div>
                            5.16如果用戶違反本第5條的規定，Mintsat有權立即暫停或終止向使用者提供服務，並有權在適用法律法規允許的範圍內採取一切必要的法律手段。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>六、違約責任</div>
                        <div>
                            6.1 對於使用者任何違反本協定的行為（包括但不限於：用戶未及時支付套餐費用，使用者使用Mintsat提供的服務在使用者所在國家/地區系非法等），Mintsat有權立即暫停或終止為使用者提供服務並在適用法律法規允許的範圍內採取一切必要的法律手段，包括但不限於要求用戶賠償Mintsat受到的相關損失。
                            <br />
                            6.2 如果Mintsat合理懷疑用戶以違法，欺詐或其他不正當方式使用Mintsat提供的服務，Mintsat有權暫停向使用者提供服務，直到使用者能夠提供符合Mintsat要求的合規證明。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>七、責任限制</div>
                        <div>
                            7.1 Mintsat對於其提供的產品的責任限制以該產品的服務協定為准。如無特別約定，Mintsat對使用者的全部責任不超過該使用者向Mintsat支付的全部費用。
                        </div>
                        <div>
                            7.2
                            對於因其無法控制的情況而導致的服務中斷或終止，Mintsat不承擔任何責任，包括但不限於戰爭（宣告或未宣告）、恐怖活動、破壞行為、封鎖、火災、閃電、天災、罷工、騷亂、暴動、內亂、防疫限制、流行病、地震、洪水、颶風、爆炸以及監管和行政行動或延誤。如果發生此類不可抗力，Mintsat應採取合理的措施以最大程度地減少干擾和損失，但對於此類不可抗力造成的或附帶的後果不承擔任何責任。
                        </div>
                        <div>
                            7.3
                            用戶同意：Mintsat提供的任何協力廠商連結只是出於便利和參考的作用。Mintsat提供該等協力廠商連結不代表其確認該等連結以及該等連結上顯示的內容。由於該等協力廠商連結不受Mintsat控制，Mintsat無法保證使用者訪問該等連結是安全、不帶病毒的。使用者訪問該等協力廠商連結以及任何其他非受Mintsat控制的協力廠商連結導致的損失Mintsat不負任何責任。
                        </div>
                        <div>
                            7.4 用戶瞭解並同意：除非Mintsat事先明確書面同意，否則在任何情況下，Mintsat亦不就以下事項以及用戶由於以下事項導致的損失承擔責任：
                        </div>
                        <div>（1）交易利潤損失；</div>
                        <div>
                            （2）服務中斷（該等中斷非因Mintsat故意不當行為導致）；
                        </div>
                        <div>
                            （3）非因Mintsat故意不當行為導致的資訊/資料的損壞或丟失；
                        </div>
                        <div>
                            （4）算力消亡或退出市場；
                        </div>
                        <div>
                            （5）法律法規及政策因素的執行或變化；
                        </div>
                        <div>
                            （6）根據使用者提供的資訊（包括但不限於身份識別資訊、財務資訊、數字資產錢包位址等），用戶不符合Mintsat的合規義務和/或內部風控政策；
                        </div>
                        <div>
                            （7）使用者過錯或違反本協定導致的損失（包括但不限於：向Mintsat提供不準確的資訊，向協力廠商洩漏密碼等）；及其他不受Mintsat控制的事件。
                        </div>
                        <div>
                            7.5
                            您同意並接受：在與本協定規定的任何其他補償或賠償權利不重複的前提下，您應向公司及其母公司、子公司和附屬公司，以及其各自的管理人員、董事、雇員、代理人和顧問賠償您違反本協定（包括但不限於您違反本協定項下的任何義務、陳述或保證）導致的任何損失、責任、索賠、指控、行動、訴訟或費用（統稱“損失”，包括合理的律師費），並就此向公司及其母公司、子公司和附屬公司，以及其各自的管理人員、董事、雇員、代理人和顧問提供辯護，保證公司及其母公司、子公司和附屬公司，以及其各自的管理人員、董事、雇員、代理人和顧問不因此遭受該等損害。在任何情況下，公司或其母公司、子公司或附屬公司，或其各自的管理人員、董事、雇員、代理人或顧問均不對您或任何協力廠商承擔任何與本協定有關的特殊、偶然、間接或結果性的損害或利潤損失。
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>八、其他約定</div>
                        <div>
                            8.1 棄權：公司對本協定項下使用者的任何違約行為延遲行使或者疏於行使公司的任何權利或救濟，不應解釋為公司豁免或者默許任何該等違約行為。
                        </div>
                        <div>
                            8.2 可分割性：若本協定的任何條款被認定不合法或者不可執行，則該認定應不影響本協定的其餘條款。
                        </div>
                        <div>
                            8.3 適用法律：本協定應全面適用英屬維京群島法律，但不考慮其法律衝突原則。
                        </div>
                        <div>
                            8.4 爭議解決：如果各方在本協定履行過程中發生任何爭議,
                            如協商不成，任何一方有權提交由新加坡國際仲裁中心仲裁，並按照提交仲裁時有效的《新加坡國際仲裁中心仲裁規則》最終解決。仲裁地為新加坡。 仲裁庭應由三 (3) 名仲裁員組成。
                            仲裁語言為英語。仲裁裁決是終局的，對雙方均具有約束力。除非仲裁裁決另行裁定，仲裁費應由敗訴一方負擔。敗訴方還應補償勝訴方的律師費等支出。
                        </div>
                        <div>
                            8.5 完整協議。 本協定構成您與 Mintsat 之間的完整法律協定，並約束您對服務的使用，並完全取代您與 Mintsat 之前就服務達成的任何協定。
                        </div>
                        <div>
                            8.6 版本：本協定英文版本效力優先適用。
                        </div>
                    </div>
                    :
                    <div className="textContent">
                        <div>This Mintsat Service Agreement (the “Agreement”) is an agreement between Mintsat (“Mintsat” or “we”) and the users (the “User” or “you”) who uses the services provided by Mintsat and/or the official website of Mintsat (the “Website”). This Agreement provides for the rights and responsibilities of both Mintsat and the User. By using the services provided by Mintsat and/or the Website, you acknowledge that you agree to all the terms of this Agreement. If you do not agree to any term of this Agreement, please do not use the services provided by Mintsat and the Website. In the event that you purchase any product provided by Mintsat, you shall agree to the service agreement of that product as well.</div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>Ⅰ. General Terms</div>
                        <div>
                            1.1 Mintsat reserves the right to change or modify the terms of this Agreement at any time at its sole discretion. It is your responsibility to periodically review this Agreement for the latest changes. Any changes or modifications of this Agreement shall take effect once it is published on the Website (unless the effective time is otherwise prescribed). You agree that by using the services provided by Mintsat and/or the Website following any changes to this Agreement, you are agreeing to accept such changes.
                            <br />
                            1.2 If you have any objections to the changes to this Agreement or if you are dissatisfied with the services provided by Mintsat and/or the Website, you should immediately: (1) stop using the services provided by Mintsat and the Website; (2) inform Mintsat to stop providing service to you. You agree that once the service is terminated, User’s right to use the services provided by Mintsat is terminated immediately.
                        </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>II. User Registration</div>
                        <div>
                            2.1 You hereby represent and warrant to Mintsat as follows, which representations and
                            warranties will be deemed repeated each time you use the services provided by Mintsat:
                       </div>
                        <div>
                            (1) if you are a natural person: you are at least 18 years old of age and have full civil
                            capacity;
                       </div>
                        <div>
                            (2) if you are an entity: you are duly organized and validly existing under the laws of the
                            jurisdiction of your organization or incorporation and, if relevant under such laws, in good
                            standing;
                       </div>
                        <div>
                            (3) your use of the services provided by Mintsat complies with the applicable laws and
                            regulations in your country/region, and your use of the services provided by Mintsat does
                            not violate your obligations to any third party;
                       </div>
                        <div>
                            (4) you have the requisite power to use the services provided by Mintsat and perform your
                            corresponding obligations, and the Agreement and the corresponding purchase order(s) are
                            valid and binding on you.
                       </div>
                        <div>
                            2.2 When providing the registration data and information (the “Registration Information”),
                            you hereby agree that:
                       </div>
                        <div>
                            (1) you will provide legal, truthful, accurate and complete Registration Information;
                       </div>
                        <div>
                            (2) you will timely notify Mintsat if there is any change to the Registration Information
                            provided by you; if the Registration Information provided by you is illegal, untrue,
                            inaccurate or incomplete, you shall bear all the corresponding responsibilities and
                            consequences;
                       </div>
                        <div>
                            (3) Mintsat reserves the right to impose certain restrictions on your account and/or suspend
                            or terminate your use of the services provided by Mintsat based on the Information provided
                            by you to Mintsat in accordance with Mintsat’s compliance obligations and internal risk
                            control policy;
                       </div>
                        <div>
                            (4) Unless obtained express written consent from Mintsat, you shall only register for one
                            account at Mintsat;
                       </div>
                        <div>
                            (5) You will be required to enter your username and password when using certain features of
                            the services provided by Mintsat. If you are unable to access your account for reasons such
                            as you forget the password, Mintsat reserves the right to request specific information from
                            you, including but not limited to proof of identity; proof of residence; telephone number
                            /Email proof and any identifiable activity information on the Website, such as transaction
                            ID, order number, withdrawal information, etc.; however, you hereby agree that you will not
                            share your username and/or password with any third party or allow any third party to access
                            your account. Mintsat is not responsible for any consequences and losses caused by your
                            improper use of your account and/or password or any third party’s use of your account and/or
                            password. If you believe that your username and/or password are known to any third party or
                            that your account has been accessed by any third party, you should notify Mintsat
                            immediately.
                       </div>
                        <div>
                            2.3 You understand and acknowledge that Mintsat has the right to provide all or part of your
                            registration information in accordance with relevant laws and regulations or the
                            requirements of regulatory authorities.
                       </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>III. Risk Factors</div>
                        <div>
                            3.1 You understand and acknowledge that there are risks associated with holding, trading,
                            investing in digital assets and use of digital asset related services. The risks listed in
                            this Section 3.1 are not exhaustive. These risks, and additional risks that may occur now or
                            in the future, may prevent you from obtaining any gains, may cause you to suffer financial
                            losses, and may even terminate the services we provide:
                       </div>
                        <div>
                            (1) Use of any digital assets or related product and service carries potential financial,
                            regulatory and other risks. Before using any digital asset or related products and services,
                            you should confirm that you have sufficient knowledge and experience in the features and
                            risks of blockchain technology, digital asset, and smart contracts. Your decision to
                            participate in any digital asset activity and/or use of related services should be based on
                            independent study and/or consultation with professionals as you deem necessary. Mintsat
                            assumes that when you use the services provided by Mintsat, you are a professional who
                            understands the digital asset industry and understands the risks of digital asset related
                            products and service. This Agreement and any services provided by Mintsat should not be
                            deemed as Mintsat’s providing you with investment advice;
                       </div>
                        <div>
                            (2) The value of any product or service provided by Mintsat and the value of any digital
                            asset may be affected by many factors not controlled by Mintsat, including but not limited
                            to mining difficulty and/or other changes in mining parameters/attributes, market price
                            fluctuations in digital assets, hardware (i.e. mining machines) obsolescence and hardware
                            amortization, etc.;
                       </div>
                        <div>
                            (3) Due to the nature of the digital asset, the value of any digital asset may lose some or
                            all of its value at any time. You are aware of the fact that the value of any digital asset
                            can decrease rapidly (and even decrease to zero). Mintsat is not responsible for any and all
                            losses caused by the market price fluctuations of any digital asset;
                       </div>
                        <div>
                            (4) Mintsat will maintain or upgrade the system periodically. You hereby confirm that you do
                            not expect that your use of the Services provided by Mintsat is continuous, without any
                            interruption. Unless otherwise expressly prescribed, Mintsat is not responsible for any and
                            all losses caused by the suspension and interruption due to Mintsat’s maintenance and
                            upgrading of its system;
                       </div>
                        <div>
                            (5) No authentication or computer security technology is 100% secure and safe. You agree to
                            bear all risks related to hacking or identity theft;
                       </div>
                        <div>
                            (6) You understand and agree that Mintsat reserves the right to post, modify, and/or provide
                            information related to our service through the Website, emails, customer service calls, SMS
                            and other official channels. Mintsat is not responsible for information obtained through
                            non-official channels of Mintsat. If you have any question related to the truthfulness of
                            information sent in the name of Mintsat, please contact us immediately.
                       </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>IV. Use of Digital Assets</div>
                        <div>
                            4.1 You may not mine, purchase, sell, exchange, hold and/or use any type of digital asset in
                            any manner that is prohibited by the laws and regulations applicable to you.
                       </div>
                        <div>
                            4.2 Before purchasing any Service Plans, you shall evaluate if the Service Plan is suitable
                            for your financial capabilities and risk preferences. By purchasing any Service Plan
                            provided by Mintsat, you acknowledge that you understand and accept all the risks associated
                            with mining, purchasing, selling, exchanging, holding and/or using digital assets, including
                            but not limited t
                       </div>
                        <div>
                            (1) Large price fluctuations: prices of any digital asset, including Bitcoin, may fall
                            sharply, or even fall to zero;
                       </div>
                        <div>
                            (2) Digital asset transactions may take a while to be confirmed. Although very unlikely, it
                            does exist the situation when some digital asset transactions may never be confirmed and
                            unconfirmed digital asset transactions mean such transactions are not completed;
                       </div>
                        <div>
                            (3) Digital asset transactions are irreversible: if you send any amount of digital assets to
                            any wrong person or digital asset wallet address, you may not be able to cancel the
                            transaction or recover these funds;
                       </div>
                        <div>
                            (4) If you lose or forget any PIN or password required to access and use the digital assets,
                            the digital assets may be lost or unavailable to you;
                       </div>
                        <div>
                            (5) Blockchain (or digital asset) technology may have unknown inherent technical defects;
                       </div>
                        <div>
                            (6) Each country may introduce and promulgate new laws, regulations, and policies that
                            affect the use of digital assets.
                       </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>V. Restrictions and Obligations</div>
                        <div>
                            5.1 You shall not use the services provided by Mintsat to engage in any activities that
                            violate applicable laws and regulations. You shall not use the Services provided by Mintsat
                            to
                       </div>
                        <div>
                            (1) support, incite or participate in any terrorist activities;
                       </div>
                        <div>
                            (2) participate in any money laundering activities;
                       </div>
                        <div>
                            (3) fabricate or distort facts, spread rumors, and disrupt social order;
                       </div>
                        <div>
                            (4) promote or produce obscene, violent and/or terrorist content; or
                       </div>
                        <div>
                            (5) participate in any other activities that violate applicable laws and regulations.
                       </div>
                        <div>
                            5.2 You agree to comply with all applicable laws and regulations, this Agreement and all
                            rules and policies announced by Mintsat from time to time regarding the use of the Website
                            and the services provided by Mintsat.
                       </div>
                        <div>
                            5.3 You shall be fully liable for the security of your account password (or other security
                            devices). Any application or order submitted through your account is deemed to have been
                            executed and agreed upon by yourself. If your account, password or other information is used
                            fraudulently or illegally due to your negligence, we are not responsible for any risks and
                            losses arising therefrom. If you suspect that your account, password or other information
                            has been fraudulently or illegally used, you shall notify us immediately.
                       </div>
                        <div>
                            5.4 You agree and represent that the source of funds used to purchase Mintsat’s products is
                            legal and compliant. You agree that you shall fully assume the relevant tax obligations
                            arising from the use of the services provided by Mintsat.
                       </div>
                        <div>
                            5.5 You agree and represent that you are using the services provided by Mintsat only for
                            your own benefit and that you are not using the services provided by Mintsat on behalf of
                            others or for the benefit of any third parties.
                       </div>
                        <div>
                            5.6 You agree to contact Mintsat immediately if you find or suspect any fraud or misconduct
                            by any third party using the services provided by Mintsat.
                       </div>
                        <div>
                            5.7 Unless obtained prior written consent from Mintsat, you may not register multiple
                            accounts on the Website in any circumstances. You shall not register multiple accounts in
                            order to obtain profits, rewards or coupons.
                       </div>
                        <div>
                            5.8 You should not participate in any activities that may affect the services provided by
                            Mintsat and/or the security of the Website.
                       </div>
                        <div>
                            5.9 You shall not use any method (such as Proxy, Tor, VPN, etc.) to block your Internet
                            traffic and IP addresses or use other technical services that may hide the user's real
                            Internet connection.
                       </div>
                        <div>
                            5.10 Intellectual property rights of any content displayed on the Website, including but not
                            limited to articles, pictures, news, materials, website structure, website layout, website
                            design, unless otherwise specified, are entirely owned by Mintsat or Mintsat’s licensor (if
                            any). The User shall respect the intellectual property rights of Mintsat and Mintsat’s
                            licensor (if any).
                       </div>
                        <div>
                            5.11 Mintsat hereby grants you a limited, non-exclusive, revocable, non-transferable and
                            non-sublicensable license during the term of this Agreement to use the services we provide
                            under this Agreement. Mintsat or its licensor reserve all rights, titles and interests in
                            and to the Service, including all source code, object code, data, information, and their
                            copyright, trademark, patent, invention and trade secrets, as well as all other rights not
                            expressly granted to you under this Agreement. This Agreement does not constitute a waiver
                            of any Mintsat's rights under the intellectual property law of the British Virgin Islands or
                            any other jurisdiction or any other federal, state or foreign law.
                       </div>
                        <div>
                            5.12 The User shall neither engage in any activities in the name of Mintsat nor use the
                            information/data on the Website without prior written authorization from Mintsat.
                       </div>
                        <div>
                            5.13 For any data or information that we provide to you during the period in which you use
                            our Product(s), you agree:
                       </div>
                        <div>
                            (1) that such data or information is only used for the purpose specified in this Agreement
                            and in compliance with all applicable laws;
                       </div>
                        <div>
                            (2) not to transfer, transmit, display or otherwise disclose or make available such data or
                            information to third parties except required by Applicable Laws; and
                       </div>
                        <div>
                            (3) not to improve or modify such data or information, nor to use such data or information
                            to make derivatives or use in combination with any other information without Mintsat's prior
                            written consent.
                       </div>
                        <div>
                            5.14 You hereby represent and warrant that your use of the services provided by Mintsat will
                            not negatively affect Mintsat's reputation or cause Mintsat to assume legal responsibility
                            or other penalties, fines, and sanctions.
                       </div>
                        <div>
                            5.15 You hereby understand that the services provided by Mintsat are not available to the
                            following (each, a "Restricted Person"): 1) residents in the following countries or regions:
                            Mainland China, Crimea region, Cuba, Iran, North Korea and Syria; 2) any entities or
                            individuals that are restricted under applicable trade sanctions and export compliance laws;
                            or 3) any entities or individuals that are restricted under Mintsat’s compliance obligations
                            and/or internal risk control policies. As to the users who use the miner sharing services
                            provided by Mintsat, the Restricted Person shall also include the residents in the United
                            States. The above list is not exhaustive. Before using the services provided by Mintsat,
                            please confirm that you are not a Restricted Person. In the event that you are a Restricted
                            Person and you use the services provided by Mintsat, all legal risks and liabilities caused
                            by or arising from such usage shall be entirely borne by you, and Mintsat shall have the
                            right to refuse to provide services to you, and you shall not be entitled to ask Mintsat to
                            provide any refund.
                       </div>
                        <div>
                            5.16 If the User violates any provisions of this Section 5, Mintsat shall have the right to
                            immediately suspend or terminate the service provided to the User, and Mintsat shall have
                            the right to take all necessary legal means within the scope permitted by the applicable
                            laws and regulations.
                       </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>VI. Liability for Breach of Agreement</div>
                        <div>
                            6.1 For any violation of this Agreement by the User (including but not limited to the User
                            fails to pay the Plan Fee in time; the User's use of the services provided by Mintsat is
                            illegal in the country/region where the User is located, etc.), Mintsat shall have the right
                            to immediately suspend or terminate the services provided to the User and take all necessary
                            legal means within the scope permitted by applicable laws and regulations, including but not
                            limited to holding the User liable for the relevant loss suffered by Mintsat.
                       </div>
                        <div>
                            6.2 If Mintsat reasonably suspects that the User uses the services provided by Mintsat in an
                            illegal, fraudulent, or other improper manner, Mintsat shall have the right to suspend the
                            provision of the services to the User until the User can provide proof of compliance to
                            Mintsat’s satisfaction.
                       </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>VII. Limitation of Liability</div>
                        <div>
                            7.1 The limitation of Mintsat's liability for the products provided by Mintsat is subject to
                            the service agreement of such product. If there is no special agreement, Mintsat's full
                            responsibility to the User shall not exceed the total fee paid by the User to Mintsat.
                       </div>
                        <div>
                            7.2 Mintsat shall not be liable for service suspension or termination occurring by reason of
                            circumstances beyond its control, including but not limited to war (declared or undeclared),
                            terrorist activities, acts of sabotage, blockade, fire, lightning, acts of god, national
                            strikes, riots, insurrections, civil commotions, quarantine restrictions, epidemics,
                            earthquakes, floods, hurricanes, explosions and regulatory and administrative actions or
                            delays. In the event of such force majeure, Mintsat shall take reasonable steps to minimize
                            interruptions and loss but shall have no liability caused by or incidental to such force
                            majeure.
                       </div>
                        <div>
                            7.3 The User agrees that any third-party links provided by Mintsat is for convenience and
                            reference only. Mintsat's provision of these third-party links does not mean that it
                            confirms these links and the content displayed on these links. Since these third-party links
                            are not controlled by Mintsat, Mintsat cannot guarantee that User's access to such links is
                            safe and virus-free. Mintsat is not responsible for any loss caused by the User’s accessing
                            such third-party links and any other third-party links not controlled by Mintsat.
                       </div>
                        <div>
                            7.4 The User hereby understands and agrees: unless it is expressly agreed in writing by
                            Mintsat in advance, under any circumstances Mintsat shall not be liable for the following
                            events and the User's losses due to the following events:
                       </div>
                        <div>(1) loss of trading profits;</div>
                        <div>
                            (2) service interruption (such interruption is not caused by Mintsat’s intentional
                            misconduct);
                       </div>
                        <div>
                            (3) damage or loss of information/data not caused by Mintsat ’s intentional misconduct;
                       </div>
                        <div>
                            (4) the computing power dies or withdraws from the market;
                       </div>
                        <div>
                            (5) implementation or change of laws, regulations and policy factors;
                       </div>
                        <div>
                            (6) that the User is restricted under Mintsat’s compliance obligations and/or internal risk
                            control policies based on the information provided by the User (including but not limited to
                            the Personally Identifiable Information, financial information and digital asset wallet
                            address);
                       </div>
                        <div>
                            (7) loss caused by the User's fault or violation of this Agreement (including but not
                            limited to providing inaccurate information to Mintsat, leaking password to third parties,
                            etc.) ; other events not controlled by Mintsat.
                       </div>
                        <div>
                            7.5 You agree and accept that: without duplication of any other rights to recovery or
                            indemnity set forth in this Agreement, you shall indemnify and defend Mintsat, its parents,
                            subsidiaries, and affiliates, and each of their respective officers, directors, employees,
                            agents and advisors, against, and shall hold Mintsat, its parents, subsidiaries, and
                            affiliates, and each of their respective officers, directors, employees, agents and
                            advisors, harmless from, any loss, liability, claim, action, suit, or expense (collectively,
                            “Losses”, including reasonable attorney’s fee) caused by or resulting from your breach of
                            this Agreement (including but not limited to your breach of any obligation, representation
                            or warranty under this Agreement). In no event shall Mintsat, its parents, subsidiaries, or
                            affiliates, or each of their respective officers, directors, employees, agents or advisors,
                            be liable to you or any third party for any special, incidental, indirect, or consequential
                            damages or damages for loss of profits arising out of or in connection with this Agreement.
                       </div>
                        <div style={{ fontSize: 21, fontWeight: 600, color: '#1d1d27', padding: '36px 0 20px' }}>VIII. Miscellaneous</div>
                        <div>
                            8.1 Waiver: no delay or omission as to the exercise of any right or power accruing upon any
                            default by the User shall impair Mintsat’s exercise of any right or power or shall be
                            construed to be a waiver of any default or acquiescence therein.
                       </div>
                        <div>
                            8.2 Severability: should any provision of this Agreement be determined to be illegal or
                            unenforceable, such determination shall not affect the remaining provisions of this
                            Agreement.
                       </div>
                        <div>
                            8.3 Governing Law: this Agreement shall be governed in all respects by the laws of British
                            Virgin Islands without regard to conflicts of law principles.
                       </div>
                        <div>
                            8.4 Dispute Resolution: if there is any dispute between the parties in the performance of
                            this Agreement, and if the negotiation fails, either party has the right to submit the
                            dispute to Singapore International Arbitration Centre for arbitration and the dispute shall
                            be finally settled in accordance with Arbitration Rules of the Singapore International
                            Arbitration Centre in effect at the time of submission for arbitration. The seat of the
                            arbitration shall be Singapore. The Tribunal shall consist of three (3) arbitrators. The
                            language of the arbitration shall be English. The arbitral award is final and binding on
                            both parties. Unless the arbitral award is otherwise determined, the arbitration fee shall
                            be borne by the losing party. The losing party shall also compensate the winning party’s
                            legal fees and other expenses.
                       </div>
                        <div>
                            8.5 Replacement of Prior Agreements: this Agreement replaces and supersedes all prior
                            agreements between you and Mintsat with respect to the subject matter provided for herein.
                       </div>
                        <div>
                            8.6 Version: the English version of this Agreement shall prevail.
                       </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Termsandconditions;