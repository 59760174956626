import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {  createNotifyStation, SubscribeMatchType, useMiracleObserver, getStore, initStore } from 'miracle-observer';
import './App.css';
import appServices from "./services";

import stations from './stations';
const { stationDefault } = stations;

const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
    // example
    store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);
let load = true;

function UserOrder(props) {
    let history = useHistory();
    const [languageInfo] = useState(() => {
        return getStore('languageInfo');
    });
    const [orderType, setOrderType] = useState(0);
    const [productBuyList, setProductBuyList] = useState(() => {
        return getStore('productBuyList');
    });
    const [productIncomeList, setProductIncomeList] = useState(() => {
        return getStore('productIncomeList');
    });
    const [productOpen, setProductOpen] = useState(false);
    const [productInfo, setProductInfo] = useState({ buyTime: "" });

    useMiracleObserver(
        'productBuyList,updateProductBuyBtcSuccess,queryProductIncomeList',
        (key, value, store) => {
            if (key === 'productBuyList') {
                setProductBuyList(value);
            } else if (key === 'updateProductBuyBtcSuccess') {
                appServices.queryProductBuyList();
            } else if (key === 'queryProductIncomeList') {
                setProductIncomeList(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    const clickProduct = (item) => {
        setProductOpen(true);
        setProductInfo(item);
    }

    function returnUnit() {
        if (productInfo.productType === "FIL") {
            return ""
        } else {
            return "$"
        }
    }

    function returnCoin() {
        if (productInfo.productType === "FIL") {
            return "FIL"
        } else {
            return ""
        }
    }

    return (
        <div className="userContent">
            <div className="userTitle">{languageInfo["order" + getStore("language")]}</div>
            <div style={{ padding: '12px 0' }}>
                {/*
                <div className="flexCenter">
                    <div
                        onClick={() => {
                            setOrderType(0);
                        }}
                        className="userTab canClick"
                        style={{ borderRadius: '8px 0 0 8px', borderRight: 0, paddingLeft: 36 }}
                    >
                        <div style={{ height: 3 }}></div>
                        <div style={{ color:  orderType === 0 ? "#222" : '#888' }}>{languageInfo["CloudHashrateOrder" + getStore("language")]}</div>
                        <div style={{ height: 3, background: orderType === 0 ? '#be8fff' : 'transparent', borderRadius: 3 }}></div>
                    </div>
                    <div
                        onClick={() => {
                            setOrderType(1);
                            // appServices.chargePower()
                            if (!searchIncome) {
                                setSearchIncome(true);
                                appServices.queryProductIncomeList({
                                    auto: true
                                });
                            }
                        }}
                        className="userTab canClick"
                        style={{ borderRadius: '0 8px 8px 0', borderLeft: 0, paddingRight: 36 }}
                    >
                        <div style={{ height: 3 }}></div>
                        <div style={{ color:  orderType === 1 ? "#222" : '#888' }}>{languageInfo["AutoRenewElectricityOrder" + getStore("language")]}</div>
                        <div style={{ height: 3, background: orderType === 1 ? '#be8fff' : 'transparent', borderRadius: 3 }}></div>
                    </div>
                </div>
                */}
                {orderType === 0 ?
                    <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden", marginTop: 16 }}>
                        <div className="flexCenter" style={{ height: 56, paddingLeft: 40, background: '#faf9fe', color: '#a3a3a3' }}>
                            <div style={{ width: "15%" }}>{languageInfo["OrderDate" + getStore("language")]}</div>
                            <div style={{ width: "10%" }}>{languageInfo["OrderID" + getStore("language")]}</div>
                            <div style={{ width: "20%" }}>{languageInfo["ProductName" + getStore("language")]}</div>
                            <div style={{ width: "12.5%" }}>{languageInfo["Duration" + getStore("language")]}</div>
                            <div style={{ width: "12.5%" }}>{languageInfo["TotalOrder" + getStore("language")]}</div>
                            <div style={{ width: "15%" }}>{languageInfo["StartTime" + getStore("language")]}</div>
                            <div style={{ width: "15%" }}>{languageInfo["EndTime" + getStore("language")]}</div>
                        </div>
                        {productBuyList.map(item => (
                            <div onClick={() => { clickProduct(item); }} key={`productBuyList${item.buyId}`} className="flexCenter canClick" style={{ height: 56, paddingLeft: 40, color: '#1d1d27', borderTop: "2px solid #faf9fe" }}>
                                <div style={{ width: "15%" }}>{item.buyTime.split("T")[0]}</div>
                                <div style={{ width: "10%" }}>{item.buyId}</div>
                                <div style={{ width: "20%" }}>{item.productName}</div>
                                <div style={{ width: "12.5%" }}>{item.productDays}{languageInfo["Days" + getStore("language")]}{item.outputType.indexOf("FIL") >= 0 ? "+180" + languageInfo["Days" + getStore("language")] : ""}</div>
                                <div style={{ width: "12.5%" }}>{item.outputType.indexOf("FIL") >= 0 ? item.buyAmount + "T" : item.outputType === "BTC" || item.outputType === "KAS" ? item.buyAmount + "TH/s" : parseFloat(item.productHistory.split("/")[2]) * item.buyAmount + "c/s"}</div>
                                <div style={{ width: "15%" }}>{new Date(item.startTime).toLocaleDateString()}</div>
                                <div style={{ width: "15%" }}>{new Date(new Date(item.startTime).getTime() + item.productDays * 24 * 60 * 60 * 1000).toLocaleDateString()}</div>
                            </div>
                        ))}
                    </div>
                    :
                    <div style={{ border: '1px solid #f5f3fe', borderRadius: 16, overflow: "hidden", marginTop: 16 }}>
                        <div className="flexCenter" style={{ height: 56, paddingLeft: 40, background: '#faf9fe', color: '#a3a3a3' }}>
                            <div style={{ width: "20%" }}>{languageInfo["OrderDate" + getStore("language")]}</div>
                            <div style={{ width: "20%" }}>{languageInfo["OrderID" + getStore("language")]}</div>
                            <div style={{ width: "30%" }}>{languageInfo["ProductName" + getStore("language")]}</div>
                            <div style={{ width: "30%" }}>{languageInfo["TotalOrder" + getStore("language")]}</div>
                        </div>
                        {productIncomeList.map(item => (
                            <div key={`1productIncomeList${item.incomeId}`} className="flexCenter" style={{ height: 56, paddingLeft: 40, color: '#1d1d27' }}>
                                <div style={{ width: "20%" }}>{item.incomeTime.split("T")[0]}</div>
                                <div style={{ width: "20%" }}>{item.buyId}</div>
                                <div style={{ width: "30%" }}>{item.productName} {languageInfo["Plan" + getStore("language")]}</div>
                                <div style={{ width: "30%" }}>{item.discountPower}U</div>
                            </div>
                        ))}
                    </div>
                }
            </div>

            <div className="scaleOpen" style={{ pointerEvents: productOpen ? 'all' : 'none', background: productOpen ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <div
                    className="loginBg"
                    style={{
                        transitionDuration: '0.5s', opacity: productOpen ? 1 : 0, transform: productOpen ? 'scale(1)' :'scale(0.8)',
                        width: 880,
                        background: '#ffffff',
                    }}
                >
                    <img
                        className="canClick"
                        style={{ width: 20, height: 20, position: 'absolute', right: 30, top: 20 }}
                        src='https://amintsat.s3.ap-southeast-1.amazonaws.com/image/UserClose.png'
                        alt=""
                        onClick={() => {
                            setProductOpen(false);
                        }}
                    />
                    <div style={{ fontSize: 24, marginBottom: 20, position: 'relative' }}>
                        <div>{productInfo.productName}{languageInfo["Plan" + getStore("language")]}</div>
                    </div>
                    <div className="orderInfo">
                        <div className="buyItem">
                            <div>{languageInfo["OrderID" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.buyId}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["OrderStatus" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.status === "end" ? languageInfo["Completed" + getStore("language")] : productInfo.status === 'stop' ? languageInfo["Stop" + getStore("language")] : productInfo.status === 'begin' ? languageInfo["Serving" + getStore("language")] : languageInfo["NoStart" + getStore("language")]}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["TotalOrder" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.outputType && productInfo.outputType.indexOf("FIL") >= 0 ? productInfo.buyAmount + "T" : productInfo.outputType === "BTC" ? productInfo.buyAmount + "TH/s" : productInfo.outputType === "ALEO" ? parseFloat(productInfo.productHistory.split("/")[2]) * productInfo.buyAmount + "c/s" : ""}</div>
                        </div>

                        <div className="buyItem">
                            <div>{languageInfo["OrderDate" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{new Date(productInfo.buyTime).toLocaleDateString()}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["StartTime" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{new Date(productInfo.startTime).toLocaleDateString()}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["EndTime" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{new Date(new Date(productInfo.startTime).getTime() + productInfo.productDays * 24 * 60 * 60 * 1000).toLocaleDateString()}</div>
                        </div>
                        {productInfo.productType !== "NORMAL" && productInfo.productType !== "FREE" ?
                            <div className="buyItem">
                                <div>{languageInfo["PayText14" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}:</div>
                                <div style={{ marginLeft: 8 }}>{returnUnit()}{(productInfo.productPrice / productInfo.productDays).toFixed(4)}{returnCoin()}</div>
                            </div>
                            :
                            <div className="buyItem">
                                <div>{languageInfo["Runtime" + getStore("language")]}</div>
                                <div style={{ marginLeft: 8 }}>100%</div>
                                <div className="canClick" style={{ position: 'relative' }}>
                                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                    <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                        <div className="question">{languageInfo["RuntimeQuestion" + getStore("language")]}</div>
                                        <div className="questionArrow"></div>
                                        <div style={{ height: 30 }}></div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="buyItem">
                            <div>{languageInfo["Electricity" + getStore("language")]}{productInfo.outputType === "ALEO" ? "/" : "/T/"}{languageInfo["Days" + getStore("language")]}:</div>
                            {productInfo.productPower === 0 || productInfo.freePower ?
                                <div style={{ marginLeft: 8 }}>{languageInfo["free" + getStore("language")]}</div>
                                :
                                <div style={{ marginLeft: 8 }}>${productInfo.freePower ? 0 : productInfo.productPower}</div>
                            }
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["PayText12" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.productDays}{languageInfo["Days" + getStore("language")]}{productInfo.outputType === "FIL" ? "+180" + languageInfo["Days" + getStore("language")] : ""}</div>
                        </div>

                        <div className="buyItem">
                            <div>{languageInfo["PayText10" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}> {productInfo.outputType === "ALEO" ? "Aleo(Coinbase)" : productInfo.outputType}</div>
                        </div>
                        <div className="buyItem">
                            <div>{languageInfo["PayoutCycle" + getStore("language")]}</div>
                            <div style={{ marginLeft: 8 }}>{languageInfo["PayoutCycle1" + getStore("language")]}</div>
                        </div>

                        <div className="buyItem">
                            <div>{languageInfo["PayText16" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>Mintsat</div>
                        </div>
                        {productInfo.productType !== "NORMAL" && productInfo.productType !== "FREE" ?
                            <div className="buyItem">
                                <div>{languageInfo["Runtime" + getStore("language")]}</div>
                                <div style={{ marginLeft: 8 }}>100%</div>
                                <div className="canClick" style={{ position: 'relative' }}>
                                    <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                    <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                        <div className="question">{languageInfo["RuntimeQuestion" + getStore("language")]}</div>
                                        <div className="questionArrow"></div>
                                        <div style={{ height: 30 }}></div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {productInfo.productType !== "NORMAL" && productInfo.productType !== "FREE" ?
                            <div className="buyItem">
                                <div>{languageInfo["PayText18" + getStore("language")]}:</div>
                                <div style={{ marginLeft: 8 }}>{returnUnit()}{productInfo.productPrice * productInfo.buyAmount}{returnCoin()}</div>
                            </div>
                            :
                            null
                        }

                        <div className="buyItem">
                            <div>{languageInfo["Share" + getStore("language")]}:</div>
                            <div style={{ marginLeft: 8 }}>{productInfo.productManage}%</div>
                            <div className="canClick" style={{ position: 'relative' }}>
                                <img src="https://amintsat.s3.ap-southeast-1.amazonaws.com/image/MinerQuestion.png" alt="" style={{ height: 12, padding: "0 8px" }} />
                                <div className="hoverShow" style={{ position: 'absolute', bottom: 0, left: 0 }} >
                                    <div className="question">
                                        <span>{languageInfo["ShareQuestion1" + getStore("language")]}</span>
                                        <span>{100 - productInfo.productManage}%</span>
                                        <span>{languageInfo["ShareQuestion2" + getStore("language")]}</span>
                                    </div>
                                    <div className="questionArrow"></div>
                                    <div style={{ height: 30 }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserOrder;